import React, { Component } from 'react'
import {
    Button,
    CircularProgress,
    IconButton,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    LinearProgress,
    withStyles
} from '@material-ui/core'
import {
    NavigateNext,
    NavigateBefore,
    Send,
    Drafts,
    DoneOutline,
    Close
} from '@material-ui/icons'


import { SlideUp } from '../../general/Transitions'
import { VendorPOItems } from '.'
import classNames from 'classnames'
import i18n from 'i18next'


const styles = theme => ({
    action__bar__currentstatus__label: {
        marginRight: theme.spacing(2)
    },
    action__bar__actionButton__current: {
        width: '190px',
        backgroundColor: theme.palette.blue[100],
        '&:hover': {
            backgroundColor: theme.palette.blue[80],
            color: theme.palette.getContrastText(theme.palette.blue[80]),
        },
        color: theme.palette.getContrastText(theme.palette.blue[100]),
    },
    subaction__bar__actionButton: {
        marginTop: theme.spacing() / 2,
        padding: '5px',
        '&:disabled': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    subaction__bar__actionButton__next: {
        backgroundColor: theme.palette.blue[100],
        color: 'white',
        width: '195px',
        '&:hover': {
            backgroundColor: theme.palette.blue[100],
            filter: 'brightness(1.1);'
        }
    },
    subactions__separator: { height: '40px', width: '100%' },
    action__bar__buttons__container: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('xs')]: {
            justifyContent: 'flex-end',
            flexDirection: 'row'
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            flexDirection: 'column'
        },
    },
    vendor__status__icon__preparing: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.red[100],
    },
    vendor__status__icon__requested: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.primary.main,
    },
    vendor__status__icon__ready: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.secondary.main,
    },
    action__bar__currentstatus: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginRight: theme.spacing()
    },
    dialog__paper: {
        width: '100%',
        marginLeft: 0,
        marginRight: 0
    },
    footer__bottons__container: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    dialog__content__container: {
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(),
    },
    dialog__loader__container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing()
    },
    subaction__bar__actionButton__delete: {
        backgroundColor: 'white',
        color: theme.palette.red[90],
        textDecoration: 'underline',
        minWidth: '100px',
        margin: '0 0 5px 5px',
        '&:hover': {
            backgroundColor: 'white',
            filter: 'brightness(1.1);'
        }
    },
    subaction__bar__actionButton__prev: {
        backgroundColor: 'white',
        color: theme.palette.red[90],
        textDecoration: 'underline',
        margin: '0 5px 5px 0',
        '&:hover': {
            backgroundColor: 'white',
            filter: 'brightness(1.1);'
        }
    },
    currentStatus__message__label: {
        textAlign: 'center',
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(2)
    },
    action__bar__missingcondition__label: {
        textAlign: 'center',
        fontWeight: 'bold',
        padding: '4px',
        borderRadius: '4px',
        backgroundColor: 'white',
        color: 'orange'
    },
    action__buttons__container: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    print__component__container: {
        marginTop: theme.spacing(),
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
        },
    }
})



//Current status indicator component
const statusIndicatorStyles = theme => ({
    statusIndicatorContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    statusIndicator__labelsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    rootBar: {
        height: theme.spacing(2)
    },
    bar1Determinate: {
        backgroundColor: theme.palette.blue[70]
    },
    bardeterminate: {
        backgroundColor: theme.palette.grey[200]
    },
    step__label: {
        color: theme.palette.text.secondary
    },
    'step__label--done': {
        color: theme.palette.blue[70]
    }
})


class VendorPOStatusIndicatorComponent extends Component {
    render() {
        const { classes, vendorPO } = this.props;
        let progressPercent = 0,
            stepsDone = 1;
        const statuses = [
            i18n.t("vendorPOStatus.preparing"),
            i18n.t("vendorPOStatus.requested"),
            i18n.t("vendorPOStatus.ready")
        ]

        switch (vendorPO.status.id) {
            case (1):
                progressPercent = 10;
                stepsDone = 1;
                break;
            case (2):
                progressPercent = 50;
                stepsDone = 2;
                break;
            case (3):
                progressPercent = 100;
                stepsDone = 3;
                break;
            case (4):
                progressPercent = 75;
                stepsDone = 3;
                break;
            default:
                break;
        }
        return (
            <div className={classes.statusIndicatorContainer}>
                <LinearProgress
                    variant="determinate"
                    value={progressPercent}
                    thickness={80}
                    classes={{
                        root: classes.rootBar,
                        determinate: classes.bardeterminate,
                        bar1Determinate: classes.bar1Determinate,

                    }} />
                <div className={classes.statusIndicator__labelsContainer}>
                    {statuses.map((status, i) => (
                        <div key={i}>
                            <Typography
                                className={stepsDone >= (i + 1) ? classes['step__label--done'] : classes.step__label}>
                                {status}
                            </Typography>
                        </div>
                    ))}
                </div>
            </div>
        )

    }
}

const VendorPOStatusIndicator = withStyles(statusIndicatorStyles)(VendorPOStatusIndicatorComponent)





class VendorPOActions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            saving: false,
            openActionsDialog: false,
            error: false,
            file: this.props.file,
            vendorPO: this.props.vendorPO
        }
    }

    componentWillReceiveProps (nextProps) {
        this.setState({
            file: nextProps.file,
            vendorPO: nextProps.vendorPO
        })
    }
    

    showActionsDialog = () => {
        this.setState({
            openActionsDialog: true
        })
    }
    hideActionsDialog = () => {
        this.setState({
            openActionsDialog: false,
            enteringPreparing: false
        })
    }

    calculateReceivedItemsCount=()=>{
        const { vendorPO } = this.state

        let received = vendorPO.items.reduce(((total, row) => parseInt(total) + (row.receivedQuantity ? parseInt(row.receivedQuantity) : 0)), 0);

        return received
    }


    calculateQuotedItemsCount = () => {
        const { vendorPO } = this.state

        let quoted = vendorPO.items.reduce(((total, row) => parseInt(total) + (row.quantity ? parseInt(row.quantity) : 0)), 0);

        return quoted
    }

    updateVendorPOItems=(vendorPOItems)=>{
        let newVendorPO = { ...this.state.vendorPO}

        newVendorPO.items = vendorPOItems;

        this.setState({
            vendorPO: newVendorPO
        })
    }


    render() {
        const {
            openActionsDialog,
            loading,
            saving,
            vendorPO,
            error,
            file
        } = this.state,
            { classes } = this.props;
            
        if (!vendorPO || !file) return null;

        let receiving = vendorPO.items.filter(item => item.receivedItems.length > 0).length > 0 

        return (
            <div>
                <div className={classes.action__bar__buttons__container}>
                    {!loading && (
                        <div className={classes.action__bar__currentstatus}>
                            {vendorPO.status.id === 1 && (
                                <Drafts className={classes.vendor__status__icon__preparing} />
                            )}
                            {(vendorPO.status.id === 2 || vendorPO.status.id === 4) && (
                                <Send className={classes.vendor__status__icon__requested} />
                            )}
                            {vendorPO.status.id === 3 && (
                                <DoneOutline className={classes.vendor__status__icon__ready} />
                            )}
                            <Typography color='textSecondary'>{vendorPO.status.name}</Typography>
                        </div>
                    )}
                    <Button
                        size="small"
                        className={classNames(classes.action__bar__actionButton__current)}
                        variant='contained'
                        disabled={loading}
                        onClick={this.showActionsDialog}
                    >
                        {i18n.t('quotes.pos.vendorPOs.changeVendorPOStatus')}
                    </Button>
                </div>


                <Dialog
                    maxWidth={'sm'}
                    open={openActionsDialog}
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    scroll='paper'
                    onExited={this.onExited}
                    TransitionComponent={SlideUp}>
                    <DialogTitle disableTypography={true} >
                        {i18n.t('quotes.actionsBar.vendorPO')} {vendorPO.poNumber}
                        <IconButton color="inherit"
                            disabled={loading}
                            onClick={this.hideActionsDialog}
                            aria-label="Close" className={classes.closeButton}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={{padding:'0'}}>
                        <div className={classes.dialog__content__container}>

                            <VendorPOStatusIndicator vendorPO={vendorPO} />

                            {(loading || saving) && (
                                <div className={classes.dialog__loader__container}>
                                    <CircularProgress  color='secondary' />
                                </div>
                            )}

                            <Typography className={classes.currentStatus__message__label}>
                                {vendorPO.status.id === 1 && i18n.t('actionBarMessages.vendorPOPreparing')}
                                {vendorPO.status.id === 3 && i18n.t('actionBarMessages.vendorPOReady')}
                            </Typography>

                            {vendorPO.status.id >= 2 &&
                                <VendorPOItems
                                    updateVendorPOItems = {this.updateVendorPOItems}
                                    vendorPO={vendorPO}
                                    editable= {vendorPO.status.id === 2 || vendorPO.status.id === 4 ? true : false}
                                />
                            }

                            {(vendorPO.status.id === 2 || vendorPO.status.id === 4) && this.calculateQuotedItemsCount() === this.calculateReceivedItemsCount() && 
                                <Typography className={classes.currentStatus__message__label}>
                                    {i18n.t('actionBarMessages.vendorPORequested')}
                                </Typography>
                            }

                            <div className={classes.action__buttons__container}>

                                {(vendorPO.status.id < 3 || vendorPO.status.id === 4) && (
                                    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                        <Button
                                            size="small"
                                            className={classNames(classes.subaction__bar__actionButton, classes.subaction__bar__actionButton__next)}
                                            variant="contained"
                                            disabled={loading || saving || error}
                                            onClick={()=>this.props.changeToNextStatus(vendorPO)}
                                        >

                                            {vendorPO.status.id === 1 && i18n.t("quotes.pos.vendorPOs.sendToVendorButton")}
                                            {(vendorPO.status.id === 2 || vendorPO.status.id === 4) && i18n.t("quotes.pos.vendorPOs.receivedButton")}

                                            <NavigateNext />
                                            {saving && <CircularProgress color='secondary'  className={classes.buttonSpinner} size={24} />}
                                        </Button>
                                    </div>
                                )}

                            </div>

                        </div>
                        <div className={classes.footer__bottons__container}>
                            {(((vendorPO.status.id === 2 || vendorPO.status.id === 4) && !receiving) || vendorPO.status.id === 3) && (
                                <Button
                                    disabled={loading || saving}
                                    className={classNames(classes.subaction__bar__actionButton, classes.subaction__bar__actionButton__prev)}
                                    onClick={this.props.confirmReturnVendorPO}>
                                    <NavigateBefore />
                                    {(vendorPO.status.id === 2 || vendorPO.status.id === 4) && i18n.t('quotes.pos.vendorPOs.returnToPreparing')}

                                    {(vendorPO.status.id === 3) && i18n.t('quotes.pos.vendorPOs.returnToSentToVendor')}

                                </Button>
                            )}
                        </div>
                    </DialogContent>
                </Dialog>

            </div>
        )
    }
}


export default withStyles(styles)(VendorPOActions)