import React, { Component } from 'react'
import i18n from 'i18next'
import mobiscroll from "@mobiscroll/react";
import moment from 'moment'
import {
    withStyles,
    Dialog,
    DialogTitle,
    Typography,
    IconButton,
    DialogContent,
    Divider,
    Grid,
    Button,
    CircularProgress,
    withMobileDialog,
} from '@material-ui/core'
import {
    KeyboardArrowRight,
    KeyboardArrowLeft,
    Delete,
    Warning
} from '@material-ui/icons'
import { EventFormSub1 } from '.'
import { SlideLeft } from '../general/Transitions'
import { formatDateAsAbsolute } from '../../lib/utils'
import API from '../../lib/api';

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as eventsActions from '../../actions/eventsActions'


mobiscroll.settings = {
    lang: 'en',
    theme: 'ios',
    display: 'bubble'
};

const styles = theme => ({
    userColorElement: {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        marginRight: '8px'
    },
    events__form__title: {
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#ccc',
        color: theme.palette.getContrastText('#ccc')
    },
    button__wrapper: {
        margin: `${theme.spacing()}px 0`,
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.only('xs')]: {
            justifyContent: 'flex-start'
        }
    },
    buttonSpinner: theme.buttonSpinner,
    events__form__title__back: {
        fontSize: '30px'
    },
    form: {
        height: '100%'
    },
    warning__message__icon: {
        marginRight: theme.spacing(1),
        fontSize: '14pt',
        color: theme.palette.orange[100]
    },
    requiredInput: {
        background: '#ffffa3 !important',
        borderBottom: `2px solid ${theme.palette.error.main}`
    },
    alert__paper: {
        maxWidth: '600px',
        // width: '100%',
        overflow: 'inherit',
        margin: '0 5px'
    },
    location__address__warning: {
        color: theme.palette.orange[80],
        fontSize: '16px',
    },
    dialog__paper: {
        width: '100%'
    },
    confirmation__popup__button: {
        width: '100%', height: '100%', borderRadius: 0, color: '#007bff', textTransform: 'initial', fontFamily: 'Roboto'
    }
});


const date = new Date();

class EventForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formType: null,
            user: null,
            deleteElement: null,
            openEventFormSub1: false,
            validating: false,
            saving: false,
            title: props.event ? props.event.title || '' : '',
            description: props.event ? props.event.description || '' : '',
            from: props.event && props.event.from ? props.event.from : date,
            to: props.event && props.event.to ? props.event.to : date,
            client: props.event ? props.event.client || null : null,
            clientContact: props.event ? props.event.clientContact || null : null,
            location: props.event ? props.event.place || null : null,
            type: props.event && props.event.visit ? props.event.visit.type || '' : '',
            objectives: props.event && props.event.visit ? props.event.visit.objectives || '' : '',
            topics: props.event && props.event.visit ? props.event.visit.topics || '' : '',
            results: props.event && props.event.visit ? props.event.visit.results || '' : '',
            nextSteps: props.event && props.event.visit ? props.event.visit.nextSteps || '' : '',
            checkIn: props.event ? props.event.checkIn : null,
            checkOut: props.event ? props.event.checkOut : null,
            showConfirmCloseMissingChangesAlert: false,
            unsavedChanges: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.logedInUser && !this.state.user) {
            API.Users.getUser({ userId: nextProps.logedInUser }).then(respond => {
                this.setState({
                    user: respond.data.records
                })
            })
        }
        let newState = {
            title: nextProps.event ? nextProps.event.title || '' : '',
            description: nextProps.event ? nextProps.event.description || '' : '',
            from: nextProps.event && nextProps.event.from ? nextProps.event.from : date,
            to: nextProps.event && nextProps.event.to ? nextProps.event.to : date,
            client: nextProps.event ? nextProps.event.client || null : null,
            clientContact: nextProps.event ? nextProps.event.clientContact || null : null,
            location: nextProps.event ? nextProps.event.place || null : null,
            type: nextProps.event && nextProps.event.visit ? nextProps.event.visit.type || '' : '',
            objectives: nextProps.event && nextProps.event.visit ? nextProps.event.visit.objectives || '' : '',
            topics: nextProps.event && nextProps.event.visit ? nextProps.event.visit.topics || '' : '',
            results: nextProps.event && nextProps.event.visit ? nextProps.event.visit.results || '' : '',
            nextSteps: nextProps.event && nextProps.event.visit ? nextProps.event.visit.nextSteps || '' : '',
            checkIn: nextProps.event ? nextProps.event.checkIn : null,
            checkOut: nextProps.event ? nextProps.event.checkOut : null,
        },
            unsavedChanges = this.state.unsavedChanges,
            event = this.props.event;


        unsavedChanges = unsavedChanges || this.verifyIfSomethingChanged(event, newState);

        this.setState({
            ...newState,
            unsavedChanges
        })
    }



    onEnter = () => {
        let props = this.props,
            now = moment(),
            date = props.selectedDate ? moment(`${moment(props.selectedDate).format('YYYY-MM-DD')} ${now.format('HH:mm:ss')}`).format() : new Date();

        let newState = {
            title: props.event ? props.event.title || '' : '',
            description: props.event ? props.event.description || '' : '',
            client: props.event ? props.event.client || null : null,
            clientContact: props.event ? props.event.clientContact || null : null,
            location: props.event ? props.event.place || null : null,
            type: props.event && props.event.visit ? props.event.visit.type || '' : '',
            objectives: props.event && props.event.visit ? props.event.visit.objectives || '' : '',
            topics: props.event && props.event.visit ? props.event.visit.topics || '' : '',
            results: props.event && props.event.visit ? props.event.visit.results || '' : '',
            nextSteps: props.event && props.event.visit ? props.event.visit.nextSteps || '' : '',
            checkIn: props.event ? props.event.checkIn : null,
            checkOut: props.event ? props.event.checkOut : null,
            unsavedChanges: false
        }

        this.setState({
            ...newState,
            from: props.event && props.event.from ? moment(props.event.from).utc().format() : date,
            to: props.event && props.event.to ? moment(props.event.to).utc().format() : moment(date).add('15', 'minutes').format(),
        }, () => {
            // if (!this.state.title) {
            //     this._titleText.focus();
            // }
        })
    }

    manageEvent = (status) => {
        const { title, description, objectives, topics, from, to,
            client, location, clientContact, type, results, nextSteps,
            checkIn, checkOut } = this.state,
            { event } = this.props;

        this.setState({
            validating: true
        })

        if (!title || title === '' || !type || (type !== 'Event' && (!client || !client.id || !clientContact || !clientContact.id || !location || !location.googleAddress))) {
            this.setState({
                showRequiredFieldsError: true
            })
            return
        }

        this.setState({
            validating: false,
            saving: true
        })

        let body = {
            id: event && event.id ? event.id : null,
            title,
            description,
            isVisit: type !== 'Event' ? true : false,
            from: formatDateAsAbsolute(from),
            to: formatDateAsAbsolute(to)
        }

        if (type !== 'Event') {
            body.place = location.address ? location.address : ''
            body.googleAddress = location ? location.googleAddress : null
            body.placeLat = location ? location.lat : null
            body.placeLng = location ? location.lng : null
            body.clientId = client ? client.id : null
            body.clientContactId = clientContact ? clientContact.id : null
            body.visit = {
                objectives,
                topics,
                type,
                results,
                nextSteps,
                status: status ? status : 1
            }
        }

        if (!event || !event.id) {
            API.Events.createEvent({ event: body }).then(res => {
                if (res.data) {
                    this.setState({
                        saving: false
                    })
                    this.props.eventsActions.addEvent(res.data.record)
                    this.props.onClose({ createdOrChangedEvent: res.data.record, action: 'create' })
                } else {
                    this.setState({
                        saving: false,
                        apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                    })
                }
            })
        } else {
            body.visit.id = event.visit.id
            body = {
                ...body,
                checkInAddress: checkIn ? checkIn.address : null,
                checkInLat: checkIn ? checkIn.lat : null,
                checkInLng: checkIn ? checkIn.lng : null,
                checkInDateTime: checkIn ? checkIn.dateTime : null,
                checkOutAddress: checkOut ? checkOut.address : null,
                checkOutLat: checkOut ? checkOut.lat : null,
                checkOutLng: checkOut ? checkOut.lng : null,
                checkOutDateTime: checkOut ? checkOut.dateTime : null,
            }

            API.Events.updateEvent({ event: body }).then(res => {
                if (res.data) {
                    this.props.eventsActions.updateEvent(res.data.record)
                    this.props.onClose({ createdOrChangedEvent: res.data.record, action: 'update' })
                } else {
                    this.setState({
                        apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                    })
                }
            })
        }
    }

    inputChange = (e) => {
        const { event } = this.props;
        let newEvent = { ...event }
        if (e.target.name !== 'objectives' && e.target.name !== 'topics') {
            newEvent[e.target.name] = e.target.value;
        } else {
            //OJO: Cambie esta implementacion porque la anterior modificaba la referencia
            // del objeto visit dentro de event en redux
            let newVisit = { ...newEvent.visit };
            newVisit[e.target.name] = e.target.value;
            newEvent = {
                ...newEvent,
                visit: {
                    ...newVisit
                }
            }
            //Esta implementacion cambia la referencia del objeto y eso esta mal
            // newEvent.visit[e.target.name] = e.target.value
        }

        this.setState({
            unsavedChanges: true
        })
        this.props.eventsActions.setEvent(newEvent)
    }

    dateFromChange = (e, inst) => {
        const { event } = this.props;
        let newEvent = {
            ...event,
            from: moment(inst.getVal()).format('YYYY-MM-DDTHH:mm:ss') + 'Z',
            to: moment(inst.getVal()).add('15', 'minutes').format('YYYY-MM-DDTHH:mm:ss') + 'Z',
        }
        this.setState({
            unsavedChanges: true
        })
        this.props.eventsActions.setEvent(newEvent)
    }

    dateToChange = (e, inst) => {
        const { event } = this.props;

        let newEvent = {
            ...event,
            to: moment(inst.getVal()).format('YYYY-MM-DDTHH:mm:ss') + 'Z',
        }
        this.setState({
            unsavedChanges: true
        })
        this.props.eventsActions.setEvent(newEvent)
    }

    showEventFormSub1 = (type) => {
        this.setState({
            formType: type
        }, () => {
            this.setState({
                openEventFormSub1: true
            })
        })
    }

    deleteCheckIn = () => {
        const { event } = this.props;

        let newEvent = {
            ...event,
            checkIn: {
                address: null,
                dateTime: null,
                lat: null,
                lng: null
            },
            results: '',
            nextSteps: '',
            deleteElement: null,
            checkOut: {
                address: null,
                dateTime: null,
                lat: null,
                lng: null
            }
        }
        this.props.eventsActions.setEvent(newEvent)
        this.refs.deleteCheckInOutConfirmation.instance.hide();
    }

    deleteCheckOut = () => {
        const { event } = this.props;

        let newEvent = {
            ...event,
            deleteElement: null,
            checkOut: {
                address: null,
                dateTime: null,
                lat: null,
                lng: null
            }
        }

        this.props.eventsActions.setEvent(newEvent)
        this.refs.deleteCheckInOutConfirmation.instance.hide();
    }

    confirmDeleteCheckIn = () => {
        this.setState({
            deleteElement: 'checkIn'
        }, () => {
            this.refs.deleteCheckInOutConfirmation.instance.show();
        })
    }

    confirmDeleteCheckOut = () => {
        this.setState({
            deleteElement: 'checkOut'
        }, () => {
            this.refs.deleteCheckInOutConfirmation.instance.show();
        })
    }
    closeConfirmed = () => {
        let { event, events } = this.props;
        this.setState({
            showConfirmCloseMissingChangesAlert: false,
        });
        //Return to previous status due to dismissed changes
        let newEvent = events.find(ev => ev.id === event.id)
        this.props.eventsActions.setEvent(newEvent)
        this.props.onClose();
    }

    verifyIfSomethingChanged = (oldEvent, state) => {
        let somethigChanged = false;
        if (oldEvent && oldEvent.id) {
            //Edit mode
            if (
                oldEvent.title !== state.title ||
                oldEvent.description !== state.description ||
                (oldEvent.client && oldEvent.client.id !== state.client.id) ||
                (oldEvent.clientContact && oldEvent.clientContact.id !== state.clientContact.id) ||
                (state.type && oldEvent.visit.type !== state.type) ||
                (state.objectives && oldEvent.visit.objectives !== state.objectives) ||
                (state.topics && oldEvent.visit.topics !== state.topics) ||
                (state.results && oldEvent.visit.results !== state.results) ||
                (state.nextSteps && oldEvent.visit.nextSteps !== state.nextSteps)) {
                somethigChanged = true;
            } else {
                somethigChanged = false;
            }
        } else {
            //create mode
            if (
                state.title ||
                state.description ||
                state.client ||
                state.clientContact ||
                state.type ||
                state.objectives ||
                state.topics ||
                state.results ||
                state.nextSteps) {
                somethigChanged = true;
            }
        }
        return somethigChanged
    }

    confirmClose = () => {
        let { events, event } = this.props,
            oldEvent = events.find(ev => ev.id === event.id),
            state = this.state,
            somethigChanged = this.verifyIfSomethingChanged(oldEvent, state);

        if (somethigChanged || state.unsavedChanges) {
            this.setState({
                showConfirmCloseMissingChangesAlert: true
            })
        } else {
            this.props.onClose();
        }
    }

    render() {
        const { title, description, formType, validating, saving,
            from, to, client, openEventFormSub1, type, clientContact,
            location, objectives, topics, user, deleteElement } = this.state,
            { classes,
                event,
                fullScreen
            } = this.props;

        const start = moment(from),
            remainder = 15 - (start.minute() % 15),
            minDate = moment(start).add(remainder, "minutes"),
            isVisit = type !== '' && type !== 'Event' ? true : false

        return (
            <div>
                <Dialog
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    scroll='paper'
                    fullScreen={fullScreen}
                    onEnter={this.onEnter}
                    open={this.props.open}
                    onExited={() => {
                        this.setState({
                            saving: false,
                            unsavedChanges: false
                        })
                    }}
                    TransitionComponent={SlideLeft}
                >

                    <DialogTitle disableTypography={true} className={classes.events__form__title}>
                        <IconButton onClick={this.confirmClose}>
                            <KeyboardArrowLeft color='inherit' className={classes.events__form__title__back} />
                        </IconButton>
                        <Typography variant='body1' color="inherit">
                            {event && event.id ? i18n.t('visits.eventForm.updateEventTitle') : i18n.t('visits.eventForm.newEventTitle')}
                        </Typography>
                        <div style={{ width: '30px' }} />
                    </DialogTitle>

                    <DialogContent style={{ padding: '0' }}>
                        <mobiscroll.Form className={classes.form}>
                            <mobiscroll.FormGroup style={event && event.id ? { marginTop: '8px' } : { marginTop: '1.5em' }}>
                                {event && event.id &&
                                    <mobiscroll.FormGroupTitle>
                                        <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '8px' }}>
                                            <div className={classes.userColorElement} style={{ background: event.user.color }} />
                                            {event.user.name}
                                        </div>
                                    </mobiscroll.FormGroupTitle>
                                }

                                <mobiscroll.Form.Label className={validating && (!type || type === '') ? classes.requiredInput : ''}
                                    onClick={() => this.showEventFormSub1('eventType')} style={{ alignItems: 'center' }}>
                                    {i18n.t('visits.eventForm.visitType')}
                                    <input value={type} name='type' disabled />
                                    <KeyboardArrowRight style={{ color: '#ccc' }} />
                                </mobiscroll.Form.Label>
                                <mobiscroll.Form.Label className={validating && (!title || title === '') ? classes.requiredInput : ''}>
                                    {i18n.t('visits.eventForm.title')}
                                    <input
                                        // ref={(comp) => { this._titleText = comp; }}
                                        value={title} autoFocus name='title'
                                        onChange={this.inputChange}/>
                                </mobiscroll.Form.Label>
                                <mobiscroll.Textarea value={description} name='description' onChange={this.inputChange}>
                                    {i18n.t('visits.eventForm.description')}
                                </mobiscroll.Textarea>
                            </mobiscroll.FormGroup>
                            <mobiscroll.FormGroup>
                                <mobiscroll.Form.Label>
                                    <mobiscroll.Datetime
                                        value={from} dateWheels="|D M d|"
                                        onSet={this.dateFromChange}
                                        invalid={[
                                            { start: '00:00', end: '06:00' }, // Every day
                                            { start: '21:00', end: '23:59' }, // Every day
                                        ]}
                                        steps={{
                                            minute: 15,
                                            second: 0,
                                            zeroBased: true
                                        }}>
                                        <mobiscroll.Input id="startDate"
                                        >{i18n.t('visits.eventForm.fromDate')}</mobiscroll.Input>
                                    </mobiscroll.Datetime>
                                </mobiscroll.Form.Label>
                                <mobiscroll.Form.Label>
                                    <mobiscroll.Datetime
                                        value={to}
                                        dateWheels="|D M d|"
                                        onSet={this.dateToChange}
                                        min={minDate}
                                        invalid={[
                                            { start: '00:00', end: '06:00' }, // Every day
                                            { start: '21:15', end: '23:59' }, // Every day
                                        ]}
                                        steps={{
                                            minute: 15,
                                            second: 0,
                                            zeroBased: true
                                        }}
                                    >
                                        <mobiscroll.Input id="endDate">{i18n.t('visits.eventForm.toDate')}</mobiscroll.Input>
                                    </mobiscroll.Datetime>
                                </mobiscroll.Form.Label>
                            </mobiscroll.FormGroup>
                            <mobiscroll.FormGroup>
                                {isVisit && <>
                                    <mobiscroll.Form.Label className={validating && isVisit && (!client || !client.id || !clientContact || !clientContact.id) ? classes.requiredInput : ''}
                                        onClick={() => this.showEventFormSub1('client')} style={{ alignItems: 'center' }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%'
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                width: '100%',
                                                alignItems: 'center',
                                                background: '#fff',
                                            }}
                                                className='mbsc-control-w mbsc-input mbsc-ios mbsc-ltr'>
                                                <div className='mbsc-label'>{i18n.t('visits.eventForm.visitClient')}</div>
                                                <div style={{
                                                    flex: '1 auto',
                                                    padding: '5px 1em',
                                                    color: '#0000008a'
                                                }}
                                                    className='mbsc-input-wrap'>
                                                    {`${client && client.id ? client.name : ''} `}
                                                    {clientContact && clientContact.id && (
                                                        <>
                                                            <br />
                                                            {clientContact && clientContact.id ? `${clientContact.firstName || ''} ${clientContact.lastName || ''}` : ''}
                                                        </>
                                                    )}
                                                </div>
                                                <KeyboardArrowRight style={{ color: '#ccc' }} />
                                            </div>
                                        </div>
                                    </mobiscroll.Form.Label>
                                    {client && client.id &&
                                        <mobiscroll.Form.Label className={validating && isVisit && (!location || !location.googleAddress || !event.locationConfirmed) ? classes.requiredInput : ''}
                                            onClick={() => this.showEventFormSub1('location')} style={{ alignItems: 'center' }}>
                                            {i18n.t('visits.eventForm.visitLocation')}
                                            <input value={location ? location.address : ''} name='location' disabled />
                                            {location && location.address && !event.locationConfirmed && (
                                                <Warning className={classes.location__address__warning} />
                                            )}
                                            <KeyboardArrowRight style={{ color: '#ccc' }} />
                                        </mobiscroll.Form.Label>
                                    }
                                </>}
                            </mobiscroll.FormGroup>

                            {isVisit && <>
                                <mobiscroll.FormGroup>
                                    <mobiscroll.Form.Label className={validating && (!title || title === '') ? classes.requiredInput : ''}>
                                        {i18n.t('visits.eventForm.visitObjectives')}
                                        <textarea rows={3} value={objectives} name='objectives' onChange={this.inputChange} />
                                    </mobiscroll.Form.Label>
                                    {/* <mobiscroll.Textarea value={objectives} name='objectives' onChange={this.inputChange}>
                                        {i18n.t('visits.eventForm.visitObjectives')}
                                    </mobiscroll.Textarea> */}
                                    <mobiscroll.Textarea value={topics} name='topics' onChange={this.inputChange}>
                                        {i18n.t('visits.eventForm.visitTopics')}
                                    </mobiscroll.Textarea>
                                </mobiscroll.FormGroup>

                                {event && event.id && event.checkIn.address && <>
                                    <mobiscroll.FormGroup>
                                        <mobiscroll.Form.Label style={{ alignItems: 'center' }} onClick={() => this.showEventFormSub1('visitReport')}>
                                            {i18n.t('visits.eventForm.visitReport')}
                                            <input value={''} disabled />
                                            <KeyboardArrowRight style={{ color: '#ccc' }} />
                                        </mobiscroll.Form.Label>

                                        <mobiscroll.Form.Label style={{ alignItems: 'center' }}>
                                            {i18n.t('visits.eventForm.checkIn')}
                                            <input disabled value={moment(event.checkIn.dateTime).utc().format('hh:mm a')} />
                                            <Delete style={{ color: '#ccc', marginRight: '5px' }} onClick={this.confirmDeleteCheckIn} />
                                        </mobiscroll.Form.Label>

                                        {event.checkOut.address &&
                                            <mobiscroll.Form.Label style={{ alignItems: 'center' }}>
                                                {i18n.t('visits.eventForm.checkOut')}
                                                <input disabled value={moment(event.checkOut.dateTime).utc().format('hh:mm a')} />
                                                <Delete style={{ color: '#ccc', marginRight: '5px' }} onClick={this.confirmDeleteCheckOut} />
                                            </mobiscroll.Form.Label>
                                        }
                                    </mobiscroll.FormGroup>
                                </>}
                            </>}

                            <mobiscroll.FormGroup>
                                <div style={{ textAlign: 'center', padding: '8px', background: '#fff', position: 'relative' }}>
                                    <mobiscroll.Button color="primary" disabled={saving} onClick={() => this.manageEvent(null)}>
                                        {i18n.t("visits.eventForm.saveEvent")}
                                        {saving && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                    </mobiscroll.Button>
                                </div>
                            </mobiscroll.FormGroup>
                        </mobiscroll.Form>
                    </DialogContent>
                </Dialog>

                <mobiscroll.Popup
                    ref="deleteCheckInOutConfirmation"
                    display="center"
                    buttons={[
                        {
                            text: deleteElement === 'checkIn' ? i18n.t('visits.eventDetails.deleteCheckInButton') : i18n.t('visits.eventDetails.deleteCheckOutButton'),
                            icon: 'delete',
                            handler: deleteElement === 'checkIn' ? this.deleteCheckIn : this.deleteCheckOut
                        },
                        'cancel'
                    ]}
                >
                    <div className="mbsc-align-center mbsc-padding">
                        <Typography variant='body2' color='textSecondary' style={{ display: 'flex' }}>
                            <Warning className={classes.warning__message__icon} />
                            {deleteElement === 'checkIn' ? i18n.t('visits.confirmation.deleteCheckInMessage') : i18n.t('visits.confirmation.deleteCheckOutMessage')}
                        </Typography>
                    </div>
                </mobiscroll.Popup>


                {/* Confirm lose changes */}
                <Dialog
                    open={this.state.showConfirmCloseMissingChangesAlert}
                    classes={{
                        paper: classes.alert__paper
                    }} >
                    <Grid container spacing={1}
                        style={{ padding: '24px 16px' }}
                    >
                        <Grid item xs={12} style={{

                        }}>
                            <Typography variant='body2'
                                color='textSecondary'
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <Warning className={classes.warning__message__icon} />
                                {i18n.t('It looks like you have been editing something.')}
                                <br />
                                <br />
                                {i18n.t('If you leave before saving, your changes will be lost.')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        height: 40
                    }}>
                        <Button
                            variant='text'
                            className={classes.confirmation__popup__button}
                            onClick={this.closeConfirmed}>
                            <Typography variant='body1'>{i18n.t('Leave')}</Typography>
                        </Button>
                        <Divider orientation="vertical" />
                        <Button
                            variant='text'
                            className={classes.confirmation__popup__button}
                            onClick={() => {
                                this.setState({
                                    showConfirmCloseMissingChangesAlert: false,
                                })
                            }}>
                            <Typography variant='body1'>{i18n.t('Stay')}</Typography>
                        </Button>
                    </div>
                </Dialog>

                <EventFormSub1
                    open={openEventFormSub1}
                    type={formType}
                    user={user}
                    isOwn={event && event.id ? this.props.isOwn : true}
                    onClose={() => {
                        this.setState({
                            formType: null,
                            openEventFormSub1: false
                        })
                    }}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        event: state.event,
        events: state.events,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        eventsActions: bindActionCreators(eventsActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(EventForm)));
