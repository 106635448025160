import React, { Component } from 'react'
import {
    Paper,
    Button,
    withStyles,
    Snackbar,
    SnackbarContent,
    Typography,
    Tabs,
    Tab, 
    Link
} from '@material-ui/core'
import {
    Drafts, DoneOutline, Warning
} from '@material-ui/icons'
import i18n from 'i18next';
import classNames from 'classnames'
import { InvoiceForm, InvoiceCard, InvoiceSummary } from '.'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../../actions/generalActions'
import * as filesActions from '../../../actions/filesActions'
//Mientras metemos sagas
import * as apiStatusActions from '../../../actions/apiStatusActions'

import Permissions from '../../../lib/permissions'



const styles = theme => ({
    newQuoteButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: `${theme.spacing(3)}px`,
        flexDirection: 'column'
    },
    newQuoteButtonContainerRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: ` 0 ${theme.spacing()}px 0 ${theme.spacing()}px`
    },
    vendorQuoteContainer: {
        padding: theme.spacing(2),
    },
    quoteDetailsActiveSection: {
        padding: theme.spacing(2)
    },
    vendor__requests__title__container: {
        padding: theme.spacing(),
        position: 'absolute'
    },
    vendor__requests__focused__section: {
        zIndex: 2,
        position: 'relative'
    },
    vendor__requests__focus__hover: {
        backgroundColor: '#000',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: '100%',
        width: '100%',
        opacity: '0.3',
        zIndex: 1
    },
    vendorQuotes__tab: {
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[100]
    },
    vendorQuotes__tab__ready: {
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[100],
        marginLeft: 'auto'
    },
    'vendorQuotes__tab--selected': {
        backgroundColor: '#fff',
        borderTop: `1px solid ${theme.palette.grey[400]}`,
        borderLeft: `1px solid ${theme.palette.grey[400]}`,
        borderRight: `1px solid ${theme.palette.grey[400]}`,
        borderBottom: 'none',
        color: theme.palette.text.primary,
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        zIndex: 10
    },
    vendorQuotes__tabs: {
        margin: `0 ${theme.spacing()}px`
    },
    vendorQuotes__tabs__flexContainer: {
    },
    vendorQuotes__tabs__activeIndicator: {
        backgroundColor: 'transparent',
        zIndex: 11,
        marginLeft: '1px',
        width: 'calc(100% - 2px) !important'
    },
    legend__root__row: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    invoice__status__icon__preparing: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.red[100],
    },
    invoice__status__icon__ready: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.secondary.main,
    },
    error: {
        backgroundColor: theme.palette.primary.main,
    },
    errorLabel: {
        color: theme.palette.getContrastText(theme.palette.primary.main)
    },
    snackbar__root__style: {
        top: '64px',
        [theme.breakpoints.down('xs')]: {
            top: '56px'
        }
    },
    vendorQuotes__rfqItems__container: {
        display: 'flex',
        alignItems: 'center'
    },
    vendor__quote__expand__colapse__button: {
        marginLeft: (theme.spacing() / 2),
        padding: theme.spacing()
    },
    vendorQuotes__rfqItems__paper: {
        // margin: theme.spacing(),
    },
    quoteitems__table: {
        fontSize: theme.typography.fontSize * 0.8,
        fontFamily: theme.typography.fontFamily,
        textAlign: 'left',
        marginTop: theme.spacing(),
        border: `solid thin ${theme.palette.grey[300]}`
    },
    quoteitems__table__row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    quoteitems__table__itemPartNumber: {
        color: theme.palette.naval.main
    },
    quoteitems__table__descriptionResumed: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: '95%',
        cursor: 'pointer'
    },
    quoteitems__table__descriptionExtended: {
        width: '100%',
        cursor: 'pointer'
    },
    quoteitems__table__manufacturer: {
        color: theme.palette.text.secondary
    },
    quoteitems__table__references: {
        color: theme.palette.text.secondary
    },
    vendorRFQ__includedItem__mark: {
        width: '20px',
        height: '20px',
        borderRadius: '50%'
    },
    "item--included": {
        background: theme.palette.green[100]
    },
    "item--noIncluded": {
        background: theme.palette.red[100]
    },
    missingToQuote: {
        color: theme.palette.secondary.main,
        marginLeft: theme.spacing() / 2,
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    missingItems__warning__icon: {
        marginLeft: theme.spacing(),
        fontSize: '14pt',
        color: theme.palette.orange[100],
    },
    action__bar__missingcondition__label: {
        //fontSize: '9pt',
        textAlign: 'right',
        fontWeight: 'bold',
        padding: '4px',
        borderRadius: '4px',
        backgroundColor: 'white',
        color: 'orange'
    },
    action__bar__readyToDeliver__label: {
        justifyContent: 'center',
        fontWeight: 'bold',
        padding: '4px',
        borderRadius: '4px',
        backgroundColor: 'white',
        color: theme.palette.green[100],
        display: 'flex'
    },
    customLink: {
        ...theme.customLink,
        marginLeft: '3px',
        marginRight: '3px'
    },
    warning__message__icon: {
        marginRight: theme.spacing(),
        fontSize: '14pt',
        color: theme.palette.orange[100],
    },
    messages__container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
})


class Invoices extends Component {
    state = {
        showCreateInvoice: false,
        file: this.props.file,
        selectedTab: 0,
        changeStatusMessage: null
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.general.showInvoiceForm){
            this.setState({
                showCreateInvoice: true
            })
            this.props.generalActions && this.props.generalActions.showInvoiceForm(false);
        }

        this.setState({
            file: nextProps.file
        })
    }

    handleCreateNewQuote = () => {
        this.setState({
            showCreateInvoice: true
        })
    }

    handleCloseCreateInvoice = () => {
        this.setState({
            showCreateInvoice: false
        })
    }

    handleChangeTab = (e, value) => {
        this.setState({
            selectedTab: value
        });
    }

    showError = ({ changeStatusMessage }) => {
        this.setState({
            changeStatusMessage: changeStatusMessage
        })
    }

    showVendorsPo=()=>{
        this.props.generalActions && this.props.generalActions.showVendorsPo(true);
    }

    calculateItemsCount = () => {
        const { file } = this.state;

        let invoicedItems = 0;
        file.invoices.forEach((inv) => {
            if (inv.status !== 1) {
                invoicedItems = invoicedItems + inv.items.reduce(((total, row) => parseInt(total) + (row.invoiceQuantity ? parseInt(row.invoiceQuantity || 0) : 0)), 0);
            }
        })

        let itemsReceived = [];

        file.vendorPOs.forEach(vPo => {
            itemsReceived = itemsReceived.concat(vPo.items)
        })

        let receivedItems = itemsReceived.reduce(((total, row) => parseInt(total) + (row.receivedQuantity ? parseInt(row.receivedQuantity) : 0)), 0),
            requestedItems = file.clientQuote[0].clientPO.items.reduce(((total, row) => parseInt(total) + (row.quantity ? parseInt(row.quantity) : 0)), 0),
            deliveredItems = file.clientQuote[0].items.reduce(((total, row) => parseInt(total) + (row.deliveredQuantity ? parseInt(row.deliveredQuantity) : 0)), 0);

        return {
            receivedItems,
            requestedItems,
            invoicedItems,
            deliveredItems
        }
    }

    calculateOtherCostAmount = () => {
        const { file } = this.state;

        let invoiced = 0,
            requested = 0;

        file.invoices.forEach((inv) => {
            if (inv.status !== 1) {
                invoiced = invoiced + inv.otherCosts.reduce(((total, row) => parseInt(total) + (row.invoiceAmount ? parseInt(row.invoiceAmount || 0) : 0)), 0);
            }
        })

        requested = file.clientQuote[0].otherCosts.filter(oc => !oc.weighted).reduce(((total, row) => parseInt(total) + (row.amount ? parseInt(row.amount || 0) : 0)), 0);

        return {
            requested,
            invoiced
        }
    }

    render() {
        const { classes } = this.props,
            {   file,
                showCreateInvoice,
                selectedTab,
                changeStatusMessage
            } = this.state;

        let firstReadyInvoice = false;

        if (!file) return null;

        let counts = {},
            otherCostsAmounts = {};
        if (file.status >= 5) {
            counts = this.calculateItemsCount()
            otherCostsAmounts = this.calculateOtherCostAmount()
        }

        let vendorsPosIsSent = file.vendorPOs.filter(vp => vp.status.id === 1).length !== file.vendorPOs.length

        //Ordenamos por estatus
        let invoices = file.invoices && file.invoices.length > 0 ? file.invoices.sort((a, b) => {
            return a.status - b.status;
        }) : [];

        return (
            <>
                <Paper className={classes.quoteDetailsActiveSection}>

                    {/* crear nuevo Invoice */}
                    <InvoiceForm
                        open={showCreateInvoice}
                        createInvoice={this.createInvoice}
                        handleClose={this.handleCloseCreateInvoice} 
                    />

                    { Permissions.checkSensitiveInformationPermission() && 
                        <div className={(invoices && invoices.length === 0) ? classes.newQuoteButtonContainer : classes.newQuoteButtonContainerRight}>
                            {/* Hay una factura pendiente por enviar */}
                            {file.invoices.filter(inv => inv.status === 1).length > 0 ?
                                <></>
                            :
                                <div style={{marginRight: '16px'}}>
                                    {/* Hay items recibidos que no han sido facturados */}
                                    {counts.receivedItems > 0 && counts.receivedItems - counts.invoicedItems > 0 &&
                                        <Typography className={classes.action__bar__readyToDeliver__label}>
                                            {`${counts.receivedItems - counts.invoicedItems} ${i18n.t('actionBarMessages.itemsNoInvoiced')}`}
                                        </Typography>
                                    }

                                    {/* No se han enviado los POs o recibidos items de los vendors*/}
                                    {counts.receivedItems === 0 &&
                                        <Typography className={classes.messages__container}>
                                            <Warning className={classes.warning__message__icon} />
                                            {vendorsPosIsSent ?
                                                i18n.t('actionBarMessages.vendorPosReadys')
                                                :
                                                i18n.t("actionBarMessages.vendorPosNoSent")
                                            }
                                            <Link className={classes.customLink}
                                                onClick={this.showVendorsPo}>
                                                {vendorsPosIsSent ?
                                                    i18n.t("actionBarMessages.pendingReceiveItemsLink")
                                                    :
                                                    i18n.t("actionBarMessages.pendingSentItemsLink")
                                                }
                                            </Link>
                                        </Typography>
                                    }


                                    {/* Faltan cosas que recibir de los vendors*/}
                                    { counts.receivedItems !== 0 && counts.receivedItems !== counts.requestedItems &&
                                        <Typography className={classes.messages__container} style={{ padding: '4px', marginBottom: '8px' }}>
                                            <Warning className={classes.warning__message__icon} />
                                            {i18n.t('actionBarMessages.waitingForVendors1')}
                                            <Link className={classes.customLink}
                                                onClick={this.showVendorsPo}>
                                                {`(missing ${counts.requestedItems - counts.receivedItems} of ${counts.requestedItems})`}
                                            </Link>
                                        </Typography>
                                    }

                                    
                                    {/* Se ha recibido todo de los vendors*/}
                                    { counts.receivedItems !== 0 && counts.receivedItems === counts.requestedItems &&
                                        <Typography className={classes.messages__container} style={{ padding: '4px', marginBottom: '8px' }}>
                                            <Warning className={classes.warning__message__icon} />
                                            {i18n.t('actionBarMessages.orderIsComplete')}
                                        </Typography>
                                    }

                                </div>
                            }

                            {(counts.invoicedItems !== counts.requestedItems || otherCostsAmounts.requested !== otherCostsAmounts.invoiced) &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={file.invoices.filter(inv => inv.status === 1).length > 0 || counts.receivedItems === 0 || (counts.receivedItems === counts.invoicedItems && counts.receivedItems !== 0 && otherCostsAmounts.invoiced === otherCostsAmounts.requested)}
                                    onClick={this.handleCreateNewQuote}>
                                    {i18n.t("quotes.invoices.newInvoice")}
                                </Button>
                            }
                        </div>
                    }

                    {file.invoices && file.invoices.length > 0 &&
                        <InvoiceSummary
                            file={file}
                        />
                    }

                    {invoices && invoices.length !== 0 &&
                        <div>
                            {/* <Hidden xsDown implementation="css"> */}
                            <Tabs
                                className={classes.vendorQuotes__tabs}
                                value={selectedTab >= 0 ? selectedTab : 0}
                                onChange={this.handleChangeTab}
                                indicatorColor="secondary"
                                textColor="secondary"
                                classes={{
                                    indicator: classes.vendorQuotes__tabs__activeIndicator,
                                    flexContainer: classes.vendorQuotes__tabs__flexContainer,
                                    root: classes.file__tabs__root,
                                }}
                                variant={invoices.length > 4 ? "scrollable" : "standard"}
                                scrollButtons="auto"
                            >
                                {invoices.map((invoice, i) => {
                                    let alignToR = false;
                                    if (invoice.status === 2 && !firstReadyInvoice) {
                                        firstReadyInvoice = true;
                                        alignToR = true;
                                    }
                                    return (<Tab
                                        key={i}
                                        label={(
                                            <div className={classes.legend__root__row}>
                                                {invoice.status === 1 && (
                                                    <Drafts className={classes.invoice__status__icon__preparing} />
                                                )}
                                                {invoice.status === 2 && (
                                                    <DoneOutline className={classes.invoice__status__icon__ready} />
                                                )}
                                                <Typography noWrap >{`${i18n.t('quotes.invoices.invoice')} #${invoice.id}`}</Typography>
                                            </div>)}
                                            classes={{
                                                root: classNames(classes.vendorQuotes__tab, alignToR ? classes.vendorQuotes__tab__ready : ''),
                                                selected: classes['vendorQuotes__tab--selected']
                                            }}
                                    />)
                                })}
                            </Tabs>

                            {invoices.map((invoice, i) => {
                                if (i === selectedTab) {
                                    return (<div key={i}>
                                        <InvoiceCard
                                            invoice={invoice}
                                            file={file}
                                            showError={this.showError}
                                            currencySymbol={file.currencySymbol}
                                        />
                                    </div>)
                                } else {
                                    return null;
                                }
                            })}

                            <Snackbar
                                className={classes.snackbar__root__style}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                open={changeStatusMessage !== null}
                                autoHideDuration={4000}
                                onClose={() => {
                                    this.setState({
                                        changeStatusMessage: null
                                    });
                                }} >
                                <SnackbarContent
                                    className={classes.error}
                                    message={<Typography className={classes.errorLabel}>{changeStatusMessage}</Typography>} />
                            </Snackbar>

                        </div>
                    }
                </Paper>
            </>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch),
        filesActions: bindActionCreators(filesActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        file: state.file,
        general: state.general,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Invoices));