import React, { useEffect, useState } from 'react'
import {
    Paper,
    IconButton,
    Typography,
    Popover,
    InputBase,
    List,
    ListItem,
    ListItemText,
    Button,
    Divider,
    makeStyles,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Link,
    CircularProgress
} from '@material-ui/core'
import {
    Delete,
    Archive,
    MoveToInbox,
    OpenInNew,
    KeyboardArrowLeft,
    InsertDriveFile,
    Search as SearchIcon,
    KeyboardArrowRight
} from '@material-ui/icons'
import AttachmentPreview from './AttachmentPreview'
import { ClientRFQForm } from '../quotes/clientRFQ'
import { CustomMessages } from '../general'

import i18n from 'i18next'
import moment from 'moment'
import API from '../../lib/api'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    email__actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing()
        }
    },
    email__paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: theme.spacing(3)
    },
    email__sender__container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    email__sender__timestamp: {

    },
    email__sender: {
        fontWeight: 'bold',
        textAlign: 'left'
    },
    email__recipient: {

    },
    email__subject: {
        fontWeight: 'bold',
        textAlign: 'left'
    },
    email__body: {
        textAlign: 'left',
        whiteSpace: 'pre-line'
    },
    attachments__container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    attachment: {
        maxWidth: 150,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer'
    },
    attachments__conponents: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    search__root: {
        margin: '10px 0',
        maxWidth: 400,
        width: '100%',
        padding: '0 10px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    search__input: {
        width: '90%'
    },
    file__list__item: {
        cursor: 'pointer',
        border: 'solid thin transparent',
        '&:hover': {
            backgroundColor: '#eeeeee',
            boxShadow: '3px 3px #dddddd',
            border: 'solid thin #dddddd'
        }
    },
    dialog__paper: {
        width: '100%'
    },
    assign__button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary
    }
}))

export default function EmailDetails(props) {
    let { history } = props;
    const classes = useStyles();
    const [openFilesOptions, setOpenFilesOptions] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [fetching, setFetching] = useState(false)
    const [openAssignFileDialog, setOpenAssignFileDialog] = useState(false)
    const [selectedAttachment, setSelectedAttachment] = useState(null)
    const [openAttachmentPreview, setOpenAttachmentPreview] = useState(false)
    const [openRFQDialog, setNewRFQDialog] = useState(false)
    const [successMessage, showSuccessMessage] = useState(null)
    const [fileNumber, setFileNumber] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [file, setFile] = useState(null)
    const [fetchingFile, setFetchingFile] = useState(false)

    const handleBack = () => {
        props.back && props.back()
    }
    const formatedDate = (date) => {
        if (moment().isSame(moment(date), 'day')) {
            return moment(date).format('hh:mm a')
        } else {
            return moment(date).format('MMM DD')
        }
    }
    useEffect(() => {
        let isSuscribed = true;
        if (props.selectedEmail && !props.selectedEmail.read) {
            API.Emails.markEmail({
                read: 1,
                folderId: props.selectedEmail.folderId,
                emailId: props.selectedEmail.emailId
            }).then(updatedResponse => {
                if (isSuscribed) {
                    let updatedEmail = updatedResponse.data.record;
                    props.updateEmails && props.updateEmails(updatedEmail)
                }
            })
        }
        return () => {
            isSuscribed = false;
        };
    }, [])

    const changeEmailsFolder=(folderId)=>{
        setFetching(true)

        let newFolder = props.folders.find(f => f.id === folderId);

        API.Emails.changeEmailsFolder({
            body: {
                emailsIds: [props.selectedEmail.emailId],
                folderId: folderId
            }
        })
            .then(response => {
                props.setSelectedMenu(newFolder)
                history.push(`/emails/${newFolder.name.toLowerCase()}/${props.selectedEmail.emailId}`);
                setFetching(false)
            }).catch(err => {
                console.log(err);
            })
    }

    const addNewRFQ=(file, message)=>{
        setNewRFQDialog(false)
        setOpenAssignFileDialog(false)
        changeEmailsFolder(2)
        setOpenFilesOptions(false)
        showSuccessMessage(message ? message : 'RFQ has been successfully created. Email archived')

        let emailHead = props.selectedEmail.bodyHtml ? props.selectedEmail.bodyHtml.split("From:")[0].length : 0,
            newBodyHtml = props.selectedEmail.bodyHtml ? props.selectedEmail.bodyHtml.slice(emailHead, props.selectedEmail.bodyHtml.length) : '';

        let body = {
            id: null,
            taskDateTime: null,
            title: props.selectedEmail.subject,
            sendByEmail: 1,
            onlyCreate: 1, 
            emailId: props.selectedEmail.emailId, 
            emailSender: props.selectedEmail.sender,
            emailRecipients: props.selectedEmail.recipient,
            emailAttachments: null,
            content: props.selectedEmail.bodyPlain ? encodeURI(props.selectedEmail.bodyPlain) : encodeURI(newBodyHtml.replace(/<\/?[^>]+>/ig, " ").replace(/&nbsp;/g, " ").replace(/&lt/g, " ").replace(/&gt/g, " ")) ,
            isTask: 0,
            taskFollowUpUserId: null,
            isPrivate: 1
        }
    
        API.Notes.createNote({ 
            fileId: file.id, 
            note: body
        }).then(res => {
            console.log(res)
        })
    }

    const searchFile=()=>{
        setFile(null)
        setFetchingFile(true)

        API.Files.getFile({fileId: fileNumber})
            .then(res =>{
                if(res.message){
                    setFetchingFile(false)
                    setErrorMessage(res.message)
                } else {
                    setErrorMessage(null)
                    setFetchingFile(false)
                    setFile(res.file)
                }
            })
    }

    let emailHead = props.selectedEmail.bodyHtml ? props.selectedEmail.bodyHtml.split("From:")[0].length : 0,
        newBodyHtml = props.selectedEmail.bodyHtml ? props.selectedEmail.bodyHtml.slice(emailHead, props.selectedEmail.bodyHtml.length) : '';

    return (
        <div className={classes.root}>
            <div className={classes.email__actions}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <IconButton onClick={handleBack}>
                        <KeyboardArrowLeft />
                    </IconButton>

                    { fetching ?
                        <CircularProgress color='secondary' size={16} />
                    :<>
                        {props.selectedMenu.id !== 1 &&
                            <IconButton className={classes.iconButton} style={{marginRight: '8px'}}
                                onClick={()=>changeEmailsFolder(1)}>
                                <MoveToInbox />
                            </IconButton>
                        }

                        {props.selectedMenu.id !== 2 &&
                            <IconButton className={classes.iconButton} style={{marginRight: '8px'}}
                                onClick={()=>changeEmailsFolder(2)}>
                                <Archive />
                            </IconButton>
                        }

                        {props.selectedMenu.id !== 3 &&
                            <IconButton className={classes.iconButton}
                                onClick={()=>changeEmailsFolder(3)}>
                                <Delete />
                            </IconButton>
                        }                    
                    </>}
                </div>
                <div>
                    {props.selectedMenu.id === 1 &&
                        <IconButton
                            variant="contained"
                            className={classes.assign__button}
                            onClick={(e) => {
                                setOpenFilesOptions(true)
                                setAnchorEl(e.currentTarget)
                            }}>
                            <OpenInNew />
                        </IconButton>
                    }   
                    <Popover
                        id='popover'
                        open={openFilesOptions}
                        anchorEl={anchorEl}
                        onClose={() => {
                            setOpenFilesOptions(false)
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Paper style={{
                            padding: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 350
                        }}>
                            <Paper style={{
                                maxHeight: 400,
                                overflow: 'auto'
                            }}>
                                <List style={{padding: 0}}>
                                    <ListItem className={classes.file__list__item} onClick={() => {
                                        setOpenAssignFileDialog(true)
                                        setOpenFilesOptions(false)
                                    }}>
                                        <ListItemText primary='Link to an existing file' />
                                        <KeyboardArrowRight />
                                    </ListItem>
                                    <Divider />
                                    <ListItem className={classes.file__list__item} onClick={() => {
                                        setNewRFQDialog(true)
                                    }}>
                                        <ListItemText primary='New RFQ' />
                                        <KeyboardArrowRight />
                                    </ListItem>
                                    <Divider />
                                </List>
                            </Paper>
                        </Paper>
                    </Popover>
                </div>
            </div>
            <Paper className={classes.email__paper}>
                <div className={classes.email__sender__container}>
                    <Typography variant='body1' color='textSecondary' className={classes.email__sender}>
                        {props.selectedEmail.senderWithFormat ? props.selectedEmail.senderWithFormat : props.selectedEmail.sender}
                    </Typography>
                    <Typography variant='body2' color='textSecondary' className={classes.email__sender__timestamp}>
                        {formatedDate(props.selectedEmail.timestamp)}
                    </Typography>
                </div>
                <Typography variant='body1' color='textSecondary' className={classes.email__recipient}>
                    {i18n.t('To')} {props.selectedEmail.recipient}
                </Typography>
                <Divider style={{ width: '100%' }} />
                <br />
                <Typography variant='h6' className={classes.email__subject}>
                    {props.selectedEmail.subject}
                </Typography>
                <br />
                <Typography variant='body1' className={classes.email__body}>
                    {props.selectedEmail.bodyPlain ? props.selectedEmail.bodyPlain : newBodyHtml.replace(/<\/?[^>]+>/ig, " ").replace(/&nbsp;/g, " ").replace(/&lt/g, " ").replace(/&gt/g, " ")}
                </Typography>
                {props.selectedEmail.attachments.length > 0 && (
                    <>
                        <br />
                        <Divider style={{ width: '100%' }} />
                        <br />
                        <div className={classes.attachments__container}>
                            {props.selectedEmail.attachments.map(att => (
                                <Link key={att.id}
                                    className={classes.attachment}
                                    color='textSecondary'
                                    onClick={() => {
                                        setSelectedAttachment(att)
                                        setOpenAttachmentPreview(true)
                                    }} variant='button'>
                                    <div className={classes.attachments__conponents}>
                                        <InsertDriveFile color='secondary' className={classes.notes__list__edit__btn__icon} />
                                        <Typography variant='body2' noWrap>{att.name}</Typography>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </>
                )}
            </Paper>

            <Dialog
                open={openAssignFileDialog}
                classes={{
                    paper: classes.dialog__paper
                }}
                onClose={() => { setOpenAssignFileDialog(false) }}>
                <DialogTitle>
                    {i18n.t('Link email to file')}
                </DialogTitle>
                <DialogContent style={{
                    height: 200
                }}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Paper className={classes.search__root}>
                            <InputBase
                                className={classes.search__input}
                                placeholder={i18n.t('Search file')}
                                aria-label={i18n.t('Search file')}
                                value={fileNumber}
                                autoFocus={true}
                                error={errorMessage}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        searchFile()
                                    }
                                }}
                                // onKeyDown={searchKeyDown}
                                onChange={(e)=>{
                                    setFileNumber(e.target.value)
                                }}
                            />
                        </Paper>

                        <Button 
                            size='small'
                            color='primary'
                            variant='contained'
                            style={{minWidth: '20px', height: '40px', borderRadius: '50px', marginLeft: '12px'}}
                            onClick={searchFile}>
                            {fetchingFile ?
                                <CircularProgress color='secondary' style={{width: '24px', height: '24px'}} />
                            :
                                <SearchIcon />
                            }
                        </Button>
                    </div>

                    { errorMessage && 
                        <Typography color='error'>
                            {errorMessage}
                        </Typography>
                    }

                    { file && <>
                        <Typography variant='body1' color={"textPrimary"}>
                            {file.client.name}
                        </Typography>
                        <Typography variant='body2' color={"textSecondary"}>
                            {`${file.client.contact.firstName} ${file.client.contact.lastName}`}
                        </Typography>

                        <Typography variant='body2' color={"textSecondary"}>
                            {`Ref: ${file.requestForQuote.reference}`}
                        </Typography>
                    </>}
                </DialogContent>
                <DialogActions>
                    <Button
                        size='small'
                        style={{minWidth: '90px'}}
                        color='secondary'
                        variant='outlined'
                        onClick={() => { setOpenAssignFileDialog(false) }}>Cancel</Button>
                    <Button
                        size='small'
                        color='primary'
                        style={{minWidth: '100px'}}
                        variant='contained'
                        disabled={!file}
                        onClick={() => { addNewRFQ(file, `Associated to File # ${fileNumber}. Email archived`) }}>Link</Button>
                </DialogActions>
            </Dialog>

            <AttachmentPreview
                selectedAttachment={selectedAttachment}
                open={openAttachmentPreview}
                close={() => {
                    setSelectedAttachment(null)
                    setOpenAttachmentPreview(false)
                }} />

            <CustomMessages
                type={'success'}
                message={successMessage}
                clearMessage={()=>
                    showSuccessMessage(null)
                }
            />

            <ClientRFQForm
                open={openRFQDialog}
                addNewRFQ={addNewRFQ}
                handleClose={()=>{
                    setNewRFQDialog(false)
                    setOpenAssignFileDialog(false)
                }}
            />
        </div>
    )
}
