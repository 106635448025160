import React, { Component } from 'react'
import {
    Grid,
    Card, 
    Link,
    CardHeader,
    CardContent,
    Typography,
    IconButton,
    Dialog, 
    DialogTitle,
    DialogContent,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Button,
    withMobileDialog,
    Divider
} from '@material-ui/core/';
import {
    Face,
    List as ListIcon,
    Close
} from '@material-ui/icons';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import { Tasks, FileListDialog, VendorQuotesListDialog, DashboardPeopleFilter } from '../widgets'
import API from '../../lib/api'
import { SlideUp } from '../general/Transitions'
import i18n from 'i18next'


const styles = theme => ({
    dashboardContainer: {
        maxWidth: '1100px',
        margin: 'auto',
        display: 'flex',
        justifyContent:'center',
        alignItems: 'center'
    },
    card: {
        // height: '140px',
        position: 'relative'
    },
    listItem: {
        paddingLeft: '10px',
        paddingRight: '5px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    listItemText: {
        paddingLeft: 0,
        paddingRight: '10px'
    },
    dashboard__cardHeader__tasks: {
        ...theme.dashboardCard.smallHeader,
        boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)',
        background: `linear-gradient(60deg, ${theme.palette.purple['light']}, ${theme.palette.purple['dark']})`
    },
    dashboard__cardHeader__clients__documents: {
        ...theme.dashboardCard.header,
        boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(76, 175, 80,.4)',
        background: `linear-gradient(60deg, #66bb6a, #43a047)`
    },
    dashboard__cardHeader__vendors__documents: {
        ...theme.dashboardCard.header,
        boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 172, 193,.4)',
        background: `linear-gradient(60deg, #26c6da, #00acc1)`
    },
    dashboard__cardHeader__other__documents: {
        ...theme.dashboardCard.smallHeader,
        background: 'linear-gradient(60deg, #ef5350, #e53935)',
        boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px - 5px rgba(244, 67, 54,.4)'
    },
    dashboard__cardHeader__action: {
        marginTop: 0,
        marginLeft: 'auto'
    },
    dashboard__cardHeader__content: {
        flex: 'none'
    },
    dashboard__content__link: {
        fontSize: '16px',
        ...theme.customLink
    },
    dashboard__actions: {
        borderTop: `1px solid ${theme.palette.grey[100]}`,
        marginTop: theme.spacing(2)
    },
    dialog__paper: {
        //width: '100%',
        marginLeft: 0,
        marginRight: 0,
        overflow: 'visible',
        width: '400px',
        height: 'auto'
    },
    dialog__content__container: {
        padding: theme.spacing(2),
        overflow: 'auto'
    },
    people__list__container: {
        height: '200px',
        overflow: 'auto'
    },
    people__actions__container: {
        display: 'flex',
        paddingTop: theme.spacing(),
        justifyContent: 'flex-end'
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(),
    },
});


class AdminDashboard extends Component {
    state = {
        loading: false,
        selectedFiles: null,
        openFilesList: false,
        selectedVendorQuotes: null,
        openVendorQuoteList: false,
        openUserSelector: false,
        allUsersSelected: true,
        selectedUsers: [], 
        tasks: null
    }

    componentDidMount = () => {
        this.setState({
            loading: true
        })
        this.getInfo([])
    }
    

    getInfo = ()=>{
        API.Users.getUserDashboardInfo().then(res => {
            if (res.info) {
                this.setState({
                    loading: false,
                    tasks: res.info.tasks,
                    clientQuotes: res.info.clientQuotes,
                    allClientQuotes: res.info.clientQuotes,
                    vendorQuotes: res.info.vendorQuotes,
                    allVendorQuotes: res.info.vendorQuotes
                })
            } else {
                this.setState({
                    loading: false,
                    error: true
                })
            }
        })
    }

    showFiles=(files)=>{
        this.setState({
            selectedFiles: files, 
            openFilesList: true
        })
    }

    closeFileList=()=>{
        this.setState({
            selectedFiles: null,
            openFilesList: false
        })
    }

    showVendorQuotes=(vendorQuotes)=>{
         this.setState({
            selectedVendorQuotes: vendorQuotes, 
            openVendorQuoteList: true
        })
    }

    closeVendorQuotesList = () => {
        this.setState({
            selectedVendorQuotes: null,
            openVendorQuoteList: false
        })
    }

    fetchInfoUsers = () => {
        this.setState({
            loading: true
        })
        this.getInfo()
    }

    openUserSelector=()=>{
        this.setState({
            openUserSelector: true
        })   
    }

    closeUserSelector=()=>{
        this.setState({
            openUserSelector: false
        })
    }

    toggleAllUsers = () => {
        const { allUsersSelected } = this.state,
              { people } = this.props;
 
        let selectedUsers = people.allPeople.filter(person => people.filterPeople.includes(person.userId)).map(person => person.userId),
            allUsers = !allUsersSelected;

        this.setState({
            allUsersSelected: allUsers,
            selectedUsers: !allUsers ? [] : selectedUsers
        })
    }

    toggleSelectUser = (user) => {
        const { allUsersSelected } = this.state

        let selectedUsers = allUsersSelected ? [] : [...this.state.selectedUsers],
            userInSelector = selectedUsers.find(u => u === user.userId);

        if (userInSelector) {
            selectedUsers = selectedUsers.filter(u => u !== user.userId)
        } else {
            selectedUsers.push(user.userId);
        }

        this.setState({
            selectedUsers,
            allUsersSelected: selectedUsers.length > 0 ? false : true
        })

    }

    handleFilter=()=>{
        const { allClientQuotes, allVendorQuotes, selectedUsers } = this.state;

        let newClientQuotes = allClientQuotes.filter(cq => selectedUsers.includes(cq.author.id)),
            newVendorQuotes = allVendorQuotes.filter(vq => selectedUsers.includes(vq.author.id));

        this.setState({
            clientQuotes: newClientQuotes,
            vendorQuotes: newVendorQuotes
        })

        this.closeUserSelector()
    }

    render() {
        const { classes, width, people } = this.props,
              { loading, 
                tasks, 
                clientQuotes, 
                vendorQuotes, 
                selectedFiles,
                openFilesList,
                selectedVendorQuotes,
                openVendorQuoteList,
                openUserSelector,
                selectedUsers,
                allUsersSelected
            } = this.state;

        return (
            <div className={classes.dashboardContainer} style={{padding: width === 'xs' ? '16px' : '50px'}}>
                <Grid container spacing={3} justify={'space-between'}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container item spacing={3}>
                            <Grid item xs={2} sm={2} style={{textAlign: 'left'}}>
                                <DashboardPeopleFilter
                                    fetchInfoUsers={this.fetchInfoUsers}
                                />
                            </Grid>
                            <Grid item xs={10} sm={10} />
                        </Grid>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={7}>
                        <Tasks
                            tasks={tasks}
                            loading={loading}
                            refreshTasks={this.getInfo}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={5}> 
                        <Grid container item spacing={3}>
                            <Grid item xs={10} sm={10}>
                                <Card className={classes.card}>
                                    <CardHeader
                                        disableTypography={true}
                                        classes={{
                                            root: classes.dashboard__cardHeader__clients__documents,
                                            action: classes.dashboard__cardHeader__action,
                                            content: classes.dashboard__cardHeader__content
                                        }}
                                        title={<div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Face/>
                                            <Typography variant='h6' style={{ color: '#fff', marginLeft: '8px' }}>
                                                {i18n.t('dashboard.pendingClientFiles')}
                                            </Typography>
                                        </div>}
                                        action={
                                            people.filterPeople.length > 1 && (
                                                <IconButton onClick={this.openUserSelector}>
                                                    <ListIcon style={{color: '#fff'}}/>
                                                </IconButton>
                                            )
                                        }
                                    />
                                    <CardContent style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                        {clientQuotes && clientQuotes.filter(cq => cq.status === 1).length > 0 &&
                                            <Link onClick={()=>this.showFiles(clientQuotes.filter(cq => cq.status === 1))}
                                                variant='button'
                                                className={classes.dashboard__content__link}>
                                                {clientQuotes ? `${clientQuotes.filter(cq => cq.status === 1).length} ${i18n.t('dashboard.rfqFiles')}` : 0} 
                                            </Link>
                                        }

                                        {clientQuotes && clientQuotes.filter(cq => cq.status === 4).length > 0 &&
                                            <Link onClick={()=>this.showFiles(clientQuotes.filter(cq => cq.status === 4))}
                                                variant='button'
                                                className={classes.dashboard__content__link}>
                                                {clientQuotes ? `${clientQuotes.filter(cq => cq.status === 4).length} ${i18n.t('dashboard.clientQuotesFiles')}` : 0}
                                            </Link>
                                        }

                                        {clientQuotes && clientQuotes.filter(cq => cq.status === 5 && cq.clientPOStatusId === 1).length > 0 &&
                                            <Link onClick={()=>this.showFiles(clientQuotes.filter(cq => cq.status === 5 && cq.clientPOStatusId === 1))}
                                                variant='button'
                                                className={classes.dashboard__content__link}>
                                                {clientQuotes ? `${clientQuotes.filter(cq => cq.status === 5 && cq.clientPOStatusId === 1).length} ${i18n.t('dashboard.poFiles')}` : 0}
                                            </Link>
                                        }

                                        {clientQuotes && clientQuotes.filter(cq => cq.status === 5 && (cq.clientPOStatusId === 2 || cq.clientPOStatusId === 3)).length > 0 &&
                                            <Link onClick={()=>this.showFiles(clientQuotes.filter(cq => cq.status === 5 && (cq.clientPOStatusId === 2 || cq.clientPOStatusId === 3)))}
                                                variant='button'
                                                className={classes.dashboard__content__link}>
                                                {clientQuotes ? `${clientQuotes.filter(cq => cq.status === 5 && (cq.clientPOStatusId === 2 || cq.clientPOStatusId === 3)).length} ${i18n.t('dashboard.invoicing')}` : 0}
                                            </Link>
                                        }

                                        {vendorQuotes && vendorQuotes.filter(vq => vq.status.id === 1).length > 0 && <>
                                            <Typography variant='body1' color='textPrimary' style={{marginTop: '16px'}}>
                                                {i18n.t('dashboard.vendorsFiles')}
                                            </Typography>

                                        
                                            <Link onClick={()=>this.showVendorQuotes(vendorQuotes.filter(vq => vq.status.id === 1))}
                                                variant='button'
                                                className={classes.dashboard__content__link}>
                                                {vendorQuotes ? `${vendorQuotes.filter(vq => vq.status.id === 1).length}  ${i18n.t('dashboard.preparingVendorQuotes')}` : 0}
                                            </Link>
                                        </>}

                                        {clientQuotes && vendorQuotes && clientQuotes.length === 0 && vendorQuotes.length === 0 &&
                                            <Typography variant='body2' color='textSecondary' className={classes.listItemText} noWrap style={{ padding: '24px' }}>
                                                {i18n.t('dashboard.noFiles')}
                                            </Typography>
                                        }
                                    </CardContent>

                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            
                <FileListDialog
                    files={selectedFiles}
                    open={openFilesList}
                    onClose={this.closeFileList}
                />

                <VendorQuotesListDialog
                    open={openVendorQuoteList}
                    vendorQuotes={selectedVendorQuotes}
                    onClose={this.closeVendorQuotesList}
                />


                <Dialog
                    maxWidth='xs'
                    open={openUserSelector}
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    scroll='paper'
                    TransitionComponent={SlideUp}>
                    <div>
                        <DialogTitle disableTypography={true} >
                            {i18n.t('peopleFilter.title')}
                            <IconButton color="inherit"
                                disabled={loading}
                                onClick={this.closeUserSelector}
                                aria-label="Close" className={classes.closeButton}>
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <div className={classes.dialog__content__container}>
                                <div className={classes.people__list__container}>
                                    <List dense={true}>
                                        <ListItem button onClick={this.toggleAllUsers}>
                                            <ListItemText>{i18n.t('peopleFilter.everyone')}</ListItemText>
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    edge="end"
                                                    onChange={this.toggleAllUsers}
                                                    checked={allUsersSelected}
                                                />
                                            </ListItemSecondaryAction>

                                        </ListItem>
                                        {people && people.allPeople.filter(person => people.filterPeople.find(p=> p.userId === person.userId)).map((person, i) => (
                                            <ListItem key={i} button onClick={() => this.toggleSelectUser(person)}>
                                                <ListItemText>{person.userName}</ListItemText>
                                                <ListItemSecondaryAction>
                                                    <Checkbox
                                                        edge="end"
                                                        onChange={() => this.toggleSelectUser(person)}
                                                        checked={selectedUsers.indexOf(person.userId) !== -1}
                                                    />
                                                </ListItemSecondaryAction>

                                            </ListItem>
                                        ))}
                                    </List>
                                </div>
                            </div>
                            <Divider />
                            <div className={classes.people__actions__container}>
                                <Button color='primary'
                                    disabled={!allUsersSelected && selectedUsers.length === 0}
                                    size='small'
                                    variant='contained' onClick={this.handleFilter}>
                                    {i18n.t('peopleFilter.filter')}
                                </Button>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        people: state.people
    }
}

export default connect(mapStateToProps)(withMobileDialog()(withStyles(styles)(AdminDashboard)));