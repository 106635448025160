import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
import auth from '../authentication/auth';
import i18n from 'i18next'
import API from '../lib/api'
import mobiscroll from "@mobiscroll/react";
import { EventForm, EventDetails, EventList } from '../components/visits'
import {
    CircularProgress,
    Typography,
    IconButton,
    Snackbar,
    Paper,
    withStyles,
    withMobileDialog,
} from '@material-ui/core'
import {
    Add,
    Warning,
    Inbox,
    Close as CloseIcon,
} from '@material-ui/icons'
import moment from 'moment'
import { renderToString } from 'react-dom/server'

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as clientsActions from '../actions/clientsActions'
import * as eventsActions from '../actions/eventsActions'
import { eventIsInValidCheckinTime } from '../lib/utils'

mobiscroll.settings = {
    lang: 'en',
    theme: 'ios',
    display: 'bubble'
};

const styles = theme => {
    return ({
        visits: {
            margin: 'auto',
            maxWidth: '1100px'
        },
        buttonSpinner: theme.buttonSpinner,
        events__addEventButton: {
            color: '#007bff',
            padding: '4px',
            position: 'absolute',
            right: '16px',
            top: '13px',
            zIndex: '1200',
        },
        events__viewSelector: {
            position: 'fixed',
            bottom: 0
        },
        visits_list_view: {
            marginBottom: theme.spacing(5),
        },
        form: {
            height: '100%'
        },
        calendar__list: {
            marginBottom: 60
        },
        event__title: {
            fontWeight: 'bold'
        },
        event__subtitle: {
            color: theme.palette.text.secondary,
            marginTop: 5
        },
        event__visit__indicator: {
            color: theme.palette.text.secondary,
            // fontStyle: 'italic'
            position: 'absolute',
            right: 5,
            bottom: 5,
            fontSize: '16px'
        },
        event__missing__visit__indicator: {
            color: theme.palette.red[80],
            position: 'absolute',
            right: 5,
            bottom: 5,
            fontSize: '16px'
        },
        empty__state__container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            fontSize: '12pt'
        },
    })
};

let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth() + 1;


class VisitsCalendar extends Component {

    state = {
        loading: false,
        currentTab: 0,
        logedInUser: null,
        openEventDetails: false,
        selectedUsersIds: [],
        month: null,
        year: null,
        search: null,
        selectedDate: null,
        selectedDateFrom: new Date(y, parseInt(m) - 1, 1),
        selectedDateTo: new Date(y, parseInt(m), 0),
        searching: false,
        view: 'month',
        openEventSummary: false,
        openEventForm: false,
        callMobileView: {
            calendar: {
                type: 'month',
                popover: true
            },
            eventList: {
                type: 'month',
                scrollable: true
            }
        },
        callView: {
            calendar: {
                type: 'month',
                labels: true,
                popover: true
            },
        },
        callViewLeft: {
            calendar: {
                type: 'month',
                labels: true,
                popover: true
            },
        },
        callViewRight: {
            eventList: {
                type: 'month',
                scrollable: true
            }
        },
        openSnackbar: false,
        snackNotificationMessage: ''
    }

    componentWillMount() {
        auth.getAuthenticatedUser().then(user => {
            this.setState({
                logedInUser: parseInt(user.attributes['custom:userID']),
                selectedUsersIds: [parseInt(user.attributes['custom:userID'])]
            })
            this.fetchEvents({
                month: m,
                year: y
            })
        }).catch(err => {
            this.setState({ logedInUser: null })
        })
    }

    componentDidMount() {
        const { params } = this.props.match;

        this.setState({
            loading: true
        })
        if (params.type === 'calendar') {
            this.setState({
                currentTab: 0
            });
            if (this.state.view !== params.subsection) {
                this.changeView({ target: { value: params.subsection } })
                this.changeMobileView({ target: { value: params.subsection } })
            }

        }
        if (params.type === 'list') {
            this.setState({
                currentTab: 1
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { params } = nextProps.match;

        if (nextProps.selectedUsersIds) {
            this.setState({
                selectedUsersIds: nextProps.selectedUsersIds
            })
        }
        if (params.type === 'calendar') {
            this.setState({
                currentTab: 0
            });
            if (this.state.view !== params.subsection) {
                this.changeView({ target: { value: params.subsection } })
                this.changeMobileView({ target: { value: params.subsection } })
            }
        }
        if (params.type === 'list') {
            this.setState({
                currentTab: 1
            });
        }

    }

    handleCloseSnackbar = () => {
        this.setState({
            openSnackbar: false
        })
    }
    fetchEvents = (args) => {
        let { filterPeople } = this.props.people,
            { logedInUser } = this.state;

        const { month, year, to, from, keepOnPage } = args,
            { eventsFilters } = this.props;

        this.setState({
            loading: !keepOnPage ? true : false
        })

        let users = filterPeople.length > 0 ? filterPeople.map(u => u.userId).join(',') : `${logedInUser}`;//usersIDs ? usersIDs.join(',') : "";

        if (parseInt(month) !== parseInt(this.state.month)) {

            this.props.eventsActions.setFilters({
                ...filterPeople,
                m: month || null,
                y: year || null,
                to: to || null,
                from: from || null
            });

            API.Events.getEvents({
                m: month || null,
                y: year || null,
                to: to || null,
                search: eventsFilters.search || null,
                missedCheckIn: eventsFilters.missedCheckIn || null,
                from: from || null,
                usersID: users
            }).then(res => {
                let newEvents = res.data.records && res.data.records.length > 0 ? res.data.records.map(event => {
                    return {
                        ...event,
                        start: event.from,
                        end: event.to,
                        color: event.user.color,
                        text: event.isVisit ? event.client.name : event.title
                    }
                }) : [];

                this.props.eventsActions.setEvents(newEvents)
                this.setState({
                    searching: false,
                    loading: false,
                    month: month || null,
                    year: year || null,
                })
            });
        } else {
            this.setState({
                searching: false,
                loading: false,
            })
        }

    }

    changeView = (event) => {
        let view = {};

        switch (event.target.value) {
            case 'month':
                view = {
                    calendar: {
                        type: 'month',
                        labels: true,
                        popover: true
                    }
                };
                break;
            case 'week':
                view = {
                    calendar: {
                        type: 'week',
                        labels: true,
                        popover: true
                    },
                    eventList: {
                        type: 'month',
                        scrollable: true
                    }
                };
                break;
            default:
                view = {
                    calendar: {
                        type: 'month',
                        labels: true,
                        popover: true
                    }
                };
                break;
        }
        this.setState({
            view: event.target.value,
            callView: view
        });
        if (event.target.name) {
            this.props.history.push(`/visits/calendar/${event.target.value}`);
        }
    }

    changeMobileView = (event) => {
        let view = {};
        switch (event.target.value) {
            case 'month':
                view = {
                    calendar: {
                        type: 'month',
                        popover: true
                    },
                    eventList: { type: 'month', scrollable: true }
                };
                break;
            case 'week':
                view = {
                    calendar: {
                        type: 'week',
                        popover: true
                    },
                    eventList: { type: 'week', scrollable: true }
                };
                break;
            case 'day':
                view = {
                    eventList: { type: 'day', scrollable: true }
                };
                break;
            default:
                view = {
                    calendar: {
                        type: 'month',
                        popover: true
                    },
                    eventList: { type: 'month', scrollable: true }
                };
                break;
        }

        this.setState({
            view: event.target.value,
            callMobileView: view
        });
        if (event.target.name) {
            this.props.history.push(`/visits/calendar/${event.target.value}`);
        }
    }

    showEventPopup = (e) => {
        this.props.eventsActions.setEvent(e && e.event ?
            {
                ...e.event,
                locationConfirmed: e.event.locationConfirmed ? true : (e.event && e.event.id && e.event.place && e.event.place.googleAddress && (e.event.place.googleAddress === e.event.place.address) ? true : false),
            } : { visit: {} })

        // this.setState({
        //     selectedDate: null
        // }, () => {
        this.setState({
            openEventForm: e && e.event ? false : true,
            openEventSummary: e && e.event ? true : false
        })
        // })
    }

    onDayChange = (event, inst) => {
        const { selectedEvent } = this.state
        if (!selectedEvent && !event.events) {
            this.setState({
                selectedDate: event.date,
                selectedEvent: null
            }, () => {
                // this.setState({
                //     openEventForm: true
                // })
            })
        }
    }

    onPageChange = (event, inst) => {
        let month = parseInt(moment(event.firstDay).format('MM')),
            year = parseInt(moment(event.firstDay).format('YYYY'));
        this.fetchEvents({
            month,
            year,
            keepOnPage: true
        })
    }

    onFormClose = ({ createdOrChangedEvent, action } = {}) => {
        this.setState({
            selectedDate: null,
            openEventForm: false,
            openEventSummary: false
        }, () => {
            this.props.eventsActions.clearEvent(null);
            this.props.clientsActions.setClients([])
            if (createdOrChangedEvent) {
                let snackNotificationMessage = '';
                if (action === 'update') {
                    snackNotificationMessage = i18n.t('{{type}} from {{date}} updated', {
                        type: createdOrChangedEvent.isVisit ? 'Visit' : 'Event',
                        date: moment(createdOrChangedEvent.from).format("MMMM DD")
                    })
                } else {
                    snackNotificationMessage = i18n.t('{{type}} created on {{date}}', {
                        type: createdOrChangedEvent.isVisit ? 'Visit' : 'Event',
                        date: moment(createdOrChangedEvent.from).format("MMMM DD")
                    })
                }
                this.setState({
                    snackNotificationMessage,
                    openSnackbar: true
                })
            }
        })

    }

    changeTab = (tab) => {
        if (this.state.currentTab !== tab) {
            if (tab === 0) {
                this.props.history.push(`/visits/calendar/${this.state.view}`);
            } else {
                this.props.history.push(`/visits/list/scheduled`);
            }
        }
        this.setState({
            currentTab: tab
        });
    }



    render() {
        const { loading, view, month, year,
            openEventForm,
            logedInUser,
            callMobileView,
            selectedDate,
            openEventSummary,
            currentTab,
            openSnackbar,
            snackNotificationMessage
        } = this.state,
            { classes, events
            } = this.props;


        return (
            <div>
                <Helmet title={`${i18n.t('companyName')} | ${i18n.t('pageSubTitle.dashboard')}`} />
                <div className={classes.visits}>


                    {loading &&
                        <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />
                    }



                    {!loading && events &&
                        <div>
                            <div>
                                <IconButton className={classes.events__addEventButton}
                                    onClick={this.showEventPopup}>
                                    <Add />
                                </IconButton>
                            </div>
                            <Paper style={{
                                height: 'auto'
                            }}>
                                <div>
                                    {/**** Calendar View *****/}
                                    {currentTab === 0 && (
                                        <div>
                                            <mobiscroll.Form className={classes.form}>
                                                <mobiscroll.Segmented value="month" name="view" checked={view === 'month'} onChange={this.changeMobileView}>
                                                    {i18n.t('visits.calendar.monthView')}
                                                </mobiscroll.Segmented>
                                                <mobiscroll.Segmented value="week" name="view" checked={view === 'week'} onChange={this.changeMobileView}>
                                                    {i18n.t('visits.calendar.weekView')}
                                                </mobiscroll.Segmented>
                                                <mobiscroll.Segmented value="day" name="view" checked={view === 'day'} onChange={this.changeMobileView}>
                                                    {i18n.t('visits.calendar.dayView')}
                                                </mobiscroll.Segmented>

                                                <div className={`${classes.calendar__list} md-switching-view-cal-cont`}>
                                                    <mobiscroll.Eventcalendar
                                                        ref="calendar"
                                                        display="inline"
                                                        onDayChange={this.onDayChange}
                                                        onEventSelect={this.showEventPopup}
                                                        view={callMobileView}
                                                        onPageChange={this.onPageChange}
                                                        noEventsText={renderToString(
                                                            <div className={classes.empty__state__container}>
                                                                <Inbox style={{ fontSize: 50, color: '#cdcdcd' }} />
                                                                <Typography variant='body2' color='textSecondary'>
                                                                    {currentTab === 0 ? i18n.t('visits.noEventsScheduled') : currentTab === 1 ? i18n.t('visits.noEventsDone') : i18n.t('visits.noEventsCanceled')}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        data={events.map(ev => ({
                                                            ...ev,
                                                            text: renderToString(
                                                                <div>
                                                                    <div className={classes.event__title}>{ev.text}</div>
                                                                    {ev.isVisit === 1 && (<div className={classes.event__subtitle}>{ev.title}</div>)}
                                                                    {ev.isVisit === 1 && eventIsInValidCheckinTime({ event: ev }) && (!ev.checkIn.dateTime || !ev.checkOut.dateTime) &&
                                                                        <div>
                                                                            <Warning className={classes.event__missing__visit__indicator} />
                                                                        </div>}
                                                                </div>
                                                            ),
                                                        }))}
                                                    />
                                                </div>
                                            </mobiscroll.Form>
                                        </div>
                                    )}

                                    {/**** List View *****/}
                                    {currentTab === 1 && (
                                        <div className={classes.visits_list_view} >
                                            <EventList
                                                changeDate={(newDate) => {
                                                    this.onPageChange({
                                                        firstDay: newDate
                                                    });
                                                }}
                                                loading={loading}
                                                logedInUser={logedInUser}
                                                month={month}
                                                year={year}
                                            />
                                        </div>
                                    )}
                                </div>
                                <mobiscroll.Optionlist
                                    lang="en"
                                    theme="ios"
                                    display="bottom"
                                    layout="liquid"
                                    itemWidth={150}
                                    select='single'
                                >
                                    <mobiscroll.OptionItem
                                        key={1}
                                        icon="calendar"
                                        onClick={() => this.changeTab(0)}
                                        selected={currentTab === 0 ? true : false}
                                        id="eventsCalendar">
                                        Calendar
                                </mobiscroll.OptionItem>
                                    <mobiscroll.OptionItem
                                        key={2}
                                        icon="material-menu"
                                        onClick={() => this.changeTab(1)}
                                        selected={currentTab === 1 ? true : false}
                                        id="eventsList">
                                        List
                                </mobiscroll.OptionItem>
                                </mobiscroll.Optionlist>
                            </Paper>
                        </div>
                    }
                </div>

                <EventForm
                    open={openEventForm}
                    selectedDate={selectedDate}
                    logedInUser={logedInUser}
                    onClose={this.onFormClose}
                />

                <EventDetails
                    open={openEventSummary}
                    logedInUser={logedInUser}
                    onClose={this.onFormClose}
                />

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                    message={<span >{snackNotificationMessage}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleCloseSnackbar}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        people: state.people,
        event: state.event,
        events: state.events,
        eventsFilters: state.eventsFilters
    }
}

function mapDispatchToProps(dispatch) {
    return {
        clientsActions: bindActionCreators(clientsActions, dispatch),
        eventsActions: bindActionCreators(eventsActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles)(VisitsCalendar)));
