import React, { Component } from 'react'
import {
    Grid,
    withStyles,
    Typography,
} from '@material-ui/core'
import { Done } from '@material-ui/icons'
import moment from "moment";
import i18n from 'i18next'
import ReactTable from "react-table"
import classNames from 'classnames'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'

const styles = theme => {
    return (
        {

            preparing__items__table: {
                fontSize: '14px',
                fontFamily: theme.typography.fontFamily,
                textAlign: 'left',
                border: `solid thin ${theme.palette.grey[300]}`
            },

            quoteitems__table__cell__rigth: {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
            },
            terms__readonly__container: {
                marginLeft: 0,
            },
            comments__readonly__container: {
                marginLeft: 0,
            },
            other__costs__title: {
                marginTop: theme.spacing(),
                display: 'flex',
                flexDirection: 'column'
            },
            other__costs__container: {
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: theme.spacing()
            },
            other__costs__table__container: {
                padding: 0,
                marginTop: theme.spacing(),
                marginBottom: theme.spacing(),
                marginLeft: theme.spacing(),
                marginRight: theme.spacing()
            },
            payment__schedule__container: {
                marginTop: theme.spacing(),
                marginBottom: theme.spacing()
            },
            payment__schedule__table__container: {
                padding: 0,
                marginTop: theme.spacing(),
                marginBottom: theme.spacing()
            },
            payment__schedule__empty__message: {
                marginLeft: theme.spacing(),
                marginRight: theme.spacing()
            }
            ,
            other__costs__empty__message: {
                marginLeft: theme.spacing(),
                marginRight: theme.spacing()
            },
            quote__items__list__title: {
                marginTop: theme.spacing(),
                marginBottom: theme.spacing(),
                marginLeft: theme.spacing(),
                marginRight: theme.spacing()
            },
            quote__items__list__container: {
                marginBottom: theme.spacing(),
                marginLeft: theme.spacing(),
                marginRight: theme.spacing()
            },
            subTitle: {
                color: theme.palette.blue[100]
            },
        })
}


class ClientQuoteReadOnly extends Component {


    static propTypes = {
        file: PropTypes.object.isRequired,
    }

    render() {
        const { file, classes, preparing, isPO } = this.props;

        if (!file) return null;

        if (!(file.clientQuote && file.clientQuote[0])) return null;


        return (
            <div style={{ padding: preparing ? '32px 0 0' : '0 8px 0' }}>
                <Grid container spacing={2} className={classes.terms__readonly__container} style={{ marginTop: '8px' }}>
                    <Grid item sm={6} md={6} xs={12}>
                        <Grid item container spacing={1}>
                            { !isPO &&
                                <Grid item sm={4} xs={12}>
                                    <Typography variant='body2' color='textSecondary'>
                                        <strong>{`${i18n.t('quotes.preparatingQuoteInformation.quoteDate')}  `}</strong>
                                        {`${moment(file.clientQuote[0].date ? file.clientQuote[0].date : new Date()).format('MMMM Do, YYYY')}`}
                                    </Typography>
                                </Grid>
                            }
                            <Grid item xs={12} sm={6}>
                                <Typography variant='body2' color='textSecondary'>
                                    <strong>{`${i18n.t('quotes.preparatingQuoteInformation.quoteTerms')}  `}</strong>
                                    {`${file.clientQuote[0].quoteTerms.name}. ${file.requestForQuote.quoteTermsReference ? file.requestForQuote.quoteTermsReference : ''}`}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Typography variant='body2' color='textSecondary'>
                                    <strong>{`${i18n.t('quotes.preparatingQuoteInformation.paymentTerms')}  `}</strong>
                                    {file.clientQuote[0].paymentTerms.name}
                                </Typography>
                            </Grid>

                            {file.clientQuote[0] && file.clientQuote[0].validFor &&
                                <Grid item sm={4} md={4} xs={12}>
                                    <Typography variant='body2' color='textSecondary'>
                                        <strong>{`${i18n.t('quotes.preparatingQuoteInformation.validFor')}  `}</strong>
                                        {file.clientQuote[0].validFor} {`${i18n.t('quotes.preparatingQuoteInformation.days')}  `}
                                    </Typography>
                                </Grid>
                            }
                            <Grid item sm={4} md={4} xs={12}>
                                <Typography variant='body2' color='textSecondary'>
                                    <strong>{`${i18n.t('quotes.preparatingQuoteInformation.quotedDeliveryDays')}  `}</strong>
                                    {file.clientQuote[0].deliveryDays} {`${i18n.t('quotes.preparatingQuoteInformation.days')}  `}
                                </Typography>
                            </Grid>

                            {file.clientQuote[0].paymentSchedule &&
                                <Grid item xs={12}>
                                    <Typography
                                        variant='body2'
                                        color='textSecondary'
                                        style={{
                                            whiteSpace: 'pre-line'
                                        }}>
                                        <strong>{`${i18n.t('quotes.preparatingQuoteInformation.paymentSchedule')}  `}</strong>
                                        {file.clientQuote[0].paymentSchedule}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item sm={6} md={6} xs={12}>
                        <Grid item container spacing={1}>
                            {file.clientQuote[0] && file.clientQuote[0].comments &&
                                <Grid item xs={12}>
                                    <Typography
                                        variant='body2'
                                        color='textSecondary'
                                        style={{
                                            whiteSpace: 'pre-line'
                                        }}>
                                        <strong>{`${i18n.t('quotes.preparatingQuoteInformation.quoteComments')}  `}</strong>
                                        {file.clientQuote[0].comments}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container spacing={2} style={{ paddingLeft: '8px' }}>
                    <Grid item xs={12} md={6}/>
                    <Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
                        {/* OTHER COSTS */}
                        {(file.clientQuote[0].otherCosts && file.clientQuote[0].otherCosts.length !== 0) &&
                            <Grid container item spacing={2} justify='flex-end'>
                                <Grid item xs={12} className={classes.other__costs__title}>
                                    <Typography variant='h6' className={classes.subTitle}>{i18n.t('quotes.preparatingQuoteInformation.otherCosts')}</Typography>
                                
                                    {file.clientQuote[0].otherCosts && file.clientQuote[0].otherCosts.length > 0 &&
                                        <ReactTable
                                            data={file.clientQuote[0].otherCosts}
                                            columns={[{
                                                Header: i18n.t('quotes.preparatingQuoteInformation.otherCostsTable.column1'),
                                                id: 'otherCostTableName',
                                                accessor: 'name'
                                            },
                                            {
                                                Header: i18n.t('quotes.preparatingQuoteInformation.otherCostsTable.column2'),
                                                id: 'otherCostTableComments',
                                                accessor: 'comments'
                                            },
                                            {
                                                Header: i18n.t('quotes.preparatingQuoteInformation.otherCostsTable.weighted'),
                                                id: 'prorated',
                                                accessor: row =>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        {row.weighted !== 0 && row.weighted !== null && row.weighted !== undefined &&
                                                            <Done />
                                                        }
                                                    </div>,
                                                width: 70
                                            },
                                            {
                                                Header: i18n.t('quotes.preparatingQuoteInformation.otherCostsTable.column4'),
                                                id: 'otherCostTableAmount',
                                                className: classes.quoteitems__table__cell__rigth,
                                                accessor: row =>
                                                    <div>
                                                        <NumberFormat value={row.amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true} />
                                                    </div>,
                                                width: 80,
                                                Footer: () => {
                                                    let total = file.clientQuote[0].otherCosts.reduce(((total, row) => parseFloat(total) + parseFloat(row.amount || 0)), 0);

                                                    return (
                                                        <div>
                                                            <NumberFormat value={total}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                style={{ fontSize: '16px' }}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            }]}
pageSize = {
    file.clientQuote[0].otherCosts.length
}
                                            filtrable={false}
                                            sortable={false}
                                            resizable={false}
                                            className={classNames(classes.preparing__items__table, ' -striped -highlight')}
                                            showPaginationTop={false}
                                            showPaginationBottom={false}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        }
                    </Grid>

                </Grid>


            </div>
        )
    }
}

export default withStyles(styles)(ClientQuoteReadOnly)