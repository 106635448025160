import 'babel-polyfill'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'array-flat-polyfill'
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import {
    isMobile
} from "react-device-detect";
import {
    CircularProgress,
} from '@material-ui/core'
import {
    NotFound,
    Accounting,
    Invoicing,
    PurchaseOrders,
    Reports,
    Warehouse,
    Main,
    Search,
    EmailsLayout,
    EventsLayout,
    Layout
} from './pages';
import { MyDeals } from './components/visits/deals'
import { Provider } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles'
import withUser from './withUser';
import GlobalParametersContextComponent from './contexts/globalParametersContext'
import theme from './theme';
import { CssBaseline } from '@material-ui/core'
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import "react-table/react-table.css"
import "react-big-calendar/lib/css/react-big-calendar.css"
import './css/App.css'
import store from './store'
import withWidth from '@material-ui/core/withWidth';
import Permissions from './lib/permissions'

//Lazy loading code splitting strategy
const Quotes = lazy(() => import('./pages/Quotes'));
const Mails = lazy(() => import('./pages/Mails'));
const Visits = lazy(() => import('./pages/Visits'));
const VisitsCalendar = lazy(() => import('./pages/VisitsCalendar'));
const VisitsCalendarDesktop = lazy(() => import('./pages/VisitsCalendarDesktop'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Users = lazy(() => import('./pages/Users'));
const CompaniesClients = lazy(() => import('./pages/CompaniesClients'));
const CompaniesVendors = lazy(() => import('./pages/CompaniesVendors'));

const ProtectedRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                Permissions.checkRoutePermission(props.location.pathname) ? (
                    <Component {...props} />
                ) :
                    <Redirect
                        to={{
                            pathname: Permissions.getDefaultPage(props.location.pathname),//'/visits',//'/dashboard', //Esto debo cambiarlo para que navegue al url por defecto de un usuario xxx sin permisos
                            state: {
                                from: props.location
                            }
                        }}
                    />
            }
        />
    )
}
class Root extends React.Component {

    render() {
        let { width } = this.props;
        return (
            <Provider store={store}>
                <MuiThemeProvider theme={theme}>
                    <Suspense
                        fallback={<div
                            style={{
                                width: '100vw',
                                height: '100vh',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            <CircularProgress color='secondary' size={40} />
                        </div>}>
                        <CssBaseline>
                            <GlobalParametersContextComponent>
                                <Router>
                                    <Switch>
                                        <Route exact path="/signIn" component={Main} />
                                        <Route exact path="/signUp" component={Main} />
                                        <Route exact path="/signedUp" component={Main} />
                                        <Route exact path="/forgotPassword" component={Main} />
                                        <Route exact path="/confirmSignUp" component={Main} />
                                        <Redirect
                                            exact
                                            from="/emails"
                                            to={`/emails/inbox`}
                                        />
                                        <Route path="/emails/*" component={(props) => (
                                            <EmailsLayout props={{ ...props }}>
                                                <Switch>
                                                    <Route exact path="/emails/:folder" component={Mails} />
                                                    <Route exact path="/emails/:folder/:emailId" component={Mails} />
                                                </Switch>
                                            </EmailsLayout>
                                        )} />
                                        <Redirect
                                            exact
                                            from="/visits"
                                            to={`/visits/calendar/month`}
                                        />

                                        <Route path="/visits/deals" component={(props) => (
                                            <EventsLayout props={{ ...props }}>
                                                <Switch>
                                                    <Route exact path="/visits/deals" component={MyDeals} />    
                                                </Switch>
                                            </EventsLayout>
                                        )} />


                                        <Route path="/visits/:type/:subsection" component={(props) => (
                                            <EventsLayout props={{ ...props }}>
                                                <Switch>
                                                    <Route exact path="/visits/:type/:subsection" component={(isMobile || width === 'xs') ? VisitsCalendar : VisitsCalendarDesktop} />
                                                </Switch>
                                            </EventsLayout>
                                        )} />

                                        <Route path="/*" component={() => (
                                            <Layout>
                                                <Switch>
                                                    <Route exact path="/" component={Main} />
                                                    <ProtectedRoute exact path="/dashboard" component={Dashboard} />
                                                    <ProtectedRoute exact path="/search" component={Search} />
                                                    <ProtectedRoute exact path="/quotes" component={Quotes} />
                                                    <ProtectedRoute exact path="/quotes/:status" component={Quotes} />
                                                    <ProtectedRoute exact path="/quotes/:status/file/:fileId" component={Quotes} />
                                                    <ProtectedRoute exact path="/quotes/file/:fileId/:currentTab?" component={Quotes} />
                                                    <ProtectedRoute exact path="/accounting" component={Accounting} />
                                                    <ProtectedRoute exact path="/invoicing" component={Invoicing} />
                                                    <ProtectedRoute exact path="/invoicing/:fileId" component={Invoicing} />
                                                    <ProtectedRoute exact path="/purchaseOrders" component={PurchaseOrders} />
                                                    <ProtectedRoute exact path="/purchaseOrders/:fileId" component={PurchaseOrders} />
                                                    <ProtectedRoute exact path="/reports" component={Reports} />
                                                    <ProtectedRoute exact path="/users" component={Users} />
                                                    <ProtectedRoute exact path="/companies" component={CompaniesClients} />
                                                    <ProtectedRoute exact path="/companies/clients" component={CompaniesClients} />
                                                    <ProtectedRoute exact path="/companies/vendors" component={CompaniesVendors} />
                                                    <ProtectedRoute exact path="/v1/visits" component={Visits} />
                                                    <ProtectedRoute exact path="/warehouse" component={Warehouse} />
                                                    <Route path="/*" component={NotFound} />
                                                </Switch>
                                            </Layout>
                                        )
                                        } />
                                    </Switch>
                                </Router>
                            </GlobalParametersContextComponent>
                        </CssBaseline>
                    </Suspense>
                </MuiThemeProvider>
            </Provider>
        );
    }
}

let RootWithWidth = withWidth()(Root);

ReactDOM.render((withUser(<RootWithWidth />)), document.getElementById('root'));