import React, { Component } from 'react'
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Typography,
    Grid,
    Button,
    withStyles,
    withMobileDialog,
    CircularProgress,
    TextField
} from '@material-ui/core'
import i18n from 'i18next'
import NumberFormat from 'react-number-format'
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { SlideUp } from '../../general/Transitions'
import { ConfirmationReturnStatusDialog } from '../../general'
import { InvoiceHeader, InvoiceItems, InvoiceOtherCosts } from '.'
import API from '../../../lib/api';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as filesActions from '../../../actions/filesActions'

const styles = theme => {
    return {
        invoice__form: {
            padding: `${theme.spacing()}px ${theme.spacing(2)}px`
        },
        invoice__form__total: {
            fontSize: '16pt',
            fontFamily: theme.typography.fontFamily,
            textAlign: 'left',
        },
        buttonSpinner: theme.buttonSpinner,
        vendor__form__textField: {
            whiteSpace: 'pre-line'
        },
        invoiceForm__textField__input:{
            fontSize: '12px'
        }
    }
};


const customStyles = {
    control: base => ({
        ...base,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        boxShadow: 'none',

        '&:hover': {
            borderColor: '#000'
        }
    })
}

const defaultComments = 'ORIGEN: \n\nPTO SALIDA: \n\nMARCA: \n\nNO. DE PIEZAS: \n\nPESO TOTAL: \n\nDIMENSIONES: \n\nCERTIFICAMOS QUE LA CARGA NO TIENE FUENTE RADIOACTIVA \n\nCERIFICAMOS NO HAY SERIALES \n\nCERTIFICAMOS LA VERACIDAD DE ESTA FACTURA',
    defaultPackingComment = 'ORIGEN: \n\nPTO. SALIDA: \n\nMARCA: \n\nNO. PIEZAS: \n\nPESO TOTAL: LBRS \n\nDIMENSIONES: \n\n'

class InvoiceForm extends Component {
    state = {
        fileId: this.props.fileId,
        error: false,
        otherCostsError: false,
        saving: false,
        editQuoteDialogOpen: false,
        selectedDate: this.props.invoice ? this.props.invoice.date : new Date(),
        comments: defaultComments,
        packingListComments: defaultPackingComment,
        otherCosts: [],
        showConfirmacionEditInvoice: false,
        items: []
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            selectedDate: nextProps.invoice ? nextProps.invoice.date : new Date(),
            comments: nextProps.invoice && nextProps.invoice.comments ? nextProps.invoice.comments : defaultComments,
            packingListComments: nextProps.invoice && nextProps.invoice.packingListComments ? nextProps.invoice.packingListComments : defaultPackingComment,
            otherCosts: !nextProps.invoice && nextProps.file && nextProps.file.clientQuote ?
                nextProps.file.clientQuote[0].otherCosts.filter(oc => !oc.weighted).map(otherCost => {
                    let invoicedOtherCost = nextProps.invoice ? nextProps.invoice.otherCosts.find(i => i.clientQuoteOtherCostId === otherCost.clientQuoteOtherCostId) : null
                    return {
                        ...otherCost,
                        otherCostId: otherCost.id,
                        invoiceAmount: invoicedOtherCost ? invoicedOtherCost.invoiceAmount : null
                    }
                }) : nextProps.invoice ? nextProps.invoice.otherCosts : [],
            items: nextProps.file && nextProps.file.clientQuote && nextProps.file.clientQuote[0].clientPO ? nextProps.file.clientQuote[0].clientPO.items.map((item) => {
                let clientQuoteItem = nextProps.file.clientQuote[0].items.find(i => i.rfqItem.id === item.rfqItemId),
                    invoicedItem = nextProps.invoice ? nextProps.invoice.items.find(i => i.clientPOItemId === item.id) : null;

                return {
                    ...item,
                    acceptedVendorQuoteItem: clientQuoteItem.acceptedVendorQuoteItem,
                    rfqItem: clientQuoteItem.rfqItem,
                    invoiceQuantity: invoicedItem ? invoicedItem.invoiceQuantity : null
                }
            }) : []
        })
    }
    

    handleDateChange = date => {
        this.setState({
            selectedDate: date
        });
    }

    updateItems=(items)=>{
        let error = items.reduce(((total, row) => total || row.error), 0);

        this.setState({
            items,
            error
        })
    }

    updateOtherCosts=(otherCosts)=>{
        // let error = otherCosts.reduce(((total, row) => total || row.error), 0);
        this.setState({
            otherCosts
            // otherCostsError: error
        })
    }

    handleInvoice=(args)=>{
        const { file, invoice } = this.props,
            {   selectedDate,
                comments,
                packingListComments,
                items,
                otherCosts
            } = this.state

        let editComment = args ? args.comments : null

        this.setState({
            saving: true
        })

        let invoiced = items.reduce(((total, row) => total + parseFloat(row.invoiceQuantity || 0)), 0),
            otherCostsInvoiced = otherCosts.reduce(((total, row) => total + parseFloat(row.invoiceAmount || 0)), 0);
        
        if (invoiced + otherCostsInvoiced === 0){
            this.setState({
                saving: false,
                error: true
            })
            return
        }
        
        let body ={
            fileId: file.id,
            date: selectedDate,
            comments,
            editComment,
            packingListComments,
            items: items.map(item => { 
                return {
                    clientPOItemId: item.id,
                    quantity: item.invoiceQuantity === "" ? 0 : item.invoiceQuantity
                }
            }), 
            otherCosts: otherCosts.filter(oc => !oc.deleted && oc.invoiceAmount && oc.invoiceAmount !== "0").map(otherCost => {
                return {
                    clientQuoteOtherCostId: otherCost.clientQuoteOtherCostId,
                    amount: otherCost.invoiceAmount,
                    originalAmount: otherCost.amount,
                    otherCostId: otherCost.otherCostId
                }
            })
        }

        if (!invoice) {
            API.Invoices.createInvoice({ body }).then(res => {
                if (res.record) {
                    this.setState({
                        saving: false
                    })
                    this.props.filesActions.setFile(res.record.file)
                    this.props.handleClose()
                } else {
                    this.setState({
                        saving: false
                    })
                }
            })
        } else {
            body.invoiceId = invoice.id;
            body.statusId = invoice.status

            API.Invoices.updateInvoice({ body }).then(res => {
                if (res.record) {
                    this.setState({
                        saving: false,
                        showConfirmacionEditInvoice: false
                    })
                    this.props.filesActions.setFile(res.record.file)
                    this.props.handleClose()
                } else {
                    this.setState({
                        saving: false,
                        showConfirmacionEditInvoice: false
                    })
                }
            })
        }
    }

    render = () => {
        const {
                saving, 
                selectedDate,
                comments,
                packingListComments,
                items,
                error,
                otherCostsError,
                otherCosts,
                showConfirmacionEditInvoice
            } = this.state,
            {   fullScreen,
                classes,
                file,
                invoice
            } = this.props;

        if(!file) return null

        let itemsTotal = items.reduce(((total, row) => {
            let quotedAmount = parseFloat(row.amount || 0),
                amountWithProfit = quotedAmount + (parseFloat(quotedAmount * row.markupPercent).toFixed(2) / 100) + (row.weightedCost ? parseFloat(row.weightedCost) / row.quantity : 0)

            return parseFloat(total) + (amountWithProfit * row.invoiceQuantity)
        }), 0),
            otherCostTotal = otherCosts.reduce(((total, row) => parseFloat(total) + (row.invoiceAmount ? parseFloat(row.invoiceAmount || 0) : 0)), 0),
            taxesTotal = items.reduce(((total, row) => {
                let quotedAmount = parseFloat(row.amount || 0),
                    amountWithProfit = quotedAmount + (parseFloat(quotedAmount * row.markupPercent).toFixed(2) / 100) + (row.weightedCost ? parseFloat(row.weightedCost) / row.quantity : 0);

                return parseFloat(total) + row.taxPercent && file.clientQuote[0].taxPercent ? (row.invoiceQuantity * amountWithProfit * file.clientQuote[0].taxPercent || 0) / 100 : 0
            }), 0);

        let total = itemsTotal + otherCostTotal + taxesTotal
    

        return (
            <div>
                <Dialog
                    scroll='paper'
                    open={this.props.open}
                    fullScreen={fullScreen}
                    maxWidth={fullScreen ? 'sm' : 'md'}
                    fullWidth={fullScreen ? false : true}
                    TransitionComponent={SlideUp} >

                    <DialogTitle disableTypography={true}>
                        <Typography variant='body1' color="inherit">{i18n.t('quotes.invoices.file')} {file.id} - {invoice ? `${i18n.t('quotes.invoices.editInvoice')} ${invoice.id}` : i18n.t('quotes.invoices.createInvoice')}</Typography>
                    </DialogTitle>
                    <DialogContent className={classes.clientRFQ__dialog__content}>

                        <div className={classes.invoice__details__info}>

                            <InvoiceHeader
                                file={file}
                            />

                            <div className={classes.invoice__form}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} >
                                        <Grid container item spacing={2}>
                                            <Grid item xs={12} sm={3}>
                                                <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                                    <InlineDatePicker
                                                        variant="outlined"
                                                        label={i18n.t('quotes.invoices.invoiceDate')}
                                                        value={selectedDate}
                                                        format="MMM DD YYYY"
                                                        InputProps={{
                                                            className: classes.clientRFQ__form__quote_date__input
                                                        }}
                                                        disabled={saving ? true : false}
                                                        className={classes.clientRFQ__form__quote_date}
                                                        styles={customStyles}
                                                        onChange={this.handleDateChange}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} >
                                        <Grid container item spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    autoComplete='off'
                                                    id="vendor-remarks-multiline"
                                                    label={i18n.t('quotes.invoices.invoiceComments')}
                                                    className={classes.vendor__form__textField}
                                                    value={comments ? comments : ''}
                                                    disabled={saving}
                                                    multiline
                                                    onChange={event => {
                                                        this.setState({
                                                            comments: event.target.value
                                                        });
                                                    }}
                                                    InputProps={{
                                                        className: classes.invoiceForm__textField__input,
                                                    }}
                                                    rowsMax='10'
                                                    rows='4'
                                                    fullWidth
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                    
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    autoComplete='off'
                                                    id="vendor-remarks-multiline"
                                                    label={i18n.t('quotes.invoices.packingListComments')}
                                                    className={classes.vendor__form__textField}
                                                    value={packingListComments ? packingListComments : ''}
                                                    disabled={saving}
                                                    multiline
                                                    onChange={event => {
                                                        this.setState({
                                                            packingListComments: event.target.value
                                                        });
                                                    }}
                                                    InputProps={{
                                                        className: classes.invoiceForm__textField__input,
                                                    }}
                                                    rowsMax='10'
                                                    rows='4'
                                                    fullWidth
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                    
                                    <Grid item xs={12} sm={4} />
                                    <Grid item xs={12} sm={8} style={{textAlign: 'right'}}>
                                        <InvoiceOtherCosts
                                            editable={true}
                                            invoices={file.invoices}
                                            otherCosts={otherCosts}
                                            currencySymbol={file.currencySymbol}
                                            invoice={this.props.invoice ? this.props.invoice : null}
                                            updateOtherCosts={this.updateOtherCosts}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} >
                                        <InvoiceItems
                                            editable={true}
                                            invoices={file.invoices}
                                            items={items}
                                            file={file}
                                            vendorPOs={file.vendorPOs ? file.vendorPOs : []}
                                            invoice={this.props.invoice ? this.props.invoice : null}
                                            updateItems={this.updateItems}
                                            clientQuoteTaxPercent={file.clientQuote && file.clientQuote[0] ? file.clientQuote[0].taxPercent : 0}
                                        />
                                    </Grid>


                                    <Grid item xs={12} sm={12} style={{alignItems: 'center', display:'flex', justifyContent: 'flex-end'}}>
                                        <Typography variant='h6' style={{marginRight: '16px'}}><strong>{i18n.t('quotes.invoices.total')}</strong></Typography>
                                        
                                        <NumberFormat value={total.toFixed(2)}
                                            displayType={'text'}
                                            decimalScale={2}
                                            prefix={file.currencySymbol}
                                            className={classes.invoice__form__total}
                                            style={{ fontSize: '18px' }}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={2} justify='space-between'>
                            {(error || otherCostsError) && (
                                <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                    <Typography color='error'>{i18n.t("quotes.invoices.errorFields")}</Typography>
                                </Grid>
                            )}
                            <Grid item xs={6}>
                                <Button
                                    color='default'
                                    variant="outlined"
                                    onClick={this.props.handleClose}>
                                    {i18n.t("quotes.invoices.cancelEdit")}
                                </Button>
                            </Grid>

                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    disabled={saving || error || otherCostsError}
                                    color="primary"
                                    variant="contained"
                                    onClick={invoice && invoice.status === 2 ? ()=>{
                                        this.setState({
                                            showConfirmacionEditInvoice: true
                                        })
                                    } : this.handleInvoice}>
                                    {i18n.t("quotes.invoices.saveInvoice")}
                                    {saving && <CircularProgress  color='secondary' className={classes.buttonSpinner} size={24} />}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>


                <ConfirmationReturnStatusDialog
                    handleClose={() => this.setState({ showConfirmacionEditInvoice: false })}
                    onConfirm={this.handleInvoice}
                    loading={saving}
                    message={i18n.t('quotes.invoices.confirmation.editConfirmation')}
                    inputLabel={i18n.t('quotes.invoices.confirmation.explainReason')}
                    cancelLabel={i18n.t('quotes.invoices.confirmation.cancelLabel')}
                    confirmLabel={i18n.t('quotes.invoices.confirmation.confirmLabel')}
                    open={showConfirmacionEditInvoice}
                />
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        filesActions: bindActionCreators(filesActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        file: state.file,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles, { withTheme: true })(InvoiceForm)));
