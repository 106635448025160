import React, { Component } from 'react'
import {
    Grid,
    TextField,
    withStyles,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    CircularProgress
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import MomentUtils from '@date-io/moment';
import { SlideUp } from '../../general/Transitions'
import { ClientQuoteAcceptedItems } from '../preparingQR'
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { CustomMessages } from '../../general'
import API from '../../../lib/api'
import i18n from 'i18next';

const styles = theme => {
    return ({
        po__textField__comment: {
            marginTop: theme.spacing(),
            whiteSpace: 'pre-line'
        },
        po__form__textField: {
            margin: '8px 0'
        },
        po__form__date: {
            fontFamily: theme.typography.fontFamily,
            margin: `${theme.spacing()}px 0`,
            fontSize: '0.8em'
        },
        po__form__date__input: {
            fontSize: 'unset'
        },
        po__form__input: {
        },
        buttonSpinner: theme.buttonSpinner,
        button__wrapper: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-end'
        },
        editClientPOForm__title__closeButton: {
            position: 'absolute',
            top: theme.spacing(),
            right: theme.spacing(),
            padding: '5px'
        },
    })
}

const customStyles = {
    control: base => ({
        ...base,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        boxShadow: 'none',

        '&:hover': {
            borderColor: '#000',
        }
    })
}

class ClientPOForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validating: false,
            loading: false,
            apiErrorMessage: null,
            showRequiredFieldsError: false,
            poNumber: props.clientPO ? props.clientPO.poNumber : '',
            poDate: props.clientPO ? new Date(props.clientPO.date) : new Date(),
            poDeliveryDate: props.clientPO ? new Date(props.clientPO.deliveryDate) : null,
            poComment: props.clientPO ? props.clientPO.comment : '',
            items: props.clientQuote ? props.clientQuote.items.map((item) => {
                let poItem = props.clientPO ? props.clientPO.items.find(i => i.rfqItemId === item.rfqItem.id) : null

                return {
                    acceptedVendorQuoteItem: item.acceptedVendorQuoteItem,
                    rfqItem: item.rfqItem,
                    id: poItem ? poItem.id : null,
                    amount: poItem ? poItem.amount : item.unitAmount,
                    quantity: poItem ? poItem.quantity : item.rfqItem.quantity,
                    weightedCost: item.weightedCost,
                    markupPercent: item.markupPercent,
                    clientQuoteItemId: item.id,
                    taxPercent: item.taxPercent,
                    include: poItem ? true : false
                }
            }) : []
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.clientPO) {
            this.setState({
                poNumber: nextProps.clientPO ? nextProps.clientPO.poNumber : '',
                poDate: nextProps.clientPO ? new Date(nextProps.clientPO.date) : new Date(),
                poDeliveryDate: nextProps.clientPO ? new Date(nextProps.clientPO.deliveryDate) : null,
                poComment: nextProps.clientPO ? nextProps.clientPO.comment : '',
                items: nextProps.clientQuote ? nextProps.clientQuote.items.map((item) => {
                    let poItem = nextProps.clientPO ? nextProps.clientPO.items.find(i => i.rfqItemId === item.rfqItem.id) : null
                    return {
                        acceptedVendorQuoteItem: item.acceptedVendorQuoteItem,
                        rfqItem: item.rfqItem,
                        id: poItem ? poItem.id : null,
                        amount: poItem ? poItem.amount : item.unitAmount,
                        quantity: poItem ? poItem.quantity : item.rfqItem.quantity,
                        weightedCost: item.weightedCost,
                        markupPercent: item.markupPercent,
                        clientQuoteItemId: item.id,
                        taxPercent: item.taxPercent,
                        include: poItem ? true : false
                    }
                }) : []
            })
        }
    }

    handleDateChange = date => {
        this.setState({
            poDate: date,
            poDeliveryDate: (new Date(date)).setDate((new Date(date)).getDate() + this.props.clientQuote.deliveryDays)
        });
    }

    handleDeliveryDateChange = date => {
        this.setState({ poDeliveryDate: date });
    }

    saveClientPO = () => {
        const { poDeliveryDate, poDate, poComment, poNumber, items } = this.state,
            { clientPO, clientQuote } = this.props;

        this.setState({
            validating: true
        })
        if (poNumber === '') {
            this.setState({
                showRequiredFieldsError: true
            })
            return
        }

        this.setState({
            validating: false,
            loading: true
        })

        let body = {
            id: clientPO.id,
            estimatedDeliveryDate: new Date(poDeliveryDate),
            poNumber: poNumber,
            comments: poComment,
            clientQuoteId: clientQuote.id,
            date: poDate,
            poItems: items.map(item => {
                return {
                    id: item.id || null,
                    amount: item.amount,
                    quantity: item.quantity,
                    include: item.include,
                    rfqItemId: item.rfqItem.id,
                    weightedCost: item.weightedCost,
                    markupPercent: item.markupPercent,
                    taxPercent: item.taxPercent,
                }
            })
        }

        API.Quotes.updateClientPO({ body }).then(resp => {
            if (resp.record) {
                this.setState({
                    loading: false
                })
                this.props.updateClientPO(resp.record.file)
            } else {
                this.setState({
                    loading: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${resp.message ? resp.message : ''}`
                })
            }
        })

    }

    updatePOItems = (items) => {
        this.setState({
            items
        })
    }

    render() {
        const { classes, clientPO, open, clientQuote, file } = this.props,
            { poDeliveryDate,
                validating,
                poDate,
                poComment,
                poNumber,
                loading,
                apiErrorMessage,
                items
            } = this.state;

        if (!clientPO || !file) return null;

        return (
            <Dialog
                maxWidth={'md'}
                fullWidth={true}
                TransitionComponent={SlideUp}
                aria-labelledby="ClientPO-form"
                open={open}
            >
                <DialogTitle id="ClientPO-form" disableTypography={true}>
                    {file.id ? `${i18n.t('quotes.preparing.file')} ${file.id} - ` : ''}{i18n.t('quotes.pos.poDetails.editClientPOTitle')}
                    <IconButton color="inherit" onClick={this.props.handleClose} aria-label="Close" className={classes.editClientPOForm__title__closeButton}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ marginTop: '16px' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                fullWidth
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.po__form__input
                                }}
                                onChange={event => {
                                    this.setState({
                                        poNumber: event.target.value
                                    });
                                }}
                                value={poNumber}
                                className={classes.po__form__textField}
                                id="poNumber"
                                type="text"
                                variant="outlined"
                                error={validating && (poNumber === '' || !poNumber)}
                                label={i18n.t('quotes.pos.poDetails.poNumber')}
                            />
                        </Grid>
                        <Grid item sm={4} xs={1} />

                        <Grid item sm={3} xs={12}>
                            <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                <InlineDatePicker
                                    fullWidth
                                    variant="outlined"
                                    format="MMM DD YYYY"
                                    label={i18n.t('quotes.pos.poDetails.poDate')}
                                    value={poDate}
                                    InputProps={{
                                        className: classes.po__form__date__input
                                    }}
                                    className={classes.po__form__date}
                                    styles={customStyles}
                                    onChange={this.handleDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item sm={3} xs={12}>
                            <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                <InlineDatePicker
                                    fullWidth
                                    variant="outlined"
                                    format="MMM DD YYYY"
                                    label={`${i18n.t('quotes.pos.poDetails.eta')} (+ ${clientQuote.deliveryDays} ${i18n.t('quotes.pos.poDetails.quoteDeliveryDays')})`}
                                    value={poDeliveryDate}
                                    minDate={(new Date(poDate)).setDate((new Date(poDate)).getDate() + clientQuote.deliveryDays)}
                                    InputProps={{
                                        className: classes.po__form__date__input
                                    }}
                                    className={classes.po__form__date}
                                    styles={customStyles}
                                    onChange={this.handleDeliveryDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item sm={9} xs={12}>
                            <TextField
                                id="outlined-multiline-flexible"
                                label={i18n.t('quotes.pos.poDetails.poComments')}
                                multiline
                                rowsMax={8}
                                rows={2}
                                fullWidth
                                className={classes.po__textField__comment}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={poComment || ''}
                                onChange={event => {
                                    this.setState({
                                        poComment: event.target.value
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <ClientQuoteAcceptedItems
                                file={file}
                                items={items}
                                updatePOItems={this.updatePOItems}
                                clientQuoteTaxPercent={file.clientQuote.length > 0 && file.clientQuote[0].taxPercent ? file.clientQuote[0].taxPercent : null}
                            />
                        </Grid>
                    </Grid>

                    <CustomMessages
                        type={'error'}
                        message={apiErrorMessage}
                        clearMessage={() => this.setState({
                            apiErrorMessage: null
                        })}
                    />
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                    <div className={classes.button__wrapper}>
                        {this.state.showRequiredFieldsError && (
                            <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex', marginBottom: '5px' }}>
                                <Typography color='error'>{i18n.t("quotes.quoteRequest.requiredFields")}</Typography>
                            </Grid>
                        )}
                        <div className={classes.button__wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={this.saveClientPO}
                                className={classes.po__form__saveButton}>
                                {i18n.t('quotes.pos.poDetails.saveBtn')}
                            </Button>
                            {loading && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(styles)(ClientPOForm);