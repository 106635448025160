const appVersion = '1.1.16';

const config = {
    appVersion,
    google: {
        mapsAPIKey: process.env.REACT_APP_PRODEK_GOOGLE_MAPS_KEY
    },
    apiGateway: {
        url: process.env.REACT_APP_PRODEK_API,
        api_key: process.env.REACT_APP_PRODEK_API_KEY
    },
    itemsLookUp: {
        peviousQuotesCount: 5
    },
    events: {
        validPastHours: 4,
        validCheckinHours: 24
    }
}
module.exports = config;