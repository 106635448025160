import React, { Component } from 'react'
import {
    Paper,
    withStyles,
    Button,
    Link,
    Divider
} from '@material-ui/core'
import i18n from 'i18next';
import { ClientRFQReadOnly } from '../clientRFQ'
import {
    ClientQuoteReadOnly,
    ClientQuoteForm,
    ClientQuoteItemsList,
    QuoteTotals
} from '.'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
//Mientras metemos sagas
import * as generalActions from '../../../actions/generalActions'
import * as clientQuoteActions from '../../../actions/clientQuoteActions'
import * as apiStatusActions from '../../../actions/apiStatusActions'
import * as filesActions from '../../../actions/filesActions'

import Permissions from '../../../lib/permissions'


const styles = theme => {
    return ({
        preparing__root__paper: {
            padding: theme.spacing(2)
        },
        print__edit__buttons__container: {
            zIndex: 1,
            position: 'absolute',
            right: theme.spacing(2),
            top: theme.spacing(),
            [theme.breakpoints.down('xs')]: {
                position: 'unset',
                padding: '16px 16px 0'
            },
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            [theme.breakpoints.only('xs')]: {
                justifyContent: 'space-between',
            }
        },
        printButton: {
            ...theme.smallButton,
            backgroundColor: theme.palette.grey[100],
            '&:hover': {
                backgroundColor: theme.palette.grey[400],
                color: theme.palette.getContrastText(theme.palette.grey[400]),
            },
            color: theme.palette.getContrastText(theme.palette.grey[100]),
            [theme.breakpoints.up('sm')]: {
                marginRight: theme.spacing(2),
            }
        },
        quote__editLink: theme.customLink,
        closeButton: {
            position: 'absolute',
            top: 0,
            right: theme.spacing(),
        },
        createClientQuote__container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: `${theme.spacing(3)}px`
        },
    })
}

class PreparingQuoteInformation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            editClientQuote: false,
            file: props.file
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            file: nextProps.file
        })
    }


    openEditQuoteDialog = () => {
        this.setState({
            editClientQuote: true
        })
    }

    updateClientQuote = (file) => {
        this.setState({
            editClientQuote: false
        })

        this.props.filesActions.setFile(file)
        this.props.generalActions && this.props.generalActions.showSelectItemVendor(false);
    }

    render() {
        const { classes, editable, general, file } = this.props,
            { editClientQuote } = this.state;

        if (!file) return null;

        let { showSelectItemVendor } = general;

        let items = file.clientQuote[0] ? file.clientQuote[0].items : [],
            creatingClientQuote = items.filter(item => item.amount).length === 0 && file.clientQuote[0] && !file.clientQuote[0].validFor && !file.clientQuote[0].deliveryDays;
        return (
            <Paper className={classes.preparing__root__paper}>
                {!creatingClientQuote && <>
                    <ClientRFQReadOnly
                        file={file}
                        isQuote={true}
                    />

                    {editable &&
                        <Divider />
                    }
                </>}

                <div style={{ position: 'relative' }}>
                    {editable && !creatingClientQuote && Permissions.checkSensitiveInformationPermission() &&
                        <div className={classes.print__edit__buttons__container}>
                            <Link className={classes.quote__editLink}
                                variant='button'
                                onClick={this.openEditQuoteDialog}>
                                {i18n.t('quotes.quoted.edit')}
                            </Link>
                        </div>
                    }

                    {editable && creatingClientQuote && Permissions.checkSensitiveInformationPermission() &&
                        <div className={classes.createClientQuote__container}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.openEditQuoteDialog}>
                                {i18n.t("quotes.preparatingQuoteInformation.itemsConfigured")}
                            </Button>
                        </div>
                    }

                    {!creatingClientQuote &&
                        <>
                            <ClientQuoteReadOnly
                                file={file}
                                preparing={editable}
                            />

                            <ClientQuoteItemsList
                                currencySymbol={file.currencySymbol}
                                items={items.sort((a, b) => {return a.rfqItem.id - b.rfqItem.id})}
                                file={file}
                                clientQuoteTaxPercent={file.clientQuote && file.clientQuote[0] ? file.clientQuote[0].taxPercent : null }
                                openEditQuoteDialog={this.openEditQuoteDialog}
                            />

                            <QuoteTotals
                                currencySymbol={file.currencySymbol}
                                items={items}
                                taxPercent={file.clientQuote && file.clientQuote[0] ? file.clientQuote[0].taxPercent : null}
                                otherCosts={file.clientQuote && file.clientQuote[0] ? file.clientQuote[0].otherCosts : []}
                            />
                        </>
                    }
                </div>

                <ClientQuoteForm
                    file={file}
                    creatingClientQuote={creatingClientQuote}
                    open={editClientQuote || (showSelectItemVendor || false)}
                    editable={editable}
                    updateClientQuote={this.updateClientQuote}
                    onClose={() => {
                        this.setState({
                            editClientQuote: false
                        })
                        this.props.generalActions && this.props.generalActions.showSelectItemVendor(false);
                    }}
                />

            </Paper>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {
        clientQuoteActions: bindActionCreators(clientQuoteActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch),
        filesActions: bindActionCreators(filesActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        file: state.file,
        general: state.general,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PreparingQuoteInformation))