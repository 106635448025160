import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
import {
    CircularProgress,
    Button,
    Modal,
    Typography,
    withStyles
}
    from '@material-ui/core/';
import { UserForm } from '../components/users'
import { Check, Close, AccountCircle } from '@material-ui/icons'
import ReactTable from "react-table";
import API from '../lib/api'
import auth from '../authentication/auth';
import i18n from 'i18next';
import classNames from 'classnames'


const styles = theme => ({
    container: {
        padding: '20px',
        margin: 'auto',
        maxWidth: '650px',
    },
    subTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    centered: {
        textAlign: 'center'
    },
    table: {
        marginTop: '20px',
        textAlign: 'left',
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily
    },
    activeSelect: {
        fontFamily: theme.typography.fontFamily,
        marginTop: '6px'
    },
    circularProgress: {
        marginTop: '20%'
    },
    complete: {
        color: 'green',
        fontSize: '17px',
    },
    incomplete: {
        color: 'red',
        fontSize: '17px',
    },
});


class Users extends Component {

    state = {
        loading: false,
        selectedUser: null,
        modalIsOpen: false,
        logedInUser: null,
        pageSize: 0,
        roles: [],
        filtered: [{
            id: 'userActive',
            value: "1"
        }]
    }

    componentWillMount() {
        this.setState({
            loading: true
        })
        auth.getAuthenticatedUser().then(user => {
            API.Users.getUsers().then(res => {
                let users = res.data.records.users.filter(u=> u.userActive === 1),
                    currentUserIndex = users.findIndex(u => u.userId === parseInt(user.attributes['custom:userID'])),
                    currentUser = users.splice(currentUserIndex, 1)[0];

                users.unshift(currentUser)

                this.setState({
                    loading: false,
                    rows: users,
                    roles: res.data.records.roles,
                    logedInUser: user,
                    pageSize: users.length
                })
            })
        }).catch(err => {
            this.setState({ logedInUser: null })
        })
    }

    openUserModal = (rowInfo) => {
        this.setState({
            modalIsOpen: true,
            selectedUser: rowInfo ? rowInfo.original : null
        });
    }

    closeUserModal = () => {
        this.setState({
            modalIsOpen: false,
            selectedUser: null
        });
    }

    render() {
        const { classes } = this.props;
        const { rows, loading, logedInUser, filtered,
            modalIsOpen, selectedUser, pageSize
        } = this.state

        return (
            <div className={classes.container}>
                <Helmet title={`${i18n.t('companyName')} | ${i18n.t('pageSubTitle.users')}`} />
                <div className={classes.subTitle}>
                    <Typography variant="h5">{i18n.t("users.title")}</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.openUserModal(null)}
                    >
                        {i18n.t("users.newUser")}
                    </Button>
                </div>
                {loading &&
                    <CircularProgress color='secondary' className={classes.circularProgress} size={24} />
                }
                {rows && !loading &&
                    <div className={classes.table}>
                        <ReactTable
                            data={rows}
                            filterable={true}
                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                    style: {
                                        cursor: 'pointer'
                                    },
                                    onClick: (e, handleOriginal) => {
                                        if (rowInfo) {
                                            this.openUserModal(rowInfo);
                                            if (handleOriginal) {
                                                handleOriginal()
                                            }
                                        }
                                    }
                                }
                            }}

                            columns={[
                                {
                                    Header: i18n.t("users.table.username"),
                                    id: 'userLogin',
                                    accessor: row => {
                                        return (
                                            <div style={{ display: 'flex' }}>
                                                {parseInt(logedInUser.attributes['custom:userID']) === row.userId &&
                                                    <AccountCircle style={{ marginRight: '3px' }} />
                                                }
                                                <Typography component='span'>{row.userLogin}</Typography>
                                            </div>
                                        )
                                    },
                                    maxWidth: 100,
                                    filterable: false,
                                    sortable: false
                                },
                                {
                                    Header: i18n.t("users.table.name"),
                                    sortable: false,
                                    id: 'userName',
                                    accessor: row => <Typography component='span'>{row.userName}</Typography>,
                                    filterMethod: function (filter, row) {
                                        return (row[filter.id].props.children).toLowerCase().includes(filter.value.toLowerCase())
                                    },
                                    minWidth: 110
                                },
                                {
                                    Header: i18n.t("users.table.email"),
                                    id: 'userEmail',
                                    accessor: row => <Typography component='span'>{row.userEmail}</Typography>,
                                    sortable: false,
                                    filterMethod: function (filter, row) {
                                        return (row[filter.id].props.children).toLowerCase().includes(filter.value.toLowerCase())
                                    },
                                    minWidth: 110
                                },
                                {
                                    Header: i18n.t("users.table.active"),
                                    accessor: "userActive",
                                    className: classes.centered,
                                    sortable: true,
                                    filterable: true,
                                    Cell: row => (row.value === 1 ? <Check className={classes.complete} /> : <Close className={classes.incomplete} />),
                                    maxWidth: 90,
                                    filterMethod: (filter, row) => {
                                        if (filter.value === "all") {
                                            return true;
                                        }
                                        if (filter.value === "0") {
                                            return !row[filter.id];
                                        }
                                        return row[filter.id];
                                    },
                                    Filter: ({ filter, onChange }) => {
                                        return (
                                            <select
                                                className={classes.activeSelect}
                                                onChange={event => onChange(event.target.value)}
                                                style={{ width: "100%" }}
                                                value={filter ? filter.value : "all"}
                                            >
                                                <option value="all">{i18n.t("users.table.showAll")}</option>
                                                <option value="1">{i18n.t("users.table.active")}</option>
                                                <option value="0">{i18n.t("users.table.inactive")}</option>
                                            </select>
                                        )
                                    }
                                },
                            ]}
                            filtered={filtered}
                            onFilteredChange={(filtered, column) => {
                                this.setState({ filtered })
                            }}
                            minRows={0}
                            defaultPageSize={rows.length}
                            pageSize={pageSize}
                            onPageSizeChange={(pageSize, page) =>
                                this.setState({ page, pageSize })
                            }
                            className={classNames(classes.table, ' -striped -highlight')}
                            showPaginationTop={false}
                            showPaginationBottom={false}
                        />
                    </div>
                }

                <Modal
                    role="dialog"
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={modalIsOpen}
                    onClose={this.closeUserModal}
                >
                    <UserForm
                        handleClose={this.closeUserModal}
                        user={selectedUser}
                        roles={this.state.roles}
                        allowChangePassword={selectedUser ? parseInt(logedInUser.attributes['custom:userID']) === selectedUser.userId : false}
                    />
                </Modal>
            </div>
        );
    }
}

export default withStyles(styles)(Users);
