import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    withStyles,
    Typography
} from '@material-ui/core/';
import i18n from 'i18next'
import { CustomSelect } from '../../general'


const styles = theme => ({
    vendor__quote__items__table: {
        marginTop: '16px',
        fontSize: theme.typography.fontSize * 0.8,
        fontFamily: theme.typography.fontFamily,
        textAlign: 'left',
        border: `solid thin ${theme.palette.grey[300]}`
    },
    vendor__quote__items__table__cell__center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    vendor__quote__items__table__itemPartNumber: {
        color: theme.palette.naval.main
    },
    vendor__quote__items__table__quantity: {
        color: theme.palette.text.secondary
    },
    customLink: {
        ...theme.customLink,
        fontSize: '16px'
    },
})

class ClientQuoteSelectItemsByVendor extends React.Component {

    state = {
        vendorQuotes: [],
        selectedVendor: null,
        vendors: [],
        vendorQuoteItems: []
    }

    componentWillReceiveProps(nextProps) {
        const { file, items } = nextProps;

        if (!file) return null;

        let vendorQuotes = [];

        let itemsRFQIds = items.map((item, i) => {
            return item.rfqItem.id
        })

        vendorQuotes = file.vendorQuotes.filter((vq) => {
            return (
                vq.items.filter(item => itemsRFQIds.includes(item.rfqItem.id)).length > 0 && vq.status.id === 3
            )
        });

        let vendors = vendorQuotes.map((vq, i) => {
            return {
                id: vq.vendor.id,
                name: vq.vendor.name,
                vendorQuoteId: vq.id
            }
        });

        this.setState({
            vendorQuotes,
            vendors
        })
    }


    onConfirm = () => {
        const { items, file } = this.props,
              { vendors, selectedVendor, vendorQuotes } = this.state;
            
        let newItems = items.map((item,index) => {
            if (selectedVendor) {
                let vendorQuoteItem = vendorQuotes
                        .find(vq => vq.id === selectedVendor.value).items
                        .find(it => it.rfqItem.id === item.rfqItem.id);

                if(vendorQuoteItem){
                    return {
                        ...item,
                        markupPercent: file && file.status > 4 ? parseFloat(((item.amount - parseFloat(vendorQuoteItem.unitAmount + (item.weightedCost ? item.weightedCost / item.rfqItem.quantity : 0))) * 100) / parseFloat(vendorQuoteItem.unitAmount + (item.weightedCost ? item.weightedCost / item.rfqItem.quantity : 0))) : item.markupPercent,
                        vendorQuoteItemId: vendorQuoteItem.id,
                        amount: file && file.status > 4 ? parseFloat(item.amount) : vendorQuoteItem.unitAmount,                        
                        noVendorComments: '',
                        unitAmount: vendorQuoteItem.unitAmount + (item.weightedCost ? item.weightedCost / item.rfqItem.quantity : 0),                        
                        acceptedVendorQuoteItem: {
                            ...item.acceptedVendorQuoteItem,
                            vendor: {
                                name: vendors.find(v => v.name === selectedVendor.label).name,
                                id: vendors.find(v => v.name === selectedVendor.label).id
                            },
                            unitAmount: vendorQuoteItem.unitAmount,
                            deliveryDays: vendorQuoteItem.deliveryDays,
                            quantity: vendorQuoteItem.quantity,
                            unit: vendorQuoteItem.unit
                        }
                    }
                } else {
                    return {
                        ...item
                    }  
                }
            } else {
                return {
                    ...item,
                    vendorQuoteItemId:  null,
                    amount: 0,
                    unitAmount: 0,
                    markupPercent: 0,
                    acceptedVendorQuoteItem: {}
                }
            } 
        })

        this.props.onConfirm(newItems);
        this.props.handleClose()
    }

    selectVendor=(vendor)=>{
        const { vendorQuotes } = this.state;

        if(vendor){
            let vendorQuote = vendorQuotes.find(vq => vq.id === vendor.value);

            this.setState({
                selectedVendor: vendor,
                vendorQuoteItems: vendorQuote.items,
            })
        } else {
            this.setState({
                selectedVendor: null
            })
        }
    }


    render() {
        const { file,
            open
        } = this.props,
            { vendors, 
                selectedVendor, 
                vendorQuoteItems } = this.state;

        if (!file || vendors.length === 0) return null;

        let vendorPO = null
        if (file.status > 4 && selectedVendor){
            vendorPO = file.vendorPOs.find(vp => vp.vendorQuoteId === selectedVendor.value && vp.status.id > 2)
        }

        return (
            <Dialog
                open={open}
                maxWidth={'sm'}
                fullWidth={true}
                scroll='paper'
                onExit={()=>{
                    this.setState({
                        selectedVendor: null
                    })
                }}
                aria-labelledby="vendorList"
                aria-describedby="Vendors Lists"
            >
                <DialogTitle id="alert-dialog-title" disableTypography={true}>
                    {file ? `${i18n.t('quotes.preparing.file')} ${file.id} - ` : ''}{i18n.t('quotes.preparatingQuoteInformation.selectAVendor')}
                </DialogTitle>

                <DialogContent>
                    <div style={{marginTop: '16px'}}>
                        <Typography variant='body2' color='textSecondary' style={{marginBotton: '8px'}}>
                            {i18n.t('quotes.preparing.selectVendorMessage')}
                        </Typography>

                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{width: '300px'}}>
                                <CustomSelect 
                                    disabled={vendorPO ? true : false}
                                    options={vendors.map(vendor => ({ label: vendor.name, value: vendor.vendorQuoteId }))}
                                    label={i18n.t('quotes.preparing.vendors')}
                                    value={selectedVendor ? selectedVendor : null}
                                    onSelect={(vendor) => this.selectVendor(vendor)}
                                />
                            </div>
                            {selectedVendor &&
                                <Typography variant='body1' color='textPrimary'>{`${vendorQuoteItems.length} ${i18n.t('quotes.preparing.itemsSelected')}`}</Typography>
                            }
                        </div>

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={this.props.handleClose}>
                        {i18n.t("quotes.preparatingQuoteInformation.cancelBtn")}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={vendorPO ? true : false}
                        onClick={this.onConfirm}>
                        {i18n.t('quotes.preparatingQuoteInformation.selectItemByVendorDone')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ClientQuoteSelectItemsByVendor);