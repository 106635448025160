import React, { Component } from 'react'
import moment from "moment";
import {
    CircularProgress,
    Typography,
    withStyles,
    withMobileDialog,
    Divider,
    IconButton,
    Box,
} from '@material-ui/core/';
import {
    Inbox,
    Warning,
    KeyboardArrowLeft,
    KeyboardArrowRight,
} from '@material-ui/icons'
import { withRouter } from "react-router";
import ReactTable from "react-table";
import i18n from 'i18next';
import classNames from 'classnames'
import { EventDetails } from '.'
import { eventIsInValidCheckinTime } from '../../lib/utils'

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as eventsActions from '../../actions/eventsActions'
import * as generalActions from '../../actions/generalActions'


const styles = theme => ({
    centered: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    table: {
        textAlign: 'left',
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily
    },
    activeSelect: {
        fontFamily: theme.typography.fontFamily,
        marginTop: '6px'
    },
    circularProgress: {
        marginTop: '20%'
    },
    done_icon: {
        color: 'green',
        fontSize: '20px',
    },
    scheduled_icon: {
        color: 'blue',
        fontSize: '20px',
    },
    header__actions__container: {
        margin: '0 8px',
        display: 'flex',
        // width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    search__root: {
        margin: '10px 0',
        maxWidth: 400,
        width: '100%',
        padding: '0 10px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    search__input: {
        width: '90%'
    },
    empty__state__container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: 100,
        paddingBottom: 100
    },
    divider: {
        border: `1px solid ${theme.palette.primary.secondary}`
    },
    event__status__icon__scheduled: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.red[100],
    },
    event__status__icon__canceled: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        [theme.breakpoints.down('sm')]: {
            marginRight: 0
        },
    },
    event__status__icon__ready: {
        marginRight: theme.spacing(),
        fontSize: '10pt',
        color: theme.palette.green[100],
    },
    card: {
        maxWidth: '100%',
        textAlign: 'left',
        borderRadious: 0,
        // marginTop: theme.spacing(2)
    },
    card__paper: {
        borderRadious: 0,
    },
    card__content: {

    },
    event__paper__section: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: '1px 0 0'
        },
    },
    events__list: {
        paddingTop: 0,
        marginBottom: 50,
    },
    events__list__item: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#fff'
    },
    events__list__date__container: {
        width: '20vw',
        margin: '0 10px 0 -15px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    events__list__info__container: {
        width: '80vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        position: 'relative',
        paddingLeft: 10
    },
    events__list__user__color: {
        width: 5,
        position: 'absolute',
        left: -5,
        bottom: 2,
        top: 2,
    },
    date__navigator__container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 10px',
        backgroundColor: '#f7f7f7'
    },
    date__navigator__icon: {
        fontWeight: 100,
        color: '#007bff'
    },
    event__missing__visit__indicator: {
        color: theme.palette.red[80],
        position: 'absolute',
        right: 5,
        bottom: 5,
        fontSize: '16px'
    }
});

class EventsList extends Component {

    constructor(props) {
        super(props);
        const { params } = props.match;

        let events = props.events ? props.events.filter(event => event.visit.status.id === 1 || !event.visit.status.id || event.visit.status.id === 2) : [];

        let currentTab;
        switch (params.subsection) {
            case 'scheduled':
                currentTab = 0;
                events = props.events ? props.events.filter(event => event.visit.status.id === 1 || !event.visit.status.id || event.visit.status.id === 2) : [];
                break;
            case 'past':
                currentTab = 1;
                events = props.events ? props.events.filter(event => event.isVisit === 1 && eventIsInValidCheckinTime({ event }) && (!event.checkIn.dateTime || !event.checkOut.dateTime)) : [];
                break;
            case 'cancelled':
                currentTab = 2;
                events = props.events ? props.events.filter(event => event.visit.status.id === 3) : [];
                break;
            case 'recently':
                currentTab = 3;
                events = props.events ?
                    props
                        .events
                        .filter(event => event.visit.status.id === 1 || !event.visit.status.id || event.visit.status.id === 2)
                        .sort((a, b) => {
                            if (a.lastUpdate < b.lastUpdate) return 1;
                            if (a.lastUpdate > b.lastUpdate) return -1;
                            return 0
                        })
                    :
                    [];
                break;
            case 'reports':
                currentTab = 4;
                events = props.events ? props.events.filter(event => event.visit.results || event.visit.nextSteps) : [];
                break;
            default:
                currentTab = 0;
                break;
        }

        this.state = {
            events: events,
            loading: props.loading,
            originalEvents: props.events,
            pageSize: props.events ? events.length : 0,
            openEventDetails: false,
            selectedUsersIds: props.selectedUsersIds || [],
            currentTab: currentTab,
        }
    }


    componentWillReceiveProps(nextProps) {
        let selectedTab = this.state.currentTab;
        const { params } = nextProps.match;

        let events = nextProps.events ? nextProps.events.filter(event => event.visit.status.id === 1 || !event.visit.status.id || event.visit.status.id === 2) : [];

        switch (params.subsection) {
            case 'scheduled':
                selectedTab = 0;
                events = nextProps.events ? nextProps.events.filter(event => event.visit.status.id === 1 || !event.visit.status.id || event.visit.status.id === 2) : [];
                break;
            case 'past':
                selectedTab = 1;
                events = nextProps.events ? nextProps.events.filter(event => event.isVisit === 1 && eventIsInValidCheckinTime({ event }) && (!event.checkIn.dateTime || !event.checkOut.dateTime)) : [];
                break;
            case 'cancelled':
                selectedTab = 2;
                events = nextProps.events ? nextProps.events.filter(event => event.visit.status.id === 3) : [];
                break;
            case 'recently':
                selectedTab = 3;
                events = nextProps.events ?
                    nextProps
                        .events
                        .filter(event => event.visit.status.id === 1 || !event.visit.status.id || event.visit.status.id === 2)
                        .sort((a, b) => {
                            if (a.lastUpdate < b.lastUpdate) return 1;
                            if (a.lastUpdate > b.lastUpdate) return -1;
                            return 0
                        })
                    :
                    [];
                break;
            case 'reports':
                selectedTab = 4;
                events = nextProps.events ? nextProps.events.filter(event => event.visit.results || event.visit.nextSteps) : [];
                break;
            default:
                selectedTab = 0;
                break;
        }



        this.setState({
            loading: nextProps.loading,
            originalEvents: nextProps.events,
            currentTab: selectedTab,
            events: events,
            pageSize: events.length,
            selectedUsersIds: nextProps.selectedUsersIds || []
        })
    }

    openEventDetailsModal = (event) => {
        this.props.eventsActions.setEvent(event)

        this.setState({
            openEventDetails: true
        })
    }

    updateEvent = (event) => {
        this.setState({
            selectedEvent: event
        })
        this.props.updateEvent(event)
    }

    changeTab = (value) => {
        const { originalEvents } = this.state;

        this.setState({
            events: originalEvents.filter(event => value === 0 ? !event.visit.status.id || event.visit.status.id === value + 1 : event.visit.status.id === value + 1),
            pageSize: originalEvents.filter(event => value === 0 ? !event.visit.status.id || event.visit.status.id === value + 1 : event.visit.status.id === value + 1).length,
            currentTab: value
        })
    }

    onFormClose = () => {
        this.setState({
            openEventDetails: false
        }, () => {
            this.props.eventsActions.clearEvent(null);
        })
    }

    previousMonth = () => {
        const { month,
            year
        } = this.props;
        let selectedDate = moment(`${year}-${month >= 10 ? month : `0${month}`}-01`),
            newDate = selectedDate.add(-1, 'month');
        this.props.changeDate(newDate)
    }
    nextMonth = () => {
        const { month,
            year
        } = this.props;
        let selectedDate = moment(`${year}-${month >= 10 ? month : `0${month}`}-01`),
            newDate = selectedDate.add(1, 'month');
        this.props.changeDate(newDate)
    }

    render() {
        const {
            classes,
            month,
            year
        } = this.props;
        const {
            loading,
            //pageSize, 
            openEventDetails,
            currentTab,
            events } = this.state;

        let selectedDate = moment(`${year}-${month >= 10 ? month : `0${month}`}-01`);
        return (
            <div>
                {loading &&
                    <CircularProgress color='secondary' className={classes.circularProgress} size={24} />
                }
                {events && !loading &&
                    <div>
                        {/* COLOCAR AQUI EL FILTRO PARA DESKTOP */}
                        <div className={classes.header__actions__container} >
                            <Typography variant='body2' color='textSecondary' style={{
                                margin: 10,
                                textAlign: 'left'
                            }}>
                                {currentTab === 0 && <>{i18n.t('visits.statusLabels.scheduled')}</>}
                                {currentTab === 1 && <>{i18n.t('visits.statusLabels.missed')}</>}
                                {currentTab === 2 && <>{i18n.t('visits.statusLabels.cancelled')}</>}
                                {currentTab === 3 && <>{i18n.t('visits.statusLabels.recently')}</>}
                                {currentTab === 4 && <>{i18n.t('visits.statusLabels.reports')}</>}
                            </Typography>
                        </div>
                        <Divider />
                        <Box className={classes.date__navigator__container}>
                            <div>
                                <IconButton size='small' className={classes.iconButton}
                                    onClick={this.previousMonth}>
                                    <KeyboardArrowLeft className={classes.date__navigator__icon} />
                                </IconButton>
                            </div>
                            <div style={{ flex: 1, }}>
                                <Typography variant='h6'>{`${selectedDate.format("MMMM")} ${selectedDate.format('YYYY')}`}</Typography>
                            </div>
                            <div>
                                <IconButton size='small' className={classes.iconButton}
                                    onClick={this.nextMonth}>
                                    <KeyboardArrowRight className={classes.date__navigator__icon} />
                                </IconButton>
                            </div>
                        </Box>

                        <Divider />

                        {events && events.length > 0 ?
                            <>
                                <ReactTable
                                    data={events}
                                    getTdProps={(state, rowInfo, column, instance) => {
                                        return {
                                            style: {
                                                cursor: 'pointer'
                                            },
                                            onClick: (e, handleOriginal) => {
                                                if (rowInfo) {
                                                    this.openEventDetailsModal(rowInfo.original);
                                                    if (handleOriginal) {
                                                        handleOriginal()
                                                    }
                                                }
                                            }
                                        }
                                    }}

                                    columns={[
                                        {
                                            Header: i18n.t("visits.table.date"),
                                            id: 'date',
                                            accessor: row => {
                                                return (
                                                    <div>
                                                        <Typography
                                                            variant='body2'
                                                            color='textSecondary'>
                                                            {moment.utc(row.from).format('ddd, MMM Do')}
                                                        </Typography>
                                                        <Typography
                                                            variant='body2'
                                                            color='textSecondary'>
                                                            {moment.utc(row.from).format('hh:mm a')}
                                                        </Typography>
                                                    </div>
                                                )
                                            },
                                            maxWidth: 170,
                                            filterable: false,
                                            sortable: false,
                                            resizable: false,
                                        },
                                        {
                                            Header: i18n.t("visits.table.client"),
                                            id: 'client',
                                            accessor: row => {
                                                return (
                                                    <div>
                                                        <Typography variant='body1' color='textPrimary' noWrap style={{ fontWeight: 'bold' }}>
                                                            {row.isVisit ? row.client.name : ''}
                                                        </Typography>
                                                        {row.clientContact.id && (
                                                            <Typography variant='body2' color='textSecondary'>{`${row.clientContact.firstName || ''} ${row.clientContact.lastName || ''}`}</Typography>
                                                        )}

                                                    </div>
                                                )
                                            },
                                            minWidth: 100,
                                            filterable: false,
                                            sortable: false
                                        },
                                        {
                                            Header: i18n.t("visits.table.event"),
                                            id: 'event',
                                            accessor: row => {
                                                return (
                                                    <div style={{
                                                        position: 'relative',
                                                        height: '100%'
                                                    }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Typography variant='body1' color='textPrimary' noWrap>
                                                                {row.title}
                                                            </Typography>
                                                        </div>
                                                        {currentTab !== 4 && (
                                                            <>
                                                                <Typography variant='body2' color='textSecondary'>{row.isVisit ? row.visit.type : ''}</Typography>
                                                                <Typography variant='body2' color='textSecondary'>
                                                                    <strong>{row.isVisit && row.checkIn.address ? `${i18n.t("visits.table.checkIn")}  ` : ''}</strong>
                                                                    {row.isVisit && row.checkIn.address ? moment(row.checkIn.dateTime).format('hh:mm a') : ''}
                                                                </Typography>
                                                            </>
                                                        )}
                                                        {row.isVisit === 1 && eventIsInValidCheckinTime({ event: row }) && (!row.checkIn.dateTime || !row.checkOut.dateTime) &&
                                                            <Warning className={classes.event__missing__visit__indicator} />
                                                        }
                                                        {currentTab === 4 && (row.visit && (row.visit.results || row.visit.nextSteps)) && (
                                                            <>
                                                                {row.visit.results && (
                                                                    <>
                                                                        <Typography variant="body2" color='textSecondary'>
                                                                            Results:
                                                                </Typography>
                                                                        <Typography variant="body2" color='textPrimary' style={{
                                                                            whiteSpace: 'pre-line'
                                                                        }}>
                                                                            {row.visit.results}
                                                                        </Typography>
                                                                    </>
                                                                )}
                                                                {row.visit.nextSteps && (
                                                                    <>
                                                                        <Typography variant="body2" color='textSecondary'>
                                                                            Next Steps:
                                                                </Typography>
                                                                        <Typography variant="body2" color='textPrimary' style={{
                                                                            whiteSpace: 'pre-line'
                                                                        }}>
                                                                            {row.visit.nextSteps}
                                                                        </Typography>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                )
                                            },
                                            sortable: false,
                                            filterable: false,
                                            minWidth: 110
                                        },
                                        {
                                            Header: i18n.t("visits.table.user"),
                                            id: 'user',
                                            style: {
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            },
                                            accessor: row => <Typography component='span'>{row.user.name}</Typography>,
                                            sortable: false,
                                            filterable: false,
                                            resizable: false,
                                            minWidth: 50
                                        }
                                    ]}
                                    minRows={0}
                                    defaultPageSize={events.length}
                                    pageSize={events.length}
                                    filterable={false}
                                    onPageSizeChange={(pageSize, page) =>
                                        this.setState({ page, pageSize })
                                    }
                                    className={classNames(classes.table, ' -striped -highlight')}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                />
                            </> :
                            <div className={classes.empty__state__container}>
                                <Inbox style={{ fontSize: 50, color: '#cdcdcd' }} />
                                <Typography variant='body2' color='textSecondary'>
                                    {currentTab === 0 && <>{i18n.t('visits.noEventsScheduled')}</>}
                                    {currentTab === 1 && <>{i18n.t('visits.noEventsMissed')}</>}
                                    {currentTab === 2 && <>{i18n.t('visits.noEventsCanceled')}</>}
                                    {currentTab === 3 && <>{i18n.t('visits.noEventsScheduled')}</>}
                                    {currentTab === 4 && <>{i18n.t('visits.noEventsScheduled')}</>}
                                </Typography>
                            </div>
                        }
                    </div>
                }

                <EventDetails
                    open={openEventDetails}
                    updateEvent={this.props.updateEvent}
                    deleteEvent={this.props.deleteEvent}
                    logedInUser={this.props.logedInUser}
                    onClose={this.onFormClose}
                />
            </div>
        );
    }
};

function mapStateToProps(state) {
    return {
        event: state.event,
        events: state.events,
        people: state.people
    }
}

function mapDispatchToProps(dispatch) {
    return {
        eventsActions: bindActionCreators(eventsActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withMobileDialog()(withStyles(styles)(EventsList))));
