import React, { Component } from 'react'
import moment from "moment";
import ReactTable from "react-table";
import {
    TextField,
    Typography,
    Hidden,
    List,
    ListItem,
    ListItemText,
    Paper,
    Divider,
    Link,
    withStyles,
    Tabs,
    Tab,
    InputAdornment,
    Fade,
    CircularProgress
} from '@material-ui/core';
import { Inbox, OpenInNew, Search } from '@material-ui/icons'
import i18n from 'i18next';
import classNames from 'classnames'
import NumberFormat from 'react-number-format'
import { TasksShortcut } from './common'
import Permissions from '../../lib/permissions'
import HeaderSummary from './HeaderSummary';
import API from '../../lib/api';

//REDUX
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../actions/generalActions'


const styles = theme => {
    return ({
        flexCell: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        table: {
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily
        },
        list__fileId: {
            fontWeight: 'bold',
        },
        list__fileDate: {

        },
        list__fileName: {

        },
        list__fileReference: {

        },
        list__fileClient: {
            fontWeight: 'bold',
        },
        list__fileAuthor: {
            color: theme.palette.naval.main,
        },
        list__vendorsTitle: {
            fontWeight: 'bold',
        },
        quoted__noRows: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh'
        },
        table__cell__rigth: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        table__cell__total: {
        },
        rfq__table__fileLink: {
            color: theme.palette.blue[100],
            cursor: 'pointer'
        },
        rfq__table__fileLink__disabled: {
            color: theme.palette.text.primary,
            cursor: 'default'
        },
        rfq__notification__icon: {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.red[100],
        },
        'rfq__notification__icon--yellow': {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.orange[100],
        },
        'rfq__notification__icon--red': {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.red[100],
        },
        list__file__lastUpdate__container: {
            position: 'absolute', bottom: 0, right: 0
        },
        list__file__lastUpdate: {
            backgroundColor: theme.palette.grey[100],
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.grey[400]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--late': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.orange[60]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.orange[30],
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--veryLate': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.red[100]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.red[50],
            color: theme.palette.getContrastText(theme.palette.red[50]),
        },
        rfq__table__fileDescriptions: {
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        rfq__table__fileDatesContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        alert__tasks__button__icon: {
            position: 'absolute', right: '0', top: '0',
            cursor: 'pointer',
            '&:hover': {
                filter: 'brightness(1.1);'
            }
        },
        alert__tasks__button__icon__mobile: {
            position: 'absolute', right: '-16px', top: '-8px',
            cursor: 'pointer',
            '&:hover': {
                filter: 'brightness(1.1);'
            }
        },
        customLink: theme.customLink,
        list__fileDetails: { position: 'relative' },
        list__countryContainer: {
            position: 'absolute',

            [theme.breakpoints.up('sm')]: {
                right: 0,
                top: 0,
            },
            [theme.breakpoints.down('sm')]: {
                right: theme.spacing(),
                bottom: 0
            }

        },
        list__fileCountry: { fontSize: '9pt' },
        country__select: {
            width: '100%'
        },
        "list__quotes__status--readyToQuote": {
            color: theme.palette.green[100],
        },
        preparing__header__section: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: theme.spacing(3),
        },
        preparing__header__section__button: {
            borderRadius: '0px'
        },
        'preparing__header__section__button--selected': {
            backgroundColor: '#efe9c3',
            border: `1px solid ${theme.palette.primary.light}`,
            borderBottom: 'none',
            color: theme.palette.text.primary,
            borderRadius: '0px'
        },
        divider: {
            marginBottom: theme.spacing(2),
            backgroundColor: theme.palette.primary.light
        },
        clientQuotes__tabs__divider: {
            marginBottom: theme.spacing(2),
            border: `1px solid ${theme.palette.secondary.main}`
        },
        empty__state__container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        total_grid: {
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'space-around',
            textAlign: 'right'
        },
        totalRow: {
            fontWeight: 'bold'
        },
        row__currency: {
            position: 'absolute',
            top: 0,
            right: theme.spacing()
        },
        circularProgress: {
            marginTop: '20%'
        },
    })
};

class Quoted extends Component {
    state = {
        rows: this.props.files,
        preparingRows: [],
        quotedRows: [],
        acceptedRows: [],
        currentTab: 0,
        fetching: false,
    }

    componentWillMount() {
        const { files } = this.props;
        let preparing = files.filter(row => row.status === 3),
            quoted = files.filter(row => row.status === 4),
            accepted = files.filter(row => row.status === 9),
            rows = preparing,
            currentTab = this.state.currentTab;

        if (preparing.length === 0 && quoted.length > 0) {
            rows = quoted;
            currentTab = 1;
        }
        this.setState({
            preparingRows: preparing,
            quotedRows: quoted,
            acceptedRows: accepted,
            rows: rows,
            currentTab
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            preparingRows: nextProps.files.filter(row => row.status === 3),
            quotedRows: nextProps.files.filter(row => row.status === 4),
            acceptedRows: nextProps.files.filter(row => row.status === 9),
            rows: this.state.currentTab === 0 ? nextProps.files.filter(row => row.status === 3) : this.state.currentTab === 1 ? nextProps.files.filter(row => row.status === 4) : nextProps.files.filter(row => row.status === 9),
            currentTab: this.state.currentTab
        })
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    quoteDateFormat = (dt) => {
        return moment(dt).format('MMM Do, YYYY')
    }

    groupBy = (e, value) => {
        const { files } = this.props;

        let status = value === 0 ? 3 : value === 1 ? 4 : 9

        this.setState({
            currentTab: value,
            rows: files.filter(row => row.status === status)
        })
    }

    fetchQuotesByStatus = (status, params) => {
        return new Promise((resolve, reject) => {
            if (!this.state.fetching) {
                this.setState({
                    fetching: true
                }, _ => {
                    API.Files.getFiles({
                        recordCount: 50,
                        offset: 0,
                        status: status,
                        params: params
                    }).then(res => {
                        let rows = this.state.currentTab === 0 ? res.data.records.filter(row => row.status === 3) : this.state.currentTab === 1 ? res.data.records.filter(row => row.status === 4) : res.data.records.filter(row => row.status === 9)

                        this.setState({
                            rows,
                            fetching: false
                        })
                    })
                })
            } else {
                resolve();
            }
        })
    }

    handleDateFilter=({selectedDateTo, selectedDateFrom, selectedCountry, selectedClient, selectedSalesPerson})=>{
        this.fetchQuotesByStatus(3, {selectedDateTo, selectedDateFrom, selectedCountry, selectedClient, selectedSalesPerson})
    }


    render = () => {
        const { classes, setSearchParmeters } = this.props,
            { rows, currentTab, preparingRows, quotedRows, fetching } = this.state;

        let countries = currentTab === 0 ? preparingRows.map(r => r.client.country.code).filter((x, i, a) => a.indexOf(x) === i) : quotedRows.map(r => r.client.country.code).filter((x, i, a) => a.indexOf(x) === i),
            bigTotalUSD = rows.reduce(((total, row) => parseFloat(total) + (row.currency === 'USD' ? parseFloat(row.totals.clientQuoteTotals.total || 0) : 0)), 0),
            bigTotalEU = rows.reduce(((total, row) => parseFloat(total) + (row.currency === 'EUR' ? parseFloat(row.totals.clientQuoteTotals.total || 0) : 0)), 0),
            otherCostTotalUSD = rows.reduce(((total, row) => parseFloat(total) + (row.currency === 'USD' ? parseFloat(row.totals.clientQuoteTotals.otherCostTotal || 0) : 0)), 0),
            otherCostTotalEU = rows.reduce(((total, row) => parseFloat(total) + (row.currency === 'EUR' ? parseFloat(row.totals.clientQuoteTotals.otherCostTotal || 0) : 0)), 0),
            profitTotalDol = rows.reduce(((total, row) => parseFloat(total) + (row.currency === 'USD' ? parseFloat(row.totals.clientQuoteTotals.profitTotal || 0) : 0)), 0),
            profitTotalEu = rows.reduce(((total, row) => parseFloat(total) + (row.currency === 'EUR' ? parseFloat(row.totals.clientQuoteTotals.profitTotal || 0) : 0)), 0);

        return (
            <>
                {this.props.rows && this.props.rows.length > 0 && (
                    <div>
                        <Hidden xsDown implementation="css">

                            <Tabs
                                className={classes.quotes__statusTabs}
                                value={currentTab}
                                onChange={this.groupBy}
                                indicatorColor="secondary"
                                textColor="secondary"
                            >

                                <Tab label={`${preparingRows ? preparingRows.length : null} ${i18n.t('quotes.quoted.preparing')}`}
                                    style={{ width: 120 }}
                                />
                                <Tab label={`${quotedRows ? quotedRows.length : null} ${i18n.t('quotes.quoted.ready')}`}
                                    style={{ width: 120 }}
                                />
                                {/* <Tab label={`${acceptedRows ? acceptedRows.length : null} ${i18n.t('quotes.quoted.accepted')}`}
                                    style={{ width: 120 }}
                                /> */}
                            </Tabs>
                            <Divider className={classes.clientQuotes__tabs__divider} />

                            { ((preparingRows.length > 0 && currentTab === 0) || (quotedRows.length > 0 && currentTab === 1)) && 
                                <HeaderSummary
                                    totalUSD={bigTotalUSD}
                                    totalEU={bigTotalEU}
                                    otherCostTotalUSD={otherCostTotalUSD}
                                    otherCostTotalEU={otherCostTotalEU}
                                    profitUSD={profitTotalDol}
                                    profitEU={profitTotalEu}
                                    handleDateFilter={this.handleDateFilter}
                                    countries={countries}
                                />
                            }

                            {fetching && (
                                <Fade in={fetching}>
                                    <CircularProgress color='secondary' className={classes.circularProgress} size={24} />
                                </Fade>
                            )}

                            {rows && rows.length > 0 && !fetching &&
                                <ReactTable
                                    data={rows}
                                    columns={[
                                        {
                                            Header: i18n.t("quotes.quoted.table.column1"),
                                            id: 'file',
                                            width: 150,
                                            filterable: true,
                                            style: {
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                            },
                                            accessor: row => (
                                                <div className={classes.rfq__table__fileDescriptions}>
                                                    <Link
                                                        variant="body2"
                                                        className={classes.rfq__table__fileLink}
                                                        onClick={() => {
                                                            this.props.handleShowQuote(row.id)
                                                        }}
                                                        underline={'hover'}
                                                    >
                                                        <strong>{i18n.t("quotes.requestedByClient.table.file")} {`${row.id}`}</strong>
                                                    </Link>
                                                    { row.clientQuote[0] && row.clientQuote[0].date && <>
                                                        <div className={classes.rfq__table__fileDatesContainer}>
                                                            <Typography
                                                                variant='body2'
                                                                color='textSecondary'>
                                                                {this.quoteDateFormat(row.clientQuote[0].date)}
                                                            </Typography>
                                                        </div>
                                                    
                                                        <div className={classes.list__file__lastUpdate__container}>
                                                            <Typography className={moment().diff(moment(row.clientQuote[0].date), 'days') < 2 ? classes.list__file__lastUpdate : (moment().diff(moment(row.clientQuote[0].date), 'days') < 4 ? classes['list__file__lastUpdate--late'] : classes['list__file__lastUpdate--veryLate'])} >
                                                                {moment(row.clientQuote[0].date).fromNow(true)}
                                                            </Typography>
                                                        </div>
                                                    </>}
                                                    <TasksShortcut
                                                        row={row}
                                                        handleShowQuote={this.props.handleShowQuote}
                                                    />
                                                </div>),
                                            Filter: ({ filter, onChange }) => (
                                                <div className={classes.flexCell}>
                                                    <TextField
                                                        id='txtClientQuoteID'
                                                        autoComplete='off'
                                                        style={{ flex: 1 }}
                                                        onChange={(e) => onChange(e.target.value)}
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Search style={{ fontSize: '12px' }} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            ),
                                            filterMethod: (filter, row) => {
                                                let { value } = filter;
                                                setSearchParmeters({ quote: value });
                                                return `${row._original.id}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 || `${this.quoteDateFormat(row._original.requestForQuote.rfqDateTime)}`.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                            }
                                        },
                                        {
                                            Header: i18n.t("quotes.quoted.table.column2"),
                                            filterable: true,
                                            id: 'quoteClientName',
                                            accessor: row => (
                                                <div className={classes.list__fileDetails}>
                                                    <Typography className={classes.list__fileClient} noWrap>{row.client.name}</Typography>
                                                    <Typography className={classes.list__fileName}>{`${row.requestForQuote.name}`}</Typography>
                                                    <Typography className={classes.list__fileReference}>{`Ref: ${row.requestForQuote.reference}`}</Typography>
                                                </div>
                                            ),
                                            style: {
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                            },
                                            Filter: ({ onChange }) => (
                                                <div className={classes.flexCell}>
                                                    <TextField
                                                        id='txtQuoteClientName'
                                                        autoComplete='off'
                                                        style={{ flex: 1 }}
                                                        onChange={(e) => onChange(e.target.value)}
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Search style={{ fontSize: '12px' }} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            ),
                                            filterMethod: (filter, row) => {
                                                let { value } = filter;
                                                setSearchParmeters({ client: value });
                                                return (`${row._original.requestForQuote.reference}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                                    `${row._original.requestForQuote.name}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                                    (row._original.status === 3 ? ('preparing').indexOf(value.toLowerCase()) >= 0 : ('ready to quote').indexOf(value.toLowerCase()) >= 0))
                                            }
                                        },
                                        {
                                            Header: i18n.t("quotes.requestedByClient.table.country"),
                                            filterable: false,
                                            id: 'country',
                                            width: 60,
                                            accessor: row => (
                                                <Typography className={classes.list__fileCountry} color='textSecondary' noWrap>{row.client.country.code}</Typography>
                                            )
                                        },
                                        {
                                            Header: i18n.t("quotes.quoted.table.column4"),
                                            width: 100,
                                            show: Permissions.checkSensitiveInformationPermission(),
                                            id: 'OtherCost',
                                            className: classNames(classes.table__cell__rigth, classes.table__cell__total),
                                            accessor: row => (
                                                <div>
                                                    {row.totals.clientQuoteTotals.otherCostTotal && row.totals.clientQuoteTotals.otherCostTotal !== 0 ?
                                                        <NumberFormat value={row.totals.clientQuoteTotals.otherCostTotal}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                        />
                                                        :
                                                        ''
                                                    }
                                                </div>
                                            )
                                        },
                                        {
                                            Header: i18n.t("quotes.quoted.table.column5"),
                                            width: 100,
                                            heigth: 50,
                                            id: 'Quoted',
                                            show: Permissions.checkSensitiveInformationPermission(),
                                            className: classNames(classes.table__cell__rigth, classes.table__cell__total),
                                            accessor: row => (
                                                <div>
                                                    {row.totals.clientQuoteTotals.total ?
                                                        <NumberFormat value={(row.totals.clientQuoteTotals.total - (row.totals.clientQuoteTotals.profitTotal || 0))}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                        />
                                                        :
                                                        ''
                                                    }
                                                </div>
                                            )
                                        },
                                        {
                                            Header: i18n.t("quotes.quoted.table.column6"),
                                            width: 80,
                                            id: 'Profit',
                                            show: Permissions.checkSensitiveInformationPermission(),
                                            className: classNames(classes.table__cell__rigth, classes.table__cell__total),
                                            accessor: row => {
                                                return (
                                                    <div>
                                                        {row.totals.clientQuoteTotals.profitTotal && row.totals.clientQuoteTotals.profitTotal !== 0 ?
                                                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                                                                <NumberFormat value={row.totals.clientQuoteTotals.profitTotal}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                />
                                                                <NumberFormat value={(row.totals.clientQuoteTotals.profitTotal * 100) / (row.totals.clientQuoteTotals.subTotal || 1)}
                                                                    displayType={'text'}
                                                                    suffix={'%'}
                                                                    decimalScale={0}
                                                                    fixedDecimalScale={false}
                                                                />
                                                            </div>
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                                )
                                            }
                                        },
                                        {
                                            Header: i18n.t("quotes.quoted.table.column7"),
                                            width: 110,
                                            heigth: 50,
                                            id: 'Total',
                                            style: { position: 'relative' },
                                            className: classNames(classes.table__cell__rigth, classes.table__cell__total),
                                            accessor: row => (
                                                <div>
                                                    {row.totals.clientQuoteTotals.total ?
                                                        <div>
                                                            <Typography className={classes.row__currency}>{row.currency}</Typography>
                                                            <NumberFormat value={row.totals.clientQuoteTotals.total + (row.totals.clientQuoteTotals.otherCostTotal || 0)}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                className={classes.totalRow}
                                                            />
                                                        </div>
                                                        :
                                                        ''
                                                    }
                                                </div>
                                            )
                                        },
                                        {
                                            Header: i18n.t("quotes.quoted.table.column3"),
                                            filterable: false,
                                            id: 'user_Author',
                                            accessor: row => {
                                                let usersListed = row.salesRepresentative ? [row.salesRepresentative.id] : [];
                                                return (
                                                    <div>
                                                        <Typography className={classes.list__fileAuthor}>{row.salesPerson.userName}</Typography>


                                                        {row.salesRepresentative && row.salesRepresentative.name && (
                                                            <Typography color='textSecondary'>{row.salesRepresentative.name}</Typography>
                                                        )}

                                                        <TasksShortcut
                                                            row={row}
                                                            handleShowQuote={this.props.handleShowQuote}
                                                        >
                                                            <div>
                                                                {(row.tasks && row.tasks.length > 0 && row.tasks.filter(task => !task.complete && task.followUpUser && task.followUpUser.name).length > 0) && (
                                                                    <div>
                                                                        <Divider />
                                                                        <div style={{ display: 'flex', marginTop: 5, justifyContent: 'flex-start' }}>
                                                                            <OpenInNew style={{ fontSize: 14 }} color='secondary' />
                                                                        </div>
                                                                        {row.tasks.filter(task =>
                                                                            !task.complete)
                                                                            .map(task => {
                                                                                if (task.followUpUser && task.followUpUser.name && usersListed.indexOf(task.followUpUser.id) < 0) {
                                                                                    usersListed.push(task.followUpUser.id);
                                                                                    return <Typography key={task.id} color='textSecondary'>{task.followUpUser.name}</Typography>
                                                                                }
                                                                                return null
                                                                            })}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </TasksShortcut>
                                                    </div>)

                                            },
                                            width: 120,
                                            style: {
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                            }
                                        },
                                    ]}
                                    pageSize={rows && rows.length >= 50 ? 50 : rows.length}
                                    className={classNames(classes.table, ' -striped -highlight')}
                                    showPaginationTop={rows && rows.length >= 50 ? true : false}
                                    showPaginationBottom={rows && rows.length >= 50 ? true : false}
                                    showPageSizeOptions={false}
                                    sortable={false}
                                    resizable={false}
                                />
                            }
                        </Hidden>
                        <Hidden smUp implementation="css">
                            <Paper>
                                <List>
                                    {rows.map((row, i) => {
                                        let usersListed = row.salesRepresentative ? [row.salesRepresentative.id] : [];
                                        return (
                                            <div key={row.id}>
                                                <ListItem key={row.id} >
                                                    <ListItemText>
                                                        <div className={classes.rfq__table__fileDescriptions}>
                                                            <Link
                                                                variant="body2"
                                                                className={classes.rfq__table__fileLink}
                                                                onClick={() => {
                                                                    this.props.handleShowQuote(row.id)
                                                                }}
                                                                underline={'hover'}
                                                            >
                                                                <strong>{i18n.t("quotes.requestedByClient.table.file")} {`${row.id}`}</strong>
                                                            </Link>
                                                            <div className={classes.rfq__table__fileDatesContainer}>
                                                                <Typography
                                                                    variant='body2'
                                                                    color='textSecondary'>
                                                                    {this.quoteDateFormat(row.clientQuote.date)}
                                                                </Typography>
                                                                <Typography className={moment().diff(moment(row.clientQuote.date), 'days') < 2 ? classes.list__file__lastUpdate : (moment().diff(moment(row.clientQuote.date), 'days') < 4 ? classes['list__file__lastUpdate--late'] : classes['list__file__lastUpdate--veryLate'])} >
                                                                    {moment(row.clientQuote.date).fromNow(true)}
                                                                </Typography>
                                                            </div>
                                                            <TasksShortcut row={row}
                                                                handleShowQuote={this.props.handleShowQuote} />
                                                        </div>
                                                        <br />
                                                        <div>
                                                            <Typography className={classes.list__fileClient} noWrap>{row.client.name}</Typography>
                                                            <Typography className={classes.list__fileName}>{`${row.requestForQuote.name}`}</Typography>
                                                            <Typography className={classes.list__fileReference}>{`${row.requestForQuote.reference}`}</Typography>
                                                            {row.status === 3 ?
                                                                <Typography color='textSecondary'>{i18n.t('quotes.quoted.preparing')}</Typography>
                                                                :
                                                                <Typography className={classes['list__quotes__status--readyToQuote']}>{i18n.t('quotes.quoted.ready')}</Typography>
                                                            }
                                                            <div>
                                                                <Typography className={classes.list__fileAuthor}>{row.salesPerson.userName}</Typography>


                                                                {row.salesRepresentative && row.salesRepresentative.name && (
                                                                    <Typography color='textSecondary'>{row.salesRepresentative.name}</Typography>
                                                                )}


                                                                <TasksShortcut
                                                                    row={row}
                                                                    handleShowQuote={this.props.handleShowQuote}
                                                                    anchorOrigin={{
                                                                        vertical: 'center',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'center',
                                                                        horizontal: 'right',
                                                                    }}
                                                                >
                                                                    <div>
                                                                        {(row.tasks && row.tasks.length > 0 && row.tasks.filter(task => !task.complete && task.followUpUser && task.followUpUser.name).length > 0) && (
                                                                            <div>
                                                                                {row.tasks.filter(task =>
                                                                                    !task.complete)
                                                                                    .map(task => {
                                                                                        if (task.followUpUser && task.followUpUser.name && usersListed.indexOf(task.followUpUser.id) < 0) {
                                                                                            usersListed.push(task.followUpUser.id);
                                                                                            return <Typography key={task.id} color='textSecondary'>{task.followUpUser.name}</Typography>
                                                                                        }
                                                                                        return null
                                                                                    })}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </TasksShortcut>


                                                            </div>
                                                            <div className={classes.list__countryContainer}>
                                                                <Typography className={classes.list__fileCountry} color='textSecondary' noWrap>{row.client.country.code}</Typography>
                                                            </div>
                                                            <Typography color='textSecondary'>
                                                                <NumberFormat value={row.totals.clientQuoteTotals.total}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    prefix={row.currencySymbol}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true} />
                                                            </Typography>
                                                        </div>
                                                    </ListItemText>
                                                </ListItem>
                                                {i < (rows.length - 1) && (
                                                    <Divider />
                                                )}
                                            </div>
                                        )
                                    })
                                    }

                                </List>
                            </Paper>
                        </Hidden>
                    </div>
                )}

                {rows && rows.length === 0 && !fetching &&(
                    <div className={classes.quoted__noRows}>
                        {this.props.people.filterPeople && this.props.people.filterPeople.length === 0 ? (
                            <div className={classes.empty__state__container}>
                                <Inbox style={{ fontSize: 50 }} color='secondary' />
                                <Typography variant='body2'>{i18n.t('quotes.quoted.noQuotes')}</Typography>
                            </div>
                        ) : (
                                <div className={classes.empty__state__container}>
                                    <Inbox style={{ fontSize: 50 }} color='secondary' />
                                    <Typography variant='body2'>
                                        {this.props.people.filterPeople.map((person, i) => (
                                            <span key={i}>{`${person.userName}${this.props.people.filterPeople.length > 1 && (this.props.people.filterPeople.length > i + 1) ? ', ' : ' '}`} </span>
                                        ))}
                                        <br />
                                        {this.props.people.filterPeople.length === 1 ? i18n.t('peopleFilter.hasNot') : i18n.t('peopleFilter.haveNot')}
                                        {i18n.t('peopleFilter.pendingQuotes')}
                                    </Typography>
                                    {Permissions.checkSensitiveInformationPermission() && (
                                        <Typography variant='body2'>
                                            <Link className={classes.customLink}
                                                onClick={() => {
                                                    this.props.generalActions.showPeopleFilter(true)
                                                }}>
                                                {i18n.t('peopleFilter.changeUsersFilter')}
                                            </Link>
                                        </Typography>
                                    )}
                                </div>
                            )}
                    </div>
                )}
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch),
    }
}

function mapStateToProps(state) {
    return {
        files: state.files,
        people: state.people,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Quoted))