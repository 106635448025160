import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import {
    Typography,
    makeStyles
} from '@material-ui/core/';
import { ManageClients, ManageClientContactsPopup } from '../components/clients'

import { connect } from 'react-redux'
import * as clientsActions from '../actions/clientsActions'
import { bindActionCreators } from 'redux'

import i18n from 'i18next'


const useStyles = makeStyles(theme => ({
    container: {
        padding: '20px',
        margin: 'auto',
        maxWidth: '700px',
        position: 'relative'
    },
    subTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    centered: {
        textAlign: 'center'
    },
    table: {
        marginTop: '20px',
        textAlign: 'left',
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily
    },
    activeSelect: {
        fontFamily: theme.typography.fontFamily,
        marginTop: '6px'
    },
    circularProgress: {
        marginTop: '20%'
    },
    complete: {
        color: 'green',
        fontSize: '17px',
    },
    incomplete: {
        color: 'red',
        fontSize: '17px',
    },
}));

const defaultClient = {
    country: {
        id: null
    },
    name: '',
    taxId: '',
    address: {
        address1: '',
        address2: ''
    },
    phone: '',
    fax: '',
    id: null,
    contacts: []
}

const defaultClientContact = {
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    id: 0
}


function Companies(props) {

    const classes = useStyles(),
        [selectedClient, setSelectedClient] = useState(defaultClient),
        [editClient, setEditClient] = useState(false),
        [selectedClientContact, setSelectedClientContact] = useState(defaultClientContact);

    const changeClient =(client) => {
        setSelectedClient(client || defaultClient)
        if (client) {
            props.clientsActions.setClient(client)
        } else {
            props.clientsActions.setClient({})
        }
    }

    const changeClientContact =(clientContact) => {
        setSelectedClientContact(clientContact || defaultClientContact)
    }

    const editOrCreateClient=(value)=>{
        setEditClient(value)
    }

    return (
        <div className={classes.container}>
            <Helmet title={`${i18n.t('companyName')} | ${i18n.t('pageSubTitle.companies')}`} />
            <div className={classes.subTitle}>
                <Typography variant="h5">{i18n.t("companies.titleClients")}</Typography>
            </div>

            <div style={{marginTop: '30px', textAlign: 'left'}}>
                
                <ManageClients
                    selectedClient={selectedClient}
                    setClient={changeClient}
                    fromCompanies={true}
                    editOrCreateClient={editOrCreateClient}
                />

                { selectedClient && selectedClient.id && !editClient &&
                    <ManageClientContactsPopup
                        changeClientContact={changeClientContact}
                        selectedClientContact={selectedClientContact}
                        fromCompanies={true}
                    />
                }

            </div>
        
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        clientsActions: bindActionCreators(clientsActions, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        general: state.general
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Companies)
