import Users from './users'
import Events from './events'
import Quotes from './quotes'
import Notes from './notes'
import Files from './files'
import GlobalParameters from './globalParameters'
import Clients from './clients'
import Vendors from './vendors'
import Reports from './reports'
import Invoices from './invoices'
import Items from './items'
import Translate from './translate'
import Emails from './emails'
import Deals from './deals'


const API = {
    Users,
    Events,
    Quotes,
    Notes,
    Files,
    GlobalParameters,
    Clients,
    Vendors,
    Reports,
    Invoices,
    Items,
    Translate,
    Emails,
    Deals
}

export default API;