import {
    SHOW_ADD_ITEM,
    SHOW_ADD_VENDOR_QUOTE,
    SHOW_SELECT_ITEM_VENDOR,
    SHOW_VENDORS_PO,
    SHOW_INVOICE_FORM,
    SHOW_ADD_VENDOR_QUOTE_ITEM,
    SHOW_EDIT_VENDOR_QUOTE_ITEM,
    SHOW_EDIT_VENDOR_QUOTE,
    SHOW_PEOPLE_FILTER,
    SHOW_CREATE_CLIENT_CONTACT,
    SHOW_CREATE_VENDOR_CONTACT,
    RELOAD_NOTES,
    SHOW_VENDORS_TAB,
    SET_GLOBAL_PARAMETERS,
    NEW_OTHER_COST
} from '../actions/generalActions'

function general(state = {}, action) {
    switch (action.type) {
        case SHOW_ADD_ITEM:
            return {
                ...state,
                showAddItem: action.show
            }
        case SHOW_ADD_VENDOR_QUOTE:
            return {
                ...state,
                showAddVendorQuote: action.show
            }
        case SHOW_SELECT_ITEM_VENDOR:
            return {
                ...state,
                showSelectItemVendor: action.show
            }
        case SHOW_VENDORS_PO:
            return {
                ...state,
                showVendorsPo: action.show
            }
        case SHOW_INVOICE_FORM:
            return {
                ...state,
                showInvoiceForm: action.show
            }
        case SHOW_ADD_VENDOR_QUOTE_ITEM:
            return {
                ...state,
                showAddVendorQuoteItem: action.show
            }
        case SHOW_EDIT_VENDOR_QUOTE_ITEM:
            return {
                ...state,
                showEditVendorQuoteItem: action.show
            }
        case SHOW_EDIT_VENDOR_QUOTE:
            return {
                ...state,
                showEditVendorQuote: action.show,
                vendorQuoteId: action.show ? action.vendorQuoteId : null
            }
        case SHOW_PEOPLE_FILTER:
            return {
                ...state,
                showPeopleFilter: action.show
            }
        case SHOW_CREATE_CLIENT_CONTACT:
            return {
                ...state,
                showCreateClientContact: action.show
            }
        case SHOW_CREATE_VENDOR_CONTACT:
            return {
                ...state,
                showCreateVendorContact: action.show
            }
        case RELOAD_NOTES:
            return {
                ...state,
                reloadNotes: action.reload
            }
        case SHOW_VENDORS_TAB:
            return {
                ...state,
                showVendorsTab: action.show
            }
        case SET_GLOBAL_PARAMETERS:
            return{
                ...state,
                globalParameters: action.globalParameters
            }
        case NEW_OTHER_COST: 
            return {
                ...state,
                globalParameters:{
                    ...state.globalParameters,
                    otherCosts: [
                        action.otherCost,
                        ...state.globalParameters.otherCosts
                    ]
                }
            }
        default:
            return state;
    }
}

export default general