// Actions types

export const SHOW_ADD_ITEM = 'SHOW_ADD_ITEM'
export const SHOW_ADD_VENDOR_QUOTE = 'SHOW_ADD_VENDOR_QUOTE'
export const SHOW_SELECT_ITEM_VENDOR = 'SHOW_SELECT_ITEM_VENDOR'
export const SHOW_VENDORS_PO = 'SHOW_VENDORS_PO'
export const SHOW_INVOICE_FORM = 'SHOW_INVOICE_FORM'
export const SHOW_ADD_VENDOR_QUOTE_ITEM = 'SHOW_ADD_VENDOR_QUOTE_ITEM'
export const SHOW_EDIT_VENDOR_QUOTE_ITEM = 'SHOW_EDIT_VENDOR_QUOTE_ITEM'
export const SHOW_EDIT_VENDOR_QUOTE = 'SHOW_EDIT_VENDOR_QUOTE'
export const SHOW_PEOPLE_FILTER = 'SHOW_PEOPLE_FILTER'
export const SHOW_CREATE_CLIENT_CONTACT = 'SHOW_CREATE_CLIENT_CONTACT'
export const SHOW_CREATE_VENDOR_CONTACT = 'SHOW_CREATE_VENDOR_CONTACT'
export const RELOAD_NOTES = 'RELOAD_NOTES'
export const SHOW_VENDORS_TAB = 'SHOW_VENDORS_TAB'
export const SET_GLOBAL_PARAMETERS = 'SET_GLOBAL_PARAMETERS'
export const NEW_OTHER_COST = 'NEW_OTHER_COST'



//Actions

export function showAddItem(show) {
    return {
        type: SHOW_ADD_ITEM,
        show
    }
}

export function showAddVendorQuote(show) {
    return {
        type: SHOW_ADD_VENDOR_QUOTE,
        show
    }
}
export function showSelectItemVendor(show) {
    return {
        type: SHOW_SELECT_ITEM_VENDOR,
        show
    }
}
export function showVendorsPo(show) {
    return {
        type: SHOW_VENDORS_PO,
        show
    }
}

export function showInvoiceForm(show) {
    return {
        type: SHOW_INVOICE_FORM,
        show
    }
}

export function showAddVendorQuoteItem(show) {
    return {
        type: SHOW_ADD_VENDOR_QUOTE_ITEM,
        show
    }
}
export function showEditVendorQuoteItem(show) {
    return {
        type: SHOW_EDIT_VENDOR_QUOTE_ITEM,
        show
    }
}

export function showEditVendorQuote(show, vendorQuoteId) {
    return {
        type: SHOW_EDIT_VENDOR_QUOTE,
        show, vendorQuoteId
    }
}

export function showPeopleFilter(show) {
    return {
        type: SHOW_PEOPLE_FILTER,
        show
    }
}
export function showCreateClientContact(show) {
    return {
        type: SHOW_CREATE_CLIENT_CONTACT,
        show
    }
}
export function showCreateVendorContact(show) {
    return {
        type: SHOW_CREATE_VENDOR_CONTACT,
        show
    }
}

export function reloadNotes(reload) {
    return {
        type: RELOAD_NOTES,
        reload
    }
}
export function showVendorsTab(show) {
    return {
        type: SHOW_VENDORS_TAB,
        show
    }
}
export function setGlobalParameters(globalParameters) {
    return {
        type: SET_GLOBAL_PARAMETERS,
        globalParameters
    }
}

export function addNewOtherCost(otherCost) {
    return {
        type: NEW_OTHER_COST,
        otherCost
    }
}




