import 'whatwg-fetch';
import config from '../../config';
import auth from '../../authentication/auth'



const Quotes = {
    getVendorQuotesList: (args) => {
        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/quotes/vendorQuotes`;

            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, { headers }).then(function (response) {
                    return response.json();
                })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    createRFQ: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/quotes/clientRFQ`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(body),
                    headers,
                    method: 'POST',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    updateRFQ: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/quotes/clientRFQ/${body.id}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(body),
                    headers,
                    method: 'PUT',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    createVendorRFQ: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/quotes/vendorQuotes`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(body),
                    headers,
                    method: 'POST',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    updateVendorRFQ: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/quotes/vendorQuotes/${body.id}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(body),
                    headers,
                    method: 'PUT',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    changeStatusVendorRFQ: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/quotes/vendorQuotes/${body.id}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(body),
                    headers,
                    method: 'PATCH',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    deleteVendorRFQ: (args) => {
        let { vendorQuoteId, comments } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/quotes/vendorQuotes/${vendorQuoteId}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify({ comments: comments }),
                    headers,
                    method: 'DELETE',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    updateClientQuoted: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/quotes/clientQuotes/${body.id}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(body),
                    headers,
                    method: 'PUT',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    acceptClientQuote: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/quotes/clientQuotes/${body.clientQuoteId}/accept`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(body),
                    headers,
                    method: 'PATCH',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    rejectClientQuote: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/quotes/clientQuotes/${body.clientQuoteId}/reject`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(body),
                    headers,
                    method: 'PATCH',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    createClientPO: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/pos/clientPO`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(body),
                    headers,
                    method: 'POST'
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    updateClientPO: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/pos/clientPO/${body.id}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(body),
                    headers,
                    method: 'PUT',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    createVendorPO: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/pos/vendorPO`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(body),
                    headers,
                    method: 'POST'
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    updateVendorPO: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/pos/vendorPO/${body.id}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(body),
                    headers,
                    method: 'PUT',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    changeStatusVendorPO: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/pos/vendorPO/${body.id}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(body),
                    headers,
                    method: 'PATCH',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    },
    updateVendorPOReceivedItem: (args) => {
        let { body } = args || {};

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/pos/vendorPO/receivedItem/${body.id}`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(body),
                    headers,
                    method: 'PATCH',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    }, 
    markAsDeleteItem: (args) => {
        let { body } = args || {}; //{ itemId, fileId }

        let promise = new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/quotes/clientQuotes/markasdeleted`;
            auth.getAuthenticatedUser().then(user => {
                const prodekAuthToken = user.signInUserSession.idToken.jwtToken;

                var headers = new Headers();
                headers.append("x-api-key", config.apiGateway.api_key);
                headers.append("Authorization", prodekAuthToken);

                fetch(url, {
                    body: JSON.stringify(body),
                    headers,
                    method: 'PATCH',
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (jsonResponse) {
                        resolve(jsonResponse);
                    }).catch(err => {
                        reject(err);
                    });
            });
        });
        return promise;
    }
}

export default Quotes;