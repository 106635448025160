import React, { Component } from 'react'
import {
    Typography,
    TextField,
    Grid,
    Button,
    withStyles,
    CircularProgress,
    withMobileDialog,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Link
} from '@material-ui/core'
// import { blueGrey } from '@material-ui/core/colors'
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import classNames from 'classnames'
import i18n from 'i18next';
import { Drafts, Phone } from '@material-ui/icons'
import { NoteEditDocuments } from '../common';

//REDUX
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../../actions/generalActions'
import * as apiStatusActions from '../../../actions/apiStatusActions'
import * as clientsActions from '../../../actions/clientsActions'


import { CustomMessages } from '../../general'
import { GlobalParametersContext } from '../../../contexts/globalParametersContext'
import CustomSelect from '../../general/CustomSelect'
import { ConfirmationDialog } from '../../general';
import { Client, ManageClientContactsPopup } from '../../clients'
import API from '../../../lib/api';
import RFQItems from './RFQItems';
import { SlideUp } from '../../general/Transitions'

import { NoteForm } from '../../notes'

import auth from '../../../authentication/auth';



const styles = theme => {
    return ({
        clientRFQ__dialog__content: {
            padding: 0
        },
        clientRFQ__container: {
            margin: 'auto',
            marginTop: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3)
        },
        clientRFQ__form__textField: {
            width: '100%',
            margin: `${theme.spacing()}px 0`,
        },
        clientRFQ__form__textField__title: {
        },
        clientRFQ__form__textField__unit: {
            width: '100%',
            margin: `${theme.spacing()}px 0`
        },
        clientRFQ__form__textField__currency: {
            margin: `${theme.spacing()}px 0`,
            width: '100%',
            minWidth: '80px',
            fontSize: '0.9em',
        },
        clientRFQ__form__input: {
            fontSize: '0.9em',
        },
        clientRFQ__form__input__description: {
            fontSize: '0.9em',
            padding: '10px',
            //'-ms-overflow-style': '-ms-autohiding-scrollbar;'
        },
        clientRFQ__form__select: {
            fontFamily: theme.typography.fontFamily,
            width: '100%',
            zIndex: '2',
            margin: `${theme.spacing()}px 0`,
        },
        clientRFQ__form__payment__terms__select: {
            zIndex: '6'
        },
        clientRFQ__form__quote__terms__select: {
            zIndex: '4'
        },
        clientRFQ__form__unit__select: {
            width: '85%'
        },
        clientRFQ__form__quote_date: {
            width: '100%',
            fontFamily: theme.typography.fontFamily,
            margin: `${theme.spacing()}px 0`,
            fontSize: '0.8em'
        },
        clientRFQ__form__quote_date__input: {
            fontSize: 'unset'
        },
        clientRFQ__form__items__table: {
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily,
            marginTop: theme.spacing(2)
        },
        clientRFQ__items__table: {
            display: 'flex',
            flexDirection: 'column'
        },
        clientRFQ__form__items__unit__select: {
            background: '#fff'
        },
        clientRFQ__form__menuItem: {
            fontSize: '1em'
        },
        clientRFQ__form__actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column'
            }
        },
        clientRFQ__edit__button: {
            padding: theme.spacing()
        },
        clientRFQ__hader__container__actionButtons: {
            fontSize: theme.typography.fontSize * 1.5
        },
        clientRFQ__editClient__seccion: {
            position: 'absolute',
            right: 0,
            top: '30px'
        },
        "clientRFQ__editSections__button--disabled": {
            marginLeft: theme.spacing() - 4,
            minWidth: '80px',
            padding: '5px 30px',
            backgroundColor: theme.palette.textPrimary.main
        },
        clientRFQ__editSections__saveButton: {
            marginLeft: theme.spacing() - 4,
            minWidth: '80px',
            padding: '5px 30px',
            backgroundColor: theme.palette.blue[100],
            '&:hover': {
                backgroundColor: theme.palette.blue[80],
            },
            color: 'white',
        },
        clientRFQ__editSections__cancelButton: {
            minWidth: '80px',
            padding: '5px 30px'
        },
        clientRFQ__editSections__fab: {
            minHeight: '0',
            width: '25px',
            height: '25px',
        },
        clientRFQ__editSections__fab__create: {
            minHeight: '0',
            margin: `0 ${theme.spacing()}px`,
            width: '25px',
            height: '25px',
            backgroundColor: theme.palette.blue[100],
            '&:hover': {
                backgroundColor: theme.palette.blue[80],
            },
            color: 'white',
        },
        clientRFQ__editActions: {
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: theme.spacing(4)
        },
        clientRFQ__selectedSecction: {
            boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
            padding: theme.spacing(2)
        },
        buttonSpinner: theme.buttonSpinner,
        clientRFQ__form__gridItem: {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexDirection: 'column'
        },
        clientRFQ__addItemButton: {
            ...theme.smallButton,
            borderColor: theme.palette.blue[100],
            color: theme.palette.blue[100],
            marginTop: theme.spacing()
        },
        clientRFQ__cancelButton: {
            marginTop: theme.spacing(3),
            ...theme.smallButton
        },
        client__divider: {
            marginBottom: theme.spacing()
        },
        customLink: theme.customLink,
        rfq__clientcontact__message__icon: {
            fontSize: '11pt',
            marginRight: '5px',
            marginBottom: '-5px'
        },
        rfq__icon: {
            fontSize: '11pt',
            marginRight: '5px',
            marginBottom: '-3px'
        },
        dialog__actions: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3)
        },
        file__details__dialog__paper: {
        },
        client__contact__title: {
            color: theme.palette.blue[100]
        },
        rfq__information__title: {
            color: theme.palette.blue[100]
        },
        client__title: {
            color: theme.palette.blue[100]
        }
    })
};
const defaultItemInputs = {
    partNumber: '',
    description: '',
    references: '',
    manufacturer: '',
    quantity: '',
    unit: ''
}

const defaultClientContact = {
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    id: 0
}

const defaultClient = {
    country: {
        id: null
    },
    name: '',
    taxId: '',
    address: {
        address1: '',
        address2: ''
    },
    phone: '',
    fax: '',
    id: null,
    contacts: []
}


const customStyles = {
    control: base => ({
        ...base,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        boxShadow: 'none',

        '&:hover': {
            borderColor: '#000'
        }
    })
}


class ClientRFQForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            itemInputs: defaultItemInputs,
            selectedDate: props.file ? new Date(props.file.requestForQuote.createDateTime) : new Date(),
            selectedCurrency: props.file && props.file.requestForQuote ? props.file.requestForQuote.currency : 'USD',
            selectedClient: props.file && props.file.client ? {
                country: {
                    id: props.file.client.country.id
                },
                name: props.file.client.name,
                taxId: props.file.client.taxId,
                address: {
                    address1: props.file.client.address.address1,
                    address2: props.file.client.address.address2
                },
                phone: props.file.client.phone,
                fax: props.file.client.fax,
                id: props.file.client.id,
                contacts: props.file.client.contact || []
            } : defaultClient,
            selectedClientContact: props.file && props.file.client && props.file.client.contact ? {
                firstName: props.file.client.contact.firstName,
                lastName: props.file.client.contact.lastName,
                email: props.file.client.contact.email,
                phone: props.file.client.contact.phone,
                id: props.file.client.contact.id
            } : defaultClientContact,
            selectedSalesRep: props.file && props.file.salesRepUserId ? props.file.salesRepUserId : null,
            selectedPaymentTerm: props.file && props.file.paymentTerms ? props.file.paymentTerms.id : null,
            selectedQuoteTerm: props.file && props.file.quoteTerms ? props.file.quoteTerms.id : null,
            clientContacts: [],
            selectedProbability: props.file && props.file.probability && props.file.probability.id ? props.file.probability.id : null,
            selectedOportunity: props.file && props.file.oportunity && props.file.oportunity.id ? props.file.oportunity.id : null,
            clientsFetched: false,
            countryFilter: props.file && props.file.client ? props.file.client.country.id : null,
            quoteReference: props.file && props.file.requestForQuote ? props.file.requestForQuote.reference : '',
            quoteTitle: props.file && props.file.requestForQuote ? props.file.requestForQuote.name : '',
            quoteDescription: props.file && props.file.requestForQuote ? props.file.requestForQuote.description : '',
            quoteTermsReference: props.file && props.file.requestForQuote ? props.file.requestForQuote.quoteTermsReference : '',
            items: [],
            users: [],
            saving: false,
            validateRFQ: false,
            deleteItemIdx: null,
            openConfirmationDialog: false,
            showItemForm: false,
            validateErrorMessage: null,
            showRequiredFieldsError: false,
            unsavedChanges: false,
            reloadingClients: false,
            openNewNote: false,
            logedInUser: null,
            showAddNote: false
        };
    }

    componentWillMount = () => {
        API.Users.getUsers().then(res => {
            this.setState({
                users: res.data.records.users.filter(user => user.userActive === 1)
            })
        })
    }
    componentDidMount = () => {
        auth.getAuthenticatedUser().then(user => {
            this.setState({ logedInUser: user })
        }).catch(err => {
            this.setState({ logedInUser: null })
        })
    }


    handleEditQuote = () => {
        const { file } = this.props;

        if (file && file.status > 1) {
            this.setState({
                showAddNote: true
            })
        } else {
            this.handleClientRFQ().catch(_ => { })
        }
    }

    closeNote = () => {
        this.setState({
            showAddNote: false,
            showQuotePopup: false
        })
    }

    fetchClients = () => {
        const { selectedClient } = this.state;
        if (selectedClient && selectedClient.name) {
            this.setState({
                clientsFetched: false
            }, _ => {
                API.Clients.getClients({
                    filter: selectedClient.name
                }).then(res => {
                    let client = res.data.records.find(c => c.id === selectedClient.id)
                    if (client) {
                        this.props.clientsActions.setClient(client);
                    }
                    this.props.clientsActions.setClients(res.data.records);
                    this.setState({
                        clientsFetched: true
                    })
                })
            })
        } else {
            this.setState({
                clientsFetched: true
            })
        }

    }

    componentWillReceiveProps = (nextProps) => {
        const { items } = this.state
        if (nextProps.file && nextProps.file.items.length > 0 && items.length === 0) {
            const newItems = [...items];

            nextProps.file.items.map(item => {
                var auxItem = { ...item }
                auxItem.unit = {
                    unitCode: item.unit,
                    unitName: item.unit
                }
                return newItems.push(auxItem)
            })
            this.setState({
                itemInputs: defaultItemInputs,
                items: newItems,
            })
        }
    }

    onEnter = () => {
        if (this.props.file) {
            let { clients, file } = this.props,
                client = clients.find(c => c.id === file.client.id),
                contacts = client ? client.contacts : [];
            this.setState({
                selectedDate: new Date(file.requestForQuote.createDateTime),
                selectedCurrency: file.requestForQuote ? file.requestForQuote.currency : 'USD',
                selectedClient: file && file.client ? {
                    country: {
                        id: file.client.country.id
                    },
                    name: file.client.name,
                    taxId: file.client.taxId,
                    address: {
                        address1: file.client.address.address1,
                        address2: file.client.address.address2
                    },
                    phone: file.client.phone,
                    fax: file.client.fax,
                    id: file.client.id,
                    contacts: file.client.contacts || contacts
                } : defaultClient,
                selectedClientContact: file.client && file.client.contact ? {
                    firstName: file.client.contact.firstName,
                    lastName: file.client.contact.lastName,
                    email: file.client.contact.email,
                    phone: file.client.contact.phone,
                    id: file.client.contact.id
                } : defaultClientContact,
                selectedSalesRep: file.salesRepUserId ? file.salesRepUserId : null,
                selectedPaymentTerm: file.paymentTerms ? file.paymentTerms.id : null,
                selectedQuoteTerm: file.quoteTerms ? file.quoteTerms.id : null,
                selectedProbability: file.probability && file.probability.id ? file.probability.id : null,
                selectedOportunity: file.oportunity && file.oportunity.id ? file.oportunity.id : null,
                countryFilter: file.client ? file.client.country.id : null,
                quoteReference: file.requestForQuote ? file.requestForQuote.reference : '',
                quoteTitle: file.requestForQuote ? file.requestForQuote.name : '',
                quoteDescription: file.requestForQuote ? file.requestForQuote.description : '',
                quoteTermsReference: file.requestForQuote ? file.requestForQuote.quoteTermsReference : '',
            })
        }
        this.fetchClients();
    }
    onExited = () => {
        this.clearForm();
    }

    handleDateChange = date => {
        this.setState({
            selectedDate: date,
            unsavedChanges: true
        });
    }

    selectCountry = (country) => {
        const { selectedClient, selectedClientContact } = this.state,
            { clients } = this.props;

        this.setState({
            unsavedChanges: true
        });
        if (country) {
            let newClients = country.value ? clients.filter(c => c.country.id === country.value) : clients,
                clientIndex = newClients.length > 0 && selectedClient ? newClients.findIndex(c => c.id === selectedClient.id) : -1;
            this.setState({
                countryFilter: country.value,
                selectedClient: clientIndex === -1 ? defaultClient : selectedClient,
                selectedClientContact: clientIndex === -1 ? defaultClientContact : selectedClientContact
            })
        } else {
            this.setState({
                countryFilter: null,
                selectedClient: defaultClient,
                selectedClientContact: defaultClientContact,
                clientContacts: []
            })
        }
    }


    selectClient = (selectedClient) => {
        const { clients } = this.props;
        this.setState({
            unsavedChanges: true
        });
        if (selectedClient) {

            let client = clients.find(c => c.id === selectedClient.value)
            this.props.clientsActions.setClient(client);
            this.setState({
                selectedClient: client ? client : defaultClient,
                selectedClientContact: defaultClientContact,
                clientContacts: []
            })

            if (client) {
                if (client.country.id) {
                    this.setState({
                        countryFilter: client.country.id
                    })
                }
                this.setState({
                    clientContacts: client.contacts
                })
            }
        } else {
            this.setState({
                selectedClient: defaultClient,
                selectedClientContact: defaultClientContact,
                clientContacts: []
            })
        }
    }

    selectClientContact = (selectedContact) => {

        this.setState({
            unsavedChanges: true
        });
        if (selectedContact) {
            const { client } = this.props;
            let clientContacts = client && client.contacts ? client.contacts : [],
                contact = clientContacts.find(c => c.id === selectedContact.value);

            this.setState({
                selectedClientContact: contact ? contact : defaultClientContact
            })
        } else {
            this.setState({
                selectedClientContact: defaultClientContact
            })
        }
    }


    handleChangeSelect = (e) => {
        this.setState({
            unsavedChanges: true,
            [e.target.name]: e.target.value
        });
    }
    selectQuoteTerms = (qt) => {
        if (qt) {
            this.setState({
                unsavedChanges: true,
                selectedQuoteTerm: qt.value
            });

        } else {
            this.setState({
                unsavedChanges: true,
                selectedQuoteTerm: null
            });
        }
    }
    selectPaymentTerms = (pt) => {
        if (pt) {
            this.setState({
                unsavedChanges: true,
                selectedPaymentTerm: pt.value
            });

        } else {
            this.setState({
                unsavedChanges: true,
                selectedPaymentTerm: null
            });
        }
    }
    validateItemsData = () => {
        const { items } = this.state;

        let ivalidItems = items.filter(item => !item.partNumber || !item.description || !item.quantity || !item.unit || (item.unit && (!item.unit.unitCode || item.unit.unitCode === 'None')));
        if (ivalidItems.length > 0) {
            return false;
        } else {
            return true
        }
    }

    validateParameters = (params) => {
        const {
            quoteReference,
            quoteTitle,
            selectedClient,
            selectedCurrency,
            selectedOportunity,
            selectedProbability,
            selectedClientContact } = this.state;
        if (params && params.savingRfq) {
            this.setState({
                validateRFQ: true
            })
            if (quoteTitle === '' ||
                quoteReference === '' ||
                !selectedOportunity ||
                !selectedProbability ||
                !selectedCurrency ||
                selectedCurrency === 'None' ||
                selectedClient.country.id === 'None' ||
                !selectedClient.country.id ||
                !selectedClient.id ||
                !selectedClientContact.id ||
                !this.validateItemsData()) {
                this.setState({
                    showRequiredFieldsError: true,
                })
                return false
            }
        }

        this.setState({
            showRequiredFieldsError: false
        })

        return true
    }

    updateFile = (file, keepRFQWindowOpen) => {
        this.props.updateFile(file)
        if (!keepRFQWindowOpen) {
            this.close()
        }
    }

    handleClientRFQ = () => {
        const { file } = this.props;
        return new Promise((resolve, reject) => {
            if (this.validateParameters({ savingRfq: true })) {
                this.setState({
                    validateErrorMessage: null,
                    validateRFQ: false,
                    saving: true
                })
                const {
                    selectedDate,
                    selectedCurrency,
                    selectedClient,
                    selectedClientContact,
                    selectedSalesRep,
                    selectedPaymentTerm,
                    selectedQuoteTerm,
                    quoteReference,
                    quoteTitle,
                    quoteDescription,
                    quoteTermsReference,
                    items,
                    selectedOportunity,
                    selectedProbability
                } = this.state;
                let body = {
                    id: file && file.requestForQuote ? file.requestForQuote.id : null,
                    clientId: selectedClient.id,
                    clientContactId: selectedClientContact.id,
                    salesRepUserId: selectedSalesRep,
                    probabilityId: selectedProbability,
                    oportunityId: selectedOportunity,
                    clientRFQ: {
                        dateTime: selectedDate,
                        name: quoteTitle,
                        reference: quoteReference,
                        description: quoteDescription,
                        quoteTermsReference: quoteTermsReference,
                        currency: selectedCurrency,
                        paymentTermsId: selectedPaymentTerm,
                        quoteTermsId: selectedQuoteTerm,
                        fileId: file && file.id ? file.id : null
                    },
                    clientRFQItems: items.map((item, i) => {
                        return {
                            rfqItemId: item.id || null,
                            partNumber: item.partNumber,
                            references: item.references,
                            description: item.description,
                            manufacturer: item.manufacturer,
                            quantity: item.quantity,
                            unit: item.unit.unitCode
                        }
                    })
                }

                if (!file) {
                    API.Quotes.createRFQ({ body }).then(res => {
                        this.setState({
                            saving: false,
                            unsavedChanges: false
                        }, () => {
                            if (res.status === 'success') {
                                this.props.addNewRFQ && this.props.addNewRFQ(res.data.records.file);
                                this.clearForm();
                                resolve();
                            } else {
                                this.setState({
                                    validateErrorMessage: (res.errors && res.errors.message ? res.errors.message : "Unhandled error saving RFQ")
                                })
                                reject();
                            }
                        })
                    })
                } else {
                    API.Quotes.updateRFQ({ body }).then(res => {
                        this.setState({
                            saving: false,
                            unsavedChanges: false
                        }, () => {
                            if (res.status === 'success') {
                                this.updateFile(res.data.records.file)
                                resolve();
                            } else {
                                this.setState({
                                    validateErrorMessage: (res.errors && res.errors.message ? res.errors.message : "Unhandled error saving RFQ")
                                })
                                reject();
                            }
                        })

                    })
                }
            } else {
                reject();
            }
        })
    }


    handleCloseClientContactsPopup = () => {
        let { selectedClientContact } = this.state,
            { client } = this.props,
            contact = client.contacts ? client.contacts.find(c => c.id === selectedClientContact.id) : defaultClientContact;

        this.setState({
            selectedClientContact: contact ? contact : defaultClientContact
        })
    }

    changeClient = (newClient) => {

        this.setState({
            selectedClient: newClient || defaultClient,
            unsavedChanges: true,
            selectedClientContact: defaultClientContact,
            clientContacts: newClient ? newClient.contacts : [],
            countryFilter: newClient ? newClient.country.id : null
        }, () => {
            if (newClient) {
                this.props.clientsActions.setClient(newClient)
            } else {
                this.props.clientsActions.setClient({})
            }
        })
    }
    updateRFQClient = (client) => {
        this.props.clientsActions.updateClient(client);
        this.setState({
            selectedClient: client
        })
    }
    updateRFQClientContact = (contact) => {
        let { clientContacts } = this.state,
            contactIndex = clientContacts.findIndex(c => c.id === contact.id),
            newClientContacts = [...clientContacts];

        if (contactIndex) {
            newClientContacts = [
                ...clientContacts.slice(0, contactIndex),
                { ...contact },
                ...clientContacts.slice(contactIndex + 1)
            ]
        }
        this.setState({
            clientContacts: newClientContacts,
            selectedClientContact: contact
        })
    }


    changeClientContact = (newContact) => {
        const { clientContacts } = this.state;

        let newClientContacts = [...clientContacts]

        if(newClientContacts && (newClientContacts.length === 0 || !newClientContacts.find(cont => cont.id === newContact.id) )) {
            newClientContacts.push(newContact)
        }
        this.setState({
            unsavedChanges: true,
            selectedClientContact: newContact,
            clientContacts: newClientContacts
        })
    }

    close = () => {
        this.setState({
            items: [],
            unsavedChanges: false,
        })
        this.props.handleClose();
    }
    clearForm = () => {
        this.setState({
            selectedDate: new Date(),
            selectedCurrency: 'USD',
            selectedClient: defaultClient,
            selectedClientContact: defaultClientContact,
            selectedSalesRep: null,
            selectedPaymentTerm: null,
            selectedProbability: null,
            selectedOportunity: null,
            countryFilter: null,
            quoteReference: '',
            quoteTitle: '',
            quoteDescription: '',
            quoteTermsReference: '',
            selectedQuoteTerm: null,
            validateRFQ: false,
            showRequiredFieldsError: false,
            clientsFetched: false,
            items: []
        })
        this.props.clientsActions.setClient({});
        this.props.clientsActions.setClients([]);
    }

    handleSaveAndExit = () => {
        this.handleClientRFQ()
            .then(_ => {
                this.close();
            })
    }

    closeNewNote = () => {
        this.setState({
            note: null,
            openNewNote: false
        })
    }
    handelNoteSaved = () => {
        this.setState({
            note: null,
            openNewNote: false
        })
    }
    render() {
        const {
            classes,
            file,
            fullScreen,
        } = this.props,
            { selectedDate,
                selectedCurrency,
                users,
                selectedClient,
                selectedClientContact,
                selectedSalesRep,
                items,
                selectedOportunity,
                selectedPaymentTerm,
                selectedQuoteTerm,
                validateRFQ,
                quoteTitle,
                quoteReference,
                quoteTermsReference,
                quoteDescription,
                openConfirmationDialog,
                saving,
                selectedProbability,
                unsavedChanges,
                clientsFetched,
                validateErrorMessage,
                reloadingClients,
                openNewNote,
                logedInUser,
                showAddNote
            } = this.state;

        let clientContacts = this.props.client && this.props.client.contacts ? this.props.client.contacts.filter(c => c.active === 1) : null;

        return (
            <GlobalParametersContext.Consumer>
                {({ countries, currency, paymentTerms, quoteTerms, probabilities, oportunities }) => (
                    <>
                        <Dialog
                            classes={{
                                paper: classes.file__details__dialog__paper
                            }}
                            scroll='paper'
                            open={this.props.open}
                            fullScreen={fullScreen}
                            maxWidth={fullScreen ? 'sm' : 'md'}
                            fullWidth={fullScreen ? false : true}
                            TransitionComponent={SlideUp}
                            onEnter={this.onEnter}
                            onExited={this.onExited}
                        >


                            <DialogTitle disableTypography={true}>
                                {!file ?
                                    <Typography variant='body1' color="inherit">{i18n.t('quotes.quoteRequest.createQuoteRequest')}</Typography>
                                    :
                                    <Typography variant='body1' color="inherit">{i18n.t('quotes.quoteRequest.file')} {file.id} - {file.client.name}</Typography>
                                }
                            </DialogTitle>
                            <DialogContent className={classes.clientRFQ__dialog__content}>

                                <div className={classes.clientRFQ__container} >

                                    {!clientsFetched && (
                                        <Grid container spacing={2} alignItems='center' justify='center' style={{ minHeight: '600px' }}>
                                            <CircularProgress color='secondary' />
                                        </Grid>
                                    )}

                                    {clientsFetched && (
                                        <>

                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        autoComplete='off'
                                                        required
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            className: classes.clientRFQ__form__input
                                                        }}
                                                        disabled={saving ? true : false}
                                                        onBlur={e => {
                                                            this.setState({
                                                                unsavedChanges: this.state.quoteTitle !== e.target.value.trim() ? true : this.state.unsavedChanges,
                                                                quoteTitle: e.target.value.trim()
                                                            });
                                                        }}
                                                        error={validateRFQ && quoteTitle === '' ? true : false}
                                                        defaultValue={quoteTitle ? quoteTitle : ''}
                                                        className={`${classes.clientRFQ__form__textField} ${classes.clientRFQ__form__textField__title}`}
                                                        id="quoteTitle"
                                                        type="text"
                                                        variant="outlined"
                                                        label={i18n.t('quotes.quoteRequest.quoteTitle')}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <TextField
                                                        id="salesRep"
                                                        select
                                                        InputProps={{
                                                            className: classes.clientRFQ__form__input
                                                        }}
                                                        label={i18n.t('quotes.quoteRequest.salesRep')}
                                                        className={classes.clientRFQ__form__select}
                                                        value={selectedSalesRep ? selectedSalesRep : file && file.salesRepUserId ? file.salesRepUserId : ''}
                                                        name='selectedSalesRep'
                                                        onChange={this.handleChangeSelect}
                                                        SelectProps={{
                                                            MenuProps: {
                                                                className: classes.menu,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        disabled={saving ? true : false}
                                                        margin="normal"
                                                        variant="outlined"
                                                    >
                                                        <MenuItem value={''} className={classes.clientRFQ__form__menuItem}>
                                                            {i18n.t('quotes.quoteRequest.quoteSelectNone')}
                                                        </MenuItem>

                                                        {users ? users.map(option => (
                                                            <MenuItem key={option.userId} value={option.userId} className={classes.clientRFQ__form__menuItem}>
                                                                {option.userName}
                                                            </MenuItem>
                                                        )) : []}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <TextField autoComplete='off'
                                                        required
                                                        id="quoteOportunity"
                                                        select
                                                        InputProps={{
                                                            className: classes.clientRFQ__form__input
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        disabled={saving ? true : false}
                                                        label={i18n.t('quotes.quoteRequest.quoteOportunity')}
                                                        className={classes.clientRFQ__form__textField}
                                                        error={validateRFQ && !selectedOportunity ? true : false}
                                                        value={selectedOportunity ? selectedOportunity : file && file.oportunity && file.oportunity.id ? file.oportunity.id
                                                            : ''}
                                                        name='selectedOportunity'
                                                        onChange={this.handleChangeSelect}
                                                        SelectProps={{
                                                            MenuProps: {
                                                                className: classes.menu,
                                                            },
                                                        }}
                                                        margin="normal"
                                                        variant="outlined"
                                                    >
                                                        {oportunities ? oportunities.map(option => (
                                                            <MenuItem key={option.id} value={option.id} className={classes.clientRFQ__form__menuItem}>
                                                                {option.name}
                                                            </MenuItem>
                                                        )) : []}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <TextField autoComplete='off' required
                                                        id="quoteProbability"
                                                        select
                                                        InputProps={{
                                                            className: classes.clientRFQ__form__input
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={validateRFQ && !selectedProbability ? true : false}
                                                        disabled={saving ? true : false}
                                                        label={i18n.t('quotes.quoteRequest.quoteProbability')}
                                                        className={classes.clientRFQ__form__textField}
                                                        value={selectedProbability ? selectedProbability : file && file.probability && file.probability.id ? file.probability.id
                                                            : ''}
                                                        name='selectedProbability'
                                                        onChange={this.handleChangeSelect}
                                                        SelectProps={{
                                                            MenuProps: {
                                                                className: classes.menu,
                                                            },
                                                        }}
                                                        margin="normal"
                                                        variant="outlined"
                                                    >
                                                        {probabilities ? probabilities.map(option => (
                                                            <MenuItem key={option.id} value={option.id} className={classes.clientRFQ__form__menuItem}>
                                                                {option.name}
                                                            </MenuItem>
                                                        )) : []}
                                                    </TextField>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={4} >
                                                <Grid item xs={12} sm={6} style={{position: 'relative'}}>
                                                    <Typography variant='h6' className={classes.client__title}>{i18n.t('quotes.quoteRequest.client')}</Typography>
                                                    <Client
                                                        changeClient={this.changeClient}
                                                        updateRFQClient={this.updateRFQClient}
                                                        validateRFQ={validateRFQ}
                                                    />
                                                </Grid>

                                                {!(selectedClient && selectedClient.id) && (
                                                    <Grid item xs={12} sm={6}>
                                                        <div className={classes.clientRFQ__editActions}>
                                                            <Typography variant='h6' className={classes.client__contact__title}>{i18n.t('quotes.quoteRequest.clientContact')}</Typography>
                                                        </div>
                                                        <Typography color="textSecondary">{i18n.t('quotes.quoteRequest.selectClientFirst')}</Typography>
                                                        {validateRFQ && ((!selectedClientContact) || (selectedClientContact && !selectedClientContact.id)) && (
                                                            <Typography color='error'>{i18n.t('quotes.quoteRequest.clientContactsRequired')}</Typography>
                                                        )}
                                                    </Grid>
                                                )}


                                                {(selectedClient && selectedClient.id) && (
                                                    <Grid item xs={12} sm={6}>
                                                        <div className={classes.clientRFQ__editActions}>
                                                            <Typography variant='h6' className={classes.client__contact__title}>{i18n.t('quotes.quoteRequest.clientContact')}</Typography>
                                                        </div>

                                                        {clientContacts && clientContacts.length > 0 &&
                                                            <Grid container>
                                                                <Grid item xs={12} sm={7}>
                                                                    <CustomSelect
                                                                        options={clientContacts.map(c => ({ label: `${c.firstName} ${c.lastName}`, value: c.id }))}
                                                                        label={i18n.t('quotes.quoteRequest.clientContact')}
                                                                        placeholder={i18n.t('clients.selectClientContact')}
                                                                        fullWidth={true}
                                                                        required={true}
                                                                        value={selectedClientContact ? (selectedClientContact.id === 0 ? null : selectedClientContact.id)
                                                                            : file && file.client && file.client.contact ? file.client.contact.id
                                                                                : null}
                                                                        onSelect={this.selectClientContact}
                                                                        disabled={reloadingClients}
                                                                        error={validateRFQ && ((!selectedClientContact) || (selectedClientContact && selectedClientContact.id === 0)) ? true : false}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={5} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                                                    <ManageClientContactsPopup
                                                                        onClose={this.handleCloseClientContactsPopup}
                                                                        selectedClientContact={selectedClientContact}
                                                                        changeClientContact={this.changeClientContact}
                                                                        updateRFQClientContact={this.updateRFQClientContact} />
                                                                </Grid>
                                                            </Grid>
                                                        }

                                                        {selectedClientContact && selectedClientContact.id !== 0 &&
                                                            <>
                                                                <Typography variant='body2' color="textSecondary">
                                                                    {selectedClientContact.firstName || ''} {selectedClientContact.lastName || ''}
                                                                </Typography>

                                                                {selectedClientContact.email &&
                                                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                                        <Drafts className={classNames(classes.customLink, classes.rfq__clientcontact__message__icon)} onClick={() => this.setState({
                                                                            openNewNote: true,
                                                                        })} />
                                                                        <Typography
                                                                            variant='body2'
                                                                            color='textSecondary'
                                                                            className={classes.customLink}
                                                                            onClick={() => this.setState({
                                                                                openNewNote: true,
                                                                            })} noWrap>{selectedClientContact.email || ''}
                                                                        </Typography>
                                                                    </div>
                                                                }


                                                                {selectedClientContact.phone &&
                                                                    <Typography variant='body2' color="textSecondary">
                                                                        <Phone className={classes.rfq__icon} />{selectedClientContact.phone}
                                                                    </Typography>
                                                                }
                                                            </>
                                                        }

                                                        {clientContacts && selectedClient && selectedClient.id && clientContacts.length === 0 && (
                                                            <>
                                                                <Typography color="textSecondary">{selectedClient.name} {i18n.t('quotes.quoteRequest.noClientContacts')}
                                                                    <br />
                                                                    <Link className={classes.customLink} onClick={() => {
                                                                        this.props.generalActions.showCreateClientContact(true)
                                                                    }}>
                                                                        {i18n.t('quotes.quoteRequest.clickToAddContact')}
                                                                    </Link>
                                                                </Typography>
                                                                <ManageClientContactsPopup
                                                                    hideButton={true}
                                                                    onClose={this.handleCloseClientContactsPopup}
                                                                    selectedClientContact={selectedClientContact}
                                                                    changeClientContact={this.changeClientContact}
                                                                    updateRFQClientContact={this.updateRFQClientContact} />
                                                            </>
                                                        )}

                                                        {validateRFQ && clientContacts && clientContacts.length === 0 && (
                                                            <Typography color='error'>{i18n.t('quotes.quoteRequest.clientContactsRequired')}</Typography>
                                                        )}
                                                    </Grid>
                                                )}
                                            </Grid>




                                            <Grid container spacing={2}>
                                                <Grid item xs={12} style={{ marginTop: '16px' }}>
                                                    <Typography variant='h6' className={classes.rfq__information__title}>{i18n.t('quotes.quoteRequest.rfq')}</Typography>
                                                </Grid>
                                                <Grid item sm={4} xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                                                <InlineDatePicker
                                                                    variant="outlined"
                                                                    label={i18n.t('quotes.quoteRequest.quoteDate')}
                                                                    value={selectedDate}
                                                                    format="MMM DD YYYY"
                                                                    InputProps={{
                                                                        className: classes.clientRFQ__form__quote_date__input
                                                                    }}
                                                                    disabled={saving ? true : false}
                                                                    className={classes.clientRFQ__form__quote_date}
                                                                    styles={customStyles}
                                                                    onChange={this.handleDateChange}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>

                                                        <Grid item xs={4}>
                                                            <TextField autoComplete='off' required
                                                                id="quoteCurrency"
                                                                select
                                                                InputProps={{
                                                                    className: classes.clientRFQ__form__input
                                                                }}
                                                                disabled={saving ? true : false}
                                                                label={i18n.t('quotes.quoteRequest.quoteCurrency')}
                                                                className={classes.clientRFQ__form__textField__currency}
                                                                value={selectedCurrency ? selectedCurrency : file && file.requestForQuote.currency ? file.requestForQuote.currency : ''}
                                                                name='selectedCurrency'
                                                                error={validateRFQ && (!selectedCurrency || selectedCurrency === 'None') ? true : false}
                                                                onChange={this.handleChangeSelect}
                                                                SelectProps={{
                                                                    MenuProps: {
                                                                        className: classes.menu,
                                                                    },
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                margin="normal"
                                                                variant="outlined"
                                                            >
                                                                {currency && currency.map(option => (
                                                                    <MenuItem key={option.currencyCode} value={option.currencyCode} className={classes.clientRFQ__form__menuItem}>
                                                                        {option.currencyCode}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <TextField autoComplete='off' required
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                InputProps={{
                                                                    className: classes.clientRFQ__form__input
                                                                }}
                                                                disabled={saving ? true : false}
                                                                onBlur={e => {
                                                                    this.setState({
                                                                        unsavedChanges: this.state.quoteReference !== e.target.value.trim() ? true : this.state.unsavedChanges,
                                                                        quoteReference: e.target.value.trim()
                                                                    });
                                                                }}
                                                                error={validateRFQ && quoteReference === '' ? true : false}
                                                                defaultValue={quoteReference ? quoteReference : ''}
                                                                className={classes.clientRFQ__form__textField}
                                                                id="quoteReference"
                                                                type="text"
                                                                variant="outlined"
                                                                label={i18n.t('quotes.quoteRequest.quoteReference')}
                                                            />
                                                        </Grid>
                                                    </Grid>


                                                </Grid>
                                                <Grid item sm={4} xs={12}>
                                                    <TextField
                                                        className={classes.clientRFQ__form__textField}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        margindense='true'
                                                        InputProps={{
                                                            className: classes.clientRFQ__form__input__description,
                                                        }}
                                                        onBlur={e => {
                                                            this.setState({
                                                                unsavedChanges: this.state.quoteDescription !== e.target.value.trim() ? true : this.state.unsavedChanges,
                                                                quoteDescription: e.target.value.trim()
                                                            });
                                                        }}
                                                        disabled={saving ? true : false}
                                                        defaultValue={quoteDescription ? quoteDescription : ''}
                                                        multiline
                                                        rows="4"
                                                        type="text"
                                                        id="quoteDescription"
                                                        variant="outlined"
                                                        label={i18n.t('quotes.quoteRequest.quoteDescription')}
                                                    />
                                                </Grid>
                                                <Grid item sm={4} xs={12}>
                                                    <Grid container spacing={2} direction='row'>
                                                        <Grid item xs={6}>
                                                            <CustomSelect options={paymentTerms ? quoteTerms.map(pt => ({ label: pt.quoteTermsName, value: pt.quoteTermsId })) : []}
                                                                label={i18n.t('quotes.quoteRequest.quoteTerms')}
                                                                fullWidth
                                                                value={selectedQuoteTerm ? selectedQuoteTerm :
                                                                    file && file.quoteTerms && file.quoteTerms.id ? file.quoteTerms.id
                                                                        : null}
                                                                onSelect={this.selectQuoteTerms}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                autoComplete='off'
                                                                className={classes.clientRFQ__form__textField}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                InputProps={{
                                                                    className: classes.clientRFQ__form__input
                                                                }}
                                                                onBlur={e => {
                                                                    this.setState({
                                                                        unsavedChanges: this.state.quoteTermsReference !== e.target.value.trim() ? true : this.state.unsavedChanges,
                                                                        quoteTermsReference: e.target.value.trim()
                                                                    });
                                                                }}
                                                                disabled={saving ? true : false}
                                                                defaultValue={quoteTermsReference ? quoteTermsReference : ''}
                                                                type="text"
                                                                id="quoteTermsReferences"
                                                                variant="outlined"
                                                                label={i18n.t('quotes.quoteRequest.quoteTermsReferences')}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0} direction='row'>
                                                        <Grid item xs={12} >
                                                            <CustomSelect
                                                                options={paymentTerms ? paymentTerms.map(pt => ({ label: pt.paymentTermsName, value: pt.paymentTermsId })) : []}
                                                                label={i18n.t('quotes.quoteRequest.paymentTerms')}
                                                                fullWidth
                                                                optionsStyle={{
                                                                    minWidth: '200px',
                                                                }}
                                                                value={selectedPaymentTerm ? selectedPaymentTerm :
                                                                    file && file.paymentTerms && file.paymentTerms.id ? file.paymentTerms.id
                                                                        : null}
                                                                onSelect={this.selectPaymentTerms}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </Grid>


                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <RFQItems
                                                        showItemsROList={false}
                                                        saveFileOnChange={file ? true : false}
                                                        showCreateItemButton={true}
                                                        items={[...items]}
                                                        updateFile={this.updateFile}
                                                        updateItems={(newItems) => {
                                                            this.setState({
                                                                items: newItems
                                                            })
                                                        }}
                                                        validateItems={validateRFQ}
                                                        file={file}
                                                        handleUnsaveChanges={(unsaved) => {
                                                            this.setState({ unsavedChanges: this.state.unsavedChanges || unsaved });
                                                        }} />
                                                </Grid>

                                            </Grid>
                                        </>
                                    )
                                    }

                                    <NoteEditDocuments
                                        open={showAddNote}
                                        fileId={file ? file.id : null}
                                        closeNote={this.closeNote}
                                        message={i18n.t('notes.clientQuoteEdit')}
                                        title={i18n.t('notes.clientQuoteEditTitle')}
                                        onSuccess={()=> this.handleClientRFQ().catch(_ => { })}
                                    />
                                </div>

                            </DialogContent>
                            {clientsFetched && (
                                <DialogActions className={classes.dialog__actions}>
                                    <Grid container spacing={2} justify='space-between'>
                                        {this.state.showRequiredFieldsError && (
                                            <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                                <Typography color='error'>{i18n.t("quotes.quoteRequest.requiredFields")}</Typography>
                                            </Grid>
                                        )}
                                        <Grid item xs={6}>
                                            <Button
                                                color='default'
                                                variant="outlined"
                                                onClick={this.close}>
                                                {i18n.t("quotes.quoteRequest.cancelEdit")}
                                            </Button>
                                        </Grid>

                                        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button
                                                disabled={saving || !unsavedChanges ? true : false}
                                                color="primary"
                                                variant="contained"
                                                onClick={this.handleEditQuote}>
                                                {i18n.t("quotes.quoteRequest.saveQuote")}
                                                {saving && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            )}

                            <CustomMessages
                                type={'error'}
                                message={validateErrorMessage}
                                clearMessage={() => this.setState({
                                    validateErrorMessage: null
                                })}
                            />

                        </Dialog>

                        <NoteForm
                            file={this.props.file || null}
                            open={openNewNote}
                            note={null}
                            user={logedInUser}
                            section={2}
                            sendByEmail={true}
                            emailAddress={selectedClientContact && selectedClientContact.email ? {
                                value: selectedClientContact.email,
                                label: `${selectedClientContact.firstName || ''} ${selectedClientContact.lastName || ''}`
                            } : null}
                            addNewNote={this.handelNoteSaved}
                            handleClose={this.closeNewNote}
                        />

                        <ConfirmationDialog
                            handleClose={this.cancelConfirmationDeleteItem}
                            onConfirm={this.deleteItem}
                            message={i18n.t('quotes.quoteRequest.confirmDeleteItemsMessage')}
                            cancelLabel={i18n.t('quotes.quoteRequest.confirmDeleteItemsNo')}
                            confirmLabel={i18n.t('quotes.quoteRequest.confirmDeleteItemsYes')}
                            open={openConfirmationDialog}
                        />

                    </>
                )}
            </GlobalParametersContext.Consumer>
        )
    }
}





function mapDispatchToProps(dispatch) {
    return {
        clientsActions: bindActionCreators(clientsActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        client: state.client,
        clients: state.clients,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog({ breakpoint: 'sm' })(withStyles(styles, { withTheme: true })(ClientRFQForm)))
