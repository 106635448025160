import React, { Component } from 'react'
import {
    withStyles,
    Grid,
    Typography,
} from '@material-ui/core'
import moment from 'moment'
import i18n from 'i18next'

const styles = theme => {
    return ({

    })
}
class VendorPOReadOnly extends Component {

    render() {
        const { vendorPO } = this.props;

        if (!vendorPO) return null;

        return (
            <Grid container spacing={2} alignItems='baseline'>
                <Grid item sm={6} md={6} xs={12}>
                    <Typography color='textSecondary' variant='body2'>
                        <strong>{i18n.t('quotes.pos.poDetails.poNumber')}</strong> {`${vendorPO.poNumber}`}
                    </Typography>

                    <Typography color='textSecondary' variant='body2'>
                        <strong>{i18n.t('quotes.pos.poDetails.poDate')}</strong> {`${moment(vendorPO.date).format('MMMM Do, YYYY')}`}
                    </Typography>

                    {vendorPO.deliveryDate &&
                        <Typography color='textSecondary' variant='body2'>
                            <strong>{`${i18n.t('quotes.pos.poDetails.eta')} (+ ${vendorPO.vendorQuoteDeliveryDays} ${i18n.t('quotes.pos.poDetails.quoteDeliveryDays')})  `}</strong> {moment(vendorPO.deliveryDate).format('MMMM Do, YYYY')}
                        </Typography>
                    }

                    {vendorPO.comment &&
                        <span>
                            <Typography color='textSecondary' variant='body2'>
                                <strong>{`${i18n.t('quotes.pos.poDetails.poComments')}  `}</strong>
                            </Typography>
                            <Typography color='textSecondary' variant='body2' style={{ whiteSpace: 'pre-line' }}>
                                {vendorPO.comment}
                            </Typography>
                        </span>
                    }
                </Grid>

                {vendorPO.deliveryAddress &&
                    <Grid item sm={6} md={6} xs={12}>
                        <Typography color='textSecondary' variant='body2'>
                            <strong>{i18n.t('quotes.pos.poDetails.deliveryAddress')}</strong>
                        </Typography>
                        <Typography color='textSecondary' variant='body2'>
                            {vendorPO.deliveryAddress.name}
                        </Typography>
                        <Typography color='textSecondary' variant='body2'>
                            {vendorPO.deliveryAddress.contactPerson}
                        </Typography>
                        <Typography color='textSecondary' variant='body2'>
                            {`${vendorPO.deliveryAddress.address1 || ''}. ${vendorPO.deliveryAddress.address2 || ''}. ${vendorPO.deliveryAddress.city || ''}, ${vendorPO.deliveryAddress.state || ''} - ${vendorPO.deliveryAddress.zipCode || ''}`}
                        </Typography>
                    </Grid>
                }
            </Grid>
        )
    }
}

export default withStyles(styles)(VendorPOReadOnly);