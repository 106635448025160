import React, { Component } from 'react'
import moment from "moment";
import ReactTable from "react-table";
import {
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    Link,
    IconButton
} from '@material-ui/core';
import {
    Close
} from '@material-ui/icons'
import { withRouter } from "react-router";
import { SlideUp } from '../general/Transitions'
import { withStyles } from '@material-ui/core/styles'
import { FileDetails } from '../quotes'
import i18n from 'i18next';
import classNames from 'classnames'
import Permissions from '../../lib/permissions'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as peopleActions from '../../actions/peopleActions'


const styles = theme => {
    return ({
        flexCell: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        table: {
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily
        },
        list__file__lastUpdate__container: {
            position: 'absolute', right: 0
        },
        list__file__lastUpdate: {
            backgroundColor: theme.palette.grey[100],
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.grey[400]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--late': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.orange[60]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.orange[30],
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--veryLate': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.red[100]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.red[50],
            color: theme.palette.getContrastText(theme.palette.red[50]),
        },
        list__fileName: {

        },
        list__fileReference: {

        },
        list__fileClient: {
            fontWeight: 'bold',
        },
        list__fileAuthor: {
            color: theme.palette.naval.main,
        },
        rfq__noRows: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh'
        },
        rfq__table__fileLink: {
            color: theme.palette.blue[100],
            cursor: 'pointer'
        },
        rfq__table__fileLink__disabled: {
            color: theme.palette.text.primary,
            cursor: 'default'
        },
        rfq__warning__icon: {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.orange[100],
        },
        rfq__noItems: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.orange[80],
            borderRadius: theme.spacing() / 2,
            width: '80px',
            padding: '0 5px'
        },
        rfq__table__fileDatesContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        rfq__table__fileDescriptions: {
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        oportunity__probability: {
            border: `solid thin ${theme.palette.secondary.main}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '8pt',
            color: theme.palette.text.secondary,
            padding: theme.spacing() / 2,
            textAlign: 'center',
            width: '80px'
        },
        'oportunity__probability--unknow': {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.getContrastText(theme.palette.grey[100])
        },
        'oportunity__probability--likely': {
            backgroundColor: theme.palette.seabook.main,
            color: theme.palette.getContrastText(theme.palette.seabook.main)
        },
        'oportunity__probability--verylikely': {
            backgroundColor: theme.palette.green[100],
            color: theme.palette.getContrastText(theme.palette.green[100])
        },
        list__fileDetails: {
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        list__countryContainer: {
            position: 'absolute',

            [theme.breakpoints.up('sm')]: {
                right: 0,
                top: 0,
            },
            [theme.breakpoints.down('sm')]: {
                right: theme.spacing(),
                bottom: 0
            }

        },
        list__fileCountry: { fontSize: '9pt' },
        country__select: {
            width: '100%'
        },
        filesList__closeWindow: {
            position: 'absolute',
            top: 0,
            right: theme.spacing(),
        },
    })
};


// const probabilities = ['unknow', 'likely', 'verylikely'];


class FileListDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: this.props.files,
            selectedFile: null,
            showFileDetail: false
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            files: nextProps.files
        })
    }

    quoteDateFormat = (dt) => {
        return moment(dt).format('MMM Do, YYYY')
    }

    handleShowQuote = (row) => {
        this.setState({
            selectedFile: row,
            showFileDetail: true
        })

    }

    closeFileDetails = () => {
        this.setState({
            selectedFile: null,
            showFileDetail: false
        })
    }

    render = () => {
        const { classes, people } = this.props,
            { files, selectedFile, showFileDetail } = this.state;

        if (!files) return null

        return (
            <>
                <Dialog
                    maxWidth={'sm'}
                    fullWidth={true}
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    onClose={this.props.onClose}
                    scroll='body'
                    aria-labelledby="filesList"
                    open={this.props.open}
                    TransitionComponent={SlideUp}
                >
                    <DialogTitle id="notes_form" disableTypography={true}>
                        {i18n.t("dashboard.filesList.title")}
                        <IconButton color="inherit"
                            onClick={this.props.onClose}
                            aria-label="Close" className={classes.filesList__closeWindow}>
                            <Close />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent style={{ padding: '16px', overflow: 'hidden' }}>
                        {files && files.length > 0 && (
                            <div>
                                <ReactTable
                                    data={files}
                                    columns={[

                                        {
                                            Header: i18n.t("quotes.requestedByClient.table.dateFile"),
                                            id: 'file',
                                            width: 150,
                                            filterable: false,
                                            style: {
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                minHeight: '60px',
                                            },
                                            accessor: row => (
                                                <div className={classes.rfq__table__fileDescriptions}>
                                                    <Link
                                                        variant="body2"
                                                        className={Permissions.checkSensitiveInformationPermission() ? classes.rfq__table__fileLink : classes.rfq__table__fileLink__disabled}
                                                        onClick={() => {
                                                            if (Permissions.checkSensitiveInformationPermission()) {
                                                                this.handleShowQuote(row)
                                                            }
                                                        }}
                                                        underline={Permissions.checkSensitiveInformationPermission() ? 'hover' : 'never'}
                                                    >
                                                        <strong>{i18n.t("dashboard.filesList.file")} {`${row.fileId}`}</strong>
                                                    </Link>



                                                    <div className={classes.rfq__table__fileDatesContainer}>
                                                        <Typography
                                                            variant='body2'
                                                            color='textSecondary'>
                                                            {this.quoteDateFormat(row.createDateTime)}
                                                        </Typography>
                                                    </div>
                                                    <div className={classes.list__file__lastUpdate__container}>
                                                        <Typography
                                                            className={moment().diff(moment(row.lastUpdateDateTime), 'days') < 2 ?
                                                                classes.list__file__lastUpdate : (moment().diff(moment(row.lastUpdateDateTime), 'days') < 4 ?
                                                                    classes['list__file__lastUpdate--late'] :
                                                                    classes['list__file__lastUpdate--veryLate'])} >
                                                            {moment(row.lastUpdateDateTime).fromNow(true)}
                                                        </Typography>
                                                    </div>
                                                </div>)
                                        },

                                        {
                                            Header: i18n.t("quotes.requestedByClient.table.clientNameRef"),
                                            filterable: false,
                                            id: 'quoteClientName',
                                            accessor: row => (
                                                <div className={classes.list__fileDetails}>
                                                    <Typography className={classes.list__fileClient} noWrap>{row.client.name}</Typography>
                                                    <Typography className={classes.list__fileName}>{`${row.requestForQuote.name}`}</Typography>
                                                    <Typography className={classes.list__fileName}>{`${row.requestForQuote.reference}`}</Typography>
                                                </div>
                                            ),
                                            style: {
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                            }
                                        },
                                        {
                                            Header: i18n.t("quotes.requestedByClient.table.country"),
                                            filterable: false,
                                            style: {
                                                display: 'flex',
                                                alignItems: "center"
                                            },
                                            id: 'country',
                                            width: 60,
                                            accessor: row => (
                                                <Typography className={classes.list__fileCountry} color='textSecondary' noWrap>{row.country.code}</Typography>
                                            )
                                        },
                                        // {
                                        //     Header: i18n.t("quotes.requestedByClient.table.importance"),
                                        //     id: 'importance',
                                        //     style: {
                                        //         display: 'flex',
                                        //         alignItems: "center"
                                        //     },
                                        //     width: 90,
                                        //     Cell: row => (
                                        //         row.original.oportunity.name ? (
                                        //             <div className={classNames(classes.oportunity__probability, classes[`oportunity__probability--${probabilities[row.original.probability.id - 1]}`])}>
                                        //                 {row.original.oportunity.name}
                                        //             </div>
                                        //         ) : null

                                        //     )
                                        // },
                                        {
                                            Header: i18n.t("quotes.requestedByClient.table.users"),
                                            filterable: true,
                                            id: 'user_Author',
                                            width: 90,
                                            show: people && people.filterPeople.length > 1 ? true : false,
                                            accessor: row => {
                                                return (
                                                    <div>
                                                        <Typography className={classes.list__fileAuthor}>{row.author.name}</Typography>

                                                        {row.salesRepresentative && row.salesRepresentative.name && (
                                                            <Typography color='textSecondary'>{row.salesRepresentativeName}</Typography>
                                                        )}
                                                    </div>
                                                )
                                            },
                                            style: {
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                            }
                                        }
                                    ]}
                                    pageSize={files && files.length >= 50 ? 50 : files.length}
                                    className={classNames(classes.table, ' -striped -highlight')}
                                    showPaginationTop={files && files.length >= 50 ? true : false}
                                    showPaginationBottom={files && files.length >= 50 ? true : false}
                                    sortable={false}
                                    resizable={false}
                                />
                            </div>
                        )}
                        {files && files.length === 0 && (
                            <div className={classes.rfq__noRows}>
                                <Typography variant='body2'>{i18n.t('dashboard.filesList.noFiles')}</Typography>
                            </div>
                        )}
                    </DialogContent>

                </Dialog>

                <FileDetails
                    open={showFileDetail}
                    onClose={this.closeFileDetails}
                    fileId={selectedFile ? selectedFile.fileId : null}
                    olderTask={selectedFile ? selectedFile.olderTask : null}
                    notesCount={selectedFile ? selectedFile.notesCount : 0}
                    status={selectedFile ? selectedFile.status : null}
                />
            </>
        );

    }
}

function mapDispatchToProps(dispatch) {
    return {
        peopleActions: bindActionCreators(peopleActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        people: state.people
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(FileListDialog)));