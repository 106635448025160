import React, { Component } from 'react'

import {
    Grid,
    TextField,
    withStyles,
    Typography,
    Link,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    IconButton,
    InputAdornment,
    ClickAwayListener,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'
import { Close, Info, Done } from '@material-ui/icons'
import i18n from 'i18next'
import classNames from 'classnames'
import ReactTable from "react-table"
import NumberFormat from 'react-number-format'
import { ConfirmationDialog } from '../../general';
import { NumberFormatInputCustom, CustomSelect } from '../../general'
import { GlobalParametersContext } from '../../../contexts/globalParametersContext'

import API from '../../../lib/api'

// REDUX
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import * as generalActions from '../../../actions/generalActions'


const styles = theme => {
    return (
        {
            other__cost__form__menu: {
                width: "auto",
            },
            other__cost__form__addButton: {
                ...theme.smallButton,
                marginTop: theme.spacing(2),
                borderColor: theme.palette.blue[100],
                color: theme.palette.blue[100]
            },

            other__cost__form__items__table: {
                fontSize: theme.typography.fontSize * 0.8,
                fontFamily: theme.typography.fontFamily,
                textAlign: 'left',
                border: `solid thin ${theme.palette.grey[300]}`
            },
            other__cost__form__items__tablecell__rigth: {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
            },
            other__cost__buttons: {
                display: 'flex',
                flexDirection: 'column',

            },
            other__costs__add__button: {
                ...theme.customLink
            },
            other__costs__title: {
                padding: '0 4px'
            },
            other__costs__container: {
                marginTop: theme.spacing(),
                marginBottom: theme.spacing()
            },
            other__costs__table__container: {
                padding: 0,
                marginTop: theme.spacing(),
                marginBottom: theme.spacing(),
                marginRight: theme.spacing()
            },
            other__costs__container_addButton: {
                display: 'flex',
                alignItems: 'center'
            },
            other__cost__closeWindow: {
                position: 'absolute',
                top: 0,
                right: theme.spacing(),
            },
            preparing__otherCost__delete: {
                minWidth: '60px',
                borderColor: theme.palette.red[80],
                '&:hover': {
                    backgroundColor: theme.palette.red[60],
                    color: theme.palette.getContrastText(theme.palette.red[100])
                },
                color: theme.palette.red[80],
            },
            other__cost__select__container: {
                marginTop: theme.spacing()
            },
            vendorOtherCostsTooltip__icon: {
                fill: theme.palette.grey[300],
                fontSize: '20px'
            },
            subTitle: {
                color: theme.palette.blue[100]
            },
            manage__button: {
                ...theme.manage__button,
                marginRight: '4px'
            }
        })
}

const VendorOtherCostsTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.grey[300],
        color: 'rgba(0, 0, 0, 0.87)',
        width: 210,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
    popper: {
        zIndex: 1
    }
}))(Tooltip);

class OtherCosts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRow: null,
            openTooltip: false,
            weighted: false,
            otherCostIndex: null,
            showOtherCostForm: false,
            otherCostAmount: null,
            selectedOtherCost: null,
            otherCostComments: null,
            validateOtherCost: false,
            deleteOtherCostIdx: null,
            openConfirmationDialog: false,
            otherCostSize: props.quoteOtherCosts ? props.quoteOtherCosts.length : 0,
            quoteOtherCosts: props.quoteOtherCosts || [],
            items: this.props.items,
            addNewCost: false,
            costName: null
        }
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            items: nextProps.items,
            quoteOtherCosts: nextProps.quoteOtherCosts,
            otherCostSize: nextProps.quoteOtherCosts ? nextProps.quoteOtherCosts.filter(oc => !oc.deleted).length : 0,
        })
    }

    handleChangeWeighted = (e) => {
        this.setState({
            weighted: e.target.checked
        });
    }

    addOtherCost = () => {
        const { otherCostAmount,
            selectedOtherCost,
            addNewCost,
            addNewOtherCostFunction,
            costName } = this.state;

        this.setState({
            validateOtherCost: true
        })

        if(addNewCost){
            if (!costName || otherCostAmount === '' || !otherCostAmount || selectedOtherCost === '' || !selectedOtherCost){
                return
            }
        } else {
            if (otherCostAmount === '' || selectedOtherCost === '' || !otherCostAmount || !selectedOtherCost) {
                return
            }
        }


        if(addNewCost){
            API.GlobalParameters.createOtherCost({name: costName}).then(res => {
                this.props.generalActions.addNewOtherCost(res.data.record);
                addNewOtherCostFunction && addNewOtherCostFunction(res.data.record)
                this.setState({
                    selectedOtherCost: res.data.record.otherCostId
                }, ()=>{
                    this.addOtherCostToDocument()
                })
            })
        } else {
            this.addOtherCostToDocument()
        }
    
    }

    addOtherCostToDocument=()=>{
        const { selectedRow,
            otherCostIndex,
            otherCostAmount,
            otherCostComments,
            selectedOtherCost,
            weighted, items,
            quoteOtherCosts } = this.state,
            { otherCosts } = this.context;

        let newOtherCosts = [...quoteOtherCosts],
            totalItems= 0,
            newItems=[],
            totalWeightedCost = 0,
            newOtherCost = {
                clientQuoteOtherCostId: selectedRow ? selectedRow.clientQuoteOtherCostId : null,
                id: selectedOtherCost,
                name: otherCosts.find(oc => oc.otherCostId === selectedOtherCost).otherCostName,
                amount: otherCostAmount,
                comments: otherCostComments,
                weighted: weighted ? weighted : null
            };

        if (selectedRow) {
            newOtherCosts[otherCostIndex] = {
                ...newOtherCosts[otherCostIndex],
                ...newOtherCost
            }

        } else {
            newOtherCosts = [...newOtherCosts, newOtherCost]
        }

        if (this.props.updateItems){
            totalItems = items.reduce(((total, item) => parseInt(total) + (parseInt(item.rfqItem.quantity) * parseFloat(item.amount))), 0)
            totalWeightedCost = newOtherCosts.filter(oc => oc.weighted).reduce(((total, item) => parseInt(total) + parseInt(item.amount)), 0)

            newItems = [...items].map((item) => {
                let lineWeightedPercent = (parseInt(item.rfqItem.quantity) * parseFloat(item.amount) * 100) / totalItems,
                    weightedCost = (lineWeightedPercent * totalWeightedCost) / 100,
                    unitAmount = parseFloat(item.acceptedVendorQuoteItem.unitAmount || 0) ;

                return {
                    ...item,
                    unitAmount: item.acceptedVendorQuoteItem.unitAmount ? unitAmount : item.unitAmount, 
                    amount: item.acceptedVendorQuoteItem.unitAmount ? unitAmount + ((unitAmount * parseFloat(item.markupPercent)) / 100) + (weightedCost / parseInt(item.rfqItem.quantity)) : item.amount,  
                    weightedCost
                }
            })

            this.props.updateItems(newItems)
        } 

        this.setState({
            showOtherCostForm: false,
            validateOtherCost: false,
            quoteOtherCosts: newOtherCosts,
            otherCostSize: newOtherCosts.length,
            selectedOtherCost: null,
            selectedRow: null,
            otherCostAmount: null,
            otherCostIndex: null,
            otherCostComments: null,
            weighted: false,
            addNewCost: false,
            costName: null
        }, () => {
            this.props.updateOtherCosts && this.props.updateOtherCosts(newOtherCosts)
        })
    }


    confirmDeleteOtherCost = () => {
        this.setState({
            openConfirmationDialog: true
        })
    }

    cancelConfirmationDeleteOtherCost = () => {
        this.setState({
            openConfirmationDialog: false
        })
    }

    deleteOtherCost = () => {
        const { otherCostIndex, weighted, items, otherCostAmount } = this.state;
        const quoteOtherCosts = [...this.state.quoteOtherCosts]

        let weightedCost = 0,
            totalItems = 0,
            totalOtherCosts = 0,
            newItems = [];

        if (weighted && items) {
            totalItems = items.reduce(((total, item) => parseInt(total) + (parseInt(item.rfqItem.quantity) * (parseFloat(item.amount)))), 0)
            totalOtherCosts = quoteOtherCosts.filter(oc => oc.weighted && !oc.deleted).reduce(((total, item) => parseInt(total) + parseFloat(item.amount)), 0)
            weightedCost = totalOtherCosts - parseFloat(otherCostAmount)

            newItems = [...items].map((item) => {
                let lineWeightedPercent = (parseInt(item.rfqItem.quantity) * parseFloat(item.amount) * 100) / totalItems,
                    newWeightedCost = (lineWeightedPercent * weightedCost) / 100,
                    unitAmount = parseFloat(item.acceptedVendorQuoteItem.unitAmount || 0);

                return {
                    ...item,
                    unitAmount: unitAmount,
                    amount: item.unitAmount ? (unitAmount + ((unitAmount * parseFloat(item.markupPercent || 0)) / 100)) + newWeightedCost : item.amount,
                    weightedCost: newWeightedCost
                }
            })

            this.props.updateItems && this.props.updateItems(newItems)
        }

        // quoteOtherCosts.splice(otherCostIndex, 1)
        quoteOtherCosts[otherCostIndex].deleted = true

        let newQuoteOtherCosts = quoteOtherCosts

        this.setState({
            quoteOtherCosts,
            showOtherCostForm: false,
            selectedRow: null,
            otherCostIndex: null,
            openConfirmationDialog: false,
            otherCostSize: newQuoteOtherCosts.length,
            weighted: false,
            addNewCost: false,
            costName: null
        })

        this.props.updateOtherCosts && this.props.updateOtherCosts(newQuoteOtherCosts)
    }

    editOtherCost = (row) => {
        const { quoteOtherCosts } = this.state,
            index = quoteOtherCosts && quoteOtherCosts.length > 0 ? quoteOtherCosts.findIndex(oc => oc.id === row.id) : null;

        this.setState({
            showOtherCostForm: true,
            selectedRow: row,
            otherCostIndex: index,
            selectedOtherCost: row.id,
            otherCostAmount: row.amount,
            otherCostComments: row.comments,
            weighted: row.weighted,
            addNewCost: false,
            costName: null
        })
    }

    closeOtherCost = () => {
        this.setState({
            selectedRow: null,
            otherCostIndex: null,
            showOtherCostForm: false,
            validateOtherCost: false,
            selectedOtherCost: null,
            otherCostAmount: null,
            otherCostComments: null,
            weighted: false,
            addNewCost: false,
            costName: null
        })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };

    render() {
        const { classes, currencySymbol, desactiveSection, vendorQuoteStatus, showVendorOtherCostsTooltip, vendorQuoteOtherCost, fileId, isClientQuote } = this.props,
            { otherCostSize,
                openTooltip,
                quoteOtherCosts,
                showOtherCostForm,
                otherCostAmount,
                selectedOtherCost,
                otherCostComments,
                validateOtherCost,
                openConfirmationDialog,
                selectedRow,
                weighted,
                addNewCost,
                costName
            } = this.state;

        return (
            <GlobalParametersContext.Consumer>
                {({ otherCosts, addNewOtherCost }) => {
                    let newOtherCost = otherCosts ? [...otherCosts] : []

                    newOtherCost.unshift({
                        otherCostId: -1,
                        otherCostName: "Create New Cost"
                    })

                    return (
                        <div>
                            <Grid container spacing={1}>
                                <Grid item xs={12} className={classes.other__costs__title} >
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant='h6' className={classes.subTitle}>{i18n.t('quotes.preparatingQuoteInformation.otherCosts')}</Typography>

                                        {
                                            // {((vendorQuoteStatus && vendorQuoteStatus !== 3) || !vendorQuoteStatus) &&
                                        }
                                            <div className={classes.other__costs__container_addButton}>
                                                <Button
                                                    className={classes.manage__button}
                                                    size="small"
                                                    variant='outlined'
                                                    disabled={desactiveSection}
                                                    onClick={() => this.setState({ showOtherCostForm: !showOtherCostForm })}>
                                                    {i18n.t('quotes.preparatingQuoteInformation.addOtherCosts')}
                                                </Button>
                                                {showVendorOtherCostsTooltip &&
                                                    <ClickAwayListener onClickAway={() => {
                                                        this.setState({
                                                            openTooltip: false
                                                        })
                                                    }}>
                                                        <VendorOtherCostsTooltip
                                                            onClose={() => {
                                                                this.setState({
                                                                    openTooltip: false
                                                                })
                                                            }}
                                                            PopperProps={{
                                                                disablePortal: true,
                                                            }}
                                                            open={openTooltip}
                                                            placement="bottom-end"
                                                            disableFocusListener
                                                            disableHoverListener
                                                            disableTouchListener
                                                            title={
                                                                <div>
                                                                    <Typography variant='body2' style={{ fontSize: '11px' }}>
                                                                        {`${i18n.t('quotes.preparatingQuoteInformation.vendorsOtherCosts')} `}
                                                                    </Typography>

                                                                    {vendorQuoteOtherCost.length !== 0 ? vendorQuoteOtherCost.map((item, i) => {
                                                                        return (
                                                                            <div key={i}>
                                                                                <div style={{ fontSize: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                                                    <Typography variant='body2' color='secondary' style={{ fontSize: '10px', width: '140px' }} noWrap>
                                                                                        {item.vendorName}
                                                                                    </Typography>
                                                                                    <Typography variant='body2' color='secondary' style={{ fontSize: '10px', width: '140px' }} noWrap>
                                                                                        {item.name}
                                                                                    </Typography>
                                                                                    <Typography variant='body2' color='secondary' style={{ fontSize: '10px' }}>
                                                                                        <NumberFormat value={item.amount}
                                                                                            displayType={'text'}
                                                                                            thousandSeparator={true}
                                                                                            prefix={currencySymbol}
                                                                                            decimalScale={2}
                                                                                            fixedDecimalScale={true}
                                                                                        />
                                                                                    </Typography>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }) :
                                                                        <Typography variant='body2' style={{ fontSize: '10px' }}>
                                                                            {`${i18n.t('quotes.preparatingQuoteInformation.noVendorOtherCost')} `}
                                                                        </Typography>
                                                                    }
                                                                </div>
                                                            }
                                                        >
                                                            <Info className={classes.vendorOtherCostsTooltip__icon} onClick={() => {
                                                                this.setState({
                                                                    openTooltip: !this.state.openTooltip
                                                                })
                                                            }}
                                                            />
                                                        </VendorOtherCostsTooltip>
                                                    </ClickAwayListener>
                                                }
                                            </div>
                                    </div>
                                </Grid>


                                {quoteOtherCosts && quoteOtherCosts.filter(oc => !oc.deleted).length > 0 &&
                                    <Grid item xs={12}>
                                        <ReactTable
                                            data={quoteOtherCosts.filter(oc => !oc.deleted) || []}
                                            columns={[
                                                {
                                                    Header: i18n.t('quotes.preparatingQuoteInformation.otherCostsTable.column1'),
                                                    id: 'otherCostTableName',
                                                    accessor: 'name',
                                                    style: {
                                                        whiteSpace: 'pre-wrap',
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    },
                                                    width: 150
                                                },
                                                {
                                                    Header: i18n.t('quotes.preparatingQuoteInformation.otherCostsTable.column2'),
                                                    id: 'otherCostTableComments',
                                                    accessor: row =>
                                                        <div>
                                                            <Typography>
                                                                {row.comments}
                                                            </Typography>
                                                        </div>,
                                                    style: {
                                                        whiteSpace: 'pre-wrap',
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }
                                                },
                                                {
                                                    Header: i18n.t('quotes.preparatingQuoteInformation.otherCostsTable.weighted'),
                                                    id: 'prorated',
                                                    show: isClientQuote,
                                                    accessor: row =>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {row.weighted !== 0 && row.weighted !== null && row.weighted !== undefined &&
                                                                <Done />
                                                            }
                                                        </div>,
                                                    width: 70
                                                },
                                                {
                                                    Header: i18n.t('quotes.preparatingQuoteInformation.otherCostsTable.column4'),
                                                    id: 'otherCostTableAmount',
                                                    className: classes.other__cost__form__items__tablecell__rigth,
                                                    accessor: row =>
                                                        <div style={{textAlign: 'right'}}>
                                                            <NumberFormat value={row.amount}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                prefix={currencySymbol}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true} />
                                                        </div>,
                                                    width: 90
                                                },
                                                {
                                                    Header: '',
                                                    id: 'deleteOtherCost',
                                                    show: (!vendorQuoteStatus || (vendorQuoteStatus && vendorQuoteStatus <= 3)),
                                                    style: {
                                                        textAlign: 'center',
                                                        display: 'flex',
                                                        justifyContent: 'center'
                                                    },
                                                    width: 45,
                                                    filterable: false,
                                                    accessor: row =>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Link
                                                                variant='button'
                                                                className={classes.other__costs__add__button}
                                                                onClick={() => {
                                                                    this.editOtherCost(row)
                                                                }}>
                                                                {i18n.t('quotes.preparatingQuoteInformation.editOtherCost')}
                                                            </Link>
                                                        </div>
                                                }
                                            ]}
                                            defaultPageSize={quoteOtherCosts.filter(oc=> !oc.deleted).length}
                                            pageSize={otherCostSize}
                                            onPageSizeChange={(pageSize, page) =>
                                                this.setState({ page, pageSize })
                                            }
                                            filtrable={false}
                                            sortable={false}
                                            resizable={false}
                                            className={classNames(classes.other__cost__form__items__table, ' -striped -highlight')}
                                            showPaginationTop={false}
                                            showPaginationBottom={false}
                                        />
                                    </Grid>
                                }

                            </Grid>

                            {showOtherCostForm &&
                                <Dialog
                                    maxWidth={'xs'}
                                    fullWidth={true}
                                    onClose={this.closeOtherCost}
                                    scroll='paper'
                                    aria-labelledby="otherCost_form"
                                    open={true}
                                >
                                    <DialogTitle id="otherCost_form" disableTypography={true}>
                                        {fileId ? `${i18n.t('quotes.preparing.file')} ${fileId} - ` : ''}{i18n.t('quotes.preparatingQuoteInformation.addOtherCosts')}
                                        <IconButton color="inherit"
                                            onClick={this.closeOtherCost}
                                            aria-label="Close" className={classes.other__cost__closeWindow}>
                                            <Close />
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent>
                                        <Grid container spacing={0} alignItems='flex-start'>
                                            <Grid item xs={12} className={classes.other__cost__select__container}>

                                                <CustomSelect options={newOtherCost ? newOtherCost.map((e) => ({
                                                        "label": e.otherCostName,
                                                        "value": e.otherCostId
                                                    })) : []}
                                                    label={i18n.t('quotes.preparatingQuoteInformation.cost')}
                                                    value={selectedOtherCost ? selectedOtherCost : null}
                                                    required={true}
                                                    error={validateOtherCost && !selectedOtherCost ? true : false}
                                                    fullWidth
                                                    onSelect={(cost) => {
                                                        if(cost && cost.value === -1){
                                                            this.setState({ 
                                                                addNewCost: true,
                                                                addNewOtherCostFunction: addNewOtherCost,
                                                                selectedOtherCost: cost ? cost.value : null
                                                            })
                                                        } else {
                                                            this.setState({
                                                                addNewCost: false,
                                                                addNewOtherCostFunction: null,
                                                                selectedOtherCost: cost ? cost.value : null
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Grid>

                                            {addNewCost &&
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required={addNewCost ? true : false}
                                                        autoComplete='off'
                                                        id="cost-name"
                                                        name="costName"
                                                        type='text'
                                                        label={i18n.t('quotes.preparatingQuoteInformation.costName')}
                                                        fullWidth
                                                        value={costName ? costName : ''}
                                                        margin="normal"
                                                        variant="outlined"
                                                        error={addNewCost && (costName === '' || !costName) && validateOtherCost ? true : false}
                                                        onChange={this.handleChange('costName')}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                            }

                                            <Grid item xs={12}>
                                                <TextField
                                                    autoComplete='off'
                                                    id="text-comment"
                                                    name="otherCostComments"
                                                    label={i18n.t('quotes.preparatingQuoteInformation.comment')}
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    value={otherCostComments ? otherCostComments : ''}
                                                    margin="normal"
                                                    variant="outlined"
                                                    onChange={this.handleChange('otherCostComments')}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField autoComplete='off' required
                                                    variant="outlined"
                                                    id="text-other_cost_amount"
                                                    label={i18n.t('quotes.preparatingQuoteInformation.amount')}
                                                    name="otherCostAmount"
                                                    error={(otherCostAmount === '' || !otherCostAmount) && validateOtherCost ? true : false}
                                                    onChange={this.handleChange('otherCostAmount')}
                                                    style={{
                                                        width: '80%',
                                                        marginTop: '10px',
                                                    }}
                                                    value={otherCostAmount ? otherCostAmount : ''}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" >{currencySymbol}</InputAdornment>,
                                                        inputComponent: NumberFormatInputCustom,
                                                    }}
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            { isClientQuote &&
                                                <Grid item xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                    <FormGroup row style={{margin: '16px 10px 0 0'}}>
                                                        <FormControlLabel
                                                            style={{ marginLeft: '6px' }}
                                                            labelPlacement="start"
                                                            label={i18n.t('quotes.preparatingQuoteInformation.weighted')}
                                                            control={
                                                                <Checkbox
                                                                    value='weighted'
                                                                    defaultChecked={weighted ? true : false}
                                                                    style={{ padding: '0', marginLeft: '5px' }}
                                                                    onChange={this.handleChangeWeighted}
                                                                />
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                            }
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions style={{ justifyContent: selectedRow ? 'space-between' : 'flex-end' }}>
                                        {selectedRow &&
                                            <Button
                                                className={classes.preparing__otherCost__delete}
                                                onClick={this.confirmDeleteOtherCost}>
                                                {i18n.t('quotes.preparatingQuoteInformation.deleteOtherCost')}
                                            </Button>
                                        }
                                        <div className={classes.button__wrapper}>
                                            <Button
                                                style={{ minWidth: '100px' }}
                                                color="primary"
                                                variant="contained"
                                                onClick={this.addOtherCost}>
                                                {i18n.t('quotes.preparatingQuoteInformation.addOtherCost')}
                                            </Button>
                                        </div>
                                    </DialogActions>
                                </Dialog>
                            }

                            <ConfirmationDialog
                                handleClose={this.cancelConfirmationDeleteOtherCost}
                                onConfirm={this.deleteOtherCost}
                                message={i18n.t('quotes.preparatingQuoteInformation.confirmation.confirmOtherCostMessage')}
                                cancelLabel={i18n.t('quotes.preparatingQuoteInformation.confirmation.cancelLabel')}
                                confirmLabel={i18n.t('quotes.preparatingQuoteInformation.confirmation.confirmLabel')}
                                open={openConfirmationDialog}
                            />
                        </div>
                    )
                }}
            </GlobalParametersContext.Consumer>
        )
    }
}
OtherCosts.contextType = GlobalParametersContext;

function mapStateToProps(state) {
    return {
        general: state.general,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OtherCosts))