import React, { Component } from 'react'
import {
    Typography,
    Grid,
    withStyles,
    withMobileDialog,
    Paper,
    Link,
    Button,
    CircularProgress
} from '@material-ui/core'
import { Drafts } from '@material-ui/icons'
import i18n from 'i18next'
import NumberFormat from 'react-number-format'
import moment from "moment";
import API from '../../../lib/api'
import { InvoiceHeader, InvoiceItems, InvoiceOtherCosts, InvoiceForm } from '.'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as filesActions from '../../../actions/filesActions'

import Permissions from '../../../lib/permissions'


const styles = theme => {
    return {
        invoice__form: {
            padding: `${theme.spacing()}px ${theme.spacing(2)}px`
        },
        invoice__form__total: {
            fontSize: '16pt',
            fontFamily: theme.typography.fontFamily,
            textAlign: 'left',
        },
        invoice__form__paper: {
            padding: theme.spacing(2),
            margin: `0 ${theme.spacing()}px`,
            position: 'relative'
        },
        invoice__editLink: theme.customLink,

        print__edit__buttons__container: {
            position: 'absolute',
            zIndex: 2,
            right: theme.spacing(4),
            top: theme.spacing() * 7,
            [theme.breakpoints.down('xs')]: {
                position: 'unset',
                padding: '16px 16px 0'
            },
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            [theme.breakpoints.only('xs')]: {
                justifyContent: 'space-between',
            }
        },
        subaction__bar__actionButton__next: {
            backgroundColor: theme.palette.blue[100],
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2),
            color: 'white',
            '&:hover': {
                backgroundColor: theme.palette.blue[100],
                filter: 'brightness(1.1);'
            }
        },
        buttonSpinner: theme.buttonSpinner,
        vendor__status__icon__preparing: {
            marginRight: theme.spacing(),
            fontSize: '10pt',
            color: theme.palette.red[100],
        },
    }
};

class InvoiceCard extends Component {
    state = {
        editInvoiceDialogOpen: false,
        otherCosts: this.props.invoice && this.props.invoice.otherCosts ? this.props.invoice.otherCosts : [],
        items: this.props.invoice && this.props.invoice.items ? this.props.invoice.items.map((item) => {
            let clientQuoteItem = this.props.file.clientQuote[0].items.find(i => i.rfqItem.id === item.rfqItem.id),
                poItem = this.props.file && this.props.file.clientQuote[0] ? this.props.file.clientQuote[0].clientPO.items.find(i => i.id === item.clientPOItemId) : null;

            return {
                ...poItem,
                rfqItem: item.rfqItem,
                acceptedVendorQuoteItem: clientQuoteItem.acceptedVendorQuoteItem,
                invoiceQuantity: item.invoiceQuantity
            }
        }) : [],
        file: this.props.file,
        saving: false
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            otherCosts: nextProps.invoice && nextProps.invoice.otherCosts ? nextProps.invoice.otherCosts : [],
            items: nextProps.invoice && nextProps.invoice.items ? nextProps.invoice.items.map((item) => {
                let clientQuoteItem = nextProps.file.clientQuote[0].items.find(i => i.rfqItem.id === item.rfqItem.id),
                    poItem = nextProps.file && nextProps.file.clientQuote[0] ? nextProps.file.clientQuote[0].clientPO.items.find(i => i.id === item.clientPOItemId) : null;

                return {
                    ...poItem,
                    rfqItem: item.rfqItem,
                    acceptedVendorQuoteItem: clientQuoteItem.acceptedVendorQuoteItem,
                    invoiceQuantity: item.invoiceQuantity
                }
            }) : [],
            file: nextProps.file
        })
    }


    updateItems = (items, error) => {
        this.setState({
            items,
            error
        })
    }

    updateOtherCosts = (otherCosts, error) => {
        this.setState({
            otherCosts,
            error
        })
    }

    handleCloseEditInvoice = () => {
        this.setState({
            editInvoiceDialogOpen: false
        })
    }
    openEditInvoiceDialog = () => {
        this.setState({
            editInvoiceDialogOpen: true
        })
    }

    handleInvoice = () => {
        const { file, invoice } = this.props;

        this.setState({
            saving: true
        })

        let body = {
            fileId: file.id,
            date: invoice.date,
            comments: invoice.comments,
            packingListComments: invoice.packingListComments,
            paymentReferences: invoice.packingListComments,
            items: invoice.items.map(item => ({
                ...item,
                quantity: item.invoiceQuantity
            })),
            otherCosts: invoice.otherCosts.map(oc => ({
                ...oc,
                clientQuoteOtherCostId: oc.clientQuoteOtherCostId,
                amount: oc.invoiceAmount,
                originalAmount: oc.amount,
                otherCostId: oc.otherCostId
            })),
            statusId: 2,
            invoiceId: invoice.id
        }

        API.Invoices.updateInvoice({ body }).then(res => {
            if (res.record) {
                this.props.filesActions.setFile(res.record.file)
                this.setState({
                    saving: false
                })
            }
        })
    }

    render = () => {
        const {
            items,
            otherCosts,
            editInvoiceDialogOpen,
            file, saving
        } = this.state,
            { classes, invoice } = this.props;

        if (!file) return null

        let itemsTotal = items.reduce(((total, row) => {
            let quotedAmount = row.amount ? row.amount : 0,
                amountWithProfit = quotedAmount + (quotedAmount * row.markupPercent / 100) + (row.weightedCost ? row.weightedCost / row.quantity : 0);

            return parseFloat(total) + (amountWithProfit * row.invoiceQuantity)
        }), 0),
            otherCostTotal = otherCosts.reduce(((total, row) => parseFloat(total) + (row.invoiceAmount ? row.invoiceAmount : 0)), 0),
            taxesTotal = items.reduce(((total, row) => {
                let quotedAmount = (row.amount ? row.amount : 0),
                    amountWithProfit = quotedAmount + ((quotedAmount * row.markupPercent) / 100) + (row.weightedCost ? row.weightedCost / row.quantity : 0);

                return (total + (row.taxPercent && file.clientQuote[0].taxPercent ? ((row.invoiceQuantity * amountWithProfit * file.clientQuote[0].taxPercent) / 100) : 0))
            }), 0);

        let total = itemsTotal + otherCostTotal + taxesTotal;

        return (
            <Paper className={classes.invoice__form__paper}>

                {Permissions.checkSensitiveInformationPermission() && <>
                    {invoice.status === 1 ?
                        <>
                            <div style={{ justifyContent: 'flex-end', display: 'flex', alignItems: 'center' }}>
                                <Drafts className={classes.vendor__status__icon__preparing} />
                                <Typography>{i18n.t('quotes.invoices.statusPreparing')}</Typography>
                                <Button
                                    size="small"
                                    disabled={saving}
                                    className={classes.subaction__bar__actionButton__next}
                                    variant="contained"
                                    onClick={this.handleInvoice}
                                >
                                    {i18n.t('quotes.invoices.updateAsReady')}
                                    {saving && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                </Button>
                            </div>
                            <div className={classes.print__edit__buttons__container}>
                                <Link className={classes.invoice__editLink}
                                    variant='button'
                                    onClick={this.openEditInvoiceDialog}>
                                    {i18n.t('quotes.invoices.edit')}
                                </Link>
                            </div>
                        </>
                        :
                        <div className={classes.print__edit__buttons__container} style={{ top: '24px' }}>
                            <Link className={classes.invoice__editLink}
                                variant='button'
                                onClick={this.openEditInvoiceDialog}>
                                {i18n.t('quotes.invoices.edit')}
                            </Link>
                        </div>
                    }
                </>}


                <InvoiceHeader
                    file={file}
                />

                <div className={classes.invoice__form}>
                    <Grid container spacing={1} justify='flex-start'>
                        <Grid item xs={12} sm={12} >
                            <Typography variant='body2' color='textSecondary'>
                                <strong>{i18n.t('quotes.invoices.invoiceDateLabel')}</strong>
                                {`${invoice.date ? moment(invoice.date).format('MMMM Do, YYYY') : ''}`}
                            </Typography>
                        </Grid>

                        {invoice.comments &&
                            <Grid item xs={12} sm={4} style={{ paddingRight: '8px' }}>
                                <Typography variant='body2' color='textSecondary' style={{ fontSize: '12px', whiteSpace: 'pre-line' }}>
                                    <strong>{i18n.t('quotes.invoices.invoiceComments')}</strong>
                                    {invoice.comments}
                                </Typography>
                            </Grid>
                        }

                        {invoice.packingListComments &&
                            <Grid item xs={12} sm={4} style={{ paddingRight: '8px' }}>
                                <Typography variant='body2' color='textSecondary' style={{ fontSize: '12px', whiteSpace: 'pre-line' }}>
                                    <strong>{i18n.t('quotes.invoices.packingListComments')}</strong>
                                    {invoice.packingListComments}
                                </Typography>
                            </Grid>
                        }

                        {otherCosts.filter(oc => oc.invoiceAmount).length !== 0 && <>
                            <Grid container item spacing={0} justify='flex-start'>
                                <Grid item xs={12} sm={4} />
                                <Grid item xs={12} sm={8} style={{ textAlign: 'right' }}>
                                    <InvoiceOtherCosts
                                        invoices={file.invoices}
                                        otherCosts={otherCosts}
                                        readOnly={true}
                                        updateOtherCosts={this.updateOtherCosts}
                                    />
                                </Grid>
                            </Grid>
                        </>}

                        <Grid item xs={12} sm={12} style={{ textAlign: 'right' }}>
                            <InvoiceItems
                                invoices={file.invoices}
                                file={file}
                                items={items}
                                updateItems={this.updateItems}
                                invoice={invoice ? invoice : null}
                                clientQuoteTaxPercent={file.clientQuote && file.clientQuote[0] ? file.clientQuote[0].taxPercent : 0}
                                vendorPOs={file.vendorPOs ? file.vendorPOs : []}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography variant='h6' style={{ marginRight: '16px' }}><strong>{i18n.t('quotes.invoices.total')}</strong></Typography>

                            <NumberFormat value={total}
                                displayType={'text'}
                                decimalScale={2}
                                className={classes.invoice__form__total}
                                prefix={file.currencySymbol}
                                style={{ fontSize: '18px' }}
                                fixedDecimalScale={true}
                                thousandSeparator={true}
                            />
                        </Grid>
                    </Grid>
                </div>


                {/* Editar Factura en estatus preparing */}
                <InvoiceForm
                    open={editInvoiceDialogOpen}
                    invoice={invoice}
                    handleClose={this.handleCloseEditInvoice}
                    createInvoice={this.createInvoice}
                />

            </Paper>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        filesActions: bindActionCreators(filesActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        file: state.file,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(withStyles(styles, { withTheme: true })(InvoiceCard)));
