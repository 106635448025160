import React, { Component } from 'react'
import {
    Dialog,
    DialogTitle,
    IconButton,
    Divider,
    withStyles,
    Grid,
    Typography,
    Button,
    DialogContent,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Collapse,
    Card,
    Snackbar,
    LinearProgress,
    SnackbarContent
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import {
    Close,
    InsertDriveFile,
    Save,
    Mail,
    LocalPrintshop,
    ExpandLess,
    ExpandMore,
    KeyboardArrowLeft,
    SaveAlt
} from '@material-ui/icons'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as generalActions from '../../actions/generalActions'
import i18n from 'i18next'
import Skeleton from 'react-loading-skeleton'
import { SlideUp } from '../general/Transitions'
import API from '../../lib/api'
import { NoteForm } from '../notes'
import auth from '../../authentication/auth';
import PDFViewer from 'mgr-pdf-viewer-react'
import Permissions from '../../lib/permissions'


const styles = theme => ({
    closeButton: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(),
    },
    popup__content__container: {
        margin: theme.spacing(2),
    },
    loading__container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 20,
        lineHeight: 2,
        overflow: 'auto'
    },
    dialog__paper: {
        width: '100%',
        height: 'auto',
        maxHeight: '800px'
    },
    'dialog__paper--preview': {
        width: '100%',
        height: '100%',
        maxHeight: '800px'
    },
    'dialog__paper--empty': {
        width: '100%',
        height: '200px',
        maxHeight: '800px'
    },
    preview__container: {
        position: 'absolute',
        bottom: 20,
        left: 20,
        right: 20,
        top: 120
    },
    preview__container__pdfviewer: {
        border: `solid thin ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[100]
    },
    preview__placeholder: {
        display: 'flex',
        border: `solid thin ${theme.palette.grey[400]}`,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'absolute',
        bottom: 20,
        left: 20,
        right: 20,
        top: 120
    },
    input: {
        fontSize: '0.9em',
    },
    select: {
        fontFamily: theme.typography.fontFamily,
        width: '100%',
        zIndex: '2',
        margin: `${theme.spacing()}px 0`,
    },
    printButton: {
        ...theme.smallButton,
        width: '120px',
        backgroundColor: theme.palette.grey[100],
        '&:hover': {
            backgroundColor: theme.palette.grey[400],
            color: theme.palette.getContrastText(theme.palette.grey[400]),
        },
        color: theme.palette.getContrastText(theme.palette.grey[100]),
    },
    preview__title__container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

    },
    preview__actions__container: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    notification__success: {
        backgroundColor: green[600]
    }
})

const docTypeIds = {
    quote: 1,
    po: 2,
    proform: 3,
    invoice: 4,
    packingList: 5,
    vendorrfq: 6,
    vendorpo: 7
}


class PrintPopup extends Component {
    state = {
        iframe: null,
        dataUrl: null,
        loading: false,
        reportType: '',
        availableDocs: [],
        availableClientDocs: [],
        availableVendorDocs: [],
        showPrintDialog: false,
        showPreview: false,
        previewTitle: '',
        previewSubTitle: '',
        pdfName: '',
        selectedVendorQuoteId: null,
        vendorQuote: null,
        openNewNote: false,
        noteSection: 0,
        logedInUser: null,
        showPrintAfterExit: false,
        selectedAttachment: null,
        openSnackBar: false,
    }

    componentWillMount = () => {
        auth.getAuthenticatedUser().then(user => {
            this.setState({ logedInUser: user })
        }).catch(err => {
            this.setState({ logedInUser: null })
        })
    }

    fetchVendorPDF = () => {
        let { file } = this.props,
            { vendorQuote, reportType, pdfName } = this.state;
        this.setState({
            loading: true
        }, () => {
            API.Reports.getVendorPDFReport({ fileId: file.id, reportType: reportType, vendorQuoteId: vendorQuote.id })
                .then(reportRes => {
                    const file_header = ';headers=filename%3D';
                    let iframe = `<iframe src="${reportRes.dataUrl.replace(';', `${file_header}${pdfName};`)}" width="100%" height="100%"></iframe>`;


                    this.setState({
                        iframe,
                        loading: false,
                        dataUrl: reportRes.dataUrl.replace(';', `${file_header}${pdfName};`)
                    })
                })
                .catch(err => {
                    this.setState({
                        iframe: null,
                        dataUrl: null,
                        loading: false
                    })
                })
        })
    }

    fetchClientPDF = () => {
        const { file } = this.props,
            { reportType, invoiceId, pdfName } = this.state;
        if (file) {
            this.setState({
                loading: true
            }, () => {
                API.Reports.getClientPDFReport({ fileId: file.id, reportType: reportType, invoiceId })
                    .then(reportRes => {
                        const file_header = ';headers=filename%3D';
                        let iframe = `<iframe src="${reportRes.dataUrl.replace(';', `${file_header}${pdfName};`)}" width="100%" height="100%"></iframe>`;
                        this.setState({
                            iframe, loading: false,
                            dataUrl: reportRes.dataUrl.replace(';', `${file_header}${pdfName};`)
                        })
                    })
                    .catch(err => {
                        this.setState({
                            iframe: null,
                            dataUrl: null,
                            loading: false
                        })
                    })
            })
        }
    }

    componentDidMount = () => {
        this.setAvailableDocs();
    }

    setAvailableDocs = () => {
        const {
            file,
        } = this.props;

        let availableClientDocs = [], availableVendorDocs = [];

        switch (file.status) {
            case 2:
            case 3:
                availableVendorDocs.push({
                    label: i18n.t('printing.vendorDocs.rfq'),
                    value: 'vendorrfq'
                })
                if (file.status === 3 && file.clientQuote.length > 0) {
                    let items = file.clientQuote[0] ? file.clientQuote[0].items : [],
                        creatingClientQuote = items.filter(item => item.amount).length === 0 && file.clientQuote[0] && !file.clientQuote[0].validFor && !file.clientQuote[0].deliveryDays;
                    if (!creatingClientQuote) {
                        availableClientDocs.push({
                            label: i18n.t('printing.clientDocs.quote'),
                            value: 'quote'
                        })
                    }
                }
                break;
            case 4:
                availableVendorDocs.push({
                    label: i18n.t('printing.vendorDocs.rfq'),
                    value: 'vendorrfq'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.quote'),
                    value: 'quote'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.proforma'),
                    value: 'proform'
                })
                break
            case 5:
                availableVendorDocs.push({
                    label: i18n.t('printing.vendorDocs.rfq'),
                    value: 'vendorrfq'
                })
                availableVendorDocs.push({
                    label: i18n.t('printing.vendorDocs.po'),
                    value: 'vendorpo'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.quote'),
                    value: 'quote'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.po'),
                    value: 'po'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.proforma'),
                    value: 'proform'
                })
                break;
            case 6:
                availableVendorDocs.push({
                    label: i18n.t('printing.vendorDocs.rfq'),
                    value: 'vendorrfq'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.quote'),
                    value: 'quote'
                })
                break;
            case 7:
                availableVendorDocs.push({
                    label: i18n.t('printing.vendorDocs.rfq'),
                    value: 'vendorrfq'
                })
                availableVendorDocs.push({
                    label: i18n.t('printing.vendorDocs.po'),
                    value: 'vendorpo'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.quote'),
                    value: 'quote'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.po'),
                    value: 'po'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.proforma'),
                    value: 'proform'
                })
                break;
            default:
                break;
        }

        this.setState({
            availableClientDocs,
            availableVendorDocs: file.vendorQuotes.length > 0 ? availableVendorDocs : [],
        })
    }
    onExit = () => {
        const { showPrintAfterExit } = this.state;
        if (showPrintAfterExit) {
            this.setState({
                openNewNote: true,
                showPrintAfterExit: false
            })
        }
        this.setState({
            iframe: null,
            reportType: '',
            loading: false,
            showPreview: false,
            previewSubTitle: '',
            previewTitle: '',
        })
    }

    closePrintAndOpenNote = () => {
        this.setState({
            showPrintDialog: false,
            showPrintAfterExit: true
        })
    }
    closeNewNote = () => {
        this.setState({
            openNewNote: false,
            vendorQuote: null
        })
    }
    deleteNote = () => {
        this.setState({
            openNewNote: false,
        })
    }
    updateNote = () => {
        this.setState({
            openNewNote: false,
        })
    }
    addNewNote = () => {
        this.setState({
            openSnackBar: true,
            openNewNote: false,
        }, () => {
            this.props.generalActions.reloadNotes && this.props.generalActions.reloadNotes(true)
        })
    }

    handleCloseSnackBar = () => {
        this.setState({
            openSnackBar: false
        })
    }
    closePrintDialog = () => {
        this.setState({
            showPrintDialog: false
        })
    }
    calculateInvoiceTotal = (invoice) => {
        const { file } = this.props;

        let items = invoice.items ? invoice.items.map((item) => {
            let poItem = file && file.clientQuote[0] ? file.clientQuote[0].clientPO.items.find(i => i.id === item.clientPOItemId) : null;

            return {
                ...poItem,
                rfqItem: item.rfqItem,
                invoiceQuantity: item.invoiceQuantity ? item.invoiceQuantity : null
            }
        }) : [];

        let itemsTotal = items.reduce(((total, row) => {
            let quotedAmount = row.amount ? row.amount : 0,
                amountWithProfit = quotedAmount + ((quotedAmount * row.markupPercent) / 100) + (row.weightedCost ? row.weightedCost / row.quantity : 0);

            return parseFloat(total) + (amountWithProfit * row.invoiceQuantity)
        }), 0),
            otherCostTotal = invoice.otherCosts.reduce(((total, row) => parseFloat(total) + (row.invoiceAmount ? row.invoiceAmount : 0)), 0),
            taxesTotal = items.reduce(((total, row) => {
                let quotedAmount = row.amount ? row.amount : 0,
                    amountWithProfit = quotedAmount + ((quotedAmount * row.markupPercent) / 100) + (row.weightedCost ? row.weightedCost / row.quantity : 0);

                return (total + (row.taxPercent && file.clientQuote[0].taxPercent ? (row.invoiceQuantity * amountWithProfit * file.clientQuote[0].taxPercent) / 100 : 0))
            }), 0),
            total = itemsTotal + otherCostTotal + taxesTotal;

        return `${file.currencySymbol}${total.toFixed(2)}`
    }

    onEnter = () => {
        this.setAvailableDocs();
    }
    downloadPdf = () => {
        const linkSource = this.state.dataUrl;
        const downloadLink = document.createElement("a");
        const fileName = this.state.pdfName;
        if (undefined === window.navigator.msSaveOrOpenBlob) {
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
        // IE-specific code
        else {
            function dataURItoBlob(dataURI) {
                // convert base64 to raw binary data held in a string
                // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
                var byteString = atob(dataURI.split(',')[1]);

                // separate out the mime component
                var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

                // write the bytes of the string to an ArrayBuffer
                var ab = new ArrayBuffer(byteString.length);

                // create a view into the buffer
                var ia = new Uint8Array(ab);

                // set the bytes of the buffer to the correct values
                for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }

                // write the ArrayBuffer to a blob, and you're done
                var blob = new Blob([ab], { type: mimeString });
                return blob;

            }
            let blob = dataURItoBlob(linkSource);
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        }
    }

    render() {
        const {
            classes,
            file
        } = this.props,
            { vendorQuote,
                iframe,
                dataUrl,
                loading,
                previewTitle,
                previewSubTitle,
                availableClientDocs,
                availableVendorDocs,
                showPrintDialog,
                showPreview,
                selectedVendorQuoteId,
                openNewNote,
                noteSection,
                logedInUser,
                selectedAttachment,
                openSnackBar,
                selectedInvoiceId
            } = this.state;
        if (!file) return null;

        return (
            <>
                <Button color='secondary'
                    variant='contained'
                    onClick={() => {
                        this.setState({
                            showPrintDialog: true
                        })
                    }}
                    className={classes.printButton}>{i18n.t('print')}</Button>
                <Dialog
                    open={showPrintDialog}
                    fullScreen={false}
                    classes={{
                        paper: availableClientDocs.length === 0 && availableVendorDocs.length === 0 ? classes['dialog__paper--empty'] : showPreview ? classes['dialog__paper--preview'] : classes.dialog__paper
                    }}
                    onExit={this.onExit}
                    onEnter={this.onEnter}
                    fullWidth={true}
                    TransitionComponent={SlideUp}
                >
                    <DialogTitle disableTypography={true}>
                        {i18n.t('printing.file')} {file.id} {file.client.name} - {i18n.t('printing.documents')}
                        <IconButton color="inherit"
                            onClick={this.closePrintDialog}
                            aria-label="Close"
                            className={classes.closeButton}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div className={classes.popup__content__container}>
                            {availableClientDocs.length === 0 && availableVendorDocs.length === 0 && (
                                <Grid container justify='center' alignItems='center'>
                                    <Grid item xs={10} style={{ height: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                                        <Typography>{i18n.t('printing.noDocuments')}</Typography>
                                        <Button onClick={this.closePrintDialog}
                                            variant='outlined'
                                            color='secondary'>{i18n.t('printing.ok')}</Button>
                                    </Grid>
                                </Grid>
                            )}
                            {!(availableClientDocs.length === 0 && availableVendorDocs.length === 0) && !showPreview && (
                                <div>
                                    <Grid container spacing={2}>

                                        <Grid item xs={12}>
                                            <Card style={{ padding: '8px' }}>
                                                <Grid container spacing={2} justify='center'>
                                                    <Grid item xs={12}>
                                                        <Typography variant='subtitle1' color='textSecondary'>{i18n.t('printing.clientDocsList')}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                                {availableClientDocs.length > 0 && (
                                                    <List >
                                                        {availableClientDocs.map((doc, i) => (
                                                            <ListItem
                                                                key={i}
                                                                divider
                                                            >
                                                                <ListItemText primary={doc.label} />
                                                                <ListItemSecondaryAction>
                                                                    <IconButton
                                                                        edge="end"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                showPreview: true,
                                                                                previewSubTitle: `${doc.label}`,
                                                                                previewTitle: `${file.client.name}`,
                                                                                reportType: doc.value,
                                                                                pdfName: `${doc.label}${file.id}.pdf`,
                                                                                iframe: null,
                                                                                invoiceId: null
                                                                            }, () => {
                                                                                this.fetchClientPDF();
                                                                            })

                                                                        }}>
                                                                        <LocalPrintshop />
                                                                    </IconButton>
                                                                    <IconButton edge="end"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                noteSection: 2,
                                                                                selectedAttachment: {
                                                                                    label: doc.label,
                                                                                    value: `client:${docTypeIds[doc.value]}`
                                                                                }
                                                                            }, () => {
                                                                                this.closePrintAndOpenNote();
                                                                            })
                                                                        }}>
                                                                        <Mail />
                                                                    </IconButton>
                                                                    <IconButton edge="end"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                noteSection: 0,
                                                                                selectedAttachment: {
                                                                                    label: doc.label,
                                                                                    value: `client:${docTypeIds[doc.value]}`
                                                                                }
                                                                            }, () => {
                                                                                this.closePrintAndOpenNote();
                                                                            })
                                                                        }}>
                                                                        <Save />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>

                                                            </ListItem>

                                                        ))}

                                                        {file.invoices.length > 0 &&
                                                            file.invoices.map((invoice, i) => {
                                                                return (
                                                                    <div key={`invoice_${i}`} >
                                                                        <ListItem
                                                                            divider
                                                                            button
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    selectedInvoiceId: selectedInvoiceId === invoice.id ? null : invoice.id,
                                                                                })
                                                                            }}
                                                                        >
                                                                            <ListItemText primary={`${i18n.t('printing.clientDocs.invoice')} ${invoice.id} - ${this.calculateInvoiceTotal(invoice)}`} />
                                                                            {selectedInvoiceId === invoice.id ? <ExpandLess /> : <ExpandMore />}
                                                                        </ListItem>
                                                                        <Collapse
                                                                            in={selectedInvoiceId === invoice.id}
                                                                            timeout="auto" unmountOnExit>
                                                                            <List component="div" disablePadding>
                                                                                <ListItem divider>
                                                                                    <ListItemText primary={`${i18n.t('printing.clientDocs.invoice')}`} />
                                                                                    <ListItemSecondaryAction>
                                                                                        <IconButton
                                                                                            edge="end"
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    showPreview: true,
                                                                                                    previewSubTitle: `${i18n.t('printing.clientDocs.invoice')} - ${invoice.id}`,
                                                                                                    previewTitle: `${file.client.name}`,
                                                                                                    pdfName: ` ${i18n.t('printing.clientDocs.invoice')}${invoice.id}.pdf`,
                                                                                                    reportType: 'invoice',
                                                                                                    iframe: null,
                                                                                                    dataUrl: null,
                                                                                                    invoiceId: invoice.id
                                                                                                }, () => {
                                                                                                    this.fetchClientPDF();
                                                                                                })

                                                                                            }}>
                                                                                            <LocalPrintshop />
                                                                                        </IconButton>
                                                                                        <IconButton edge="end"
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    noteSection: 2,
                                                                                                    selectedAttachment: {
                                                                                                        label: `${i18n.t('printing.clientDocs.invoice')} - ${invoice.id}`,
                                                                                                        value: `client:${invoice.id}:${docTypeIds['invoice']}`
                                                                                                    }
                                                                                                }, () => {
                                                                                                    this.closePrintAndOpenNote();
                                                                                                })
                                                                                            }}>
                                                                                            <Mail />
                                                                                        </IconButton>
                                                                                        <IconButton edge="end"
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    noteSection: 0,
                                                                                                    selectedAttachment: {
                                                                                                        label: `${i18n.t('printing.clientDocs.invoice')} - ${invoice.id}`,
                                                                                                        value: `client:${invoice.id}:${docTypeIds['invoice']}`
                                                                                                    }
                                                                                                }, () => {

                                                                                                    this.closePrintAndOpenNote();
                                                                                                })
                                                                                            }}>
                                                                                            <Save />
                                                                                        </IconButton>
                                                                                    </ListItemSecondaryAction>
                                                                                </ListItem>
                                                                                <ListItem key={i} divider>
                                                                                    <ListItemText primary={`${i18n.t('printing.clientDocs.packingList')}`} />
                                                                                    <ListItemSecondaryAction>
                                                                                        <IconButton
                                                                                            edge="end"
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    showPreview: true,
                                                                                                    previewSubTitle: `${i18n.t('printing.clientDocs.packingList')} - ${invoice.id}`,
                                                                                                    previewTitle: `${file.client.name}`,
                                                                                                    pdfName: `${i18n.t('printing.clientDocs.packingList')}${invoice.id}.pdf`,
                                                                                                    reportType: 'packingList',
                                                                                                    iframe: null,
                                                                                                    dataUrl: null,
                                                                                                    invoiceId: invoice.id
                                                                                                }, () => {
                                                                                                    this.fetchClientPDF();
                                                                                                })

                                                                                            }}>
                                                                                            <LocalPrintshop />
                                                                                        </IconButton>
                                                                                        <IconButton edge="end"
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    noteSection: 2,
                                                                                                    selectedAttachment: {
                                                                                                        label: `${i18n.t('printing.clientDocs.packingList')} - ${invoice.id}`,
                                                                                                        value: `client:${invoice.id}:${docTypeIds['packingList']}`
                                                                                                    }
                                                                                                }, () => {

                                                                                                    this.closePrintAndOpenNote();
                                                                                                })
                                                                                            }}>
                                                                                            <Mail />
                                                                                        </IconButton>
                                                                                        <IconButton edge="end"
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    noteSection: 0,
                                                                                                    selectedAttachment: {
                                                                                                        label: `${i18n.t('printing.clientDocs.packingList')} - ${invoice.id}`,
                                                                                                        value: `client:${invoice.id}:${docTypeIds['packingList']}`
                                                                                                    }
                                                                                                }, () => {

                                                                                                    this.closePrintAndOpenNote();
                                                                                                })
                                                                                            }}>
                                                                                            <Save />
                                                                                        </IconButton>
                                                                                    </ListItemSecondaryAction>
                                                                                </ListItem>
                                                                            </List>
                                                                        </Collapse>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </List>
                                                )}

                                                {availableClientDocs.length === 0 && (
                                                    <Typography variant='body2' color='textSecondary'>{i18n.t('printing.noClientDocuments')}</Typography>
                                                )}
                                            </Card>

                                        </Grid>

                                        {Permissions.checkSensitiveInformationPermission() &&
                                            <Grid item xs={12}>
                                                <Card style={{ padding: '8px', }}>
                                                    <>
                                                        <Grid container spacing={2} justify='center'>
                                                            <Grid item xs={12}>
                                                                <Typography variant='subtitle1' color='textSecondary'>{i18n.t('printing.vendorDocsList')}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Divider />
                                                        {availableVendorDocs.length > 0 && file.vendorQuotes.length > 0 && (
                                                            <List>
                                                                {file.vendorQuotes.map((vq, i) => {
                                                                    let vendorPO = file.vendorPOs.find(vp => vp.vendorQuoteId === vq.id),
                                                                        availableVendorDocsNew = availableVendorDocs

                                                                    if (vendorPO && (!vendorPO.poNumber || vendorPO.isVoid === 1)) {
                                                                        availableVendorDocsNew = availableVendorDocs.filter(ad => ad.value !== 'vendorpo')
                                                                    }

                                                                    return (
                                                                        <div key={i}>
                                                                            <ListItem
                                                                                divider
                                                                                button
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        selectedVendorQuoteId: this.state.selectedVendorQuoteId === vq.id ? null : vq.id,
                                                                                        selectedDocument: null
                                                                                    })
                                                                                }}>
                                                                                <ListItemText primary={`${vq.id} - ${vq.vendor.name}`} />
                                                                                {selectedVendorQuoteId === vq.id ? <ExpandLess /> : <ExpandMore />}
                                                                            </ListItem>
                                                                            <Collapse
                                                                                in={selectedVendorQuoteId === vq.id}
                                                                                timeout="auto" unmountOnExit>
                                                                                <List component="div" disablePadding>
                                                                                    {availableVendorDocsNew.map((doc, i) => (
                                                                                        <ListItem key={i}
                                                                                            divider
                                                                                            className={classes.nested}
                                                                                        >
                                                                                            <ListItemText primary={doc.label} />

                                                                                            <ListItemSecondaryAction>
                                                                                                <IconButton edge="end"
                                                                                                    onClick={() => {
                                                                                                        this.setState({
                                                                                                            showPreview: true,
                                                                                                            previewSubTitle: `${doc.label}`,
                                                                                                            previewTitle: `${vq.vendor.name}`,
                                                                                                            pdfName: `${doc.label}${vq.id}.pdf`,
                                                                                                            vendorQuote: vq,
                                                                                                            reportType: doc.value,
                                                                                                            iframe: null,
                                                                                                            dataUrl: null,
                                                                                                        }, () => {
                                                                                                            this.fetchVendorPDF();
                                                                                                        })

                                                                                                    }}>
                                                                                                    <LocalPrintshop />
                                                                                                </IconButton>
                                                                                                <IconButton edge="end"
                                                                                                    onClick={() => {
                                                                                                        this.setState({
                                                                                                            noteSection: 2,
                                                                                                            vendorQuote: vq,
                                                                                                            selectedAttachment: {
                                                                                                                label: `${doc.label} - ${vq.id}`,
                                                                                                                value: `vendor:${vq.id}:${docTypeIds[doc.value]}`
                                                                                                            }
                                                                                                        }, () => {

                                                                                                            this.closePrintAndOpenNote();
                                                                                                        })
                                                                                                    }}>
                                                                                                    <Mail />
                                                                                                </IconButton>
                                                                                                <IconButton edge="end"
                                                                                                    onClick={() => {
                                                                                                        this.setState({
                                                                                                            noteSection: 0,
                                                                                                            selectedAttachment: {
                                                                                                                label: `${doc.label} - ${vq.id}`,
                                                                                                                value: `vendor:${vq.id}:${docTypeIds[doc.value]}`
                                                                                                            }
                                                                                                        }, () => {
                                                                                                            this.closePrintAndOpenNote();
                                                                                                        })
                                                                                                    }}>
                                                                                                    <Save />
                                                                                                </IconButton>
                                                                                            </ListItemSecondaryAction>

                                                                                        </ListItem>
                                                                                    ))}
                                                                                </List>
                                                                            </Collapse>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </List>
                                                        )}
                                                    </>
                                                    {!(availableVendorDocs.length > 0 && file.vendorQuotes.length > 0) && (
                                                        <Typography variant='body2' color='textSecondary'>{i18n.t('printing.noVendorsDocuments')}</Typography>
                                                    )}
                                                </Card>
                                            </Grid>
                                        }
                                    </Grid>
                                </div>
                            )}
                        </div>
                        <div>
                            {showPreview && (
                                <>
                                    <div>
                                        <Grid container spacing={2} alignItems='center'>
                                            <Grid item xs={3}>
                                                <IconButton
                                                    disabled={loading}
                                                    onClick={() => {
                                                        this.setState({
                                                            showPreview: false,
                                                            previewTitle: '',
                                                            previewSubTitle: '',
                                                            iframe: null,
                                                            dataUrl: null,
                                                        })
                                                    }
                                                    }>
                                                    <KeyboardArrowLeft />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={6} className={classes.preview__title__container} >
                                                <div style={{
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    width: '95%',
                                                    cursor: 'pointer'
                                                }}><Typography variant='body1' color='textPrimary' noWrap>{previewTitle}</Typography></div>
                                                <Typography variant='body2' color='textSecondary' noWrap>{previewSubTitle}</Typography>
                                            </Grid>
                                            <Grid item xs={3} className={classes.preview__actions__container}>
                                                <IconButton edge="end" disabled={!dataUrl || loading}
                                                    onClick={this.downloadPdf}>
                                                    <SaveAlt />
                                                </IconButton>
                                                <IconButton edge="end" disabled={loading}
                                                    onClick={() => {
                                                        this.setState({
                                                            noteSection: 2
                                                        }, () => {
                                                            this.closePrintAndOpenNote();
                                                        })
                                                    }}>
                                                    <Mail />
                                                </IconButton>
                                                <IconButton edge="end" disabled={loading}
                                                    onClick={() => {
                                                        this.setState({
                                                            noteSection: 0
                                                        }, () => {

                                                            this.closePrintAndOpenNote();
                                                        })
                                                    }}>
                                                    <Save />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {!iframe && loading && (
                                            <LinearProgress color="secondary" />
                                        )}
                                        {iframe && (
                                            <>
                                                <div className={classes.preview__container}>
                                                    <PDFViewer document={{
                                                        base64: this.state.dataUrl.split(',')[1]
                                                    }}
                                                        css={classes.preview__container__pdfviewer}
                                                        scale={0.8} />
                                                </div>
                                            </>
                                        )}
                                        {!iframe && (
                                            <div className={classes.preview__placeholder} >
                                                {loading ?
                                                    (
                                                        <div className={classes.loading__container}>
                                                            <div style={{ fontSize: 20, lineHeight: 2, width: '90%' }}>
                                                                <h1><Skeleton /></h1>
                                                                <Skeleton count={12} />
                                                            </div>
                                                        </div>
                                                    ) :
                                                    (
                                                        <>
                                                            <InsertDriveFile color='secondary' fontSize="large" />
                                                            <Typography color='textSecondary'>
                                                                {i18n.t('printing.selectDocumentToPrint')}
                                                            </Typography>
                                                        </>
                                                    )}

                                            </div>
                                        )}
                                    </div>
                                </>
                            )}

                        </div>

                    </DialogContent>
                </Dialog>
                <NoteForm
                    open={openNewNote}
                    file={file}
                    note={null}
                    isPrinting={true}
                    vendorQuote={vendorQuote}
                    selectedAttachment={selectedAttachment}
                    user={logedInUser}
                    section={noteSection}
                    deleteNote={this.deleteNote}
                    updateNote={this.updateNote}
                    addNewNote={this.addNewNote}
                    handleClose={this.closeNewNote}
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={openSnackBar}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackBar}
                    ContentProps={{
                        'aria-describedby': 'message',
                    }}
                >
                    <SnackbarContent className={classes.notification__success}
                        message={<span id="message">{i18n.t('printing.noteSaved')}</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.handleCloseSnackBar}
                            >
                                <Close />
                            </IconButton>,
                        ]} />
                </Snackbar>
            </>
        )
    }
}

PrintPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
}

function mapStateToProps(state) {
    return {
        file: state.file,
        apiStatus: state.apiStatus
    }
}
function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch)
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrintPopup))