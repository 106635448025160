import React from 'react';
import moment from 'moment';
import {
    Grid,
    Paper,
    Typography,
    withStyles,
    IconButton,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
} from '@material-ui/core/';
import {
    Edit,
    CheckBoxOutlineBlank,
    CheckBox as CheckBoxIcon,
    Lock,
    ExpandLess,
    ExpandMore,
    OpenInNew,
    InsertDriveFile
} from '@material-ui/icons'
// import API from '../../lib/api'
import i18n from 'i18next';
import Permissions from '../../lib/permissions'


const styles = theme => ({
    notes__list__paper: {
        margin: `${theme.spacing()}px 2px`,
        padding: theme.spacing(),
        position: 'relative'
    },
    notes__list__edit__btn: {
        marginLeft: theme.spacing(2),
        padding: '8px'
    },
    notes__list__edit__btn__icon: {
        fontSize: '21px'
    },
    notes__list__task__due__date: {
        display: 'flex',
        padding: '5px 0 5px 10px'
    },
    notes__list__task__due__date__label: {
        textDecoration: 'underline',
        fontWeight: 'bold'
    },
    "notes__list__task__due__date--complete": {
        background: '#519839'
    },
    "notes__list__task__due__date--due": {
        background: '#cf513d'
    },
    "notes__list__task__due__date--due_soon": {
        background: '#e6c60d'
    },
    "notes__list__task__due__date--default": {
        background: 'rgba(9,45,66,.08)'
    },
    notes__list__highlight: {
        background: theme.palette.beige[30]
    },
    note__list__vendorQuote__button: {
        marginRight: theme.spacing()
    },
    notes__divider: {
        margin: `${theme.spacing()}px 0`
    },
    note__header__container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    email__send__indicator: {
        padding: theme.spacing() / 2,
        backgroundColor: theme.palette.yellow[50],
        color: theme.palette.getContrastText(theme.palette.yellow[50]),
        borderRadius: theme.spacing() / 2,
        width: 'fit-content',
        maxWidth: '120px'
    },
    email__error__indicator: {
        padding: theme.spacing() / 2,
        backgroundColor: theme.palette.red[50],
        color: theme.palette.getContrastText(theme.palette.red[50]),
        borderRadius: theme.spacing() / 2,
        width: 'fit-content'
    },
    attachment__container: { display: 'flex', alignItems: 'center', justifyContent: 'center' }
});


class Note extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedNote: null,
            note: props.note,
            highlight: props.highlight
        }
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            note: nextProps.note,
            highlight: nextProps.highlight
        })

        if (nextProps.highlight) {
            setTimeout(() => {
                this.setState({
                    highlight: false
                });
            }, 5000);
        }
    }


    selectNote = (index) => {
        const { selectedNote } = this.state;
        this.setState({
            selectedNote: index === selectedNote ? null : index
        })
    }

    render() {
        const { selectedNote, highlight } = this.state;
        const { classes, user, note, index, hideVendorQuoteLink } = this.props;

        let diffTaskDueDate = note.taskDateTime ?
            moment().format('MM-DD-YY') > moment(note.taskDateTime).format('MM-DD-YY') ? -1 :
                moment().format('MM-DD-YY') < moment(note.taskDateTime).format('MM-DD-YY') ? 1 :
                    moment().format('MM-DD-YY') === moment(note.taskDateTime).format('MM-DD-YY') ? 0 : null
            : null

        return (
            <Paper className={`${classes.notes__list__paper} ${highlight ? classes.notes__list__highlight : ''}`}>
                <Grid container wrap="nowrap" spacing={2} justify='flex-start'>
                    <Grid item xs={12}>
                        {note && note.noteStatus.id === 1 && (
                            <div className={classes.email__send__indicator}>
                                <Typography color='textSecondary' variant='body2'>{note.sendByEmail ? i18n.t('notes.sending') : i18n.t('notes.saving')}</Typography>
                            </div>
                        )}
                        {note && note.noteStatus.id === 3 && (
                            <div className={classes.email__error__indicator}>
                                <Typography color='textSecondary' variant='body2'>{note.sendByEmail ? i18n.t('notes.errorSending') : i18n.t('notes.errorSaving')}</Typography>
                            </div>
                        )}
                        <Grid container justify="space-between" alignItems='center' >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {note && note.private !== 0 && !note.isTask && !note.sendByEmail &&
                                    <Lock style={{ fontSize: '16px', marginRight: '8px' }} />
                                }
                                {note && note.isTask === 0 ?
                                    <Typography>{moment(note.createDateTime).format('MMMM Do h:mm a')}</Typography>
                                    :
                                    <div>
                                        {note && note.taskFollowUpUser.id &&
                                            <Typography>{note.taskFollowUpUser.name}</Typography>
                                        }
                                    </div>
                                }
                            </div>

                            <div className={classes.note__header__container}>
                                {note && note.isTask !== 0 &&
                                    <div>
                                        <div className={`${classes.notes__list__task__due__date} ${note && note.taskComplete === 1 ? classes["notes__list__task__due__date--complete"] :
                                            diffTaskDueDate === 0 ? classes["notes__list__task__due__date--due_soon"] :
                                                diffTaskDueDate < 0 ? classes["notes__list__task__due__date--due"] : classes["notes__list__task__due__date--default"]}`}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    style={{ marginLeft: '0' }}
                                                    control={
                                                        <Checkbox style={{ padding: '0', marginRight: '8px' }}
                                                            icon={<CheckBoxOutlineBlank style={{
                                                                fontSize: '15px',
                                                                color: note && note.taskComplete !== 0 ? '#fff' :
                                                                    diffTaskDueDate === 0 ? '#fff' :
                                                                        diffTaskDueDate < 0 ? '#fff' : 'default'
                                                            }} />}

                                                            checkedIcon={<CheckBoxIcon style={{
                                                                fontSize: '15px',
                                                                color: note && note.taskComplete !== 0 ? '#fff' :
                                                                    diffTaskDueDate === 0 ? '#fff' :
                                                                        diffTaskDueDate < 0 ? '#fff' : 'default'
                                                            }} />}

                                                            checked={note && note.taskComplete ? true : false}
                                                            onChange={(e) => this.props.completeTask(e, index)}
                                                            color="default"
                                                        />
                                                    }
                                                    label={
                                                        <Typography className={classes.notes__list__task__due__date__label}
                                                            style={{
                                                                color: note && note.taskComplete !== 0 ? '#fff' :
                                                                    diffTaskDueDate === 0 ? '#fff' :
                                                                        diffTaskDueDate < 0 ? '#fff' : 'inherit'
                                                            }}>

                                                            {`${moment(note && note.taskDateTime).format('MMMM Do')} 
                                                                ${note && note.taskComplete !== 0 ? 'Complete' :
                                                                    diffTaskDueDate === 0 ? 'Due Soon' :
                                                                        diffTaskDueDate < 0 ? 'Past Due' : ''}`}
                                                        </Typography>
                                                    }
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                }

                                {(((user && note && parseInt(user.attributes['custom:userID']) === note.author.id) || (note && note.sendByEmail))) ?
                                    <IconButton
                                        className={classes.notes__list__edit__btn}
                                        aria-label="Search"
                                        disabled={note.noteStatus.id === 1 ? true : false}
                                        onClick={(e) => this.props.openNewNote(e, note)}>
                                        {note && !note.sendByEmail ?
                                            (<Edit className={classes.notes__list__edit__btn__icon} />)
                                            :
                                            (<OpenInNew className={classes.notes__list__edit__btn__icon} />)
                                        }

                                    </IconButton>
                                    :
                                    <></>
                                }
                            </div>
                        </Grid>
                        {note && note.vendorQuoteId && !hideVendorQuoteLink && Permissions.checkSensitiveInformationPermission() &&
                            <Typography color='textPrimary'
                                className={classes.note__list__vendorQuote__button}>
                                {`${i18n.t("notes.form.vendorQuote")} ${note.vendorQuoteId}`}
                            </Typography>
                        }
                        {(note && note.private === 0 && (user && parseInt(user.attributes['custom:userID']) !== note.author.id)) &&
                            <Typography>{i18n.t('notes.from')} {note.author.name}</Typography>
                        }
                        {note && note.sendByEmail !== 0 &&
                            <>
                                <Typography><i>{i18n.t('notes.to')}</i> {note.emailRecipients} </Typography>
                                {note.emailRecipientsCC && (
                                    <Typography><i>{i18n.t('notes.cc')}</i> {note.emailRecipientsCC} </Typography>
                                )}
                                {note.emailRecipientsBCC && (
                                    <Typography><i>{i18n.t('notes.bcc')}</i> {note.emailRecipientsBCC} </Typography>
                                )}
                            </>
                        }
                        <Grid item xs={6}>
                            <Divider className={classes.notes__divider} />
                        </Grid>

                        <Typography style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>{note.title}</strong>
                            {note && note.noteStatus.id !== 1 && (
                                <span onClick={() => this.selectNote(index)}>
                                    {index === selectedNote ? <ExpandLess /> : <ExpandMore />}
                                </span>
                            )}
                        </Typography>

                        {index === selectedNote ?
                            (<>
                                <Typography style={{ whiteSpace: 'pre-line' }}>{note.content ? decodeURI(note.content) : ''}</Typography>
                                {note && (note.documents.length > 0 || note.attachments.length > 0) && (
                                    <Grid container spacing={2} style={{ marginTop: 16 }}>
                                        {note.documents.map(doc => {
                                            let separate = doc.name.split('.'),
                                                date = moment(doc.timestamp).format('MMM Do, hh:mm a'),
                                                label = separate[0].split('-').length > 1 ? `${separate[0].split('-')[0]}-${separate[0].split('-')[1]}.${doc.name.split('.')[1]}` : doc.name;

                                            return (
                                                <Grid key={doc.id} item xs={6} sm={4}>
                                                    <a key={doc.id} href={doc.url} download={label} target='_blank' rel="noopener noreferrer">
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                            <InsertDriveFile color='secondary' className={classes.notes__list__edit__btn__icon} />
                                                            <Typography key={doc.id} variant='body2' noWrap> {label}</Typography>
                                                        </div>
                                                    </a>
                                                    <Typography key={doc.id} variant='body2' noWrap>{date}</Typography>

                                                </Grid>
                                            )
                                        })}

                                        {note.attachments.map(doc => {
                                            return (
                                                <Grid key={doc.id} item xs={6} sm={4}>
                                                    <a key={doc.id} href={doc.url} download={doc.url} target='_blank' rel="noopener noreferrer">
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                            <InsertDriveFile color='secondary' className={classes.notes__list__edit__btn__icon} />
                                                            <Typography key={doc.id} variant='body2' noWrap> {doc.name}</Typography>
                                                        </div>
                                                    </a>
                                                    <Typography key={doc.id} variant='body2' noWrap>{moment(doc.timestamp).format('MMM Do, hh:mm a')}</Typography>

                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                )}
                            </>)
                            :
                            (<>
                                {note && (note.documents.length > 0 || note.attachments.length > 0) ?
                                    <Typography variant='body2' color='textSecondary'>{parseInt(note.documents.length) + parseInt(note.attachments.length)} {parseInt(note.documents.length) + parseInt(note.attachments.length) === 1 ? i18n.t('notes.attachment') : i18n.t('notes.attachments')}</Typography>
                                    :
                                    null
                                }
                            </>
                            )
                        }
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(Note);