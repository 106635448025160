import React, { Component } from 'react'
import moment from "moment";
import ReactTable from "react-table";
import {
    TextField,
    Typography,
    Hidden,
    List,
    ListItem,
    ListItemText,
    Paper,
    Divider,
    Button,
    Link,
    Select,
    InputAdornment
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import { Send, Drafts, DoneOutline, Warning, Inbox, OpenInNew, Search } from '@material-ui/icons'
import i18n from 'i18next';
import classNames from 'classnames'
import { VendorQuotesList, VendorQuotePopup } from './vendorQR';
import { TasksShortcut } from './common'
import Permissions from '../../lib/permissions'

import API from '../../lib/api'

//Redux
import * as vendorQuotesActions from '../../actions/vendorQuotesActions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../actions/generalActions'
import * as filesActions from '../../actions/filesActions'
import * as vendorsActions from '../../actions/vendorsActions'




const styles = theme => {
    return ({
        flexCell: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        table: {
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily,
            marginTop: theme.spacing(3)
        },
        list__fileId: {
            fontWeight: 'bold',
        },
        list__fileDate: {

        },
        list__fileName: {

        },
        list__fileReference: {

        },
        list__fileClient: {
            fontWeight: 'bold',
        },
        list__fileAuthor: {
            color: theme.palette.naval.main,
        },
        list__vendorsTitle: {
            fontWeight: 'bold',
        },
        list__vendors__cell: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
        list__vendors__cell__row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },

        vendor__quotes__list: {
        },

        vendor__status__icon__preparing: {
            marginRight: theme.spacing(),
            fontSize: '12pt',
            color: theme.palette.red[100],
        },
        vendor__status__icon__requested: {
            marginRight: theme.spacing(),
            fontSize: '12pt',
            color: theme.palette.primary.main,
        },
        vendor__status__icon__ready: {
            marginRight: theme.spacing(),
            fontSize: '12pt',
            color: theme.palette.secondary.main,
        },
        vendor__notification__icon: {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.red[100],
        },
        vendor__warning__icon: {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.orange[100],
        },
        vendor__rfq__noRows: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh'
        },
        vendor__quotes__header__section: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: theme.spacing(3),
        },
        vendor__quotes__header__section__button: {
            borderRadius: '0px'
        },
        'vendor__quotes__header__section__button--selected': {
            backgroundColor: '#efe9c3',
            border: `1px solid ${theme.palette.primary.light}`,
            borderBottom: 'none',
            color: theme.palette.text.primary,
            borderRadius: '0px'
        },
        divider: {
            marginBottom: theme.spacing(2),
            backgroundColor: theme.palette.primary.light
        },
        rfq__table__fileLink: {
            color: theme.palette.blue[100],
            cursor: 'pointer'
        },
        rfq__table__fileLink__disabled: {
            color: theme.palette.text.primary,
            cursor: 'default'
        },
        rfq__notification__icon: {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.red[100],
        },
        'rfq__notification__icon--yellow': {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.orange[100],
        },
        'rfq__notification__icon--red': {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.red[100],
        },
        list__file__lastUpdate__container: {
            position: 'absolute', bottom: 0, right: 0
        },
        list__file__lastUpdate: {
            backgroundColor: theme.palette.grey[100],
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.grey[400]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--late': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.orange[60]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.orange[30],
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--veryLate': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.red[100]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.red[50],
            color: theme.palette.getContrastText(theme.palette.red[50]),
        },
        rfq__table__fileDescriptions: {
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        rfq__table__fileDatesContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        alert__tasks__button__icon: {
            position: 'absolute',
            right: '0', top: '0',
            cursor: 'pointer',
            '&:hover': {
                filter: 'brightness(1.1);'
            }
        },
        alert__tasks__button__icon__mobile: {
            position: 'absolute',
            right: '-16px', top: '-8px',
            cursor: 'pointer',
            '&:hover': {
                filter: 'brightness(1.1);'
            }
        },
        customLink: theme.customLink,
        list__fileDetails: { position: 'relative' },
        list__countryContainer: {
            position: 'absolute',

            [theme.breakpoints.up('sm')]: {
                right: 0,
                top: 0,
            },
            [theme.breakpoints.down('sm')]: {
                right: theme.spacing(),
                bottom: 0
            }

        },
        list__fileCountry: { fontSize: '9pt' },
        list__vendorQuotes__status: {
            fontSize: '10pt'
        },
        oportunity__probability: {
            border: `solid thin ${theme.palette.secondary.main}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '8pt',
            color: theme.palette.text.secondary,
            padding: theme.spacing() / 2,
            textAlign: 'center',
            width: '80px'
        },
        'oportunity__probability--unknow': {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.getContrastText(theme.palette.grey[100])
        },
        'oportunity__probability--likely': {
            backgroundColor: theme.palette.seabook.main,
            color: theme.palette.getContrastText(theme.palette.seabook.main)
        },
        'oportunity__probability--verylikely': {
            backgroundColor: theme.palette.green[100],
            color: theme.palette.getContrastText(theme.palette.green[100])
        },
        country__select: {
            width: '100%'
        },
        "list__vendorQuotes__status--readyToQuote": {
            color: theme.palette.green[100],
        },
        list__vendors__cell__row__pointer: {
            cursor: 'pointer'
        },
        empty__state__container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }
    })
};

const probabilities = ['unknow', 'likely', 'verylikely'];

class requestedToVendor extends Component {

    state = {
        rows: this.props.rows,
        groupBySection: 'client',
        file: null,
        highlightRow: null,
        pageSize: this.props.rows.length > 50 ? 50 : this.props.rows.length,
        showVendorQuote: false
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            rows: nextProps.rows,
            pageSize: this.props.rows.length > 50 ? 50 : this.props.rows.length
        })
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    }

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    }

    quoteDateFormat = (dt) => {
        return moment(dt).format('MMM Do, YYYY')
    }

    groupBy = (value) => {
        this.setState({
            groupBySection: value
        })
    }

    openEditVendorQuote = (vendorQuote, fileId) => {
        if (fileId) {
            API.Files.getFile({
                fileId: fileId
            }).then(res => {
                if (res.file) {
                    this.props.filesActions.setFile(res.file);
                    this.setState({
                        showVendorQuote: true,
                        file: res.file,
                        vendorQuote: res.file.vendorQuotes.find(vq => vq.id === vendorQuote.vendorQuoteId),
                    })
                }
            })
        }
    }

    handleClose = () => {
        this.setState({
            showVendorQuote: false,
        })
    }
    onExited = () => {
        this.props.filesActions.setFile({});
        this.setState({
            file: null,
            vendorQuote: null
        })
    }

    deleteVendorQuote = (vendorQuote) => {
        this.setState({
            highlightRow: vendorQuote.fileId
        })

        setTimeout(() => {
            this.setState({ highlightRow: null });
        }, 7000);
    }

    updateVendorQuote = (vendorQuote, editVendorRFQ) => {
        this.props.vendorQuotesActions.updateVendorQuote(vendorQuote)
        this.setState({
            highlightRow: vendorQuote.fileId,
            vendorQuote: vendorQuote
        });

        setTimeout(() => {
            this.setState({ highlightRow: null });
        }, 7000);
    }

    handleCloseEditVendorQuote = () => {
        this.props.generalActions && this.props.generalActions.showEditVendorQuote(false);
        this.setState({
            showEditVendorQuote: false
        })
    }

    calculateStatus = (row) => {
        // let refqItemsIds = row.items ? row.items.map((item, i) => {
        //     return item.rfqItemId ? item.rfqItemId : item.id
        // }) : [],
        //     vendorsRFQ = row.vendors ? row.vendors.filter(v => v.statusId === 3) : [],
        //     itemsIncluded = [],
        //     newItemsIncluded = [];

        // vendorsRFQ.forEach(vRfq => {
        //     itemsIncluded = vRfq.items.map((item, i) => {
        //         return item.rfqItemId
        //     })
        //     newItemsIncluded = newItemsIncluded.concat(itemsIncluded)
        // })

        // newItemsIncluded = Array.from(new Set(newItemsIncluded))

        // if (refqItemsIds.length === newItemsIncluded.length) {

        if (row.vendors && row.vendors.filter(v => v.statusId === 3).length > 0) {
            return i18n.t("quotes.requestedToVendor.statusReady")
        } else {
            return i18n.t("quotes.requestedToVendor.statusInProgress")
        }
    }

    render = () => {
        const { classes, setSearchParmeters, vendorQuotes, vendors } = this.props,
            { rows,
                groupBySection,
                pageSize,
                showVendorQuote,
                file,
                vendorQuote,
                highlightRow
            } = this.state;

        let countries = rows.map(r => r.client.country.code).filter((x, i, a) => a.indexOf(x) === i);

        return (
            <div>
                {rows && rows.length > 0 && Permissions.checkSensitiveInformationPermission() && (
                    <div>
                        <div className={classes.vendor__quotes__header__section}>
                            <Button
                                onClick={() => this.groupBy('client')}
                                className={groupBySection === 'client' ? classes['vendor__quotes__header__section__button--selected'] : classes.vendor__quotes__header__section__button}>
                                {rows ? rows.length : null} {i18n.t('quotes.requestedToVendor.groupByClient')}
                            </Button>
                            <Button
                                onClick={() => this.groupBy('vendor')}
                                className={groupBySection === 'vendor' ? classes['vendor__quotes__header__section__button--selected'] : classes.vendor__quotes__header__section__button}>
                                {vendorQuotes ? vendorQuotes.length : null} {i18n.t('quotes.requestedToVendor.groupByVendor')}
                            </Button>
                        </div>
                        <Divider className={classes.divider} />
                    </div>
                )}

                {groupBySection === 'client' &&
                    <div>
                        {rows && rows.length > 0 && (
                            <div>
                                <Hidden xsDown implementation="css">
                                    <ReactTable
                                        data={rows}
                                        getTdProps={(state, rowInfo, column, instance) => {
                                            return {
                                                style: {
                                                    background: (rowInfo ? (highlightRow === rowInfo.original.id ? '#ffffb3' : null) : null)
                                                }
                                            }
                                        }}
                                        columns={[
                                            {
                                                Header: i18n.t("quotes.requestedToVendor.table.column1"),
                                                id: 'file',
                                                width: 170,
                                                filterable: true,
                                                style: {
                                                    textAlign: 'left',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    minHeight: '80px'
                                                },
                                                accessor: row => (
                                                    <div className={classes.rfq__table__fileDescriptions}>
                                                        <Link
                                                            variant="body2"
                                                            className={Permissions.checkSensitiveInformationPermission() ? classes.rfq__table__fileLink : classes.rfq__table__fileLink__disabled}
                                                            onClick={() => {
                                                                if (Permissions.checkSensitiveInformationPermission()) {
                                                                    this.props.handleShowQuote(row.id)
                                                                }
                                                            }}
                                                            underline={Permissions.checkSensitiveInformationPermission() ? 'hover' : 'never'}
                                                        >
                                                            <strong>{i18n.t("quotes.requestedByClient.table.file")} {`${row.id}`}</strong>
                                                        </Link>
                                                        <div className={classes.rfq__table__fileDatesContainer}>
                                                            <Typography
                                                                variant='body2'
                                                                color='textSecondary'>
                                                                {this.quoteDateFormat(row.createDateTime)}
                                                            </Typography>
                                                        </div>
                                                        <div className={classes.list__file__lastUpdate__container}>
                                                            <Typography className={moment().diff(moment(row.createDateTime), 'days') < 2 ? classes.list__file__lastUpdate : (moment().diff(moment(row.createDateTime), 'days') < 4 ? classes['list__file__lastUpdate--late'] : classes['list__file__lastUpdate--veryLate'])} >
                                                                {moment(row.createDateTime).fromNow(true)}
                                                            </Typography>
                                                        </div>
                                                        {Permissions.checkSensitiveInformationPermission() && (
                                                            <TasksShortcut
                                                                row={row}
                                                                handleShowQuote={this.props.handleShowQuote}
                                                            />
                                                        )}
                                                    </div>),
                                                Filter: ({ filter, onChange }) => (
                                                    <div className={classes.flexCell}>
                                                        <TextField
                                                            id='txtClientQuoteID'
                                                            autoComplete='off'
                                                            style={{ flex: 1 }}
                                                            onChange={(e) => onChange(e.target.value)}
                                                            variant="outlined"
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Search style={{ fontSize: '12px' }} />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                ),
                                                filterMethod: (filter, row) => {
                                                    let { value } = filter;
                                                    setSearchParmeters({ quote: value });
                                                    return `${row._original.id}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 || `${this.quoteDateFormat(row._original.createDateTime)}`.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                                }
                                            },
                                            {
                                                Header: i18n.t("quotes.requestedToVendor.table.column2"),
                                                filterable: true,
                                                id: 'quoteClientName',
                                                accessor: row => (
                                                    <div className={classes.list__fileDetails}>
                                                        <Typography className={classes.list__fileClient} noWrap>{row.client.name}</Typography>
                                                        <Typography className={classes.list__fileName}>{`${row.requestForQuote.name}`}</Typography>
                                                        <Typography className={classes.list__fileReference}>{`Ref: ${row.requestForQuote.reference}`}</Typography>
                                                    </div>
                                                ),
                                                style: {
                                                    textAlign: 'left',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                },
                                                Filter: ({ onChange }) => (
                                                    <div className={classes.flexCell}>
                                                        <TextField
                                                            id='txtQuoteClientName'
                                                            autoComplete='off'
                                                            style={{ flex: 1 }}
                                                            onChange={(e) => onChange(e.target.value)}
                                                            variant="outlined"
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Search style={{ fontSize: '12px' }} />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                ),
                                                filterMethod: (filter, row) => {
                                                    let { value } = filter;
                                                    setSearchParmeters({ client: value });
                                                    return (`${row._original.client.name}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                                        `${row._original.requestForQuote.name}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                                        `${row._original.requestForQuote.reference}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                                        `${row._original.client.country.name}`.toLowerCase().indexOf(value.toLowerCase()) >= 0)
                                                }
                                            },
                                            {
                                                Header: i18n.t("quotes.requestedToVendor.table.column3"),
                                                filterable: true,
                                                id: 'vendors',
                                                show: Permissions.checkSensitiveInformationPermission(),
                                                accessor: row => {
                                                    let status = this.calculateStatus(row);
                                                    return (
                                                        <div className={classes.list__vendors__cell}>
                                                            <Typography className={`${classes.list__vendorQuotes__status} ${status === i18n.t("quotes.requestedToVendor.statusReady") ? classes["list__vendorQuotes__status--readyToQuote"] : ''}`} color='textSecondary' noWrap>
                                                                {status}
                                                            </Typography>
                                                            {row.vendors && row.vendors.length ?
                                                                row.vendors.map((v, i) => (
                                                                    <div key={i} className={`${classes.list__vendors__cell__row} ${classes.list__vendors__cell__row__pointer}`}
                                                                        onClick={() => this.openEditVendorQuote(v, row.id)}>

                                                                        {v.statusId === 1 && (
                                                                            <Drafts className={classes.vendor__status__icon__preparing} />
                                                                        )}
                                                                        {v.statusId >= 2 && (
                                                                            <Send className={classes.vendor__status__icon__requested} />
                                                                        )}
                                                                        {v.statusId === 3 && (
                                                                            <DoneOutline className={classes.vendor__status__icon__ready} />
                                                                        )}
                                                                        <div>
                                                                            <Typography key={i} noWrap>
                                                                                <strong>{`${v.vendorQuoteId} `}</strong>
                                                                                <span style={{ textDecoration: 'underline' }}>{v.vendorName}</span>
                                                                            </Typography>
                                                                            <Typography color='textSecondary' style={{fontSize: '14px'}}>
                                                                                {v.statusId === 1 &&
                                                                                    `${i18n.t("quotes.vendorQuotesList.created")} ${this.quoteDateFormat(v.vendorQuoteDateTimeCreated)} \n`
                                                                                }

                                                                                {(v.statusId >= 2) &&
                                                                                    `${i18n.t("quotes.vendorQuotesList.requested")} ${this.quoteDateFormat(v.vendorQuoteDateTimeRequested)} \n`
                                                                                }

                                                                                {v.statusId === 3 &&
                                                                                    `${i18n.t("quotes.vendorQuotesList.ready")} ${this.quoteDateFormat(v.vendorQuoteDateTimeResponse)}`
                                                                                }
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                :
                                                                <div className={classes.list__vendors__cell__row}>
                                                                    <Warning className={classes.vendor__warning__icon} />
                                                                    <Typography>
                                                                        {i18n.t('quotes.requestedToVendor.noVendorQuotes')}
                                                                    </Typography>
                                                                </div>

                                                            }
                                                        </div>
                                                    )
                                                },
                                                style: {
                                                    textAlign: 'left',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                },
                                                Filter: ({ filter, onChange }) => (
                                                    <div className={classes.flexCell}>
                                                        <TextField
                                                            id='txtVendor'
                                                            autoComplete='off'
                                                            style={{ flex: 1 }}
                                                            onChange={(e) => onChange(e.target.value)}
                                                            variant="outlined"
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Search style={{ fontSize: '12px' }} />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                ),
                                                filterMethod: (filter, row) => {
                                                    let { value } = filter,
                                                        status = this.calculateStatus(row._original);

                                                    setSearchParmeters({ vendor: value });
                                                    return `${row._original.vendors.map(v => v.vendorName).join(' ')}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 || status.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                                }
                                            },
                                            {
                                                Header: i18n.t("quotes.requestedByClient.table.country"),
                                                filterable: true,
                                                id: 'country',
                                                width: 60,
                                                accessor: row => (
                                                    <Typography className={classes.list__fileCountry} color='textSecondary' noWrap>{row.client.country.code}</Typography>
                                                ),
                                                Filter: ({ filter, onChange }) => (
                                                    <div className={classes.flexCell}>
                                                        <Select
                                                            disabled={!countries || (countries && countries.length <= 0)}
                                                            native
                                                            fullwidth="true"
                                                            onChange={event => onChange(event.target.value)}
                                                            style={{ width: '100%', minWidth: '55px' }}
                                                            classes={{
                                                                select: classes.country__select
                                                            }}
                                                            value={filter ? filter.value : "all"}
                                                        >
                                                            <option value="all">All</option>
                                                            {countries && countries.map(country => (
                                                                <option key={country} value={country}>{country}</option>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                ),
                                                filterMethod: (filter, row) => {
                                                    let { value } = filter;
                                                    return value.toLowerCase() === 'all' ? true : (row._original.client.country.code.toLowerCase() === value.toLowerCase())

                                                }
                                            },
                                            {
                                                Header: i18n.t("quotes.requestedByClient.table.importance"),
                                                id: 'importance',
                                                width: 90,
                                                Cell: row => (

                                                    row.original.oportunity.name ? (
                                                        <div className={classNames(classes.oportunity__probability, classes[`oportunity__probability--${probabilities[row.original.probability.id - 1]}`])}>
                                                            {row.original.oportunity.name}
                                                        </div>
                                                    ) : null

                                                )
                                            },
                                            {
                                                Header: i18n.t("quotes.requestedToVendor.table.column4"),
                                                filterable: true,
                                                id: 'user_Author',
                                                width: 130,
                                                accessor: row => {
                                                    let usersListed = row.salesRepresentative ? [row.salesRepresentative.id] : [];
                                                    return (
                                                        <div>
                                                            <Typography className={classes.list__fileAuthor}>{row.salesPerson.userName}</Typography>

                                                            {row.salesRepresentative && row.salesRepresentative.name && (
                                                                <Typography color='textSecondary'>{row.salesRepresentative.name}</Typography>
                                                            )}
                                                            {Permissions.checkSensitiveInformationPermission() && (
                                                                <TasksShortcut
                                                                    row={row}
                                                                    handleShowQuote={this.props.handleShowQuote}
                                                                    anchorOrigin={{
                                                                        vertical: 'center',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'center',
                                                                        horizontal: 'right',
                                                                    }}
                                                                >
                                                                    <div>
                                                                        {(row.tasks && row.tasks.length > 0 && row.tasks.filter(task => task.followUpUser && task.followUpUser.name).length > 0) && (
                                                                            <div>
                                                                                <Divider />
                                                                                <div style={{ display: 'flex', marginTop: 5, justifyContent: 'flex-start' }}>
                                                                                    <OpenInNew style={{ fontSize: 14 }} color='secondary' />
                                                                                </div>

                                                                                {row.tasks
                                                                                    .filter(task =>
                                                                                        !task.complete)
                                                                                    .map(task => {
                                                                                        if (task.followUpUser && task.followUpUser.name && usersListed.indexOf(task.followUpUser.id) < 0) {
                                                                                            usersListed.push(task.followUpUser.id);
                                                                                            return <Typography key={task.id} color='textSecondary'>{task.followUpUser.name}</Typography>
                                                                                        }
                                                                                        return null
                                                                                    })}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </TasksShortcut>
                                                            )}
                                                        </div>)

                                                },
                                                style: {
                                                    textAlign: 'left',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                },
                                                Filter: ({ onChange }) => (
                                                    <div className={classes.flexCell}>
                                                        <TextField
                                                            id='txtUser_Author'
                                                            autoComplete='off'
                                                            style={{ flex: 1 }}
                                                            onChange={(e) => onChange(e.target.value)}
                                                            variant="outlined"
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Search style={{ fontSize: '12px' }} />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                ),
                                                filterMethod: (filter, row) => {
                                                    let { value } = filter;
                                                    setSearchParmeters({ salesperson: value });

                                                    return (`${row._original.salesPerson.userName}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                                        (row._original.salesRepresentative ? `${row._original.salesRepresentative.name}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 : false) ||
                                                        (row._original.tasks && row._original.tasks.length > 0 ? row._original.tasks.find(t => t.followUpUser.name.toLowerCase().indexOf(value.toLowerCase() >= 0)) : false)
                                                    )
                                                }
                                            }
                                        ]}
                                        minRows={0}
                                        pageSize={pageSize}
                                        onPageSizeChange={(pageSize, page) =>
                                            this.setState({ page, pageSize })
                                        }
                                        sortable={false}
                                        resizable={false}
                                        className={classNames(classes.table, ' -striped -highlight')}
                                        showPaginationTop={rows && rows.length >= 50 ? true : false}
                                        showPaginationBottom={rows && rows.length >= 50 ? true : false}
                                    />
                                </Hidden>
                                <Hidden smUp implementation="css">
                                    <Paper>
                                        <List>
                                            {rows.map((row, i) => {
                                                let usersListed = row.salesRepresentative ? [row.salesRepresentative.id] : [];
                                                let status = this.calculateStatus(row);
                                                return (
                                                    <div key={row.id}>
                                                        <ListItem key={row.id} >
                                                            <ListItemText >
                                                                <div className={classes.rfq__table__fileDescriptions}>
                                                                    <Link
                                                                        variant="body2"
                                                                        className={Permissions.checkSensitiveInformationPermission() ? classes.rfq__table__fileLink : classes.rfq__table__fileLink__disabled}
                                                                        onClick={() => {
                                                                            if (Permissions.checkSensitiveInformationPermission()) {
                                                                                this.props.handleShowQuote(row.id)
                                                                            }
                                                                        }}
                                                                        underline={Permissions.checkSensitiveInformationPermission() ? 'hover' : 'never'}
                                                                    >
                                                                        <strong>{i18n.t("quotes.requestedByClient.table.file")} {`${row.id}`}</strong>
                                                                    </Link>
                                                                    <div className={classes.rfq__table__fileDatesContainer}>
                                                                        <Typography
                                                                            variant='body2'
                                                                            color='textSecondary'>
                                                                            {this.quoteDateFormat(row.createDateTime)}
                                                                        </Typography>
                                                                        <Typography className={moment().diff(moment(row.createDateTime), 'days') < 2 ? classes.list__file__lastUpdate : (moment().diff(moment(row.createDateTime), 'days') < 4 ? classes['list__file__lastUpdate--late'] : classes['list__file__lastUpdate--veryLate'])} >
                                                                            {moment(row.createDateTime).fromNow(true)}
                                                                        </Typography>
                                                                    </div>
                                                                    {Permissions.checkSensitiveInformationPermission() && (
                                                                        <TasksShortcut row={row}
                                                                            handleShowQuote={this.props.handleShowQuote} />
                                                                    )}
                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <Typography className={classes.list__fileClient} noWrap>{row.client.name}</Typography>
                                                                    <Typography className={classes.list__fileName}>{`${row.requestForQuote.name}`}</Typography>
                                                                    <Typography className={classes.list__fileReference}>{`${row.requestForQuote.reference}`}</Typography>
                                                                    {row.oportunity.name ? (
                                                                        <div className={classNames(classes.oportunity__probability, classes[`oportunity__probability--${probabilities[row.probability.id - 1]}`])}>
                                                                            {row.oportunity.name}
                                                                        </div>
                                                                    ) : null}
                                                                    <div className={classes.list__vendors__cell}>
                                                                        {Permissions.checkSensitiveInformationPermission() && (
                                                                            <>
                                                                                <Typography className={`${classes.list__vendorQuotes__status} ${status === i18n.t("quotes.requestedToVendor.statusReady") ? classes["list__vendorQuotes__status--readyToQuote"] : ''}`} color='textSecondary' noWrap>
                                                                                    {status}
                                                                                </Typography>
                                                                                {row.vendors && row.vendors.length ?
                                                                                    row.vendors.map((v, i) => (
                                                                                        <div key={i} className={`${classes.list__vendors__cell__row} ${classes.list__vendors__cell__row__pointer}`}
                                                                                            onClick={() => this.openEditVendorQuote(v, row.id)}>

                                                                                            {v.statusId === 1 && (
                                                                                                <Drafts className={classes.vendor__status__icon__preparing} />
                                                                                            )}
                                                                                            {v.statusId === 2 && (
                                                                                                <Send className={classes.vendor__status__icon__requested} />
                                                                                            )}
                                                                                            {v.statusId === 3 && (
                                                                                                <DoneOutline className={classes.vendor__status__icon__ready} />
                                                                                            )}
                                                                                            <Typography key={i} noWrap>
                                                                                                <strong>{`${v.vendorQuoteId} `}</strong>
                                                                                                <span style={{ textDecoration: 'underline' }}>{v.vendorName}</span>
                                                                                            </Typography>
                                                                                        </div>
                                                                                    ))
                                                                                    :
                                                                                    <div className={classes.list__vendors__cell__row}>
                                                                                        <Warning className={classes.vendor__warning__icon} />
                                                                                        <Typography>
                                                                                            {i18n.t('quotes.requestedToVendor.noVendorQuotes')}
                                                                                        </Typography>
                                                                                    </div>

                                                                                }
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <Typography className={classes.list__fileAuthor}>{row.salesPerson.userName}</Typography>


                                                                        {row.salesRepresentative && row.salesRepresentative.name && (
                                                                            <Typography color='textSecondary'>{row.salesRepresentative.name}</Typography>
                                                                        )}
                                                                        {Permissions.checkSensitiveInformationPermission() && (
                                                                            <TasksShortcut
                                                                                row={row}
                                                                                handleShowQuote={this.props.handleShowQuote}
                                                                            >
                                                                                <div>
                                                                                    {row.tasks && row.tasks.length > 0 && row.tasks
                                                                                        .filter(task =>
                                                                                            !task.complete)
                                                                                        .map(task => {
                                                                                            if (task.followUpUser && task.followUpUser.name && usersListed.indexOf(task.followUpUser.id) < 0) {
                                                                                                usersListed.push(task.followUpUser.id);
                                                                                                return <Typography key={task.id} color='textSecondary'>{task.followUpUser.name}</Typography>
                                                                                            }
                                                                                            return null
                                                                                        })}
                                                                                </div>
                                                                            </TasksShortcut>
                                                                        )}
                                                                    </div>
                                                                    <div className={classes.list__countryContainer}>
                                                                        <Typography className={classes.list__fileCountry} color='textSecondary' noWrap>{row.client.country.code}</Typography>
                                                                    </div>
                                                                </div>
                                                            </ListItemText>
                                                        </ListItem>
                                                        {i < (rows.length - 1) && (
                                                            <Divider />
                                                        )}
                                                    </div>
                                                )
                                            })}

                                        </List>
                                    </Paper>
                                </Hidden>
                            </div>
                        )}
                        {rows && rows.length === 0 && (
                            <div className={classes.vendor__rfq__noRows}>
                                {this.props.people.filterPeople && this.props.people.filterPeople.length === 0 ? (
                                    <div className={classes.empty__state__container}>
                                        <Inbox style={{ fontSize: 50 }} color='secondary' />
                                        <Typography variant='body2'>{i18n.t('quotes.requestedToVendor.noQuotes')}</Typography>
                                    </div>
                                ) : (
                                        <div className={classes.empty__state__container}>
                                            <Inbox style={{ fontSize: 50 }} color='secondary' />
                                            <Typography variant='body2'>
                                                {this.props.people.filterPeople.map((person, i) => (
                                                    <span key={i}>{`${person.userName}${this.props.people.filterPeople.length > 1 && (this.props.people.filterPeople.length > i + 1) ? ', ' : ' '}`} </span>
                                                ))}
                                                <br />
                                                {this.props.people.filterPeople.length === 1 ? i18n.t('peopleFilter.hasNot') : i18n.t('peopleFilter.haveNot')}
                                                {i18n.t('peopleFilter.pendingVendorRFQ')}
                                            </Typography>
                                            {Permissions.checkSensitiveInformationPermission() && (
                                                <Typography variant='body2'>
                                                    <Link className={classes.customLink}
                                                        onClick={() => {
                                                            this.props.generalActions.showPeopleFilter(true)
                                                        }}>
                                                        {i18n.t('peopleFilter.changeUsersFilter')}
                                                    </Link>
                                                </Typography>
                                            )}
                                        </div>
                                    )}
                            </div>
                        )}
                    </div>
                }

                {groupBySection === 'vendor' &&
                    <div className={classes.vendor__quotes__list}>
                        <VendorQuotesList
                            countries={countries}
                            vendorQuotes={vendorQuotes}
                            handleShowQuote={this.props.handleShowQuote}
                        />
                    </div>
                }


                <VendorQuotePopup
                    vendorQuote={vendorQuote}
                    open={showVendorQuote}
                    file={file}
                    vendors={vendors}
                    handleClose={this.handleClose}
                    onExited={this.onExited}
                    deleteVendorQuote={this.deleteVendorQuote}
                    updateVendorQuote={this.updateVendorQuote}
                />
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return {
        vendorsActions: bindActionCreators(vendorsActions, dispatch),
        filesActions: bindActionCreators(filesActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
        vendorQuotesActions: bindActionCreators(vendorQuotesActions, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        vendors: state.vendors,
        files: state.files,
        people: state.people,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(requestedToVendor))