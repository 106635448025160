import React, { Component } from 'react'
import {
    Paper,
    Grid,
    Divider,
    Link,
    withStyles,
    Typography,
    Tabs, Tab,
} from '@material-ui/core'
import { Send, Drafts, DoneOutline, 
    Inbox, Warning, NotInterested } from '@material-ui/icons'
import {
    VendorPO,
    ClientPOTotals,
    ClientPOReadOnly,
    ClientPOForm,
    ClientPOItemsList
} from '../po'
import classNames from 'classnames'
import i18n from 'i18next';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as generalActions from '../../../actions/generalActions'
import * as filesActions from '../../../actions/filesActions'
//Mientras metemos sagas
import * as apiStatusActions from '../../../actions/apiStatusActions'

import Permissions from '../../../lib/permissions'


const styles = theme => {
    return (
        {
            po__root__paper: {
                padding: theme.spacing(2),
            },
            po__section__active: {
                position: 'relative'
                // borderWidth: '2px',
                // borderStyle: 'solid',
                // borderColor: theme.palette.primary.light
            },
            po__vendorPO__tabs__activeIndicator: {
                backgroundColor: 'transparent',
                zIndex: 11,
                marginLeft: '1px',
                width: 'calc(100% - 2px) !important'
            },
            po__vendorPO__tab: {
                color: theme.palette.text.primary,
                border: `1px solid ${theme.palette.grey[300]}`,
                backgroundColor: theme.palette.grey[100]
            },
            po__vendorPO__tab__ready: {
                color: theme.palette.text.primary,
                border: `1px solid ${theme.palette.grey[300]}`,
                backgroundColor: theme.palette.grey[100],
                marginLeft: 'auto'
            },
            'po__vendorPO__tab--selected': {
                backgroundColor: '#fff',
                borderTop: `1px solid ${theme.palette.grey[400]}`,
                borderLeft: `1px solid ${theme.palette.grey[400]}`,
                borderRight: `1px solid ${theme.palette.grey[400]}`,
                borderBottom: 'none',
                color: theme.palette.text.primary,
                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
                zIndex: 10
            },
            pos__vendorsTabs: {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '230px',
                [theme.breakpoints.down('sm')]: {
                    width: '200px'
                }
            },
            vendor__status__icon__void: {
                marginRight: theme.spacing(),
                fontSize: '10pt',
                color: theme.palette.text.secondary,
            },
            vendor__status__icon__preparing: {
                marginRight: theme.spacing(),
                fontSize: '10pt',
                color: theme.palette.red[100],
            },
            vendor__status__icon__requested: {
                marginRight: theme.spacing(),
                fontSize: '10pt',
                color: theme.palette.primary.main,
            },
            vendor__status__icon__ready: {
                marginRight: theme.spacing(),
                fontSize: '10pt',
                color: theme.palette.secondary.main,
            },
            print__edit__buttons__container: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                [theme.breakpoints.only('xs')]: {
                    justifyContent: 'space-between',
                }
            },
            printButton: {
                ...theme.smallButton,
                backgroundColor: theme.palette.grey[100],
                '&:hover': {
                    backgroundColor: theme.palette.grey[400],
                    color: theme.palette.getContrastText(theme.palette.grey[400]),
                },
                color: theme.palette.getContrastText(theme.palette.grey[100]),
                [theme.breakpoints.up('sm')]: {
                    marginRight: theme.spacing(2),
                }
            },
            printInvoiceButton: {
                ...theme.smallButton,
                width: '120px',
                backgroundColor: theme.palette.grey[100],
                '&:hover': {
                    backgroundColor: theme.palette.grey[400],
                    color: theme.palette.getContrastText(theme.palette.grey[400]),
                },
                color: theme.palette.getContrastText(theme.palette.grey[100]),
                [theme.breakpoints.up('sm')]: {
                    marginRight: theme.spacing(2),
                }
            },
            poClient__editLink: {
                ...theme.customLink,
                position: 'absolute',
                top: '12px',
                right: '10px'
            },
            po__divider: {
                margin: '16px 0'
            },
            "clientPO__status--readyToDeliver": {
                color: theme.palette.green[100],
            },
            "clientPO__status--partial": {
                color: theme.palette.orange[100],
            },
            legend__root__row: {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
            },
            action__bar__readyToDeliver__label: {
                padding: 0,
                borderRadius: theme.spacing() / 2,
                color: theme.palette.green[100],
                textDecoration: 'underline',
                fontWeight: 'bold',
                textAlign: 'right'
            },
            action__bar__missingcondition__label: {
                //fontSize: '9pt',
                textAlign: 'right',
                fontWeight: 'bold',
                padding: '8px',
                borderRadius: '4px',
                backgroundColor: 'white',
                color: 'orange'
            },
            empty__state__container: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: theme.spacing(3)
            },
            warning__message__icon: {
                marginRight: theme.spacing(),
                fontSize: '14pt',
                color: theme.palette.orange[100],
            },
            messages__container: {
                display: 'flex',
                justifyContent: 'flex-end'
            },
            customLink: {
                ...theme.customLink,
                marginLeft: '3px',
                marginRight: '4px',
            },
            updatePOCustomLink: {
                ...theme.customLink,
                display: 'flex', 
                alignItems: 'center',
                fontSize: theme.typography.fontSize,
                fontFamily: theme.typography.fontFamily
            }
        })
}

class ClientPO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
            vendorPOs: props.file ? props.file.vendorPOs : [],
            file: props.file,
            items: props.file && props.file.clientQuote && props.file.clientQuote[0].clientPO ? props.file.clientQuote[0].clientPO.items.sort((a, b) => {return a.rfqItemId - b.rfqItemId}) : [],
            editClientPODialogOpen: false,
            clientPO: props.file && props.file.clientQuote && props.file.clientQuote[0] ? props.file.clientQuote[0].clientPO : null,
            currentTab: props.showVendorsPOs ? 1 : 0
        }
    }

    componentWillReceiveProps(nextProps) {
        const { file } = nextProps;

        if (file) {
            this.setState({
                file: file,
                items: file.clientQuote && file.clientQuote[0].clientPO ? file.clientQuote[0].clientPO.items.sort((a, b) => {return a.rfqItemId - b.rfqItemId}) : [],               
                vendorPOs: file.vendorPOs ? file.vendorPOs : [],
                clientPO: file.clientQuote && nextProps.file.clientQuote[0] ? nextProps.file.clientQuote[0].clientPO : null
            })
        }

        if (nextProps.general && nextProps.general.showVendorsPo) {
            this.setState({
                currentTab: 1
            })
            this.props.generalActions && this.props.generalActions.showVendorsPo(false);
        }
    }

    updateVendorsPO = (file) => {
        this.props.filesActions.setFile(file)

        let newVendorQuotes = file.vendorQuotes.filter(vrfq => {
            return vrfq.items.filter(item => item.isAccepted).length > 0
        });

        this.setState({
            vendorPOs: file.vendorPOs,
            vendorQuotes: newVendorQuotes
        })
    }

    showPOs = (e, value) => {
        this.setState({
            currentTab: value
        })
    }

    handleCloseEditClientPO = () => {
        this.setState({
            editClientPODialogOpen: false
        })
    }

    handleVendorTabChange = (e, value) => {
        this.setState({ selectedTab: value });
    }

    updateClientPO = (file) => {
        this.props.filesActions.setFile(file)
        this.handleCloseEditClientPO()
    }

    calculateClientPOStatus = () => {
        const { file } = this.state;

        if (!file) return ''

        let invoicedItems = 0,
            invoiced = true;

        let posNoReady = file.vendorPOs.filter(vp => vp.status.id !== 3).length > 0;

        if (file.invoices.length === 0) {
            if (!posNoReady) {
                return i18n.t('quotes.pos.poDetails.readyToInvoice')
            } else {
                return i18n.t('quotes.pos.poDetails.preparing')
            }
        }

        if (file.invoices.length !== 0) {
            file.clientQuote[0].items.forEach(cItem => {
                invoicedItems = file.invoices.reduce(((total, row) => parseFloat(total) + (
                    row.items.find(i => i.clientQuoteItemId === cItem.id) ? row.items.find(i => i.clientQuoteItemId === cItem.id).quantity : 0
                )), 0);

                if (cItem.rfqItem.quantity !== invoicedItems) {
                    invoiced = false
                }
            })

            if (invoiced) {
                return i18n.t('quotes.pos.poDetails.invoiced')
            } else {
                return i18n.t('quotes.pos.poDetails.partiallyInvoiced')
            }

        }
    }

    calculateItemsCount = () => {
        const { file } = this.state;

        let invoicedItems = 0,
            vpItems = [] ;
        file.invoices.forEach((inv) => {
            if (inv.status !== 1) {
                invoicedItems = invoicedItems + inv.items.reduce(((total, row) => parseInt(total) + (row.invoiceQuantity ? parseInt(row.invoiceQuantity || 0) : 0)), 0);
            }
        })

        file.vendorPOs.forEach(vp => {
            vpItems = vpItems.concat(vp.items)
        });

        let receivedItems = vpItems.reduce(((total, row) => parseInt(total) + (row.receivedQuantity ? parseInt(row.receivedQuantity) : 0)), 0),
            requestedItems = vpItems.reduce(((total, row) => parseInt(total) + (row.quantity ? parseInt(row.quantity) : 0)), 0),
            deliveredItems = file.clientQuote[0].clientPO.items.reduce(((total, row) => parseInt(total) + (row.deliveredQuantity ? parseInt(row.deliveredQuantity) : 0)), 0);

        return {
            receivedItems,
            requestedItems,
            invoicedItems,
            deliveredItems
        }
    }

    openInvoice = () => {
        this.props.generalActions.showInvoiceForm(true)
    }

    showVendorsPo = () => {
        this.setState({
            currentTab: 1
        })
    }

    render() {
        const { classes, editable, hideVendorsPOS } = this.props,
            { selectedTab,
                currentTab,
                editClientPODialogOpen,
                clientPO,
                file,
                items
            } = this.state;

        if (!file) return null;

        let firstReadyInvoice = false;

        let vendorPOs = this.state.vendorPOs.length > 0 ? this.state.vendorPOs.sort((a, b) => {
            return a.status.id - b.status.id;
        }) : [],
            vendorsPosIsSent = vendorPOs.filter(vp => vp.status.id === 1).length !== vendorPOs.length

        let counts = this.calculateItemsCount()

        return (

            <div>
                <Paper className={classNames(classes.po__root__paper, editable ? classes.po__section__active : '')}>
                    {!hideVendorsPOS && file.status <= 5 && <>
                        { Permissions.checkSensitiveInformationPermission() &&
                            <div style={{ position: 'absolute', right: '16px', top: '10px', zIndex: '10', cursor: 'pointer' }}>
                                {file.invoices && file.invoices.length > 0 && file.invoices.filter(inv => inv.status === 1).length > 0 ?
                                    <Typography className={classes.messages__container} style={{ marginTop: '10px' }}>
                                        <Warning className={classes.warning__message__icon} />
                                        <Link className={classes.customLink}
                                            onClick={this.props.showInvoice}>
                                            {`Invoice #${file.invoices.find(inv => inv.status === 1).id}`}
                                        </Link>
                                        {i18n.t('actionBarMessages.pendingInvoice')}
                                    </Typography>
                                    :
                                    <>
                                        {/* Hay items recibidos que no han sido facturados */}
                                        {counts.receivedItems > 0 && counts.receivedItems - counts.invoicedItems > 0 &&
                                            <Typography className={classes.action__bar__readyToDeliver__label} onClick={this.openInvoice}>
                                                {`${counts.receivedItems - counts.invoicedItems} ${i18n.t('actionBarMessages.itemsNoInvoiced')}`}
                                            </Typography>
                                        }

                                        {/* No se han enviado los POs o recibidos items de los vendors*/}
                                        {counts.receivedItems === 0 &&
                                            <Typography className={classes.messages__container} style={{ marginTop: '10px' }}>
                                                <Warning className={classes.warning__message__icon} />
                                                {vendorsPosIsSent ?
                                                    i18n.t('actionBarMessages.vendorPosReadys')
                                                    :
                                                    i18n.t("actionBarMessages.vendorPosNoSent")
                                                }
                                                {currentTab === 0 &&
                                                    <Link className={classes.customLink}
                                                        onClick={this.showVendorsPo}>
                                                        {vendorsPosIsSent ?
                                                            i18n.t("actionBarMessages.pendingReceiveItemsLink")
                                                            :
                                                            i18n.t("actionBarMessages.pendingSentItemsLink")
                                                        }
                                                    </Link>
                                                }
                                            </Typography>
                                        }


                                        {/* Faltan cosas que recibir de los vendors*/}
                                        { counts.receivedItems !== 0 && counts.receivedItems !== counts.requestedItems &&
                                            <Typography className={classes.messages__container} style={{ padding: 0 }}>
                                                <Warning className={classes.warning__message__icon} />
                                                {i18n.t('actionBarMessages.waitingForVendors1')}
                                                {currentTab === 0 ?
                                                    <Link className={classes.customLink}
                                                        onClick={this.showVendorsPo}>
                                                        {`(missing ${counts.requestedItems - counts.receivedItems} of ${counts.requestedItems})`}
                                                    </Link>
                                                    :
                                                    `(missing ${counts.requestedItems - counts.receivedItems} of ${counts.requestedItems})`
                                                }
                                            </Typography>
                                        }

                                        {/* Se ha recibido todo de los vendors*/}
                                        { counts.receivedItems !== 0 && counts.receivedItems === counts.requestedItems &&
                                            <Typography className={classes.messages__container} style={{ padding: 0 }}>
                                                <Warning className={classes.warning__message__icon} />
                                                {i18n.t('actionBarMessages.orderIsComplete')}
                                            </Typography>
                                        }
                                    </>
                                }
                            </div>
                        }

                        { Permissions.checkSensitiveInformationPermission() && <>
                            <Tabs
                                value={currentTab}
                                onChange={this.showPOs}
                                indicatorColor="secondary"
                                textColor="secondary"
                            >

                                <Tab
                                    key={0}
                                    label={(
                                        <div className={classes.legend__root__row}>
                                            {clientPO.status.id === 1 && (
                                                <Drafts className={classes.vendor__status__icon__preparing} />
                                            )}
                                            {clientPO.status.id === 2 && (
                                                <Send className={classes.vendor__status__icon__requested} />
                                            )}
                                            {clientPO.status.id === 3 && (
                                                <DoneOutline className={classes.vendor__status__icon__ready} />
                                            )}
                                            <Typography color='textSecondary'>{i18n.t('quotes.pos.poDetails.title')}</Typography>
                                        </div>)
                                    }
                                    style={{ width: 120 }}
                                />

                            
                                <Tab
                                    key={1}
                                    label={<Typography color='textSecondary'>{i18n.t('quotes.pos.poDetails.vendorTitle')}</Typography>}
                                    style={{ width: 120 }}
                                />
                                
                            </Tabs>
                        
                            <Divider className={classes.vendorPos__tabs__divider} />
                        </>}
                    </>}

                    {currentTab === 0 &&
                        <div style={{ padding: '16px 0px 1px' }}>
                            <Typography variant='body2' color='textSecondary' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                {clientPO.status.id === 1 && (
                                    <Drafts className={classes.vendor__status__icon__preparing} />
                                )}
                                {clientPO.status.id === 2 && (
                                    <Send className={classes.vendor__status__icon__requested} />
                                )}
                                {clientPO.status.id === 3 && (
                                    <DoneOutline className={classes.vendor__status__icon__ready} />
                                )}
                                {clientPO.status.name}
                            </Typography>

                            <Grid container spacing={2} style={{position: 'relative'}}>
                                <Grid item xs={12}>
                                    {!hideVendorsPOS &&
                                        <ClientPOReadOnly
                                            clientPO={clientPO}
                                            file={file}
                                            clientQuote={file && file.clientQuote && file.clientQuote[0] ? file.clientQuote[0] : null}
                                        />
                                    }
                                </Grid>

                                { Permissions.checkSensitiveInformationPermission() &&
                                    <div className={classes.print__edit__buttons__container}>
                                        {editable && clientPO.status.id <= 2 &&
                                            <Link className={classes.poClient__editLink}
                                                variant='button'
                                                onClick={() => {
                                                    this.setState({
                                                        editClientPODialogOpen: true
                                                    })
                                                }}>
                                                {i18n.t('quotes.pos.poDetails.editClientPO')}
                                            </Link>
                                        }
                                    </div>
                                }
                            </Grid>

                            <div style={{ marginRight: '8px' }}>
                                <ClientPOItemsList
                                    currencySymbol={file.currencySymbol}
                                    items={items}
                                    file={file}
                                    clientQuoteTaxPercent={file.clientQuote && file.clientQuote[0] ? file.clientQuote[0].taxPercent : null}
                                />
                            </div>

                            <ClientPOTotals
                                file={file}
                                items={items}
                            />

                        </div>
                    }

                    {currentTab === 1 && !hideVendorsPOS &&
                        <div>
                            {vendorPOs.length > 0 ?
                                <Grid container spacing={2} style={{ padding: '16px 8px' }}>
                                    <Grid item sm={12}>
                                        <Tabs
                                            className={classes.po__vendorPO__tabs}
                                            value={selectedTab}
                                            onChange={this.handleVendorTabChange}
                                            indicatorColor="secondary"
                                            textColor="secondary"
                                            classes={{
                                                indicator: classes.po__vendorPO__tabs__activeIndicator,
                                                flexContainer: classes.po__vendorPO__tabs__flexContainer,
                                            }}
                                        >
                                            {vendorPOs.sort((a, b) => {
                                                let aStatus = a.status.id,
                                                    bStatus = b.status.id;
                                                if (aStatus === 4){
                                                    aStatus = 2
                                                }
                                                if (bStatus === 4) {
                                                    bStatus = 2
                                                }
                                                if(a.isVoid && a.poNumber){
                                                    aStatus = 3
                                                }
                                                if (b.isVoid && b.poNumber) {
                                                    bStatus = 3
                                                }
                                                return aStatus - bStatus;
                                            }).map((vp, i) => {
                                                let alignToR = false;
                                                if ((vp.status.id === 3 || (vp.isVoid && vp.poNumber)) && !firstReadyInvoice) {
                                                    firstReadyInvoice = true;
                                                    alignToR = true;
                                                }
                                                return (
                                                    <Tab
                                                        key={i}
                                                        label={(
                                                            <div>
                                                                <div className={classes.pos__vendorsTabs}>
                                                                    {vp.isVoid && vp.poNumber ? (
                                                                        <NotInterested className={classes.vendor__status__icon__void } />
                                                                    ) : <>
                                                                        {vp.status.id === 1 && (
                                                                            <Drafts className={classes.vendor__status__icon__preparing} />
                                                                        )}

                                                                        {(vp.status.id === 2 || vp.status.id === 4) && (
                                                                            <Send className={classes.vendor__status__icon__requested} />
                                                                        )}
                                                                        {vp.status.id === 3 && (
                                                                            <DoneOutline className={classes.vendor__status__icon__ready} />
                                                                        )}
                                                                    </>}
                                                                    <Typography noWrap >{vp.vendorQuoteId} - {vp.vendor.name}</Typography>
                                                                </div>
                                                            </div>
                                                        )}
                                                        classes={{
                                                            root: classNames(classes.po__vendorPO__tab, alignToR ? classes.po__vendorPO__tab__ready : ''),
                                                            selected: classes['po__vendorPO__tab--selected']
                                                        }}
                                                    />
                                                )
                                            })}
                                        </Tabs>


                                        {vendorPOs.map((vp, i) => {
                                            if (i === selectedTab) {
                                                return (
                                                    <Paper style={{ padding: '16px', background: vp.isVoid && vp.poNumber ? '#e0e0e0' : '#fff' }} key={i}>
                                                        <VendorPO
                                                            updateVendorsPO={this.updateVendorsPO}
                                                            editable={editable}
                                                            currencySymbol={file.currencySymbol}
                                                            vendorPO={vp}
                                                        />
                                                    </Paper>
                                                )
                                            } else {
                                                return null
                                            }
                                        })}
                                    </Grid>
                                </Grid>
                                :
                                <div className={classes.empty__state__container}>
                                    <Inbox style={{ fontSize: 30 }} color='secondary' />
                                    <Typography variant='body2'>{i18n.t('quotes.pos.vendorPOs.noQuotes')}</Typography>
                                </div>
                            }
                        </div>
                    }

                    <ClientPOForm
                        file={file}
                        open={editClientPODialogOpen}
                        updateClientPO={this.updateClientPO}
                        handleClose={this.handleCloseEditClientPO}
                        clientPO={clientPO}
                        clientQuote={file && file.clientQuote && file.clientQuote[0] ? file.clientQuote[0] : null}
                    />
                </Paper>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        generalActions: bindActionCreators(generalActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch),
        filesActions: bindActionCreators(filesActions, dispatch)
    }
}


function mapStateToProps(state) {
    return {
        general: state.general,
        apiStatus: state.apiStatus,
        file: state.file
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClientPO));