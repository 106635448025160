import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import {
    CircularProgress,
    withStyles,
    Typography,
    Grid,
    Fade,
} from '@material-ui/core/';
import i18n from 'i18next';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as filesActions from '../actions/filesActions'
import * as vendorQuotesActions from '../actions/vendorQuotesActions'
import * as apiStatusActions from '../actions/apiStatusActions'

import {
    FileDetails,
    PurchaseOrders,
    QuotesListLeyend
} from '../components/quotes';

import { PeopleFilter, CustomMessages } from '../components/general'
import API from '../lib/api';

const styles = theme => ({
    quotes__container: {
        padding: '40px 20px',
        margin: 'auto',
        maxWidth: '1100px'
    },
    quotes__container__header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '24px'
    },
    quotes__container__titleContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('xs')]: {
            justifyContent: 'flex-start'
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-between',
            marginBottom: theme.spacing()
        },
    },
    circularProgress: {
        marginTop: '20%'
    }
});
let searchParameters = {};


class PurchaseOrdersModule extends Component {

    state = {
        pageTitle: 'Purchase Orders',
        status: 5,
        loading: true,
        fetching: false,
        showEditQuote: false,
        selectedFileId: null,
        row: null,
        successMessage: null
    }


    componentWillReceiveProps = (nextProps) => {
        const { match } = nextProps;

        if (this.state.selectedFileId && !match.params.fileId) {
            this.handleCreateQuoteClose();
        }
        if (!this.state.loading && !this.state.selectedFileId && match.params.fileId) {
            this.handleShowQuote(match.params.fileId);
        }
    }

    componentDidMount() {
        this.handleGetFiles();
    }

    fetchQuotesByStatus = () => {
      const { status } = this.state

        return new Promise((resolve, reject) => {
            if (!this.state.fetching) {
                this.props.apiStatusActions.fetching()
                this.setState({
                    fetching: true,
                    loading: true
                }, _ => {
                    API.Files.getFiles({
                        recordCount: 50,
                        offset: 0,
                        status,
                        userId: this.props.account ? this.props.account.userId : null
                    }).then(res => {
                      
                      this.props.filesActions.setFiles(res.data && res.data.records ? res.data.records : []);

                      this.setState({
                          fetching: false,
                          loading: false
                      });
                      this.props.apiStatusActions.fetched()
                      resolve();
                    })
                })
            } else {
                resolve();
            }
        })
    }

    handleGetFiles = () => {
        this.fetchQuotesByStatus()

        const { match } = this.props;
        if (match.params.fileId) {
            this.handleShowQuote(parseInt(match.params.fileId, 10));
        }
    }

    reloadList = () => {
      this.setState({
          loading: true
      }, () => {
          this.fetchQuotesByStatus();
      })
    }

    reloadPage = () => {
      const { history } = this.props
      let url = '/purchaseOrders'
              
      let parameters = [],
          parametersKeys = Object.keys(searchParameters);

      if (searchParameters && parametersKeys.length > 0) {
          parametersKeys.forEach((key) => {
              let value = searchParameters[key];
              if (value) {
                  parameters.push(`${key}=${value}`)
              }
          })
      }
      if (parameters.length > 0) {
          url += `?${parameters.join('&')}`
      }

      if (history.location.pathname !== url) {
          history.push(url)
      }

      this.fetchQuotesByStatus();

      this.setState({
          loading: true
      })
    };


    handleCreateQuoteClose = (successMessage) => {
      const { history } = this.props;

        this.setState({
            successMessage: successMessage || null,
            showEditQuote: false,
            selectedFileId: null
        });

        let url = `/purchaseOrders`

        if (history.location.pathname !== url) {
            history.push(url)
        }
    }
    
    handleShowQuote = (fileId) => {
        const { history, files } = this.props;
        let file = files.find(r => parseInt(r.id) === parseInt(fileId));

        this.setState({
            showEditQuote: true,
            selectedFileId: fileId,
            row: file || null
        }, () => {
            let newPath = `/purchaseOrders/${fileId}`;
            if (history.location.pathname !== newPath) {
                history.push(newPath)
            }
        })
    }

    setSearchParmeters = (newSearchParameters) => {
        searchParameters = {
            ...searchParameters,
            ...newSearchParameters
        };
    }

    updateFileList = (file) => {
        this.props.filesActions.updateFile(file);
    }

    render() {
        const {
            loading,
            status,
            showEditQuote,
            selectedFileId,
            row,
            pageTitle,
            successMessage
        } = this.state,
            { classes, files } = this.props;

        return (
            <div>
                <Helmet title={`${i18n.t('companyName')} | ${pageTitle}`} />
                <div className={classes.quotes__container}>

                    <div className={classes.quotes__container__header}>
                        <Grid container spaing={0} justify='space-between'>
                            <Grid xs={12} sm={10} item className={classes.quotes__container__titleContainer}>
                                <Typography variant="h5">{i18n.t("quotes.pos.purchaseOrders")}</Typography>
                                <PeopleFilter
                                    reloadList={this.reloadList} />
                            </Grid>
                        </Grid>
                    </div>

                    <Fade in={!loading}>
                        <div>
                            {!loading &&
                              <PurchaseOrders
                                  rows={files}
                                  hideVendorPOs={true}
                                  reloadParent={this.reloadPage}
                                  handleShowQuote={this.handleShowQuote}
                                  setSearchParmeters={this.setSearchParmeters} />
                            }
                        </div>
                    </Fade>
                    {loading && (
                        <Fade in={loading}>
                            <CircularProgress color='secondary' className={classes.circularProgress} size={24} />
                        </Fade>
                    )}
                    {(!loading && files.length > 0) && (
                        <QuotesListLeyend status={status} />
                    )}

                </div>

                <CustomMessages
                    type={'success'}
                    message={successMessage}
                    clearMessage={() => this.setState({
                        successMessage: null
                    })}
                />


                <FileDetails
                    open={showEditQuote}
                    onClose={this.handleCreateQuoteClose}
                    fileId={selectedFileId}
                    olderTask={row ? row.olderTask : null}
                    notesCount={row ? row.notesCount : 0}
                    status={row && row.finished ? row.status : status}
                    updateFileList={this.updateFileList}
                    showVendorsPOs={false}
                    focusedVendorQuote={false}
                    showTasksSection={false} />


            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        vendorQuotesActions: bindActionCreators(vendorQuotesActions, dispatch),
        filesActions: bindActionCreators(filesActions, dispatch),
        apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
    }
}


function mapStateToProps(state, ownProps) {
    return {
        files: state.files,
        vendorQuotes: state.vendorQuotes,
        people: state.people,
        account: state.account,
        apiStatus: state.apiStatus
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PurchaseOrdersModule));

