import React, { Component } from 'react'
import moment from "moment";
import {
    Typography,
    Button,
    Grid,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    withStyles,
    TextField
} from '@material-ui/core';
import i18n from 'i18next';
import NumberFormat from 'react-number-format'
import Permissions from '../../lib/permissions'
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';

//REDUX
import { connect } from 'react-redux'

const customStyles = {
    control: base => ({
        ...base,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        boxShadow: 'none',

        '&:hover': {
            borderColor: '#000'
        }
    })
}

const styles = theme => {
    return ({
        headerSummary__form__input: {
            fontSize: '1em',
            textAlign: 'left'
        },
        headerSummary__form__textField: {
            width: '100%'
        },
    })
}

class HeaderSummary extends Component {
    state = {
        selectedDateTo: moment(),
        selectedDateFrom: moment('11-01-2019'),
        selectedCountry: 'all',
        selectedClient: '',
        selectedSalesPerson: 'all'
    }

    handleDateToChange = date => {
        this.setState({
            selectedDateTo: date
        });
    }

    handleDateFromChange = date => {
        this.setState({
            selectedDateFrom: date
        });
    }

    render = () => {
        const { totalUSD, totalEU, profitUSD, people, countries,
                profitEU, otherCostTotalUSD, otherCostTotalEU, classes } = this.props,
            {   selectedClient,
                selectedDateTo,
                selectedDateFrom,
                selectedCountry,
                selectedSalesPerson
            } = this.state;

        return (
            <Grid container spacing={1} alignItems='center' style={{margin: '16px 0'}}>
                <Grid item xs={12}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                        <div style={{maxWidth: '110px', marginRight: '8px'}}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <InlineDatePicker
                                    variant="outlined"
                                    label={i18n.t('listHeader.dateFrom')}
                                    value={selectedDateFrom}
                                    maxDate={moment()}
                                    format="MM/DD/YYYY"
                                    styles={customStyles}
                                    onChange={this.handleDateFromChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        className: classes.headerSummary__form__input
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        <div style={{maxWidth: '110px', marginRight: '8px'}}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <InlineDatePicker
                                    variant="outlined"
                                    label={i18n.t('listHeader.dateTo')}
                                    value={selectedDateTo}
                                    format="MM/DD/YYYY"
                                    minDate={selectedDateFrom}
                                    maxDate={moment()}
                                    styles={customStyles}
                                    onChange={this.handleDateToChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        className: classes.headerSummary__form__input
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        <div style={{width: '110px', marginRight: '8px'}}>
                            <FormControl variant="outlined" style={{width: '100%'}}>
                                <InputLabel>
                                    {i18n.t('listHeader.country')}
                                </InputLabel>
                                <Select
                                    value={selectedCountry}
                                    className={classes.headerSummary__form__input}
                                    onChange={(e)=>{
                                        this.setState({
                                            selectedCountry: e.target.value
                                        })
                                    }}
                                    labelWidth={70}
                                >
                                    <MenuItem value="all">{"All"}</MenuItem>
                                    {countries && countries.map(country => (
                                        <MenuItem key={country} value={country}>{country}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div style={{width: '280px', marginRight: '8px'}}>
                            <TextField
                                autoComplete='off'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    className: classes.headerSummary__form__input
                                }}
                                onChange={(e)=>{
                                    this.setState({
                                        selectedClient: e.target.value
                                    })
                                }}
                                value={selectedClient}
                                className={classes.headerSummary__form__textField}
                                id="filterClient"
                                type="text"
                                variant="outlined"
                                label={i18n.t('listHeader.client')}
                                size="small"
                            />
                        </div>
                        { Permissions.checkSensitiveInformationPermission() &&
                            <div style={{width: '130px', marginRight: '8px'}}>
                                <FormControl variant="outlined" style={{width: '100%'}}>
                                    <InputLabel>
                                        {i18n.t('listHeader.salesPerson')}
                                    </InputLabel>
                                    <Select
                                        value={selectedSalesPerson}
                                        className={classes.headerSummary__form__input}
                                        onChange={(e)=>{
                                            this.setState({
                                                selectedSalesPerson: e.target.value
                                            })
                                        }}
                                        labelWidth={100}
                                    >
                                        <MenuItem value="all">{"All"}</MenuItem>
                                        {people && people.allPeople && people.allPeople.map(person => (
                                            <MenuItem key={person.userId} value={person.userId}>{person.userName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        }
                        
                        <Button
                            color="primary"
                            variant="contained"
                            style={{minWidth: '70px', height: '32px'}}
                            onClick={()=> {
                                let person = selectedSalesPerson === 'all' ? null : people.allPeople.find(p => p.userId === selectedSalesPerson) 

                                this.props.handleDateFilter({
                                    selectedDateTo, 
                                    selectedDateFrom, 
                                    selectedCountry: selectedCountry && selectedCountry === 'all' ? null : selectedCountry, 
                                    selectedClient, 
                                    selectedSalesPerson: person ? person.userName : null
                                })
                            }}>
                            {i18n.t("quotes.invoices.filter")}
                        </Button>
                    </div>
                </Grid>

                <Grid item xs={12} justify='flex-end' style={{marginTop: '8px'}}>
                    <Grid container item direction='column' alignItems='flex-start'>
                        
                        <div style={{display: 'flex', border: '1px solid', padding: '0 16px'}}>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems:'flex-start', marginRight: '48px'}}>
                                <Typography
                                    variant='body1'
                                    style={{marginLeft: '47px'}}
                                    color='textPrimary'>
                                    <strong>{i18n.t('quotes.invoices.total')}</strong>
                                </Typography>
                                <NumberFormat value={totalUSD + otherCostTotalUSD}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'USD $ '}
                                    decimalScale={2}
                                    style={{fontSize: '16px'}}
                                    fixedDecimalScale={true}
                                />
                                <NumberFormat value={totalEU + otherCostTotalEU}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'EUR € '}
                                    style={{fontSize: '16px'}}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                />
                            </div>

                            { Permissions.checkSensitiveInformationPermission() && 

                                <div style={{display: 'flex', flexDirection: 'column', alignItems:'flex-start', marginRight: '16px'}}>
                                    <Typography
                                        variant='body1'
                                        color='textPrimary'>
                                        <strong>{i18n.t('quotes.invoices.totalMarkup')}</strong>
                                    </Typography>
                                    <NumberFormat value={profitUSD}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        style={{fontSize: '16px'}}
                                        fixedDecimalScale={true}
                                    />
                                    <NumberFormat value={profitEU}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        style={{fontSize: '16px'}}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                </div>
                            }

                            { Permissions.checkSensitiveInformationPermission() && 
                                <div style={{display: 'flex', flexDirection: 'column', alignItems:'flex-start'}}>
                                    <Typography
                                        variant='body1'
                                        color='textPrimary'>
                                        <strong>{`(%)`}</strong>
                                    </Typography>
                                    <NumberFormat value={((profitUSD * 100) /  (totalUSD - profitUSD)) || 0}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        suffix ={' %'}
                                        style={{fontSize: '16px'}}
                                        fixedDecimalScale={true}
                                    />
                                    <NumberFormat value={((profitEU * 100) / (totalEU - profitEU)) || 0}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        style={{fontSize: '16px'}}
                                        decimalScale={0}
                                        suffix ={' %'}
                                        fixedDecimalScale={true}
                                    />
                                </div>
                            }
                        </div>
                    
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        people: state.people
    }
}

export default connect(mapStateToProps, null)(withStyles(styles)(HeaderSummary))