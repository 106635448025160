import React from 'react';
import {
    Button,
    TextField,
    withStyles,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    CircularProgress,
    IconButton,
    Link,
    Grid,
    Divider,
    Typography,
    Tabs,
    Tab,
    withMobileDialog
} from '@material-ui/core/';

import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {
    CheckBox as CheckBoxIcon,
    CheckBoxOutlineBlank,
    Close,
    InsertDriveFile,
    AttachFile,
    RemoveCircleOutline
} from '@material-ui/icons';
import i18n from 'i18next';
import Select, { components } from 'react-select';
import ReactSelect from 'react-select/lib/Creatable';
import { ConfirmationDialog, CustomMessages } from '../general';
import { SlideUp } from '../general/Transitions'
import Permissions from '../../lib/permissions'


import API from '../../lib/api'

const styles = theme => ({
    customLink: theme.customLink,
    noteForm: {
        display: 'flex',
        flexDirection: 'column'
    },
    noteForm__noteTitle: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    noteForm__noteContent: {},
    noteForm__input: {
    },
    noteForm__select: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        fontFamily: theme.typography.fontFamily
    },
    noteForm__datePicker: {
        width: '30%',
        fontFamily: theme.typography.fontFamily
    },
    noteForm__followUp__datePicker: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2)
    },
    noteForm__divider: {
        margin: `${theme.spacing(2)}px 0`
    },
    noteForm__send_by_email__actions: {
    },
    noteForm__reccipients__select: {
        zIndex: 10,
    },
    noteForm__attachments__select: {
        marginBottom: theme.spacing(2),
        //zIndex: 40
    },
    noteForm__reccipientsCC__select: {
        marginTop: theme.spacing(2),
        zIndex: 8,
    },
    noteForm__reccipientsCCO__select: {
        marginTop: theme.spacing(2),
        zIndex: 5,
    },
    noteForm__users_select: {
        zIndex: 50,
        width: '50%'
    },
    note__delete: {
        textDecoration: 'underline',
        margin: '8px'
    },
    note__actions__cancelBtn: {
        width: '110px'
    },
    noteForm__input__file: {
        display: 'none'
    },
    noteForm__attach__button: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing()
    },
    buttonSpinner: theme.buttonSpinner,
    button__wrapper: {
        margin: theme.spacing(),
        position: 'relative'
    },
    noteForm__closeWindow: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(),
    },
    notesForm__loading__container: {
        position: 'relative',
        height: '50px',
        width: '50px',
        margin: 'auto'
    },
    tab__container: {
        padding: theme.spacing(2)
    },
    dialog__buttons__container__root: {
        padding: 0,
        justifyContent: 'space-between'
    },
    dialog__paper: {
        width: '100%',
        minHeight: '512px'
    },

})

const customStyles = {
    control: base => ({
        ...base,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        boxShadow: 'none',
        fontFamily: 'inherit',
        '&:hover': {
            borderColor: '#000',
        }
    }),
}


class NoteForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedTab: props.note ? (props.note.sendByEmail ? 2 : props.note.isTask ? 1 : 0) : 0,
            fileNoteSendByEmail: props.note ? props.note.sendByEmail ? true : false : false,
            fileNoteIsTask: (props.onlyTask ? true : (props.note ? props.note.isTask ? true : false : false)),
            taskFollowUpUser: '',
            attachButtonLabels: [],
            privateNote: props.note ? props.note.private ? true : false : true,
            fileNoteTitle: props.note ? props.note.title : '',
            fileNoteContent: props.note ? decodeURI(props.note.content) : '',
            recipients: props.note ? props.note.emailRecipients : [],
            recipientsCC: props.note ? props.note.emailRecipientsCC : [],
            recipientsBCC: props.note ? props.note.emailRecipientsBCC : [],
            defaultRecipients: [],
            authorUserId: props.note ? props.note.author.id : null,
            activeAttachments: false,
            selectedTaskDate: props.note && props.note.taskDateTime ? props.note.taskDateTime : new Date(),
            attachments: [],
            validateForm: false,
            saving: false,
            apiErrorMessage: null,
            openConfirmDelete: false,
            note: props.note,
            file: props.file || null,
            fileId: props.fileId || null,
            showCC: false,
            showBCC: false,
            filesAttachments: []
        };
    }

    componentWillMount = () => {
        const { file, fileId } = this.props;
        if (file && !fileId) {
            this.fecthUsers();
        }
    }


    fecthUsers = () => {
        const { file, note } = this.props;
        API.Users.getUsers().then(res => {
            var users = res.data.records.users.map((ele, i) => {
                return { value: ele.userId, label: ele.userName, email: ele.userEmail, active: ele.userActive }
            })

            let newRecipients = []
            if (note && note.emailRecipients) {
                let nR = note.emailRecipients.split(',')
                newRecipients = nR.map((r, i) => {
                    let label = file && file.client && file.client.contact && file.client.contact.email === r.trim() ? `${file.client.contact.firstName} ${file.client.contact.lastName}` : r
                    return { value: r, label: label }
                })

                this.setState({
                    recipients: newRecipients
                })

            }

            if (file && file.client && file.client.contact) {
                let elem = {
                    value: file.client.contact.email,
                    label: `${file.client.contact.firstName} ${file.client.contact.lastName}`
                }

                this.setState({
                    defaultRecipients: [elem],
                })
            }

            this.setState({
                users: users.filter(u => u.active === 1),
                taskFollowUpUser: note && note.taskFollowUpUser.id ? users.find(i => i.value === note.taskFollowUpUser.id) : ''
            })
        })
    }

    formatEmailRecipients = (note, file) => {

        if (!note) return

        let newRecipients = [],
            newRecipientsCC = [],
            newRecipientsBCC = [];

        if (note.emailRecipients) {
            let nR = note.emailRecipients.split(',')
            newRecipients = nR.map((r, i) => {
                let label = file && file.client && file.client.contact && file.client.contact.email === r.trim() ? `${file.client.contact.firstName} ${file.client.contact.lastName}` : r
                return { value: r, label: label }
            })
        }

        if (note.emailRecipientsCC) {
            let nR = note.emailRecipientsCC.split(',')
            newRecipientsCC = nR.map((r, i) => {
                let label = file && file.client && file.client.contact && file.client.contact.email === r.trim() ? `${file.client.contact.firstName} ${file.client.contact.lastName}` : r
                return { value: r, label: label }
            })
        }


        if (note.emailRecipientsBCC) {
            let nR = note.emailRecipientsBCC.split(',')
            newRecipientsBCC = nR.map((r, i) => {
                let label = file && file.client && file.client.contact && file.client.contact.email === r.trim() ? `${file.client.contact.firstName} ${file.client.contact.lastName}` : r
                return { value: r, label: label }
            })
        }

        this.setState({
            recipients: newRecipients,
            recipientsCC: newRecipientsCC,
            recipientsBCC: newRecipientsBCC
        })
    }

    componentWillReceiveProps = (nextProps) => {
        this.formatEmailRecipients(nextProps.note, nextProps.file)

        let subject = null,
            content = null,
            vendorRFQ = null;

        if (nextProps.selectedAttachment) {
            this.setState({
                attachments: [nextProps.selectedAttachment]
            })

            subject = `${i18n.t('emails.subject')}`;

            switch (nextProps.selectedAttachment.label) {
                case 'Quote':
                case 'Proforma':
                    subject = subject + `File #${nextProps.file.id} - Reference ${nextProps.file.requestForQuote.reference}`
                    content = `Estimado Sr(a) ${nextProps.file.client.contact.firstName} ${nextProps.file.client.contact.lastName}, \n \n \nA continuación le hacemos llegar la cotización / proforma solicitada. \n\n\n\nReferencia Prodek: ${nextProps.file.id} \nReferencia ${nextProps.file.client.name}: ${nextProps.file.requestForQuote.reference} \n\n\n\n\nAtentamente,\n\n\n`

                    break;
                case 'PO Confirmation':
                    subject = subject + `Order Confirmation #${nextProps.file.id} - PO #${nextProps.file.clientQuote[0].clientPO.poNumber}`
                    content = `Estimado Sr(a) ${nextProps.file.client.contact.firstName} ${nextProps.file.client.contact.lastName}, \n \n \nA continuación, le hacemos llegar SU CONFIRMACION de O/C ${nextProps.file.clientQuote[0].clientPO.poNumber} - FAVOR REVISAR CON DETENIMIENTO. \nCualquier modificación o comentario deberá ser enviado en un LAPSO DE 24 HORAS a partir de este correo electrónico. Después de 24 horas, la orden será considerada como “ACEPTADA Y CONFORME” y no podrá ser modificada sin retrasar la fecha estimada para su entrega, según se indicara en nuestra propuesta inicial. \n\nLA FECHA ESTIMADA DE ENTREGA SE LES INFORMARA CUANDO FABRICA NOS LA CONFIRME. – \n\n\n¡GRACIAS POR SU COMPRA...!  \n\n\nReferencia Prodek: ${nextProps.file.id} \nReferencia ${nextProps.file.client.name}: ${nextProps.file.requestForQuote.reference} \n\nNotas:  \n\n\n\n\nAtentamente,\n\n\n`

                    break;
                default:
                    switch (nextProps.selectedAttachment.label.split('-')[0].trim()) {
                        case "Invoice":
                        case "Packing List":
                            subject = subject + `Invoice #${nextProps.selectedAttachment.label.split('-')[1].trim()} - PO #${nextProps.file.clientQuote[0].clientPO.poNumber}`
                            content = `Estimado Sr(a) ${nextProps.file.client.contact.firstName} ${nextProps.file.client.contact.lastName}, \n \n \nA continuación le adjuntamos copia de nuestra factura #${nextProps.selectedAttachment.label.split('-')[1].trim()} y su lista de empaque respectiva, correspondiente a su Orden de Compra #${nextProps.file.clientQuote[0].clientPO.poNumber} \nNotificamos entrega a su embarcador asignado XXXXXX el día xx/xx/xxxx \n\n\n\nReferencia Prodek: ${nextProps.file.id} \nReferencia: O/C-${nextProps.file.clientQuote[0].clientPO.poNumber} \n\nNotas: \n\n\n\n\n\n\n\n\n\n\n\nAtentamente,\n\n\n`

                            break;
                        case "Vendor RFQ":
                            vendorRFQ = nextProps.file.vendorQuotes.find(v => `${v.id}` === nextProps.selectedAttachment.label.split('-')[1].trim())
                            subject = subject + `RFQ #${nextProps.selectedAttachment.label.split('-')[1].trim()}`
                            content = `Dear ${vendorRFQ.vendor.name} / ${vendorRFQ.contact.firstName} ${vendorRFQ.contact.lastName}, \n\n\nPlease quote the items in the attached document, indicating FOB price, estimated weight and delivery time. \nWe appreciate your prompt response indicating validity of quotation. \n\n\nProdek References: RFQ ${nextProps.file.id} \nNotes: \n   - Please quote in USD \n\n\n\n\nSincerely,\n\n\n`
                            break;
                        case "Vendor PO":
                            vendorRFQ = nextProps.file.vendorQuotes.find(v => `${v.id}` === nextProps.selectedAttachment.label.split('-')[1].trim())
                            subject = subject + `PURCHASE ORDER #${nextProps.selectedAttachment.label.split('-')[1].trim()} - Reference ${nextProps.selectedAttachment.label.split('-')[1].trim()}`
                            content = `Dear ${vendorRFQ.vendor.name} / ${vendorRFQ.contact.firstName} ${vendorRFQ.contact.lastName}, \n\n\nAttached please find our Purchase order number: PO-${nextProps.selectedAttachment.label.split('-')[1].trim()}\nPlease confirm delivery date and terms for payment. \n\n\nNotes \nIF ANY OF THE SHIPPING PACKING INVOLVES WOODEN CRATES OR PALLETS, THEY MUST BE MADE OF CERTIFIED TREATED WOOD AND MUST INCLUDE THE CERTIFICATE OF COMPLIANCE. \nOTHERWISE PLEASE LET US KNOW ASAP. \n\nALL BOXES MUST BE MARKED AS FOLLOWS: \n\nPRODEK, INC \nP.O.#  PO-${nextProps.selectedAttachment.label.split('-')[1].trim()} \n\n\nPLEASE PROVIDE US WITH THE FOLLOWING: \n   - HARMONIZE CODE (SCHEDULE B OR TARIFF NUMBER) \n   - CONTRY OF ORIGIN \n   - COUNTRY OF MANUFACTURE \n\n\nNOTE: UNMARKED BOXES WILL BE RETURNED AT THE VENDOR’S EXPENSE \n\n\n\n\nSincerely, \n\n\n\n\n`
                            break;
                        default:
                            break;
                    }
                    break;
            }

        }

        if (nextProps.isPrinting) {
            let label = '',
                newRecipients = []

            if (nextProps.vendorQuote) {
                label = `${nextProps.vendorQuote.contact.firstName} ${nextProps.vendorQuote.contact.lastName}`
                newRecipients = [{ value: nextProps.vendorQuote.contact.email.trim(), label: label }]
            } else {
                label = `${nextProps.file.client.contact.firstName} ${nextProps.file.client.contact.lastName}`
                newRecipients = nextProps.file.client.contact.email ? { value: nextProps.file.client.contact.email.trim(), label: label } : {}
            }
            this.setState({
                recipients: newRecipients
            })
        }

        const { users } = this.state;

        this.setState({
            file: nextProps.file,
            fileId: nextProps.fileId || null,
            note: nextProps.note,
            fileNoteSendByEmail: nextProps.note ? nextProps.note.sendByEmail ? true : false : false,
            // fileNoteIsTask: nextProps.note ? nextProps.note.isTask ? true : false : false,
            fileNoteIsTask: (nextProps.onlyTask ? true : (nextProps.note ? nextProps.note.isTask ? true : false : false)),
            privateNote: nextProps.note ? nextProps.note.private ? true : false : true,
            fileNoteTitle: nextProps.isPrinting && subject ? subject : nextProps.note ? nextProps.note.title : '',
            fileNoteContent: nextProps.isPrinting && content ? decodeURI(content) : nextProps.note ? decodeURI(nextProps.note.content) : '',
            authorUserId: nextProps.note ? nextProps.note.author.id : null,
            taskFollowUpUser: nextProps.note && nextProps.note.taskFollowUpUser.id ? users.find(i => i.value === nextProps.note.taskFollowUpUser.id) : '',
            selectedTaskDate: nextProps.note && nextProps.note.taskDateTime ? new Date(nextProps.note.taskDateTime) : new Date(),
            selectedTab: nextProps.note ? (nextProps.note.sendByEmail ? 2 : nextProps.note.isTask ? 1 : 0) : 0,
        })

        if (nextProps.section) {
            this.setState({
                selectedTab: nextProps.section,
                fileNoteIsTask: nextProps.section === 1 ? true : false,
                fileNoteSendByEmail: nextProps.section === 2 ? true : false,
            })
        }

        if (nextProps.sendByEmail) {
            this.setState({
                fileNoteSendByEmail: true
            })
        }
        if (nextProps.emailAddress) {
            this.setState({
                recipients: [nextProps.emailAddress]
            })
        }
    }


    checkFile = () => {
        const { file, fileId } = this.state;

        this.setState({
            filesAttachments: []
        })

        if (!file && fileId) {
            this.fetchFile(fileId);
            this.fecthUsers();
        } else {
            this.getRecipientsOptions()
        }
    }

    fetchFile = (fileId) => {
        if (fileId && !this.state.fetching) {
            this.setState({
                loading: true,
                file: null,
                fetching: true
            }, () => {
                API.Files.getFile({
                    fileId
                }).then(res => {
                    if (res.file) {
                        this.setState({
                            fileId,
                            file: res.file,
                            loading: false,
                            fetching: false,
                        }, _ => {
                            this.getRecipientsOptions();
                        })
                    }
                })
            })
        }
    }


    handleChange = name => e => {
        if (name === 'fileNoteIsTask' && e.target.checked) {
            this.setState({
                privateNote: true
            })
        }
        this.setState({
            [name]: e.target.checked
        });
    };

    handleChangeAttachments = (value) => {
        this.setState({
            attachments: value
        })
    };

    handleChangeRecipients = (value) => {
        this.setState({
            recipients: value
        })
    };

    handleChangeRecipientsCC = (value) => {
        this.setState({
            recipientsCC: value
        })
    }

    handleChangeRecipientsCCO = (value) => {
        this.setState({
            recipientsBCC: value
        })
    }

    handleChangeAgentSelect = (taskFollowUpUser) => {
        this.setState({
            taskFollowUpUser: taskFollowUpUser
        })
    }

    handleDateChange = date => {
        this.setState({ selectedTaskDate: date });
    }

    handleChooseFile = (e) => {
        e.stopPropagation()

        const { attachButtonLabels } = this.state;

        let attachButtonLabelsNew = [],
            attachButtonLabelsCopy = [...attachButtonLabels],
            files = e.target.files;

        if (files.length > 1) {
            attachButtonLabelsNew = Object.keys(files).map((key, i) => {
                return files[key].name
            })
        } else {
            attachButtonLabelsNew = [e.target.value.split("\\").pop()]
        }

        this.setState({
            filesAttachments: files,
            attachButtonLabels: attachButtonLabelsCopy.concat(attachButtonLabelsNew)
        })
    }

    customAttachmentOption = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input type="checkbox" checked={props.isSelected} onChange={() => null} />
                    <label style={{ marginLeft: '5px' }}>{props.label}</label>
                </components.Option>
            </div>
        )
    }

    customContactOption = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <label style={{ marginLeft: '5px' }}>{props.label}</label>
                </components.Option>
            </div>
        )
    }

    customMultiValue = props => {
        return (
            <components.MultiValue {...props}>
                <span>{props.data.label}</span>
            </components.MultiValue>
        )
    }

    validateNoteForm = () => {
        this.setState({
            validateForm: true
        })
        const { fileNoteTitle,
            fileNoteContent,
            fileNoteIsTask,
            fileNoteSendByEmail,
            taskFollowUpUser,
            recipients } = this.state;

        if (fileNoteIsTask && taskFollowUpUser === '') return false

        if (fileNoteSendByEmail && recipients.length === 0) return false

        if (fileNoteTitle === '' || fileNoteContent === '') return false

        return true
    }

    confirmDeleteNote = () => {
        this.setState({
            openConfirmDelete: !this.state.openConfirmDelete
        })
    }

    deleteNote = () => {
        const //{ file } = this.props,
            { file, note } = this.state;

        this.setState({
            saving: true
        })

        API.Notes.deleteNote({ fileId: file.id, noteId: note.id })
            .then(res => {
                if (res.serverStatus === 2) {
                    this.setState({
                        openConfirmDelete: false,
                        saving: false
                    })

                    this.props.deleteNote && this.props.deleteNote(note.id)

                } else {
                    this.setState({
                        saving: false,
                        openConfirmDelete: false,
                        apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                    })
                }
            })
    }

    handleNote = () => {
        if (this.validateNoteForm()) {
            this.setState({
                validateForm: false,
                saving: true
            })

            const { vendorQuoteId } = this.props,
                {
                    file,
                    fileNoteSendByEmail,
                    fileNoteIsTask,
                    taskFollowUpUser,
                    privateNote,
                    attachments,
                    recipients,
                    fileNoteContent,
                    fileNoteTitle,
                    recipientsCC,
                    recipientsBCC,
                    note,
                    filesAttachments,
                    selectedTaskDate } = this.state;


            let formatRecipients = recipients && recipients.length ? recipients.map((r) => {
                return r.value
            }).join(",") : recipients && recipients.value ? recipients.value : null,
                formatRecipientsCC = recipientsCC ? recipientsCC.map((r) => {
                    return r.value
                }).join(",") : null,
                formatRecipientsBCC = recipientsBCC ? recipientsBCC.map((r) => {
                    return r.value
                }).join(",") : null,
                clientQuoteDocs = attachments ? attachments.filter((a) => {
                    return (`${a.value}`).indexOf('client:') >= 0;
                }).map(a => {
                    let args = a.value.split(':');
                    return {
                        invoiceId: args.length === 3 ? args[1] : null,
                        documentTypes: [
                            (args.length === 3 ? args[2] : args[1]),
                        ]
                    }
                }) : [],//}).map(a => (a.value)) : [],
                vendorQuotesDocs = attachments ? attachments.filter((a) => {
                    return (`${a.value}`).indexOf('vendor:') >= 0;
                }).map(a => {
                    return {
                        vendorQuoteId: a.value.split(':')[1],
                        documentTypes: [
                            a.value.split(':')[2],
                        ]
                    }
                }) : [],
                formatAttachments = clientQuoteDocs.length > 0 || vendorQuotesDocs.length > 0 ? {
                    clientQuoteDocs,
                    vendorQuotesDocs
                } : null;

            let body = {
                id: note ? note.id : null,
                taskDateTime: fileNoteIsTask ? moment(selectedTaskDate).format('YYYY-MM-DD hh:mm:ss') : null,
                title: fileNoteTitle,
                sendByEmail: fileNoteSendByEmail,
                emailRecipients: formatRecipients,
                emailRecipientsCC: formatRecipientsCC,
                emailRecipientsBCC: formatRecipientsBCC,
                emailAttachments: formatAttachments,
                content: encodeURI(fileNoteContent),
                isTask: fileNoteIsTask,
                taskFollowUpUserId: taskFollowUpUser.value,
                isPrivate: privateNote
            }
            if (vendorQuoteId) {
                body.vendorQuoteId = vendorQuoteId
            }

            if (note) {
                API.Notes.updateNote({ fileId: file.id, note: body, filesAttachments }).then(res => {
                    if (res.id) {
                        this.setState({
                            saving: false
                        })
                        this.props.updateNote && this.props.updateNote(res)
                    } else {
                        this.setState({
                            saving: false,
                            apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                        })
                    }
                })
            } else {
                API.Notes.createNote({ fileId: file.id, note: body, filesAttachments }).then(res => {
                    if (res.id) {
                        this.setState({
                            saving: false
                        })
                        this.props.addNewNote && this.props.addNewNote(res)
                    } else {
                        this.setState({
                            saving: false,
                            apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                        })
                    }
                })
            }
        }
    }

    deleteAttachment = (index) => {
        const { attachButtonLabels, filesAttachments } = this.state;

        let attachments = [...attachButtonLabels],
            newFilesAttachments = [...filesAttachments];

        attachments.splice(index, 1);
        newFilesAttachments.splice(index, 1);

        this.setState({
            filesAttachments: newFilesAttachments,
            attachButtonLabels: attachments
        })
    }

    close = () => {
        this.setState({
            validateForm: false,
            selectedTab: 0,
            fileNoteSendByEmail: false,
            fileNoteIsTask: false,
            taskFollowUpUser: '',
            attachButtonLabels: [],
            privateNote: true,
            fileNoteTitle: '',
            fileNoteContent: '',
            recipients: [],
            recipientsCC: [],
            recipientsBCC: [],
            authorUserId: null,
            activeAttachments: false,
            selectedTaskDate: new Date(),
            attachments: [],
            saving: false,
            apiErrorMessage: null,
            openConfirmDelete: false,
            showCC: false,
            showBCC: false

        }, () => {
            this.props.handleClose();
        })
    }

    getRecipientsOptions = () => {
        const { file } = this.state;
        if (!file) return []

        API.Files.getFileRecipients({ fileId: file.id })
            .then(res => {
                if (res.contacts) {
                    let clientContacts = [{
                        ...res.contacts.client,
                        options: res.contacts.client.options.map(option => {
                            return {
                                label: `${option.clientContactFirstName} ${option.clientContactLastName}`,
                                value: option.clientContactEmail
                            }
                        }).sort((a, b) => {
                            return a.label - b.label;
                        })
                    }],
                        vendorsContacts = res.contacts.vendors.map(contact => {
                            return {
                                ...contact,
                                options: contact.options.map(option => {
                                    return {
                                        label: `${option.vendorContactFirstName} ${option.vendorContactLastName}`,
                                        value: option.vendorContactEmail
                                    }
                                }).sort((a, b) => {
                                    return a.label - b.label;
                                })
                            }
                        })

                    let response = Permissions.checkSensitiveInformationPermission() ? clientContacts.concat(vendorsContacts) : clientContacts

                    this.setState({
                        defaultRecipients: response
                    })
                } else {
                    this.setState({
                        saving: false,
                        apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`,
                        defaultRecipients: []

                    })
                    return []
                }
            })
    }

    getAttachmentsOptions = () => {
        const { file } = this.state;
        let availableClientDocs = [], availableVendorDocs = [];

        switch (file.status) {
            case 2:
            case 3:
                availableVendorDocs.push({
                    label: i18n.t('printing.vendorDocs.rfq'),
                    value: 6//'vendorrfq'
                })
                if (file.status === 3 && file.clientQuote.length > 0) {
                    let items = file.clientQuote[0] ? file.clientQuote[0].items : [],
                        creatingClientQuote = items.filter(item => item.amount).length === 0 && file.clientQuote[0] && !file.clientQuote[0].validFor && !file.clientQuote[0].deliveryDays;
                    if (!creatingClientQuote) {
                        availableClientDocs.push({
                            label: i18n.t('printing.clientDocs.quote'),
                            value: 'quote'
                        })
                    }
                }
                break;
            case 4:
                if (Permissions.checkSensitiveInformationPermission()) {
                    availableVendorDocs.push({
                        label: i18n.t('printing.vendorDocs.rfq'),
                        value: 6//'vendorrfq'
                    })
                }
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.quote'),
                    value: `client:${1}`//'quote'
                })
                break
            case 5:
                if (Permissions.checkSensitiveInformationPermission()) {
                    availableVendorDocs.push({
                        label: i18n.t('printing.vendorDocs.rfq'),
                        value: 6//'vendorrfq'
                    })
                    availableVendorDocs.push({
                        label: i18n.t('printing.vendorDocs.po'),
                        value: 7//'vendorpo'
                    })
                }
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.quote'),
                    value: `client:${1}`//'quote'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.po'),
                    value: `client:${2}`//'po'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.proforma'),
                    value: `client:${3}`//'proform'
                })

                break;
            case 6:
                if (Permissions.checkSensitiveInformationPermission()) {
                    availableVendorDocs.push({
                        label: i18n.t('printing.vendorDocs.rfq'),
                        value: 6//'vendorrfq'
                    })
                }
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.quote'),
                    value: `client:${1}`//'quote'
                })
                break;
            case 7:
                if (Permissions.checkSensitiveInformationPermission()) {
                    availableVendorDocs.push({
                        label: i18n.t('printing.vendorDocs.rfq'),
                        value: 6//'vendorrfq'
                    })
                    availableVendorDocs.push({
                        label: i18n.t('printing.vendorDocs.po'),
                        value: 7//'vendorpo'
                    })
                }
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.quote'),
                    value: `client:${1}`//'quote'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.po'),
                    value: `client:${2}`//'po'
                })
                availableClientDocs.push({
                    label: i18n.t('printing.clientDocs.proforma'),
                    value: `client:${3}`//'proform'
                })
                break;
            default:
                break;
        }

        file.invoices.forEach(invoice => {
            availableClientDocs.push({
                label: `${i18n.t('printing.clientDocs.invoice')} - ${invoice.id}`,
                value: `client:${invoice.id}:4`
            })
            availableClientDocs.push({
                label: `${i18n.t('printing.clientDocs.packingList')} - ${invoice.id}`,
                value: `client:${invoice.id}:5`
            })
        })

        let groupedAvailableAttachments = [
            { label: 'Client', options: availableClientDocs },
        ];


        if (file.vendorQuotes.length > 0 && Permissions.checkSensitiveInformationPermission()) {
            file.vendorQuotes.forEach(vq => {
                groupedAvailableAttachments.push({
                    label: `${i18n.t('printing.vendorDocsList')} - ${vq.id} - ${vq.vendor.name}`,
                    options: availableVendorDocs.map(option => ({
                        label: `${option.label} - ${vq.id}`,
                        value: `vendor:${vq.id}:${option.value}`
                    }))
                })
            })
        }

        return groupedAvailableAttachments
    }

    render() {
        const { selectedTab,
            fileNoteIsTask,
            taskFollowUpUser,
            defaultRecipients,
            apiErrorMessage,
            privateNote,
            authorUserId,
            attachments,
            recipients,
            users,
            fileNoteContent,
            fileNoteTitle,
            recipientsCC,
            recipientsBCC,
            selectedTaskDate,
            attachButtonLabels,
            validateForm,
            openConfirmDelete,
            saving,
            note,
            file,
            showCC,
            showBCC
        } = this.state,
            {
                classes,
                user,
                width,
                theme,
                vendorQuoteId,
                onlyTask
            } = this.props;

        return (
            <div>
                <Dialog
                    fullScreen={width === 'xs' ? true : false}
                    maxWidth={'sm'}
                    fullWidth={true}
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    onClose={this.close}
                    onExit={this.close}
                    scroll='paper'
                    onEnter={this.checkFile}
                    aria-labelledby="notes_form"
                    open={this.props.open}
                    TransitionComponent={SlideUp}
                >
                    <DialogTitle id="notes_form" disableTypography={true}>
                        {file ? `${i18n.t("notes.form.file")} ${file.id} / ` : ''} {!vendorQuoteId && file && file.client && file.client.id ? `${file.client.name} / ` : ''} {vendorQuoteId || (note && note.vendorQuoteId) ? `${i18n.t("notes.form.vendorQuote")} ${vendorQuoteId ? vendorQuoteId : note ? note.vendorQuoteId : ''} / ` : ''} {i18n.t("notes.form.title")}
                        <IconButton color="inherit"
                            onClick={this.close}
                            aria-label="Close" className={classes.noteForm__closeWindow}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>

                        {!note && (
                            <>
                                <Tabs color='secondary'
                                    value={selectedTab}>
                                    <Tab label={i18n.t('notes.tabs.note')}
                                        disabled={note && note.sendByEmail ? true : false}
                                        onClick={() => {
                                            this.setState({
                                                selectedTab: 0,
                                                fileNoteIsTask: false,
                                                fileNoteSendByEmail: false
                                            })
                                        }} />
                                    <Tab label={i18n.t('notes.tabs.task')}
                                        disabled={note && note.sendByEmail ? true : false}
                                        onClick={() => {
                                            this.setState({
                                                selectedTab: 1,
                                                fileNoteIsTask: true,
                                                fileNoteSendByEmail: false
                                            })
                                        }} />
                                    <Tab label={i18n.t('notes.tabs.email')}
                                        onClick={() => {
                                            this.setState({
                                                selectedTab: 2,
                                                fileNoteIsTask: false,
                                                fileNoteSendByEmail: true
                                            })
                                        }} />
                                </Tabs>
                                <Divider color='secondary' />
                            </>
                        )}
                        {/* NOTA */}
                        {selectedTab === 0 && (
                            <div className={classes.tab__container}>
                                <form className={classes.noteForm} autoComplete="off">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            {!onlyTask && (
                                                <FormGroup row>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                icon={<CheckBoxOutlineBlank style={{ fontSize: '15px' }} />}
                                                                checkedIcon={<CheckBoxIcon style={{ fontSize: '15px' }} />}
                                                                checked={privateNote}
                                                                disabled={saving || fileNoteIsTask}
                                                                onChange={this.handleChange('privateNote')}
                                                                value="privateNote"
                                                                color="primary"
                                                            />
                                                        }
                                                        label={i18n.t("notes.form.privateNote")}
                                                    />
                                                </FormGroup>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField autoComplete='off' required
                                                disabled={saving}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    className: classes.noteForm__input
                                                }}
                                                error={validateForm && fileNoteTitle === '' ? true : false}
                                                className={classes.noteForm__noteTitle}
                                                id="NoteTitle"
                                                type="text"
                                                onChange={(e) => {
                                                    this.setState({
                                                        fileNoteTitle: e.target.value
                                                    })
                                                }}
                                                defaultValue={fileNoteTitle}
                                                variant="outlined"
                                                label={i18n.t("notes.form.noteTitle")}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField autoComplete='off' required
                                                disabled={saving}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    className: classes.noteForm__input
                                                }}
                                                onChange={(e) => {
                                                    this.setState({
                                                        fileNoteContent: e.target.value
                                                    })
                                                }}
                                                error={validateForm && fileNoteContent === '' ? true : false}
                                                className={classes.noteForm__noteContent}
                                                multiline
                                                fullWidth
                                                rows="4"
                                                type="text"
                                                id="content"
                                                variant="outlined"
                                                defaultValue={fileNoteContent}
                                                label={i18n.t("notes.form.content")}
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        )}
                        {/* TASK */}
                        {selectedTab === 1 && (
                            <div className={classes.tab__container}>
                                <form className={classes.noteForm} autoComplete="off">
                                    <Grid container spacing={2} >
                                        <Grid item xs={12}>
                                            <TextField autoComplete='off' required
                                                disabled={saving}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    className: classes.noteForm__input
                                                }}
                                                error={validateForm && fileNoteTitle === '' ? true : false}
                                                className={classes.noteForm__noteTitle}
                                                id="NoteTitle"
                                                type="text"
                                                onChange={(e) => {
                                                    this.setState({
                                                        fileNoteTitle: e.target.value
                                                    })
                                                }}
                                                defaultValue={fileNoteTitle}
                                                variant="outlined"
                                                label={i18n.t("notes.form.noteTitle")}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField autoComplete='off' required
                                                disabled={saving}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    className: classes.noteForm__input
                                                }}
                                                onChange={(e) => {
                                                    this.setState({
                                                        fileNoteContent: e.target.value
                                                    })
                                                }}
                                                error={validateForm && fileNoteContent === '' ? true : false}
                                                className={classes.noteForm__noteContent}
                                                multiline
                                                fullWidth
                                                rows="4"
                                                type="text"
                                                id="content"
                                                variant="outlined"
                                                defaultValue={fileNoteContent}
                                                label={i18n.t("notes.form.content")}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}>
                                                <Select
                                                    required
                                                    disabled={saving}
                                                    value={taskFollowUpUser}
                                                    error={validateForm && (fileNoteIsTask && taskFollowUpUser === '') ? true : false}
                                                    className={`${classes.noteForm__select} ${classes.noteForm__users_select}`}
                                                    placeholder={i18n.t("notes.form.assignTo")}
                                                    onChange={this.handleChangeAgentSelect}
                                                    options={users}
                                                    maxMenuHeight='150'
                                                    styles={{
                                                        ...customStyles,
                                                        control: base => ({ ...base, borderColor: validateForm && taskFollowUpUser === '' ? '#DB5461' : 'rgba(0, 0, 0, 0.23)', }),
                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                    }}
                                                    menuPosition='fixed'
                                                    menuPlacement='auto'
                                                />

                                                <div className={classes.noteForm__followUp__datePicker}>
                                                    <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                                        <InlineDatePicker
                                                            disabled={saving}
                                                            variant="outlined"
                                                            minDate={note ? new Date(new Date().getFullYear(), 0, 1) : new Date()}
                                                            label={i18n.t("notes.form.followUp")}
                                                            value={selectedTaskDate}
                                                            format="MMM DD YYYY"
                                                            className={classes.noteForm__datePicker}
                                                            styles={customStyles}
                                                            onChange={this.handleDateChange}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        )}

                        {/* EMAIL */}
                        {selectedTab === 2 && (
                            <div className={classes.tab__container}>

                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className={classes.noteForm__send_by_email__actions}>
                                            <Grid container alignItems='center' spacing={0}>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={1} alignItems='baseline'>
                                                        <Grid item xs={10}>
                                                            {!note ? (
                                                                <ReactSelect
                                                                    isDisabled={note || defaultRecipients.length === 0 ? true : saving}
                                                                    className={`${classes.noteForm__select} ${classes.noteForm__reccipients__select}`}
                                                                    isMulti
                                                                    fullWidth
                                                                    name="recipients"
                                                                    value={recipients}
                                                                    placeholder={i18n.t("notes.form.recipients")}
                                                                    components={{
                                                                        Option: this.customContactOption,
                                                                        MultiValue: this.customMultiValue
                                                                    }}
                                                                    styles={{ ...customStyles, menuPortal: base => ({ ...base, zIndex: 1000 }) }}
                                                                    onChange={this.handleChangeRecipients}
                                                                    options={defaultRecipients}
                                                                    closeMenuOnSelect={false}
                                                                    hideSelectedOptions={false}
                                                                    menuPosition='fixed'
                                                                    menuPlacement='auto'
                                                                />
                                                            ) : (
                                                                    <>
                                                                        <Typography><i>{i18n.t('notes.to')}</i> {note.emailRecipients} </Typography>
                                                                        {note.emailRecipientsCC && (
                                                                            <Typography><i>{i18n.t('notes.cc')}</i> {note.emailRecipientsCC} </Typography>
                                                                        )}
                                                                        {note.emailRecipientsBCC && (
                                                                            <Typography><i>{i18n.t('notes.bcc')}</i> {note.emailRecipientsBCC} </Typography>
                                                                        )}
                                                                    </>
                                                                )}

                                                        </Grid>
                                                        {!showCC && !note && (
                                                            <Grid item xs={1} >
                                                                <Typography
                                                                    variant='body2'
                                                                    color='textSecondary' className={classes.customLink}
                                                                    disabled={note ? true : saving}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            showCC: true
                                                                        })
                                                                    }}>
                                                                    {i18n.t("notes.form.recipientsCC")}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                        {!showBCC && !note && (
                                                            <Grid item xs={1}>
                                                                <Typography
                                                                    variant='body2'
                                                                    color='textSecondary' className={classes.customLink}
                                                                    disabled={note ? true : saving}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            showBCC: true
                                                                        })
                                                                    }}>
                                                                    {i18n.t("notes.form.recipientsBCC")}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>

                                                {showCC && !note && (
                                                    <Grid item xs={10}>
                                                        <ReactSelect
                                                            isDisabled={note ? true : saving}
                                                            className={`${classes.noteForm__select} ${classes.noteForm__reccipientsCC__select}`}
                                                            isMulti
                                                            name="recipientsCC"
                                                            value={recipientsCC}
                                                            placeholder={i18n.t("notes.form.recipientsCC")}
                                                            components={{
                                                                Option: this.customContactOption,
                                                                MultiValue: this.customMultiValue
                                                            }}
                                                            options={defaultRecipients}
                                                            styles={{ ...customStyles, menuPortal: base => ({ ...base, zIndex: 1000 }) }}
                                                            onChange={this.handleChangeRecipientsCC}
                                                            menuPosition='fixed'
                                                            menuPlacement='auto'
                                                        />
                                                    </Grid>
                                                )}
                                                {showBCC && !note && (
                                                    <Grid item xs={10}>
                                                        <ReactSelect
                                                            isDisabled={note ? true : saving}
                                                            className={`${classes.noteForm__select} ${classes.noteForm__reccipientsCCO__select}`}
                                                            isMulti
                                                            name="recipientsBCC"
                                                            value={recipientsBCC}
                                                            placeholder={i18n.t("notes.form.recipientsBCC")}
                                                            options={defaultRecipients}
                                                            components={{
                                                                Option: this.customContactOption,
                                                                MultiValue: this.customMultiValue
                                                            }}
                                                            onChange={this.handleChangeRecipientsCCO}
                                                            styles={{ ...customStyles, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            menuPosition='fixed'
                                                            menuPlacement='auto'
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {!note ? (
                                            <TextField autoComplete='off' required
                                                disabled={note ? true : saving}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    className: classes.noteForm__input
                                                }}
                                                error={validateForm && fileNoteTitle === '' ? true : false}
                                                className={classes.noteForm__noteTitle}
                                                id="NoteTitle"
                                                type="text"
                                                onChange={(e) => {
                                                    this.setState({
                                                        fileNoteTitle: e.target.value
                                                    })
                                                }}
                                                defaultValue={fileNoteTitle}
                                                variant="outlined"
                                                label={i18n.t("notes.form.subject")}
                                            />
                                        ) : (
                                                <Typography><strong><i>{i18n.t('notes.form.subject')}</i> {fileNoteTitle}</strong> </Typography>
                                            )}

                                    </Grid>

                                    <Grid item xs={12}>
                                        {!note ? (
                                            <TextField autoComplete='off' required
                                                disabled={note ? true : saving}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    className: classes.noteForm__input
                                                }}
                                                onChange={(e) => {
                                                    this.setState({
                                                        fileNoteContent: e.target.value
                                                    })
                                                }}
                                                error={validateForm && fileNoteContent === '' ? true : false}
                                                className={classes.noteForm__noteContent}
                                                multiline
                                                fullWidth
                                                rows="4"
                                                type="text"
                                                id="content"
                                                variant="outlined"
                                                defaultValue={fileNoteContent}
                                                label={i18n.t("notes.form.body")}
                                            />
                                        ) : (
                                                <Typography>{fileNoteContent}</Typography>
                                            )}
                                    </Grid>

                                </Grid>
                            </div>
                        )}

                        {/* ATTACHMENTS */}
                        <div className={classes.tab__container}>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {file && file.status !== 1 && !note &&
                                        <ReactSelect
                                            isDisabled={note ? true : saving}
                                            className={`${classes.noteForm__select} ${classes.noteForm__attachments__select}`}
                                            isMulti
                                            components={{
                                                Option: this.customAttachmentOption,
                                                MultiValue: this.customMultiValue
                                            }}
                                            options={this.getAttachmentsOptions()}
                                            name="attachments"
                                            value={attachments}
                                            isSearchable={false}
                                            placeholder={i18n.t("notes.form.chooseAttachments")}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            onChange={this.handleChangeAttachments}
                                            styles={{
                                                ...customStyles,
                                                menuPortal: base => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                    fontFamily: theme.typography.fontFamily
                                                })
                                            }}
                                            menuPortalTarget={document.body}
                                            menuPosition='fixed'
                                            menuPlacement='auto'
                                        />
                                    }
                                    {note && note.documents.length > 0 && (
                                        <Grid container spacing={2} style={{ marginTop: 16 }}>
                                            <Grid item xs={12}>
                                                <Divider />
                                                <Typography variant='body2'>{i18n.t("notes.attachments")}</Typography>
                                            </Grid>

                                            {note.documents.map(doc => (
                                                <Grid key={doc.id} item xs={6} sm={4}>
                                                    <a key={doc.id} href={doc.url} target='_blank' rel="noopener noreferrer">
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                            <InsertDriveFile color='secondary' className={classes.notes__list__edit__btn__icon} />
                                                            <Typography key={doc.id} variant='body2' noWrap> {doc.name}</Typography>
                                                        </div>
                                                    </a>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </div>

                        {(!note || (note && !note.sendByEmail)) && (
                            <div className={classes.tab__container} style={{ paddingTop: '0' }}>
                                <label htmlFor="text-button-file">
                                    <Link
                                        variant="body2"
                                        color="secondary"
                                        className={classes.noteForm__attach__button}>
                                        <AttachFile style={{ fontSize: 20 }} />
                                        {i18n.t("notes.form.attachments.uploadFile")}
                                    </Link>
                                </label>
                                <input
                                    disabled={saving}
                                    accept="image/*, application/pdf, .gif, .jpg, .png, .doc, .txt, .rtf"
                                    className={classes.noteForm__input__file}
                                    id="text-button-file"
                                    multiple
                                    type="file"
                                    onChange={this.handleChooseFile}
                                />
                                <Grid container spacing={1}>
                                    {attachButtonLabels.map((label, i) => {
                                        return (
                                            <Grid item xs={6} key={i}>
                                                <Typography style={{ display: 'flex', alignItems: 'center' }} noWrap>
                                                    <RemoveCircleOutline onClick={() => this.deleteAttachment(i)}
                                                        style={{ color: 'red', fontSize: '1.2em', marginRight: '4px' }} />
                                                    {label}
                                                </Typography>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </div>
                        )}


                        <CustomMessages
                            type={'error'}
                            message={apiErrorMessage}
                            clearMessage={() => this.setState({
                                apiErrorMessage: null
                            })}
                        />

                    </DialogContent>
                    <DialogActions
                        classes={{
                            root: classes.dialog__buttons__container__root
                        }}>
                        {(user && parseInt(user.attributes['custom:userID']) === authorUserId) && !note.sendByEmail ?
                            <Link className={classes.note__delete}
                                disabled={saving}
                                component='button'
                                variant="body2"
                                color={saving ? "inherit" : "error"}
                                onClick={this.confirmDeleteNote}
                            >
                                <strong>{i18n.t("notes.form.deleteNote")}</strong>
                            </Link>
                            :
                            <div />
                        }
                        {(!note || (note && !note.sendByEmail)) && (
                            <div className={classes.button__wrapper}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={saving}
                                    onClick={!note ? this.handleNote : note.sendByEmail ? this.close : this.handleNote}>
                                    {selectedTab === 2 ? i18n.t("notes.form.sendEmail") : !note ? i18n.t("notes.form.createBtn") : note.sendByEmail ? i18n.t("notes.form.closeBtn") : i18n.t("notes.form.createBtn")}
                                </Button>
                                {saving && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                            </div>
                        )}
                    </DialogActions>
                </Dialog>


                <ConfirmationDialog
                    handleClose={this.confirmDeleteNote}
                    onConfirm={this.deleteNote}
                    loading={saving}
                    message='Are you sure you wish to delete this note?'
                    cancelLabel='No!'
                    confirmLabel='Yes, Delete It'
                    open={openConfirmDelete}
                />
            </div>
        );
    }
}

export default withMobileDialog()(withStyles(styles, { withTheme: true })(NoteForm));
