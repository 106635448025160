import React, { Component } from 'react'
import moment from "moment";
import ReactTable from "react-table";
import {
    TextField,
    Typography,
    Hidden,
    List,
    ListItem,
    ListItemText,
    Paper,
    Divider,
    Link,
    Select,
    Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import i18n from 'i18next';
import classNames from 'classnames'

const styles = theme => {
    return ({
        flexCell: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        table: {
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily
        },
        list__fileId: {
            fontWeight: 'bold',
        },
        list__fileDate: {

        },
        list__file__lastUpdate__container: {
            position: 'absolute', bottom: 0, right: 0
        },
        list__file__lastUpdate: {
            backgroundColor: theme.palette.grey[100],
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.grey[400]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--late': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.orange[60]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.orange[30],
            color: theme.palette.text.secondary,
        },
        'list__file__lastUpdate--veryLate': {
            padding: `0 ${theme.spacing() / 2}px`,
            border: `solid thin ${theme.palette.red[100]}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '9pt',
            backgroundColor: theme.palette.red[50],
            color: theme.palette.getContrastText(theme.palette.red[50]),
        },
        list__fileName: {

        },
        list__fileReference: {

        },
        list__fileClient: {
            fontWeight: 'bold',
        },
        list__fileAuthor: {
            color: theme.palette.naval.main,
        },
        rfq__table__fileLink: {
            color: theme.palette.blue[100],
            cursor: 'pointer'
        },
        rfq__warning__icon: {
            marginRight: theme.spacing(),
            fontSize: '16pt',
            color: theme.palette.orange[100],
        },
        rfq__noItems: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.orange[80],
            borderRadius: theme.spacing() / 2,
            width: '80px',
            padding: '0 5px'
        },
        rfq__table__fileDatesContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        rfq__table__fileDescriptions: {
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        oportunity__probability: {
            border: `solid thin ${theme.palette.secondary.main}`,
            borderRadius: theme.spacing() / 2,
            fontSize: '8pt',
            color: theme.palette.text.secondary,
            padding: theme.spacing() / 2,
            textAlign: 'center',
            width: '80px'
        },
        'oportunity__probability--unknow': {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.getContrastText(theme.palette.grey[100])
        },
        'oportunity__probability--likely': {
            backgroundColor: theme.palette.seabook.main,
            color: theme.palette.getContrastText(theme.palette.seabook.main)
        },
        'oportunity__probability--verylikely': {
            backgroundColor: theme.palette.green[100],
            color: theme.palette.getContrastText(theme.palette.green[100])
        },
        list__fileDetails: {
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        list__countryContainer: {
            position: 'absolute',

            [theme.breakpoints.up('sm')]: {
                right: 0,
                top: 0,
            },
            [theme.breakpoints.down('sm')]: {
                right: theme.spacing(),
                bottom: 0
            }

        },
        list__fileCountry: { fontSize: '9pt' },
        country__select: {
            width: '100%'
        },
        search__title: {
            display: 'flex',
            marginLeft: '8px',
            marginBottom: '8px',
            marginTop: theme.spacing(3)
        },
        statusName__label:{
            border: '1px solid #ccc',
            padding: '1px 8px'
        }
    })
};


class ClientRFQSearchResponse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: this.props.files
        }
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            files: nextProps.files
        })
    }


    handleChangePage = (event, page) => {
        this.setState({ page });
    }

    handleChangeRowsPerPage = event => {
        this.setState({
            rowsPerPage: event.target.value
        });
    }

    quoteDateFormat = (dt) => {
        return moment(dt).format('MMM Do, YYYY')
    }

    render = () => {
        const { classes } = this.props,
            { files } = this.props;

        if(!files) return null

        let countries = files.map(r => r.client.country.code).filter((x, i, a) => a.indexOf(x) === i);

        return (
            <>
                {files && files.length > 0 && (
                    <div>
                        <Grid xs={12} sm={10} item className={classes.search__title}>
                            <Typography variant="h6">{i18n.t("search.titleResults")}</Typography>
                        </Grid>
                        <Hidden xsDown implementation="css">
                            <ReactTable
                                data={files}
                                columns={[

                                    {
                                        Header: i18n.t("quotes.requestedByClient.table.dateFile"),
                                        id: 'file',
                                        width: 150,
                                        filterable: true,
                                        style: {
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            minHeight: '80px'
                                        },
                                        accessor: row => (
                                            <div className={classes.rfq__table__fileDescriptions}>
                                                <Link
                                                    variant="body2"
                                                    className={classes.rfq__table__fileLink}
                                                    onClick={() => {
                                                        this.setState({
                                                            newRow: null
                                                        })
                                                        this.props.handleShowQuote(row)
                                                    }
                                                    }
                                                >
                                                    <strong>{i18n.t("quotes.requestedByClient.table.file")} {`${row.id}`}</strong>
                                                </Link>



                                                <div className={classes.rfq__table__fileDatesContainer}>
                                                    <Typography
                                                        variant='body2'
                                                        color='textSecondary'>
                                                        {this.quoteDateFormat(row.createDateTime)}
                                                    </Typography>
                                                </div>
                                                <div className={classes.list__file__lastUpdate__container}>
                                                    <Typography
                                                        className={moment().diff(moment(row.lastUpdateDateTime), 'days') < 2 ?
                                                            classes.list__file__lastUpdate : (moment().diff(moment(row.lastUpdateDateTime), 'days') < 4 ?
                                                                classes['list__file__lastUpdate--late'] :
                                                                classes['list__file__lastUpdate--veryLate'])} >
                                                        {moment(row.lastUpdateDateTime).fromNow(true)}
                                                    </Typography>
                                                </div>
                                            </div>),
                                        Filter: ({ onChange }) => (
                                            <div className={classes.flexCell}>
                                                <TextField
                                                    id='txtFile'
                                                    style={{ flex: 1 }}
                                                    onChange={(e) => onChange(e.target.value)}
                                                />
                                            </div>
                                        ),
                                        filterMethod: (filter, row) => {
                                            let { value } = filter;
                                            return `${row._original.id}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 || `${this.quoteDateFormat(row._original.createDateTime)}`.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                        }
                                    },

                                    {
                                        Header: i18n.t("quotes.requestedByClient.table.clientNameRef"),
                                        filterable: true,
                                        id: 'quoteClientName',
                                        accessor: row => (
                                            <div className={classes.list__fileDetails}>
                                                <Typography className={classes.list__fileClient} noWrap>{row.client.name}</Typography>
                                                <Typography className={classes.list__fileName}>{`${row.requestForQuote.name}`}</Typography>
                                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                    { row.po.poNumber ?
                                                        <Typography className={classes.list__fileName}>{`PO# ${row.po.poNumber}`}</Typography>
                                                    :
                                                        <Typography className={classes.list__fileName}>{`Ref ${row.requestForQuote.reference}`}</Typography>
                                                    }    
                                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                                        { row.po.poNumber && row.po.statusName ?
                                                            <Typography color='textSecondary' variant='body2' className={classes.statusName__label}>{row.po.statusName === 'Ready' ? "PO ready to deliver" : `PO - ${row.po.statusName}`}</Typography>
                                                        :
                                                            <Typography className={classes.statusName__label}>{`${row.statusName}`}</Typography>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ),
                                        style: {
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        },
                                        Filter: ({ filter, onChange }) => (
                                            <div className={classes.flexCell}>
                                                <TextField
                                                    id='txtQuoteClientName'
                                                    style={{ flex: 1 }}
                                                    onChange={(e) => onChange(e.target.value)}
                                                />
                                            </div>
                                        ),
                                        filterMethod: (filter, row) => {
                                            let { value } = filter;
                                            return (`${row._original.client.name}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                                `${row._original.requestForQuote.reference}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
                                                `${row._original.requestForQuote.name}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 || (
                                                row._original.po.clientPONumber ? `${row._original.po.clientPONumber}`.toLowerCase().indexOf(value.toLowerCase()) >= 0 : false))
                                        }
                                    },
                                    {
                                        Header: i18n.t("quotes.requestedByClient.table.country"),
                                        filterable: true,
                                        style: {
                                            display: 'flex',
                                            alignItems: "center"
                                        },
                                        id: 'country',
                                        width: 60,
                                        accessor: row => (
                                            <Typography className={classes.list__fileCountry} color='textSecondary' noWrap>{row.client.country.code}</Typography>
                                        ),
                                        Filter: ({ filter, onChange }) => (
                                            <div className={classes.flexCell}>
                                                <Select
                                                    disabled={!countries || (countries && countries.length <= 0)}
                                                    native
                                                    fullwidth="true"
                                                    onChange={event => onChange(event.target.value)}
                                                    style={{ width: '100%', minWidth: '55px' }}
                                                    classes={{
                                                        select: classes.country__select
                                                    }}
                                                    value={filter ? filter.value : "all"}
                                                >
                                                    <option value="all">All</option>
                                                    {countries && countries.map(country => (
                                                        <option key={country} value={country}>{country}</option>
                                                    ))}
                                                </Select>
                                            </div>
                                        ),
                                        filterMethod: (filter, row) => {
                                            let { value } = filter;
                                            return value.toLowerCase() === 'all' ? true : (row._original.client.country.code.toLowerCase() === value.toLowerCase())

                                        }
                                    },
                                    {
                                        Header: i18n.t("quotes.quoted.table.column3"),
                                        filterable: true,
                                        id: 'user_Author',
                                        accessor: row => {
                                            return (
                                                <div>
                                                    <Typography className={classes.list__fileAuthor}>{row.salesPerson.userName}</Typography>

                                                    {row.salesRepresentative && row.salesRepresentative.name && (
                                                        <Typography color='textSecondary'>{row.salesRepresentative.name}</Typography>
                                                    )}
                                                </div>)

                                        },
                                        width: 150,
                                        style: {
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        },
                                        filterMethod: (filter, row) => {
                                            let { value } = filter;
                                            return (`${row._original.salesPerson.userName}`.toLowerCase().includes(value.toLowerCase()) || (
                                                row._original.salesRepresentative.name ? `${row._original.salesRepresentative.name}`.toLowerCase().includes(value.toLowerCase()) : false))
                                        }
                                    }
                                ]}
                                pageSize={files && files.length >= 50 ? 50 : files.length}
                                className={classNames(classes.table, ' -striped -highlight')}
                                showPaginationTop={files && files.length >= 50 ? true : false}
                                showPaginationBottom={files && files.length >= 50 ? true : false}
                                sortable={false}
                                resizable={false}
                            />
                        </Hidden>
                        <Hidden smUp implementation="css">
                            <Paper>
                                <List>
                                    {files.map((row, i) => {
                                        //let usersListed = row.salesRepresentative ? [row.salesRepresentative.id] : [];
                                        return (
                                            <div key={`${row.id}-${i}`}>
                                                <ListItem key={row.id} >
                                                    <ListItemText>
                                                        <div className={classes.rfq__table__fileDescriptions}>
                                                            <Link
                                                                variant="body2"
                                                                className={classes.rfq__table__fileLink}
                                                                onClick={() => this.props.handleShowQuote(row.id)}
                                                            >
                                                                <strong>{i18n.t("quotes.requestedByClient.table.file")} {`${row.id}`}</strong>
                                                            </Link>
                                                            <div className={classes.rfq__table__fileDatesContainer}>
                                                                <Typography
                                                                    variant='body2'
                                                                    color='textSecondary'>
                                                                    {this.quoteDateFormat(row.createDateTime)}
                                                                </Typography>
                                                                <Typography className={moment().diff(moment(row.lastUpdateDateTime), 'days') < 2 ?
                                                                    classes.list__file__lastUpdate : (moment().diff(moment(row.lastUpdateDateTime), 'days') < 4 ?
                                                                        classes['list__file__lastUpdate--late'] : classes['list__file__lastUpdate--veryLate'])} >
                                                                    {moment(row.lastUpdateDateTime).fromNow(true)}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div>
                                                            <Typography className={classes.list__fileClient} noWrap>{row.client.name}</Typography>
                                                            <Typography className={classes.list__fileName}>{`${row.requestForQuote.name}`}</Typography>
                                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                                { row.po.poNumber ?
                                                                    <Typography className={classes.list__fileName}>{`PO# ${row.po.poNumber}`}</Typography>
                                                                :
                                                                    <Typography className={classes.list__fileName}>{`Ref ${row.requestForQuote.reference}`}</Typography>
                                                                }    
                                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                                    { row.po.poNumber && row.po.statusName ?
                                                                        <Typography color='textSecondary' variant='body2' className={classes.statusName__label}>{row.po.statusName === 'Ready' ? "PO ready to deliver" : `PO - ${row.po.statusName}`}</Typography>
                                                                    :
                                                                        <Typography className={classes.statusName__label}>{`${row.statusName}`}</Typography>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <Typography className={classes.list__fileAuthor}>{row.salesPerson.userName}</Typography>
                                                                {row.salesRepresentative && row.salesRepresentative.name && (
                                                                    <Typography color='textSecondary'>{row.salesRepresentative.name}</Typography>
                                                                )}
                                                            </div>
                                                            <div className={classes.list__countryContainer}>
                                                                <Typography className={classes.list__fileCountry} color='textSecondary' noWrap>{row.client.country.code}</Typography>
                                                            </div>
                                                        </div>
                                                    </ListItemText>
                                                </ListItem>
                                                {i < (files.length - 1) && (
                                                    <Divider />
                                                )}
                                            </div>
                                        )
                                    })
                                    }

                                </List>
                            </Paper>
                        </Hidden>
                    </div>
                )}
            </>
        );

    }
}

export default withStyles(styles)(ClientRFQSearchResponse);