import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
import styled from 'styled-components'
import { EventsCalendar } from '../components/agenda'
import { DashboardPeopleFilter } from '../components/widgets'
import { ManageEvents, EventsList, EventDetails } from '../components/visits/v1'
import {InlineDatePicker, MuiPickersUtilsProvider} from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import auth from '../authentication/auth';
import i18n from 'i18next'
import moment from "moment";
import API from '../lib/api'
import {connect} from 'react-redux'
import {
    withStyles,
    Typography,
    withMobileDialog,
    Grid,
    Button,
    TextField,
    CircularProgress,
    Divider,
    IconButton,
    Hidden
} from '@material-ui/core'
import { 
        Clear,
        Today,
        Search,
        List } from '@material-ui/icons'
import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min.css'

const styles = theme => ({
    visits: {
        padding: '20px',
        margin: 'auto',
        maxWidth: '1100px'
    },
    visits__header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    view_icons: {
        marginRight: theme.spacing.unit,
        fontSize: '10pt',
        color: theme.palette.secondary.main,
    },
    visits_tabs: {
        marginTop: theme.spacing.unit * 2
    },
    visits_calendar_view: {
        padding: theme.spacing.unit * 3,
        [theme.breakpoints.only('xs')]: {
            padding: 0
        }
    },
    visits_list_view: {
        paddingBottom: theme.spacing.unit * 3,
        [theme.breakpoints.only('xs')]: {
            padding: 0
        }
    },
    divider: {
        border: `1px solid ${theme.palette.primary.main}`
    },
    visits__container__titleContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('xs')]: {
            justifyContent: 'flex-start'
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-between'
        },
    },
    createVisit__container: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    visits__search__datePicker: {
        fontFamily: theme.typography.fontFamily
    },
    visits__search_clearIcon: {
        position: 'absolute',
        top: '16px',
        right: '10px',
        cursor: 'pointer'
    },
    buttonSpinner: theme.buttonSpinner,
    events__views: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        margin: '16px 0',
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            marginBottom: theme.spacing.unit * 3
        }
    },
    visit__search__iconButton:{
        padding: '8px'
    },
    customLink: {
        ...theme.customLink,
        color: 'gray',
        display: 'flex'
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
});

const CalendarWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:row;
    @media (max-width: 400px) {
        flex-direction:column;
        padding:10px;
    }
    .users__selector{
        /* max-width:50%; */
        min-width:300px;
        flex:1;
        @media (max-width: 400px) {
            margin-bottom:50px;
        }
    }
    .full__calendar{
        /* max-width:50%; */
        flex:3;
        @media (max-width: 400px) {
            font-size:7pt;
        }
    }
`

const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth() + 1;

const customStyles = {
    control: base => ({
        ...base,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        boxShadow: 'none',

        '&:hover': {
            borderColor: '#000'
        }
    })
}

class Visits extends Component {

    state = {
        events: [],
        loading: false,
        currentTab: 0,
        logedInUser: null,
        openEventDetails: false,
        selectedEvent: null,
        selectedUsersIds: [],
        month: m,
        year: y,
        search: null,
        selectedDateFrom: new Date( y, parseInt(m) - 1 , 1),
        selectedDateTo: new Date(y, parseInt(m), 0),
        searching: false,
    }

    componentWillMount(){
        auth.getAuthenticatedUser().then(user => {
            this.setState({
                logedInUser: parseInt(user.attributes['custom:userID']),
                selectedUsersIds: [parseInt(user.attributes['custom:userID'])]
            })
            this.fetchEvents({
                month: m,
                year: y
            })
        }).catch(err => {
            this.setState({ logedInUser: null })
        })
    }
        
    componentDidMount() {
        this.setState({
            loading: true
        })
    }
    
    componentWillReceiveProps (nextProps) {
        if(nextProps.selectedUsersIds){
            this.setState({
                selectedUsersIds: nextProps.selectedUsersIds
            })
        }
    }

    fetchEvents = (args) => {
        const { month, year, usersIDs, to, from, search} = args;

        this.setState({
            loading: true
        })

        let users = usersIDs ? usersIDs.join(',') : "";
       
        API.Events.getEvents({
            m: month || null,
            y: year || null,
            to: to || null,
            search: search || null,
            from: from || null,
            usersID: users 
        }).then(res => {
            let newEvents = res.data.records && res.data.records.length > 0 ? res.data.records.map(event => {
                return {
                    ...event,
                    start: event.taskDateTime,
                    end: event.taskDateTime,
                }
            }) : [];

            this.setState({
                searching: false,
                loading: false,
                events: newEvents,
                month: month || null,
                year: year || null
            })
        });
    }

    changeTab = (value) => {
        this.setState({
            currentTab: value
        })
    }

    addNewEvent = (event) => {
        const { events } = this.state

        let newEvents = [...events]

        newEvents.unshift(event)

        this.setState({
            events: newEvents
        })
    }

    openEvent=(event)=>{
        this.setState({
            openEventDetails: true,
            selectedEvent: event
        })
    }

    updateEvent=(event)=>{
        const { events } = this.state;

        let newEvents = [...events],
            index = newEvents.findIndex(ev => ev.id === event.id)

        if(index !== null){
            newEvents[index] = event;
        }

        this.setState({
            events: newEvents,
            selectedEvent: event
        })
    }

    deleteEvent=(eventId)=>{
        const { events } = this.state;

        let newEvents = [...events],
            index = newEvents.findIndex(ev => ev.id === eventId);

        newEvents.splice(index, 1) 

        this.setState({
            events: newEvents
        })
    }

    setSelectedUsers = (usersIDs) => {
        this.setState({
            loading: true
        })
        this.fetchEvents({usersIDs, to: date})

        this.setState({
            selectedUsersIds: usersIDs
        })
    }

    handleDateToChange = date => {
        this.setState({ selectedDateTo: date });
    }

    handleDateFromChange = date => {
        this.setState({ selectedDateFrom: date });
    }

    clearDateFrom=(e)=>{
        e.stopPropagation()

        this.setState({
            selectedDateFrom: null
        })
    }

    clearDateTo = (e) => {
        e.stopPropagation()

        this.setState({
            selectedDateTo: null
        })
    }

    search=()=>{
        const { search, selectedDateFrom, selectedUsersIds,
                selectedDateTo } = this.state;

        this.setState({
            searching: true
        })

        this.fetchEvents({
            to: selectedDateTo ? moment(selectedDateTo).format('YYYY-MM-DD') : null,
            from: selectedDateFrom ? moment(selectedDateFrom).format('YYYY-MM-DD') : null,
            search: search && search !== '' ? search : null,
            month: null,
            year: null,
            usersID: selectedUsersIds
        })
    }

    render() {
        const { loading, selectedUsersIds, openEventDetails, 
                selectedEvent, events, selectedDateFrom,
                currentTab, month, year, selectedDateTo,
                searching, search, logedInUser } = this.state,
             { classes } = this.props;

        return (
            <div>
                <Helmet title={`${i18n.t('companyName')} | ${i18n.t('pageSubTitle.dashboard')}`} />
                
                <div className={classes.visits}>
                    <Grid container spacing={0} justify='space-between' style={{paddingLeft: '8px'}}>
                        <Grid xs={9} item className={classes.visits__container__titleContainer}>
                            <Typography style={{marginRight: '16px'}} variant="h5">{i18n.t("visits.title")}</Typography>
                        </Grid>

                        <Grid item xs={3} className={classes.createVisit__container}>
                            <ManageEvents 
                                addNewEvent={this.addNewEvent} 
                            />
                        </Grid>
                    </Grid>

                    <Hidden xsDown implementation="css">
                        <Grid container spacing={1} style={{ display: 'flex', marginTop: '24px'}}>
                            <Grid item xs={12} sm={4}>
                                <TextField autoComplete='off' fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.input
                                    }}
                                    value={search || ''}
                                    onChange={(e)=>{
                                        this.setState({
                                            search: e.target.value
                                        })
                                    }}
                                    className={classes.textField}
                                    id="title"
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t("visits.search")}
                                />
                            </Grid>
                            
                            { currentTab === 0 && <>
                                <Grid item sm={2} xs={5} style={{ position: 'relative'}}>
                                    <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                        <InlineDatePicker
                                            format="MMM DD YYYY"
                                            variant="outlined"
                                            label={i18n.t("visits.dateFrom")}
                                            value={selectedDateFrom}
                                            className={classes.visits__search__datePicker}
                                            styles={customStyles}
                                            emptyLabel=" "
                                            onChange={this.handleDateFromChange}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <div className={classes.visits__search_clearIcon}>
                                        <Clear style={{color:'#ccc', fontSize: '16px'}} onClick={this.clearDateFrom}/>
                                    </div>
                                </Grid>

                                <Grid item sm={2} xs={5} style={{position: 'relative'}}>
                                    <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                        <InlineDatePicker
                                            format="MMM DD YYYY"
                                            variant="outlined"
                                            label={i18n.t("search.dateTo")}
                                            value={selectedDateTo}
                                            className={classes.visits__search__datePicker}
                                            styles={customStyles}
                                            emptyLabel=" "
                                            minDate={selectedDateFrom ? selectedDateFrom : ''}
                                            onChange={this.handleDateToChange}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <div className={classes.visits__search_clearIcon}>
                                        <Clear style={{ color: '#ccc', fontSize: '16px'}} onClick={this.clearDateTo}/>
                                    </div>
                                </Grid>
                            </>}
                            <Grid item sm={1} xs={2} style={{display: 'flex'}}>
                                <IconButton color="secondary" className={classes.visit__search__iconButton}
                                    disabled={searching || (search === '' && !selectedDateFrom && !selectedDateTo)}
                                    onClick={this.search}>
                                    <Search />
                                    {searching && <CircularProgress color='secondary' className={classes.buttonSpinner} size={24} />}
                                </IconButton>
                            </Grid>
                        </Grid>
                        
                        <Divider style={{margin: '16px 0'}}/>
                    </Hidden>

                    <Hidden smUp implementation="css">
                        <Grid container spacing={1} style={{ display: 'flex', margin: '24px 0', width: '100%'}}>
                            <Grid item xs={12} sm={12}>
                                <TextField autoComplete='off' fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.input
                                    }}
                                    value={search || ''}
                                    onChange={(e)=>{
                                        this.setState({
                                            search: e.target.value
                                        })
                                    }}
                                    className={classes.textField}
                                    id="title"
                                    type="text"
                                    variant="outlined"
                                    label={i18n.t("visits.search")}
                                />
                            </Grid>
                        </Grid>
                    </Hidden>
                   

                    <div className={classes.events__views}>
                        <DashboardPeopleFilter
                            selectUsers={this.setSelectedUsers}
                            selectedUsers={selectedUsersIds}
                        />
                        <div style={{marginLeft: '16px'}}>
                            <Button variant="contained" size="small" style={{minWidth: '75px', marginRight: '8px'}} 
                                onClick={()=> currentTab === 0 ? this.changeTab(1) : this.changeTab(0)}
                                className={classes.button} startIcon={ currentTab === 0 ? <Today /> : <List /> }>
                                {currentTab === 0 ? i18n.t('visits.agendaView') : i18n.t('visits.listView')}
                            </Button>
                        </div>
                    </div>

                            
                    {currentTab === 0 && 
                        <div className={classes.visits_list_view}>
                            <EventsList
                                filterByUsers={selectedUsersIds} 
                                fetchEvents={this.fetchEvents}
                                events={events}
                                deleteEvent={this.deleteEvent}
                                updateEvent={this.updateEvent}
                                loading={loading}
                                logedInUser={logedInUser}
                                selectedUsersIds={selectedUsersIds}
                                month={month}
                                year={year}
                            />
                        </div>
                    }


                    <div style={{display: currentTab === 1 ? 'block' : 'none'}} className={classes.visits_calendar_view}>
                        <CalendarWrapper>
                            <div className="full__calendar">
                                <EventsCalendar 
                                    filterByUsers={selectedUsersIds} 
                                    fetchEvents={this.fetchEvents}
                                    events={events.map(event => ({
                                        ...event,
                                        start: new Date(event.from),
                                        end: new Date(event.to)
                                    }))}
                                    fromEvents={true}
                                    loading={loading}
                                    openElement={this.openEvent}
                                />
                            </div>
                        </CalendarWrapper>
                    </div>
                </div>


                <EventDetails
                    event={selectedEvent}
                    open={openEventDetails}
                    updateEvent={this.updateEvent}
                    deleteEvent={this.deleteEvent}
                    handleClose={()=>{
                        this.setState({
                            selectedEvent: null,
                            openEventDetails: false
                        })
                    }}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        people: state.people
    }
}


export default connect(mapStateToProps)(withMobileDialog()(withStyles(styles)(Visits)));
