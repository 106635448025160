import React from 'react';
import {
    Paper,
    Grid,
    TextField,
    Button,
    CircularProgress,
    withStyles,
    Typography,
    Link,
    InputAdornment,
    IconButton,
    FormGroup,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    Switch,
    Snackbar,
    SnackbarContent
} from '@material-ui/core';
import {
    Check,
    Close,
    Visibility,
    VisibilityOff,
    CheckBox as CheckBoxIcon,
    CheckBoxOutlineBlank
} from '@material-ui/icons'
import i18n from 'i18next';
import { Auth } from 'aws-amplify';
import classNames from 'classnames'

const styles = theme => ({
    user__form__paper: {
        ...theme.modal.paper,
        maxWidth: '500px'
    },
    user__form: theme.modal.container,
    user__form__title: theme.modal.title,
    user__form__buttons: theme.modal.buttons,
    user__form__password__show_password_icon: {
        width: '0.9em',
        height: '0.9em'
    },
    user__form__roles__list: {
        paddingTop: 0,
        display: 'flex',
        flexWrap: 'wrap'
    },
    user__form__roles__list__item: {
        height: '30px',
        width: '33%',
        paddingLeft: '10px',
        paddingRight: '5px'
    },
    user__form__roles__list__text: {
        paddingLeft: 0,
        paddingRight: '10px',
        fontSize: '0.8em'
    },
    user__form__user_active_field: {
        margin: 0
    },
    complete: {
        color: 'green',
        fontSize: '17px',
        marginRight: theme.spacing(),
        width: '0.7em',
        height: '0.7em'
    },
    incomplete: {
        color: 'red',
        marginRight: theme.spacing(),
        fontSize: '17px',
        width: '0.7em',
        height: '0.7em'
    },
    input: {
        padding: theme.spacing()
    },
    passwordInput: {
        padding: 0
    },
    rolesProgress: {
        position: 'absolute',
        top: '50%'
    },
    textField: {
        margin: `${theme.spacing()}px 0`
    },
    passwordTextField: {
        maxWidth: '57%'
    },
    success: {
        backgroundColor: theme.palette.green[100],
    },
    error: {
        backgroundColor: theme.palette.error.main,
    },
    errorLabel: {
        color: theme.palette.getContrastText(theme.palette.error.main)
    },
});

class UserForm extends React.Component {

    constructor(props) {
        super(props);
        this.inputs = {};
        this.state = {
            generalError: null,
            loading: false,
            changePassword: false,
            password: '',
            currentPassword: '',
            loadingRoles: false,
            showCurrentPassword: false,
            showPassword: false,
            isActive: props.user ? props.user.userActive ? true : false : true,
            checkedUserRoles: props.user ? props.user.userRoles : [],
            roles: [],
            currentPasswordError: null,
            changePasswordSuccess: null
        };
    }

    validUserArgs = () => {
        const { username, password, email, name } = this.inputs,
            { user } = this.props;

        if (!username || username === '' || !email || email === '' || !name || name === '') {
            this.setState({
                loading: false,
                usernameEmpty: username ? false : true,
                passwordEmpty: password ? false : true,
                emailEmpty: email ? false : true,
                nameEmpty: name ? false : true
            });
            return false;
        }

        if (!this.validateEmail(email)) {
            this.setState({
                generalError: 'Enter a valid email account'
            });
            return false
        }

        if (!user && !password && password === '') {
            return false
        }

        if (password && password !== '' && !this.validatePassword(password)) {
            return false
        }

        this.setState({
            loading: true
        });

        return true
    }

    manageUser = () => {
        const { password, currentPassword } = this.state,
            { allowChangePassword } = this.props;

        if (password && currentPassword && allowChangePassword) {

            if (!this.validatePassword(password)) {
                return
            }

            Auth.currentAuthenticatedUser()
                .then(user => {
                    return Auth.changePassword(user, currentPassword, password);
                }).then(data => {
                    this.setState({
                        currentPasswordError: null,
                        changePassword: false,
                        password: '',
                        currentPassword: '',
                        changePasswordSuccess: 'Your password has been updated'
                    })
                }).catch(err => {
                    if (err.code === "NotAuthorizedException") {
                        this.setState({
                            currentPasswordError: 'Incorrect current password'
                        })
                    }
                });
        }
    }

    validateEmail = (email) => {
        var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validatePassword = (password) => {
        if (password.toUpperCase() !== password && password.toLowerCase() !== password && password.length >= 6) {
            return true
        } else {
            return false
        }
    }

    hasLowerCase = str => {
        return str.toUpperCase() !== str;
    };

    hasUpperCase = str => {
        return str.toLowerCase() !== str;
    };

    onKeyDown = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            this.editUser()
        }
    }

    handleClickShowPassword = () => {
        this.setState(state => ({
            showPassword: !state.showPassword
        }));
    }

    handleClickShowCurrentPassword = () => {
        this.setState(state => ({
            showCurrentPassword: !state.showCurrentPassword
        }));
    }

    changePassword = () => {
        this.setState(state => ({
            changePassword: !state.changePassword
        }));
    }

    isUserActive = (e) => {
        this.setState({
            isActive: e.target.checked
        });
    }

    handleToggleCheckboxes = value => () => {
        const { checkedUserRoles } = this.state;
        const currentIndex = checkedUserRoles.indexOf(value);
        const newChecked = [...checkedUserRoles];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checkedUserRoles: newChecked
        });
    };

    render() {
        const { user, classes, allowChangePassword, roles } = this.props;
        const { isActive, checkedUserRoles, changePassword, generalError,
            usernameEmpty, passwordEmpty, currentPasswordEmpty,
            emailEmpty, nameEmpty, loading, loadingRoles,
            showPassword, showCurrentPassword, currentPasswordError,
            changePasswordSuccess, password, currentPassword
        } = this.state;

        return (
            <Paper className={classes.user__form__paper} tabIndex={-1}>
                <div className={classes.user__form__title}>
                    <Typography color='secondary' variant='h5'>{`${i18n.t('users.formTitle')} ${user ? ` - ${user.userTypeName}` : ''}`}</Typography>
                </div>
                <div className={classes.user__form}>
                    <div>
                        <Grid container spacing={2} style={{}}>
                            <Grid item xs={7}>
                                <TextField autoComplete='off' fullWidth required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.input
                                    }}
                                    className={classes.textField}
                                    onChange={event => {
                                        this.setState({ nameEmpty: false });
                                        this.inputs.name = event.target.value;
                                    }}
                                    id="name"
                                    type="text"
                                    variant="outlined"
                                    defaultValue={user ? user.userName : ''}
                                    error={nameEmpty ? true : false}
                                    label={i18n.t("users.name")}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField autoComplete='off' fullWidth required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.input
                                    }}
                                    className={classes.textField}
                                    onChange={event => {
                                        this.setState({ usernameEmpty: false });
                                        this.inputs.username = event.target.value;
                                    }}
                                    id="username"
                                    type="text"
                                    variant="outlined"
                                    defaultValue={user ? user.userLogin : ''}
                                    error={usernameEmpty ? true : false}
                                    label={i18n.t("users.username")}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <TextField autoComplete='off' fullWidth required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.input
                                    }}
                                    className={classes.textField}
                                    onChange={event => {
                                        this.setState({ emailEmpty: false });
                                        this.inputs.email = event.target.value;
                                    }}
                                    id="email"
                                    type="text"
                                    variant="outlined"
                                    defaultValue={user ? user.userEmail : ''}
                                    error={emailEmpty ? true : false}
                                    label={i18n.t("users.email")}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.input
                                    }}
                                    className={classes.textField}
                                    onChange={event => {
                                        this.inputs.phone_number = event.target.value;
                                    }}
                                    id="phone_number"
                                    type="tel"
                                    variant="outlined"
                                    defaultValue={user ? user.userPhone : ''}
                                    label={i18n.t("users.phoneNumber")}
                                    placeholder="+1 555 555 1212"
                                />
                            </Grid>
                            <FormGroup style={{ marginTop: '-12px', paddingBottom: '5px' }}>
                                <FormControlLabel
                                    className={classes.user__form__user_active_field}
                                    control={
                                        <Switch
                                            checked={isActive}
                                            onChange={this.isUserActive}
                                            value="isActive"
                                            color="primary"
                                        />
                                    }
                                    label={i18n.t("users.userActive")}
                                />
                            </FormGroup>
                        </Grid>

                        <Grid container spacing={1} alignItems="flex-start">
                            <Grid item>
                                <Typography>{i18n.t("users.roles")}</Typography>
                            </Grid>
                            <Grid item>
                                <List className={classes.user__form__roles__list} cols={3}>
                                    {roles && roles.map(role => (
                                        <ListItem className={classes.user__form__roles__list__item}
                                            key={role.roleId}
                                            dense button
                                            onClick={this.handleToggleCheckboxes(role.roleId)}>
                                            <Checkbox
                                                disabled={loadingRoles}
                                                icon={<CheckBoxOutlineBlank style={{ fontSize: '15px' }} />}
                                                checkedIcon={<CheckBoxIcon style={{ fontSize: '15px' }} />}
                                                checked={checkedUserRoles.indexOf(role.roleId) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                            />
                                            <ListItemText className={classes.user__form__roles__list__text} primary={role.roleName} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} alignItems="flex-start" justify="space-between">
                            {user && allowChangePassword &&
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Link style={{ textDecoration: 'underline', marginRight: '5px' }}
                                        component='button'
                                        variant="body2"
                                        color="secondary"
                                        onClick={this.changePassword}
                                    >
                                        {i18n.t("users.changePassword")}
                                    </Link>

                                    <Snackbar
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        open={changePasswordSuccess !== null}
                                        autoHideDuration={6000}
                                        onClose={() => {
                                            this.setState({
                                                changePasswordSuccess: null
                                            });
                                        }} >
                                        <SnackbarContent
                                            className={classes.success}
                                            message={<Typography className={classes.errorLabel}>{changePasswordSuccess}</Typography>} />
                                    </Snackbar>
                                    
                                </Grid>
                            }
                            {(!user || changePassword) &&
                                <Grid item xs={12}>
                                    {changePassword && allowChangePassword &&
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <TextField autoComplete='off' fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                className={classNames(classes.textField, classes.passwordTextField)}
                                                id="currentPassword"
                                                value={currentPassword}
                                                label={i18n.t("users.currentPassword")}
                                                type={showCurrentPassword ? 'text' : 'password'}
                                                error={currentPasswordEmpty || currentPasswordError ? true : false}
                                                onChange={event => {
                                                    this.setState({ currentPasswordEmpty: false, currentPassword: event.target.value });
                                                }}
                                                InputProps={{
                                                    className: classes.passwordInput,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="Toggle password visibility"
                                                                onClick={this.handleClickShowCurrentPassword}
                                                            >
                                                                {showCurrentPassword ? <VisibilityOff className={classes.user__form__password__show_password_icon} /> : <Visibility className={classes.user__form__password__show_password_icon} />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />

                                            <Snackbar
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                open={currentPasswordError !== null}
                                                autoHideDuration={6000}
                                                onClose={() => {
                                                    this.setState({
                                                        currentPasswordError: null
                                                    });
                                                }} >
                                                <SnackbarContent
                                                    className={classes.error}
                                                    message={<Typography className={classes.errorLabel}>{currentPasswordError}</Typography>} />
                                            </Snackbar>
                                        </div>
                                    }
                                    <TextField autoComplete='off' fullWidth required={!user ? true : false}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        className={classNames(classes.textField, classes.passwordTextField)}
                                        error={passwordEmpty ? true : false}
                                        id="password"
                                        value={password}
                                        label={user ? i18n.t("users.newPassword") : i18n.t("users.password")}
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={event => {
                                            this.setState({ passwordEmpty: false, password: event.target.value });
                                        }}
                                        InputProps={{
                                            className: classes.passwordInput,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="Toggle password visibility"
                                                        onClick={this.handleClickShowPassword}
                                                    >
                                                        {showPassword ? <VisibilityOff className={classes.user__form__password__show_password_icon} /> : <Visibility className={classes.user__form__password__show_password_icon} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <div style={{ textAlign: 'left' }}>
                                        <div>
                                            {password && password.length > 6 ? <Check className={classes.complete} /> : <Close className={classes.incomplete} />}
                                            <span style={{ marginRight: '5px', color: password && password.length > 6 ? '#ccc' : '#000', fontSize: '10px' }}>Password length greater than or equal to 6</span>
                                        </div>
                                        <div>
                                            {password && this.hasUpperCase(password) ? <Check className={classes.complete} /> : <Close className={classes.incomplete} />}
                                            <span style={{ marginRight: '5px', color: password && this.hasUpperCase(password) ? '#ccc' : '#000', fontSize: '10px' }}>Password must have uppercase characters</span>
                                        </div>

                                        <div>
                                            {password && this.hasLowerCase(password) ? <Check className={classes.complete} /> : <Close className={classes.incomplete} />}
                                            <span style={{ marginRight: '5px', color: password && this.hasLowerCase(password) ? '#ccc' : '#000', fontSize: '10px' }}>Password must have lowercase characters</span>
                                        </div>
                                    </div>
                                </Grid>
                            }
                        </Grid>

                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={generalError !== null}
                            autoHideDuration={6000}
                            onClose={() => {
                                this.setState({
                                    generalError: null
                                });
                            }} >
                            <SnackbarContent
                                className={classes.error}
                                message={<Typography className={classes.errorLabel}>{generalError}</Typography>} />
                        </Snackbar>

                        {allowChangePassword &&
                            <Grid container spacing={2} alignItems="center" justify="center">
                                <Grid item xs={12}>
                                    <Typography style={{ color: 'red' }}>{i18n.t('users.onlyChangePassword')}</Typography>
                                </Grid>
                            </Grid>
                        }

                    </div>
                    <Grid container justify="center" className={classes.user__form__buttons}>
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={this.props.handleClose}>
                            {i18n.t("users.cancelBtn")}
                        </Button>
                        <div>
                            <Button
                                color="primary"
                                onClick={user && allowChangePassword ? () => this.manageUser() : () => alert(' Not implemented yet')}
                                variant="contained">
                                {user ? i18n.t("users.updateBtn") : i18n.t("users.createBtn")}
                            </Button>
                            {loading &&
                                <CircularProgress  color='secondary' size={24} />
                            }
                        </div>
                    </Grid>
                </div>
            </Paper>
        );
    }
}

export default withStyles(styles)(UserForm);
