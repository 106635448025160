import React, { Component } from 'react'
import {
    Grid,
    withStyles,
    Typography,
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
import i18n from 'i18next';
import Permissions from '../../../lib/permissions'


const styles = theme => {
    return (
        {
            quote__totals__container: {
                marginLeft: theme.spacing(),
                marginRight: theme.spacing(),
            },
            totals__container: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end'
            },
            totals__row: {
                display: 'flex',
                justifyContent: 'space-between',
                width: '200px'
            }
        })
}

class QuoteTotals extends Component {

    state = {
        file: this.props.file,
        items: this.props.items
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            file: nextProps.file,
            items: nextProps.items
        })
    }


    render() {
        const { classes } = this.props,
            { file, items } = this.state;

        if (!file) return null;

        let otherCostTotal = file.clientQuote[0].otherCosts && file.clientQuote[0].otherCosts.length > 0 ? file.clientQuote[0].otherCosts.filter(oc => !oc.weighted).reduce(((total, item) => parseFloat(total) + item.amount), 0) : 0,
            itemsQuotedTotal = (items.length > 0 ?
                items.reduce(((total, item) =>
                    parseFloat(total) + item.quantity * ((item.amount ? item.amount : 0))), 0)
                : 0),
            itemsQuotedClientTotal = (items.length > 0 ?
                items.reduce(((total, item) =>
                    parseFloat(total) + ((item.amount + (item.amount * item.markupPercent / 100) + (item.weightedCost ? (item.weightedCost / item.quantity) : 0)) * item.quantity)
                ), 0)
                : 0),
            itemsQuotedTaxes = (items.length > 0 && file.clientQuote[0].taxPercent ?
                items.filter(item => item.taxPercent).reduce(((total, row) => {
                    let quotedAmount = (row.amount ? row.amount : 0),
                        amountWithProfit = quotedAmount + (quotedAmount * row.markupPercent / 100) + (row.weightedCost ? row.weightedCost / row.quantity : 0);

                    return (total + ((file.clientQuote[0].taxPercent ? parseFloat(file.clientQuote[0].taxPercent) : 0) * amountWithProfit * row.quantity) / 100)
                }), 0)
                : 0),
            total = otherCostTotal + itemsQuotedClientTotal + itemsQuotedTaxes,
            totalProfit = itemsQuotedClientTotal - itemsQuotedTotal;

        return (
            <div>
                {itemsQuotedClientTotal > 0 && (
                    <Grid container spacing={0}>
                        <Grid item xs={12} className={classes.quote__totals__container}>
                            <div className={classes.totals__container}>
                                <div className={classes.totals__row}>
                                    <Typography variant='body1' color='textSecondary'>{i18n.t('quotes.totals.otherCosts')} </Typography>
                                    <Typography variant='body1' color='textPrimary' style={{ marginLeft: '5px' }}>
                                        <NumberFormat value={otherCostTotal}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={file.currencySymbol}
                                            decimalScale={2}
                                            fixedDecimalScale={true} />
                                    </Typography>
                                </div>
                                {Permissions.checkSensitiveInformationPermission() ?
                                    <div className={classes.totals__row}>
                                        <Typography variant='body1' color='textSecondary'>{i18n.t('quotes.totals.quoted')} </Typography>
                                        <Typography variant='body1' color='textPrimary' style={{ marginLeft: '5px' }}>
                                            <NumberFormat value={itemsQuotedTotal}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={file.currencySymbol}
                                                decimalScale={2}
                                                fixedDecimalScale={true} />
                                        </Typography>
                                    </div>
                                    :
                                    <div className={classes.totals__row}>
                                        <Typography variant='body1' color='textSecondary'>{i18n.t('quotes.totals.quoted')} </Typography>
                                        <Typography variant='body1' color='textPrimary' style={{ marginLeft: '5px' }}>
                                            <NumberFormat value={itemsQuotedTotal + totalProfit}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={file.currencySymbol}
                                                decimalScale={2}
                                                fixedDecimalScale={true} />
                                        </Typography>
                                    </div>
                                }

                                {file.clientQuote[0].taxPercent &&
                                    <div className={classes.totals__row}>
                                        <Typography variant='body1' color='textSecondary'>{i18n.t('quotes.totals.taxes')} </Typography>
                                        <Typography variant='body1' color='textPrimary' style={{ marginLeft: '5px' }}>
                                            <NumberFormat value={itemsQuotedTaxes}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={file.currencySymbol}
                                                decimalScale={2}
                                                fixedDecimalScale={true} />
                                        </Typography>
                                    </div>
                                }

                                {Permissions.checkSensitiveInformationPermission() &&
                                    <div className={classes.totals__row}>
                                        <Typography variant='body1' color='textSecondary'>{i18n.t('quotes.totals.profit')} </Typography>
                                        <Typography variant='body1' color='textPrimary' style={{ marginLeft: '5px' }}>
                                            <NumberFormat value={totalProfit}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={file.currencySymbol}
                                                decimalScale={2}
                                                fixedDecimalScale={true} />
                                        </Typography>
                                    </div>
                                }

                                <div className={classes.totals__row}>
                                    <Typography variant='body1' color='textSecondary'><strong>{i18n.t('quotes.totals.total')}</strong></Typography>
                                    <Typography variant='body1' color='textPrimary' style={{ marginLeft: '5px' }}><strong>
                                        <NumberFormat value={total}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={file.currencySymbol}
                                            decimalScale={2}
                                            fixedDecimalScale={true} />
                                    </strong></Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                )}
            </div>
        )
    }
}

export default withStyles(styles)(QuoteTotals);