import React, { Component } from 'react'
import {
    Drawer,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Modal,
    Collapse,
    withMobileDialog
} from '@material-ui/core'
import { UserForm } from './users';
import i18n from 'i18next';
import {
    Dashboard,
    Face,
    Assessment,
    // AccountBalance,
    Assignment,
    Domain,
    LocationCity,
    Description,
    Settings,
    Payment,
    ExpandMore,
    ExpandLess,
    People,
    Bookmark,
    AccountCircle,
    ExitToApp,
    Mail,
    Work,
    PermContactCalendar
} from '@material-ui/icons'
import { ConfirmationDialog } from './general';
import { withRouter } from "react-router";
import auth from '../authentication/auth';
import API from '../lib/api';
import AppVersion from './AppVersion';
import Permissions from '../lib/permissions'

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as peopleActions from '../actions/peopleActions'



class LeftSideBar extends Component {
    state = {
        openConfirmationLogout: false,
        logedInUser: null,
        optionsOpened: false,
        companiesOpened: false,
        profileSectionOpened: false,
        openProfile: false,
        user: null,
        userRoles: null
    }
    componentWillMount = () => {
        this.getUser()
    }

    getUser = () => {
        auth.getAuthenticatedUser()
            .then(user => {
                API.Users.getUserRoles({ userId: user.attributes['custom:userID'] }).then(res => {
                    let userRoles = res.data.records.map((elem, i) => {
                        return elem.roleId
                    })

                    this.setState({
                        logedInUser: user,
                        userRoles: userRoles
                    })
                })
            }).catch(err => {
                this.setState({ logedInUser: null })
            })
    }

    confirmationLogout = () => {
        this.setState({
            openConfirmationLogout: true
        })
    }

    cancelConfirmationLogout = () => {
        this.setState({
            openConfirmationLogout: false
        })

        this.props.toggleOpenMenu()
    }

    logout = () => {
        this.setState({
            openConfirmationLogout: false
        }, () => {
            auth.signOut().then(() => {
                this.props.peopleActions.setFilterPeople([])
                this.props.peopleActions.setPeople([])
                this.props.history.push('/signIn');
            }).catch(err => console.log(err));
        })
    }

    navigate = (path) => {
        const { history, toggleOpenMenu, account, people } = this.props;

        if(account.userId && account.userTypeId === 3){
            this.props.peopleActions.setFilterPeople(people.allPeople)
        } else {
            this.props.peopleActions.setFilterPeople([{ userId: account.userId, userName: account.userName }])
        }

        toggleOpenMenu();
        history.push(path);
    }

    handleOpenProfile = () => {
        const { openProfile, logedInUser } = this.state;

        if (!openProfile) {
            API.Users.getUser({ userId: logedInUser.attributes['custom:userID'] }).then(respond => {
                this.setState({
                    user: respond.data.records[0],
                    openProfile: !openProfile
                })
            })
        } else {
            this.setState({
                user: null,
                openProfile: !openProfile
            })
        }
    }

    render() {
        const { menuVisible, toggleOpenMenu } = this.props,
            { openConfirmationLogout,
                logedInUser,
                userRoles,
                optionsOpened,
                companiesOpened,
                profileSectionOpened,
                openProfile,
                user,
            } = this.state;

        return (
            <div>
                <Drawer open={menuVisible}
                    onClose={toggleOpenMenu}>

                    <List>
                        {Permissions.checkMenuPermission('dashboard') && (
                            <ListItem
                                button onClick={() => this.navigate('/dashboard')}>
                                <ListItemIcon><Dashboard /></ListItemIcon>
                                <ListItemText>{i18n.t("sideBar.dashboard")}</ListItemText>
                            </ListItem>
                        )}
                        {Permissions.checkMenuPermission('visits') && (
                            <ListItem button onClick={() => this.navigate('/visits')}>
                                <ListItemIcon><LocationCity /></ListItemIcon>
                                <ListItemText>{i18n.t("sideBar.visits")}</ListItemText>
                            </ListItem>
                        )}
                        {Permissions.checkMenuPermission('quotes') && (
                            <ListItem button onClick={() => this.navigate('/quotes/rfq')}>
                                <ListItemIcon><Assessment /></ListItemIcon>
                                <ListItemText>{i18n.t("sideBar.quotes")}</ListItemText>
                            </ListItem>
                        )}
                        {Permissions.checkMenuPermission('purchaseOrders') && (
                            <ListItem button onClick={() => this.navigate('/purchaseOrders')}>
                                <ListItemIcon><Description /></ListItemIcon>
                                <ListItemText>{i18n.t("sideBar.purchaseOrders")}</ListItemText>
                            </ListItem>
                        )}
                        {Permissions.checkMenuPermission('invoicing') && (
                            <ListItem button onClick={() => this.navigate('/invoicing')}>
                                <ListItemIcon><Payment /></ListItemIcon>
                                <ListItemText>{i18n.t("sideBar.invoicing")}</ListItemText>
                            </ListItem>
                        )}
                        {Permissions.checkMenuPermission('warehouse') && (
                            <ListItem button onClick={() => this.navigate('/warehouse')}>
                                <ListItemIcon><Domain /></ListItemIcon>
                                <ListItemText>{i18n.t("sideBar.warehouse")}</ListItemText>
                            </ListItem>
                        )}
                        {/* {Permissions.checkMenuPermission('accounting') && (
                            <ListItem button onClick={() => this.navigate('/accounting')}>
                                <ListItemIcon><AccountBalance /></ListItemIcon>
                                <ListItemText>{i18n.t("sideBar.accounting")}</ListItemText>
                            </ListItem>
                        )} */}
                        {Permissions.checkMenuPermission('reports') && (
                            <ListItem button onClick={() => this.navigate('/reports')}>
                                <ListItemIcon><Assignment /></ListItemIcon>
                                <ListItemText>{i18n.t("sideBar.reports")}</ListItemText>
                            </ListItem>
                        )}
                        {Permissions.checkMenuPermission('emails') && (
                            <ListItem button onClick={() => this.navigate('/emails/inbox')}>
                                <ListItemIcon><Mail /></ListItemIcon>
                                <ListItemText>{i18n.t("sideBar.emails")}</ListItemText>
                            </ListItem>
                        )}

                        {Permissions.checkMenuPermission('options') && (
                            <>
                                <Divider />
                                <ListItem button onClick={() => this.setState({ optionsOpened: !optionsOpened })}>
                                    <ListItemIcon>
                                        <Settings />
                                    </ListItemIcon>
                                    <ListItemText>{i18n.t("sideBar.subMenu.options")}</ListItemText>
                                    {optionsOpened ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={optionsOpened} timeout="auto" unmountOnExit >
                                    <List component="div" >

                                        <ListItem button onClick={() => this.setState({ companiesOpened: !companiesOpened })}>
                                            <ListItemIcon><Domain /></ListItemIcon>
                                            <ListItemText>{i18n.t("sideBar.subMenu.companies")}</ListItemText>
                                            {companiesOpened ? <ExpandLess /> : <ExpandMore />}
                                        </ListItem>
                                        <Collapse in={companiesOpened} timeout="auto" unmountOnExit >
                                            <List component="div" >
                                                <ListItem button onClick={() => this.navigate('/companies/clients')}>
                                                    <ListItemIcon><PermContactCalendar/></ListItemIcon>
                                                    <ListItemText>{i18n.t("sideBar.subMenu.clients")}</ListItemText>
                                                </ListItem>
                                            </List>

                                            <List component="div" >
                                                <ListItem button onClick={() => this.navigate('/companies/vendors')}>
                                                    <ListItemIcon><Work/></ListItemIcon>
                                                    <ListItemText>{i18n.t("sideBar.subMenu.vendors")}</ListItemText>
                                                </ListItem>
                                            </List>
                                        </Collapse>

                                        {userRoles && userRoles.includes(3) ?
                                            <ListItem button onClick={() => this.navigate('/users')}>
                                                <ListItemIcon><People /></ListItemIcon>
                                                <ListItemText>{i18n.t("sideBar.subMenu.users")}</ListItemText>
                                            </ListItem>
                                            :
                                            <ListItem button onClick={this.handleOpenProfile}>
                                                <ListItemIcon><AccountCircle /></ListItemIcon>
                                                <ListItemText>{i18n.t("sideBar.subMenu.profile")}</ListItemText>
                                            </ListItem>
                                        }

                                        <ListItem button onClick={() => this.navigate('/others')}>
                                            <ListItemIcon><Bookmark /></ListItemIcon>
                                            <ListItemText>{i18n.t("sideBar.subMenu.others")}</ListItemText>
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </>
                        )}
                        <Divider />
                        {logedInUser && (
                            <>

                                <ListItem button onClick={() => this.setState({ profileSectionOpened: !profileSectionOpened })}>
                                    <ListItemIcon>
                                        <AccountCircle />
                                    </ListItemIcon>
                                    <ListItemText>{logedInUser.attributes.name ? logedInUser.attributes.name : logedInUser.username}</ListItemText>
                                    {profileSectionOpened ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={profileSectionOpened} timeout="auto" unmountOnExit >
                                    <ListItem button onClick={() => {
                                        toggleOpenMenu();
                                        this.handleOpenProfile()
                                    }}>
                                        <ListItemIcon><Face /></ListItemIcon>
                                        <ListItemText>{i18n.t("sideBar.subMenu.profile")}</ListItemText>
                                    </ListItem>
                                    <ListItem button onClick={() => {
                                        toggleOpenMenu();
                                        this.confirmationLogout()
                                    }}>
                                        <ListItemIcon><ExitToApp /></ListItemIcon>
                                        <ListItemText>{i18n.t("sideBar.logout")}</ListItemText>
                                    </ListItem>
                                </Collapse>
                            </>
                        )}
                        <Divider />
                    </List>
                    <List style={{ position: 'absolute', bottom: '0', margin: 'auto' }}>
                        <ListItem>
                            <AppVersion />
                        </ListItem>
                    </List>
                </Drawer>

                <ConfirmationDialog
                    handleClose={this.cancelConfirmationLogout}
                    onConfirm={this.logout}
                    message='Are you sure you wish to sign out?'
                    cancelLabel='Cancel'
                    confirmLabel='Sign Out'
                    open={openConfirmationLogout}
                />

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={openProfile}
                    onClose={this.handleOpenProfile}
                >
                    <UserForm
                        handleClose={this.handleOpenProfile}
                        user={user}
                        allowChangePassword={true}
                    />
                </Modal>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        peopleActions: bindActionCreators(peopleActions, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        account: state.account,
        people: state.people
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withMobileDialog()(LeftSideBar)));