import React, { Component } from 'react'
import {
    Grid,
    TextField,
    withStyles,
    Typography,
    Button,
    Tooltip,
    CircularProgress,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    ClickAwayListener,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'
import { Info, Warning } from '@material-ui/icons';
import i18n from 'i18next';
import MomentUtils from '@date-io/moment';
import { CustomMessages, CustomSelect } from '../../general'
import { GlobalParametersContext } from '../../../contexts/globalParametersContext'
import {
    ClientQuoteItemsList,
    QuoteTotals
} from '.'
import API from '../../../lib/api';
import { OtherCosts, NoteEditDocuments } from '../common'
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import NumberFormatInputCustom from '../../general/NumberFormatInputCustom';
import { SlideUp } from '../../general/Transitions'


const styles = theme => {
    return (
        {
            preparing__form__textField: {
                // marginLeft: theme.spacing(),
            },
            preparing__form__menu: {
                width: "auto",
            },
            preparing__form__gridItem: {
            },
            preparing__form__gridItem__saveBtn: {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginRight: theme.spacing(),
            },
            button__wrapper: {
                margin: theme.spacing(),
                position: 'relative'
            },
            buttonSpinner: theme.buttonSpinner,
            preparing__form__saveButton: {
                marginTop: theme.spacing()
            },
            preparing__form__addButton: {
                marginTop: theme.spacing(2),
                width: theme.spacing() * 10,
                minWidth: theme.spacing() * 10,
            },
            preparing__form__pickerProvider: {
                width: '100%'
            },
            preparing__form__datePicker: {
                marginLeft: theme.spacing(),
                marginTop: theme.spacing(),
                fontFamily: theme.typography.fontFamily
            },
            preparing__items__table: {
                fontSize: theme.typography.fontSize * 0.8,
                fontFamily: theme.typography.fontFamily,
                textAlign: 'left',
                border: `solid thin ${theme.palette.grey[300]}`
            },
            preparing__hader__container__clientName__label: {
                textAlign: 'left',
                color: '#000',
            },
            quoteitems__table__itemPartNumber: {
                color: theme.palette.blue[100]
            }, quoteitems__table__descriptionResumed: {
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: '95%',
                cursor: 'pointer'
            },
            quoteitems__table__descriptionExtended: {
                width: '100%',
                cursor: 'pointer'
            },
            quoteitems__table__quantity: {
                color: theme.palette.text.secondary
            },

            quoteitems__table__textInput: {
                fontSize: theme.typography.fontSize * 0.8,
            },
            quoteitems__table__cell__center: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            quoteitems__table__cell__rigth: {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
            },
            preparing__form__other__cost__buttons: {
                display: 'flex',
                flexDirection: 'column',

            },
            other__costs__add__button: {
                marginLeft: theme.spacing(),
                marginRight: theme.spacing()
            },
            other__costs__title: {
                marginLeft: theme.spacing(),
                marginRight: theme.spacing()
            },
            other__costs__container: {
                marginTop: theme.spacing(),
                marginBottom: theme.spacing()
            },
            other__costs__table__container: {
                padding: 0,
                marginTop: theme.spacing(),
                marginBottom: theme.spacing(),
                marginLeft: theme.spacing(),
                marginRight: theme.spacing()
            },
            preparing__hader__client__name__container: {
                marginLeft: 0,
            },
            preparing__client__information__container: {
                marginLeft: theme.spacing(),
                marginRight: theme.spacing()
            },
            preparing__items__title: {
                marginTop: theme.spacing(),
                marginBottom: theme.spacing(),
                marginLeft: theme.spacing(),
                marginRight: theme.spacing()
            },
            preparing__items__container: {
                marginBottom: theme.spacing(),
                marginLeft: theme.spacing(),
                marginRight: theme.spacing()
            },
            preparing__quote__date: {
                fontFamily: theme.typography.fontFamily,
                fontSize: '1em',
                margin: `${theme.spacing()}px 0 0`,
                width: '100%'
            },
            preparing__quote__date__input: {
                fontSize: 'unset',
            },
            deliveryDaysTooltip__icon: {
                fill: theme.palette.grey[300],
                fontSize: '20px'
            },
            deliveryDaysWarning: {
                backgroundColor: theme.palette.error.main
            },
            vendor__warning__icon: {
                marginRight: theme.spacing(),
                fontSize: '16pt',
                color: theme.palette.orange[100],
            },
            warning__message__icon: {
                marginRight: theme.spacing(),
                fontSize: '14pt',
                color: theme.palette.orange[100],
            },
        })
}

const customStyles = {
    control: base => ({
        ...base,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        boxShadow: 'none',

        '&:hover': {
            borderColor: '#000',
        }
    })
}

const DeliveryDaysTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.grey[300],
        color: 'rgba(0, 0, 0, 0.87)',
        width: 270,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
    popper: {
        zIndex: 1
    }
}))(Tooltip);

class QuoteForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            deliveryDays: null,
            validFor: null,
            vendorQuoteOtherCost: [],
            selectedPaymentTerms: 1,
            selectedQuoteTerms: 1,
            quotedComments: '',
            saving: false,
            items: [],
            quoteDate: new Date(),
            quoteOtherCosts: [],
            quotePaymentSchedules: '',
            apiErrorMessage: null,
            openTooltip: false,
            estimatedDeliveryDays: 0,
            validateForm: false,
            showRequiredFieldsError: false,
            taxPercent: '',
            showAddNote: false,
            showWarningMessage: false
        }
    }

    componentWillMount() {
        this.setValues(this.props)
    }

    componentWillReceiveProps = (nextProps) => {
        this.setValues(nextProps)
    }

    setValues = (props) => {
        let items = [];

        if (props.file.vendorQuotes.length === 1) {
            items = props.file && props.file.clientQuote[0] ?
                props.file.clientQuote[0].items.map((item) => {
                    let vqItem = props.file.vendorQuotes[0] && props.file.vendorQuotes[0].items ? props.file.vendorQuotes[0].items.find(i => i.rfqItem.id === item.rfqItem.id) : null

                    return {
                        ...item,
                        acceptedVendorQuoteItem: {
                            ...item.acceptedVendorQuoteItem,
                            ...vqItem,
                            vendor: props.file.vendorQuotes[0] ? props.file.vendorQuotes[0].vendor : null
                        },
                        include: true,
                        unitAmount: item.unitAmount || ( vqItem && vqItem.unitAmount ? vqItem.unitAmount : ''),
                        markupPercent: item.markupPercent || 0,
                        amount: item.amount || (vqItem && vqItem.unitAmount ? vqItem.unitAmount : ''),
                        vendorQuoteItemId: item.acceptedVendorQuoteItem && item.acceptedVendorQuoteItem.id ? item.acceptedVendorQuoteItem.id : vqItem ? vqItem.id : null
                    }
                }) : [];
        } else {
            items = props.file && props.file.clientQuote[0] ?
                props.file.clientQuote[0].items.map((item) => {
                    return {
                        ...item,
                        include: true,
                        vendorQuoteItemId: item.acceptedVendorQuoteItem && item.acceptedVendorQuoteItem.id ? item.acceptedVendorQuoteItem.id : null
                    }
                }) : [];
        }

        let estimatedDeliveryDays = [],
            itemsAcceptedVendor = items.filter(n => Object.keys(n.acceptedVendorQuoteItem).length !== 0),
            vendorQuotes = itemsAcceptedVendor.map((item, j) => {
                return props.file.vendorQuotes.find((vq) =>
                    vq.items.filter(i => i.id === item.vendorQuoteItemId).length > 0
                ) || {}
            }),
            vendorsOtherCosts = [];

        vendorQuotes = [...new Set(vendorQuotes.map(x => x.id))].map(id => {
            return vendorQuotes.find(vq => vq.id === id)
        })

        vendorQuotes.forEach(vq => {
            if (vq.otherCosts && vq.otherCosts.length) {
                vendorsOtherCosts = vendorsOtherCosts.concat(
                    vq.otherCosts.map(v => {
                        return ({
                            ...v,
                            vendorQuoteId: vq.id,
                            vendorName: vq.vendor.name
                        })
                    }))
            }
            if (vq.deliveryDays && vq.vendor) {
                estimatedDeliveryDays.push({ vendor: vq.vendor.name, deliveryDays: vq.deliveryDays })
            }
            return vq
        })
        this.setState({
            deliveryDays: props.file.clientQuote[0] && props.file.clientQuote[0].deliveryDays ? props.file.clientQuote[0].deliveryDays : null,
            validFor: props.file.clientQuote[0] && props.file.clientQuote[0].validFor ? props.file.clientQuote[0].validFor : null,
            vendorQuoteOtherCost: vendorsOtherCosts,
            selectedPaymentTerms: props.file.clientQuote[0] && props.file.clientQuote[0].paymentTerms && props.file.clientQuote[0].paymentTerms.id ? props.file.clientQuote[0].paymentTerms.id : 1,
            selectedQuoteTerms: props.file.clientQuote[0] && props.file.clientQuote[0].quoteTerms && props.file.clientQuote[0].quoteTerms.id ? props.file.clientQuote[0].quoteTerms.id : 1,
            quotedComments: props.file.clientQuote[0] && props.file.clientQuote[0].comments ? props.file.clientQuote[0].comments : '',
            saving: false,
            applyTax:  props.file.clientQuote[0] && props.file.clientQuote[0].taxPercent ? true : false,
            taxPercent: props.file.clientQuote[0] && props.file.clientQuote[0].taxPercent ? props.file.clientQuote[0].taxPercent : '',
            items,
            quoteDate: props.file.clientQuote[0] && props.file.clientQuote[0].date ? new Date(props.file.clientQuote[0].date) : new Date(),
            quoteOtherCosts: props.file && props.file.clientQuote[0] ? props.file.clientQuote[0].otherCosts : [],
            quotePaymentSchedules: props.file && props.file.clientQuote[0] ? props.file.clientQuote[0].paymentSchedule : [],
            apiErrorMessage: null,
            openTooltip: false,
            estimatedDeliveryDays,
            validateForm: false,
            showRequiredFieldsError: false
        })
    }

    updateItems = (newItems) => {
        const { file } = this.props;

        let itemsAcceptedVendor = newItems.filter(n => Object.keys(n.acceptedVendorQuoteItem).length !== 0);

        let vendorQuotes = itemsAcceptedVendor.map((item, j) => {
            return file.vendorQuotes.find((vq) =>
                vq.items.filter(i => i.id === item.vendorQuoteItemId).length > 0
            ) || {}
        })

        vendorQuotes = [...new Set(vendorQuotes.map(x => x.id))].map(id => {
            return vendorQuotes.find(vq => vq.id === id)
        })

        let vendorsOtherCosts = [],
            estimatedDeliveryDays = [];

        vendorQuotes.forEach(vq => {
            if (vq.otherCosts && vq.otherCosts.length) {
                vendorsOtherCosts = vendorsOtherCosts.concat(
                    vq.otherCosts.map(v => {
                        return ({
                            ...v,
                            vendorQuoteId: vq.id,
                            vendorName: vq.vendor.name
                        })
                    }))
            }
            if (vq.deliveryDays && vq.vendor) {
                estimatedDeliveryDays.push({ vendor: vq.vendor.name, deliveryDays: vq.deliveryDays })
            }
            return vq
        })

        this.setState({
            estimatedDeliveryDays,
            vendorQuoteOtherCost: vendorsOtherCosts,
            items: newItems
        });
        // this.savePreparingQuote();
    }

    calculateTotals = () => {
        const { quoteOtherCosts, items, taxPercent, applyTax } = this.state;

        let otherCostTotal = quoteOtherCosts && quoteOtherCosts.length > 0 ? quoteOtherCosts.filter(oc => (!oc.item || !oc.item.id) && !oc.weighted).reduce(((total, item) => parseFloat(total) + parseFloat(item.amount)), 0) : 0,
            itemsQuotedTotal = (items.length > 0 ?
                items.reduce(((total, item) =>
                    parseFloat(total) + (parseFloat((item.rfqItem.quantity * parseFloat(item.acceptedVendorQuoteItem.unitAmount || 0))))
                ), 0)
                : 0),
            itemsQuotedClientTotal = (items.length > 0 ?
                items.reduce(((total, item) =>
                    parseFloat(total) + (parseFloat((item.rfqItem.quantity * parseFloat(item.amount || 0))))
                ), 0)
                : 0),
            taxesTotal = applyTax && items.length > 0 ? items.filter(i => i.taxPercent).reduce(((total, item) => parseFloat(total) + (parseFloat(item.rfqItem.quantity) * parseFloat(item.amount || 0) * parseFloat(taxPercent || 0) / 100 )  ), 0) : 0 , 
            totalProfit = itemsQuotedClientTotal - itemsQuotedTotal;

        let totals = {
            total: itemsQuotedClientTotal,
            subTotal: itemsQuotedTotal,
            profitTotal: totalProfit,
            otherCostTotal: otherCostTotal,
            taxesTotal
        }

        return totals
    }

    savePreparingQuote = () => {
        const {
            selectedPaymentTerms,
            selectedQuoteTerms,
            items, validFor,
            quoteOtherCosts,
            quotedComments,
            deliveryDays,
            quoteDate,
            taxPercent,
            applyTax,
            quotePaymentSchedules } = this.state,
            { file } = this.props;

        this.setState({
            validateForm: true
        })

        if (!deliveryDays || !validFor || (applyTax && !taxPercent) ) {
            this.setState({
                showRequiredFieldsError: true
            })
            return
        }


        this.setState({
            saving: true,
            validateForm: false,
            showRequiredFieldsError: false
        })

        let body = {
            id: file.clientQuote[0].id,
            paymentTermsId: selectedPaymentTerms,
            paymentSchedule: quotePaymentSchedules,
            quoteTermsId: selectedQuoteTerms,
            deliveryDays,
            validFor: parseInt(validFor),
            taxPercent: applyTax ? taxPercent : null,
            date: quoteDate,
            otherCosts: quoteOtherCosts.map((oc, i) => {
                return {
                    clientQuoteOtherCostId: oc.clientQuoteOtherCostId,
                    otherCostId: oc.id,
                    comments: oc.comments,
                    amount: oc.amount,
                    itemId: oc.item ? oc.item.id : null,
                    weighted: oc.weighted,
                    deleted: oc.deleted
                }
            }),
            comments: quotedComments,
            items: items.map(item => {
                return {
                    ...item,
                    taxPercent: applyTax ? item.taxPercent : 0
                }
            })
        }

        API.Quotes.updateClientQuoted({ body }).then(res => {
            if (res.data) {

                this.setState({
                    saving: false
                })
                let clientQuote = res.data.record.clientQuote,
                    totals = this.calculateTotals();

                clientQuote.total = totals.total
                clientQuote.subTotal = totals.subTotal
                clientQuote.profitTotal = totals.profitTotal
                clientQuote.otherCostTotal = totals.otherCostTotal
                clientQuote.taxesTotal = totals.taxesTotal

                this.props.updateClientQuote(res.data.record.file)
                this.props.onClose()
            } else {
                this.setState({
                    saving: false,
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })

    }

    handleChange = name => event => {
        const { file } = this.props;

        this.setState({ 
            [name]: event.target.checked,
            taxPercent: event.target.checked ? file.clientQuote[0] && file.clientQuote[0].taxPercent ? file.clientQuote[0].taxPercent : "" : "",
        });
    };

    editClientQuote = () => {
        const { deliveryDays, validFor, applyTax, taxPercent } = this.state,
              { file } = this.props;

        this.setState({
            validateForm: true
        })

        if (!deliveryDays || !validFor || (applyTax && !taxPercent)) {
            this.setState({
                showRequiredFieldsError: true
            })
            return
        }

        if (file.status > 4) {
            this.setState({
                validateForm: false,
                showAddNote: true
            })
        } else {
            this.savePreparingQuote()
        }
    }

    closeNote = () => {
        this.setState({
            showAddNote: false,
        })
    }

    render() {
        const { classes, file, creatingClientQuote } = this.props,
            { selectedPaymentTerms,
                selectedQuoteTerms,
                deliveryDays, showAddNote,
                validFor, quoteDate,
                items, saving,
                quotedComments,
                quoteOtherCosts,
                apiErrorMessage,
                openTooltip,
                quotePaymentSchedules,
                vendorQuoteOtherCost,
                estimatedDeliveryDays,
                validateForm,
                showRequiredFieldsError,
                applyTax, taxPercent,
                showWarningMessage
            } = this.state;

        if (!file) return null;


        return (
            <GlobalParametersContext.Consumer>
                {({ paymentTerms, quoteTerms }) => <>
                    <Dialog
                        scroll='paper'
                        open={this.props.open}
                        maxWidth={'md'}
                        fullWidth={true}
                        TransitionComponent={SlideUp}>


                        <DialogTitle disableTypography={true} style={{ zIndex: '2' }}>
                            <Typography variant='body1' color="inherit">
                                {file ? `${i18n.t('quotes.preparing.file')} ${file.id} - ` : ''}{creatingClientQuote ? i18n.t('quotes.preparing.createQuote') : i18n.t('quotes.preparing.editQuote')}
                            </Typography>
                        </DialogTitle>
                        <DialogContent className={classes.clientRFQ__dialog__content}>
                            <div style={{ paddingTop: '16px', position: 'relative' }}>
                                <Grid container spacing={2} style={{ marginLeft: 0, marginTop: '8px' }}>
                                    <Grid item xs={12} sm={4}>
                                        <Grid container item spacing={1}>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <MuiPickersUtilsProvider utils={MomentUtils} className={classes.preparing__form__pickerProvider}>
                                                    <InlineDatePicker
                                                        variant="outlined"
                                                        label={i18n.t('quotes.preparatingQuoteInformation.quoteDate')}
                                                        value={quoteDate}
                                                        format="MMM DD YYYY"
                                                        InputProps={{
                                                            className: classes.preparing__quote__date__input
                                                        }}
                                                        className={classes.preparing__quote__date}
                                                        styles={customStyles}
                                                        onChange={(date) => {
                                                            this.setState({
                                                                quoteDate: date
                                                            })
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid item sm={6} md={6} xs={12}
                                                className={classes.preparing__form__gridItem}
                                            >
                                                <CustomSelect options={quoteTerms ? quoteTerms.map(pt => ({ label: pt.quoteTermsName, value: pt.quoteTermsId })) : []}
                                                    label={i18n.t('quotes.preparatingQuoteInformation.quoteTerms')}
                                                    optionsStyle={{
                                                        minWidth: '200px'
                                                    }}
                                                    fullWidth={true}
                                                    disabled={saving}
                                                    value={selectedQuoteTerms ? selectedQuoteTerms : quoteTerms[0].quoteTermsId}
                                                    onSelect={(qt) => this.setState({
                                                        selectedQuoteTerms: qt ? qt.value : null
                                                    })}
                                                />
                                            </Grid>

                                            <Grid item sm={12} md={12} xs={12}
                                                className={classes.preparing__form__gridItem}
                                            >
                                                <CustomSelect options={paymentTerms && paymentTerms.map(pt => ({ label: pt.paymentTermsName, value: pt.paymentTermsId }))}
                                                    label={i18n.t('quotes.preparatingQuoteInformation.paymentTerms')}
                                                    optionsStyle={{
                                                        minWidth: '200px'
                                                    }}
                                                    fullWidth={true}
                                                    disabled={saving}
                                                    value={selectedPaymentTerms ? selectedPaymentTerms : paymentTerms[0].paymentTermsId}
                                                    onSelect={(paymentTerm) => this.setState({
                                                        selectedPaymentTerms: paymentTerm ? paymentTerm.value : null
                                                    })}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    autoComplete='off'
                                                    variant="outlined"
                                                    id="client_quote_payment_schedules"
                                                    style={{ margin: '8px 0', width: '100%' }}
                                                    label={i18n.t('quotes.preparatingQuoteInformation.paymentSchedule')}
                                                    name="paymentSchedules"
                                                    className={classes.preparing__form__textField}
                                                    onChange={event => {
                                                        this.setState({
                                                            quotePaymentSchedules: event.target.value
                                                        });
                                                    }}
                                                    multiline
                                                    rowsMax='3'
                                                    rows='1'
                                                    value={quotePaymentSchedules ? quotePaymentSchedules : ''}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Grid container item spacing={2} style={{ marginLeft: 0, marginTop: 0 }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <TextField
                                                    autoComplete='off'
                                                    id="quote-comments-multiline"
                                                    label={i18n.t('quotes.preparatingQuoteInformation.quoteComments')}
                                                    className={classes.preparing__form__textField}
                                                    value={quotedComments}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            quotedComments: e.target.value
                                                        })
                                                    }}
                                                    multiline
                                                    rowsMax='10'
                                                    rows='3'
                                                    fullWidth
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={saving}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <TextField required
                                            autoComplete='off'
                                            variant="outlined"
                                            id="client_quote_validFor"
                                            label={i18n.t('quotes.preparatingQuoteInformation.validFor')}
                                            name="validFor"
                                            style={{ margin: '9px 5px', maxWidth: '100px' }}
                                            error={validateForm && !validFor}
                                            className={classes.preparing__form__textField}
                                            onChange={event => {
                                                this.setState({
                                                    validFor: event.target.value
                                                });
                                            }}
                                            value={validFor ? validFor : ''}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end" >
                                                    {i18n.t('quotes.vendorQuoteRequest.itemsTable.days')}
                                                </InputAdornment>,
                                                inputComponent: NumberFormatInputCustom,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />

                                        <div style={{ display: 'flex', alignItems: 'center', padding: '0' }}>
                                            <TextField required
                                                autoComplete='off'
                                                variant="outlined"
                                                error={validateForm && !deliveryDays}
                                                id="client_quote_delivery_days"
                                                style={{ margin: '9px 5px', maxWidth: '100px' }}
                                                label={i18n.t('quotes.preparatingQuoteInformation.quotedDeliveryDays')}
                                                name="deliveryDays"
                                                className={classes.preparing__form__textField}
                                                onChange={event => {
                                                    this.setState({
                                                        deliveryDays: event.target.value
                                                    });
                                                }}
                                                value={deliveryDays ? deliveryDays : ''}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end" >
                                                        {i18n.t('quotes.vendorQuoteRequest.itemsTable.days')}
                                                    </InputAdornment>,
                                                    inputComponent: NumberFormatInputCustom,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />

                                            {/* Referencial vendor estimated Delivery Days*/}

                                            <ClickAwayListener onClickAway={() => {
                                                this.setState({
                                                    openTooltip: false
                                                })
                                            }}>
                                                <DeliveryDaysTooltip
                                                    onClose={() => {
                                                        this.setState({
                                                            openTooltip: false
                                                        })
                                                    }}
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    open={openTooltip}
                                                    placement="bottom-end"
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={
                                                        <div>
                                                            {estimatedDeliveryDays.length > 0 && parseInt(deliveryDays) < estimatedDeliveryDays.reduce((max, elem) => { return (parseInt(elem.deliveryDays) > max) ? parseInt(elem.deliveryDays) : max }, 0) &&
                                                                <div style={{ display: 'flex' }}>
                                                                    <Warning className={classes.vendor__warning__icon} />
                                                                    <Typography variant='body2' style={{ fontSize: '13px', marginBottom: '8px' }}>
                                                                        {`${i18n.t('quotes.preparatingQuoteInformation.clientDaysMenorThatVendorDays')} `}
                                                                    </Typography>
                                                                </div>
                                                            }

                                                            <Typography variant='body2' style={{ fontSize: '14px' }}>
                                                                {`${i18n.t('quotes.preparatingQuoteInformation.estimatedDeliveryDays')} `}
                                                            </Typography>

                                                            {estimatedDeliveryDays.length !== 0 ? estimatedDeliveryDays.map((item, i) => {
                                                                return (
                                                                    <div key={i}>
                                                                        <div style={{ fontSize: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                                            <Typography variant='body2' color='secondary' style={{ fontSize: '13px', width: '140px' }} noWrap>
                                                                                {item.vendor}
                                                                            </Typography>
                                                                            <Typography variant='body2' color='secondary' style={{ fontSize: '13px' }}>
                                                                                {`${item.deliveryDays}  ${i18n.t('quotes.preparatingQuoteInformation.days')} `}
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }) :
                                                                <Typography variant='body2' style={{ fontSize: '14px' }}>
                                                                    {`${i18n.t('quotes.preparatingQuoteInformation.noVendorsSelected')} `}
                                                                </Typography>
                                                            }
                                                        </div>
                                                    }
                                                >
                                                    <Info className={classes.deliveryDaysTooltip__icon} onClick={() => {
                                                        this.setState({
                                                            openTooltip: !this.state.openTooltip
                                                        })
                                                    }} style={estimatedDeliveryDays.length > 0 && parseInt(deliveryDays) < estimatedDeliveryDays.reduce((max, elem) => { return (parseInt(elem.deliveryDays) > max) ? parseInt(elem.deliveryDays) : max }, 0) ? { fill: 'orange' } : {}} />
                                                </DeliveryDaysTooltip>
                                            </ClickAwayListener>
                                        </div>
                                   

                                        <FormGroup row>
                                            <FormControlLabel
                                                style={{marginLeft: '6px'}}
                                                labelPlacement="start"
                                                label={i18n.t('quotes.preparatingQuoteInformation.taxes')}
                                                control={
                                                    <Checkbox
                                                        checked={applyTax}
                                                        style={{padding:'0', marginLeft: '5px'}}
                                                        onChange={this.handleChange('applyTax')}
                                                    />
                                                }
                                            />
                                        </FormGroup>        

                                        <TextField
                                            autoComplete='off'
                                            variant="outlined"
                                            id="client_quote_tax"
                                            disabled={!applyTax}
                                            name="tax"
                                            style={{ margin: '9px 5px', maxWidth: '100px' }}
                                            error={applyTax && !taxPercent}
                                            className={classes.preparing__form__textField}
                                            onChange={event => {
                                                this.setState({
                                                    taxPercent: event.target.value
                                                });
                                            }}
                                            value={taxPercent ? taxPercent : ''}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end" >
                                                    {i18n.t('quotes.preparatingQuoteInformation.percent')}
                                                </InputAdornment>,
                                                inputComponent: NumberFormatInputCustom,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>


                                <Grid container spacing={2} style={{ marginTop: '8px' }}>
                                    <Grid item xs={12} sm={12} md={4}/>

                                    <Grid item xs={12} sm={12} md={8}>
                                        {/* OTHER COSTS */}
                                        <OtherCosts
                                            fileId={file && file.id ? file.id : ''}
                                            showVendorOtherCostsTooltip={true}
                                            vendorQuoteOtherCost={vendorQuoteOtherCost}
                                            currencySymbol={file.currencySymbol}
                                            quoteOtherCosts={quoteOtherCosts}
                                            items={items}
                                            isClientQuote={true}
                                            updateItems={this.updateItems}
                                            updateOtherCosts={newOtherCosts => this.setState({ quoteOtherCosts: newOtherCosts })}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} style={{ marginTop: '8px', flexDirection: 'column' }}>
                                    {/* List of items */}
                                    <ClientQuoteItemsList
                                        currencySymbol={file.currencySymbol}
                                        items={items.sort((a, b) => {return a.rfqItem.id - b.rfqItem.id})}
                                        editable={true}
                                        file={file}
                                        quoteOtherCosts={quoteOtherCosts}
                                        applyTax={applyTax}
                                        clientQuoteTaxPercent={taxPercent}
                                        updateClientQuote={this.props.updateClientQuote}
                                        updateItems={this.updateItems}
                                        warningVendorPOMessage={()=>{
                                            this.setState({
                                                showWarningMessage: true
                                            })
                                        }}
                                    />
                                </Grid>

                                <QuoteTotals
                                    currencySymbol={file.currencySymbol}
                                    items={items}
                                    taxPercent={taxPercent}
                                    otherCosts={quoteOtherCosts ? quoteOtherCosts : null} />

                                {showWarningMessage && file.status > 4 &&
                                    <div style={{ display: 'flex', position: 'absolute', margin: '16px 0', right: '8px' }}>
                                        <Warning className={classes.warning__message__icon} />
                                        <Typography>{i18n.t('quotes.preparatingQuoteInformation.warningVendorsChanges')}</Typography>
                                    </div>
                                }

                            </div>

                            <CustomMessages
                                type={'error'}
                                message={apiErrorMessage}
                                clearMessage={() => this.setState({
                                    apiErrorMessage: null
                                })}
                            />

                        </DialogContent>
                        <DialogActions style={{ zIndex: '2' }}>
                            <Button
                                color="secondary"
                                variant="outlined"
                                disabled={saving}
                                onClick={this.props.onClose}>
                                {i18n.t("quotes.preparatingQuoteInformation.cancelBtn")}
                            </Button>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'relative' }}>
                                {showRequiredFieldsError && (
                                    <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex', marginRight: '8px', position: 'absolute', right: 0, top: -15 }}>
                                        <Typography color='error'>{i18n.t("quotes.quoteRequest.requiredFields")}</Typography>
                                    </Grid>
                                )}
                                <Button
                                    disabled={saving}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.editClientQuote}
                                    className={classes.preparing__form__saveButton}>
                                    {i18n.t('quotes.preparatingQuoteInformation.save')}
                                    {saving && <CircularProgress className={classes.buttonSpinner} color='secondary' size={24} />}
                                </Button>
                            </div>

                        </DialogActions>
                    </Dialog>

                    <NoteEditDocuments
                        open={showAddNote}
                        closeNote={this.closeNote}
                        fileId={file ? file.id : null}
                        message={i18n.t('notes.clientQuoteEdit')}
                        title={i18n.t('notes.clientQuoteEditTitle')}
                        onSuccess={this.savePreparingQuote}
                    />

                </>}
            </GlobalParametersContext.Consumer>
        )
    }
}

QuoteForm.contextType = GlobalParametersContext;

export default withStyles(styles)(QuoteForm);