import React, { Component } from 'react'
import {
    withStyles,
    Typography,
    Grid,
} from '@material-ui/core'
import i18n from 'i18next'


const styles = theme => {
    return {
        clientrfq__hader__container: {
            padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
            position: 'relative'
        },
        clientrfq__hader__container__clientName__container: {
            overflow: 'hidden'
        },
        clientrfq__hader__container__clientName__label: {
            textAlign: 'left',
            color: '#000',
            [theme.breakpoints.down('sm')]: {
                marginRight: theme.spacing(4.5)
            }

        },
        clientrfq__hader__container__salesperson__date: {
            marginLeft: 0,
            marginRight: theme.spacing()
        },
        clientrfq__details__info: {
            padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
        },
        rfq__subtitle__divider: {
            width: '80%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            }
        },
        customLink: theme.customLink,
        rfq__icon: {
            fontSize: '11pt',
            marginRight: '5px',
            marginBottom: '-3px'
        },
        rfq__icon__email: {
            fontSize: '11pt',
            marginRight: '5px',
            marginBottom: '-5px',
            color: theme.palette.blue[100]
        },
        rfq__editLink: theme.customLink,
        oportunity__probability__label: {
            border: `solid thin ${theme.palette.secondary.main}`,
            fontSize: '8pt',
            borderRadius: theme.spacing() / 2,
            padding: theme.spacing() / 2,
            color: theme.palette.text.secondary,
            textAlign: 'center',
            width: '80px',
            lineHeight: 1
        },
        'oportunity__probability__label--unknow': {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.getContrastText(theme.palette.grey[100])
        },
        'oportunity__probability__label--likely': {
            backgroundColor: theme.palette.seabook.main,
            color: theme.palette.getContrastText(theme.palette.seabook.main)
        },
        'oportunity__probability__label--verylikely': {
            backgroundColor: theme.palette.green[100],
            color: theme.palette.getContrastText(theme.palette.green[100])
        },
        printButton: {
            ...theme.smallButton,
            backgroundColor: theme.palette.grey[100],
            '&:hover': {
                backgroundColor: theme.palette.grey[400],
                color: theme.palette.getContrastText(theme.palette.grey[400]),
            },
            color: theme.palette.getContrastText(theme.palette.grey[100]),
            [theme.breakpoints.up('sm')]: {
                marginRight: theme.spacing(2),
            }
        }
    }
};


class InvoiceHeader extends Component {
    state = {
        fileId: this.props.fileId
    }

    render = () => {
        const { classes, file } = this.props;

        if (!file) return null

        return (
            <div>
                <Grid container
                    justify="flex-start"
                    alignItems='baseline'
                    spacing={1}
                    className={classes.clientrfq__hader__container}>

                    <Grid item xs={12}>
                        <Typography
                            variant='h6'
                            className={classes.clientrfq__hader__container__clientName__label}
                        > {file.client.name}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <Typography variant='body2' color="textSecondary" noWrap>
                            {file.client.address.address1}
                            {file.client.address.address2 && (
                                <>
                                    <br />
                                    {file.client.address.address2}
                                </>
                            )}
                            <br />
                            <span><strong>{`${i18n.t('quotes.invoices.header.phone')} `}</strong>{file.client.phone}</span>
                            {file.client.fax && (
                                <>
                                    <br />
                                    <strong>{`${i18n.t('quotes.invoices.header.fax')} `}</strong>{file.client.fax}
                                </>
                            )}
                            <br />
                            <span>{file.client.contact.firstName} {file.client.contact.lastName}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Typography variant='body2' color="textSecondary" noWrap>
                            <strong>{`${i18n.t('quotes.invoices.header.file')} `}</strong>{file.id}

                            {file.requestForQuote.reference && (<>
                                <br />
                                <strong>{`${i18n.t('quotes.invoices.header.references')} `}</strong>{file.requestForQuote.reference}
                            </>)}
                            {file.paymentTerms.name && (
                                <>
                                    <br />
                                    <strong>{`${i18n.t('quotes.invoices.header.paymentTerms')} `}</strong>{file.paymentTerms.name}
                                </>
                            )}

                            {file.quoteTerms.name && (
                                <>
                                    <br />
                                    <strong>{`${i18n.t('quotes.invoices.header.quoteTerms')} `}</strong>{`${file.quoteTerms.name}. ${file.requestForQuote.quoteTermsReference ? file.requestForQuote.quoteTermsReference : ''}`}
                                </>
                            )}

                        </Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(InvoiceHeader);