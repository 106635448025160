import React, { Component } from 'react'
import i18n from 'i18next'
import mobiscroll from "@mobiscroll/react";
import {
    Dialog,
    DialogTitle,
    Typography,
    IconButton,
    DialogContent,
    Button,
    Snackbar,
    Grid,
    withMobileDialog,
    withStyles,
} from '@material-ui/core'
import {
    KeyboardArrowRight,
    KeyboardArrowLeft,
    Warning,
    Edit,
    Room,
    Check,
    Cancel,
    Schedule,
    Assignment,
    MoreVert,
    Close as CloseIcon
} from '@material-ui/icons'
import {
    SpeedDial,
    SpeedDialIcon,
    SpeedDialAction
} from '@material-ui/lab';
import moment from 'moment'
import Geocode from 'react-geocode';
import { geolocated } from "react-geolocated";
import { EventFormSub1, EventForm } from '.'
import { SlideLeft } from '../general/Transitions'
import { ConfirmationDialog } from '../general'
import config from '../../config';
import API from '../../lib/api';
import {
    eventIsInValidCheckinTime,
    eventCanCheckIn
} from '../../lib/utils'

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as eventsActions from '../../actions/eventsActions'

const GOOGLE_API_KEY = config.google.mapsAPIKey;

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.enableDebug();


mobiscroll.settings = {
    lang: 'en',
    theme: 'ios',
    display: 'bubble'
};

const styles = theme => ({
    userColorElement: {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        marginRight: '8px'
    },
    events__form__title: {
        padding: '0 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#ccc',
        color: theme.palette.getContrastText('#ccc')
    },
    events__form__title__back: {
        fontSize: '30px'
    },
    formGroup__content: {
        background: '#FFF',
        padding: '16px',
        borderBottom: '1px solid #ccc',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    formGroup__content__delete: {
        background: '#FFF',
        padding: 0,
        borderBottom: '1px solid #ccc',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    formGroup__content__clickeableSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    formGroup__userTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px'
    },
    formGroup__title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    event__optionList: {
        background: '#efeff4',
        borderBottom: 'none',
        borderTop: 'none'
    },
    event__editButton: {
        minWidth: '52px',
        padding: '0',
        margin: '0',
        background: "#fff",
        color: '#007bff'
    },
    event__deleteButton: {
        color: `${theme.palette.error.main} !important`
    },
    warning__message__icon: {
        marginRight: theme.spacing(1),
        fontSize: '14pt',
        color: theme.palette.orange[100]
    },
    events__editButton: {
        textDecoration: 'underline'
    },
    speedDial: {
        position: 'absolute',
        '&.MuiSpeedDial-directionDown': {
            top: theme.spacing(1),
            right: 0
        }
    },
    speedDial__fab: {
        width: theme.spacing(5) - 5,
        height: theme.spacing(5) - 5,
        color: '#fff',
        backgroundColor: '#3f97f6',
        '&.MuiFab-primary:hover': {
            backgroundColor: '#3f97f6',
        }
    },
    speedDial__tooltip: {
        // width: 'fit-content',
        marginBottom: '8px'
    },
    event__overlay: {
        position: 'fixed',
        display: 'none',
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: '3',
        cursor: 'pointer',
        '-webkit-transform': 'translateZ(0)'
    },
    form: {
        height: '100%'
    },
    action__disabled: {
        cursor: 'default',
        pointerEvents: 'none'
    },
    alert__paper: {
        maxWidth: 350,
        width: '100%',
        margin: '0 5px'
    },
    event__missing__visit__container: {
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    event__missing__visit__indicator: {
        color: theme.palette.red[80],
        marginRight: 10
    },
    dialog__paper: {
        width: '100%'
    },
});



class EventDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checkingIn: false,
            checkingOut: false,
            formType: null,
            openEventForm: false,
            openSubListForm: false,
            checkMessage: null,
            kmReference: 0,
            user: null,
            confirmCancelEvent: false,
            openActions: false,
            checkIn: props.event && props.event.id ? props.event.checkIn : null,
            checkOut: props.event && props.event.id ? props.event.checkOut : null,
            showCheckInPopup: false,
            snackNotificationMessage: '',
            openSnackbar: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.logedInUser && !this.state.user) {
            API.Users.getUser({ userId: nextProps.logedInUser }).then(respond => {
                this.setState({
                    user: respond.data.records
                })
            })
        }

        this.setState({
            checkIn: nextProps.event && nextProps.event.id ? nextProps.event.checkIn : null,
            checkOut: nextProps.event && nextProps.event.id ? nextProps.event.checkOut : null,
        })
    }

    handleCloseSnackbar = () => {
        this.setState({
            openSnackbar: false
        })
    }
    inputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    showSubFormPopUp = (type) => {
        this.setState({
            formType: type
        }, () => {
            this.setState({
                openSubListForm: true
            })
        })
    }

    onFormClose = () => {
        this.setState({
            formType: null,
            openEventForm: false,
            openSubListForm: false,
            openActions: false
        })
    }

    manageEvent = (status) => {
        const { checkIn, checkOut, checkingIn,
            checkingOut } = this.state,
            { event } = this.props;

        let body = {
            ...event,
            clientId: event.client.id,
            clientContactId: event.clientContact.id,
            isPrivate: event.private,
            place: event.place && event.place.address ? event.place.address : null,
            googleAddress: event.place && event.place.googleAddress ? event.place.googleAddress : null,
            placeLat: event.place && event.place.lat ? event.place.lat : null,
            placeLng: event.place && event.place.lng ? event.place.lng : null,
            checkInAddress: checkIn ? checkIn.address : null,
            checkInLat: checkIn ? checkIn.lat : null,
            checkInLng: checkIn ? checkIn.lng : null,
            checkInDateTime: checkIn ? checkIn.dateTime : null,
            checkOutAddress: checkOut ? checkOut.address : null,
            checkOutLat: checkOut ? checkOut.lat : null,
            checkOutLng: checkOut ? checkOut.lng : null,
            checkOutDateTime: checkOut ? checkOut.dateTime : null,
            visit: {
                ...event.visit,
                status: status ? status : 1
            }
        }

        API.Events.updateEvent({ event: body }).then(res => {
            if (res.data) {
                this.props.eventsActions.updateEvent(res.data.record)
                if (checkingIn || checkingOut) {
                    this.setState({
                        snackNotificationMessage: checkingIn ? `Check In: ${moment(checkIn.dateTime).utc().format('hh:mm a')}` : `Check Out: ${moment(checkOut.dateTime).utc().format('hh:mm a')}`,
                        openSnackbar: true
                    })
                }
            } else {
                this.setState({
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
            this.setState({
                checkingIn: false,
                confirmCancelEvent: false,
                checkingOut: false
            })
        })
    }

    rad = (x) => {
        return x * Math.PI / 180
    }

    getDistance = (lat1, lon1, lat2, lon2) => {
        var R = 6378.137; //Radio de la tierra en km
        var dLat = this.rad(lat2 - lat1);
        var dLong = this.rad(lon2 - lon1);
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.rad(lat1)) * Math.cos(this.rad(lat2)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d.toFixed(3);
    }

    calculateCheckIn = () => {
        const { isGeolocationAvailable,
            isGeolocationEnabled, event,
            coords, geoLocalization } = this.props
        let placeLat = geoLocalization ? geoLocalization.geometry.location.lat : event && event.place && event.place.lat ? event.place.lat : null,
            placeLng = geoLocalization ? geoLocalization.geometry.location.lng : event && event.place && event.place.lng ? event.place.lng : null;

        let message = null

        if (!isGeolocationAvailable) {
            message = i18n.t('visits.eventDetails.noGeolocationAvailable')
        }

        if (!isGeolocationEnabled) (
            message = i18n.t('visits.eventDetails.noGeolocationEnabled')
        )

        if (message) {
            this.setState({
                checkMessage: message,
                openActions: false,
                showCheckInPopup: true,
            }, () => {
                this.setState({
                    showCheckInPopup: true,
                })
                // this.refs.checkInPopUp.instance.show();
            })
            return
        }

        if (coords) {
            let km = this.getDistance(coords.latitude, coords.longitude, placeLat, placeLng)

            // if (moment.utc(event.from).diff(moment(new Date()), 'hours') > 24 || km > 10) {
            if (!eventCanCheckIn({ event }) || km > 10) {
                this.setState({
                    kmReference: km,
                    checkIn: {
                        lat: coords.latitude,
                        lng: coords.longitude
                    },
                    openActions: false
                }, () => {
                    this.setState({
                        showCheckInPopup: true,
                    })
                    // this.refs.checkInPopUp.instance.show();
                })
            } else {
                Geocode.fromLatLng(coords.latitude, coords.longitude).then(
                    response => {
                        if (response.status === 'OK') {
                            const address = response.results[0].formatted_address;

                            this.setState({
                                checkingIn: true,
                                checkIn: {
                                    address: address,
                                    dateTime: moment().format('YYYY-MM-DDTHH:mm:ss') + 'Z',//new Date(),
                                    lat: coords.latitude,
                                    lng: coords.longitude
                                }
                            })

                            this.manageEvent(1)

                        } else {
                            this.setState({
                                checkIn: {
                                    address: null,
                                    lat: coords.latitude,
                                    lng: coords.longitude
                                }
                            })
                        }
                    },
                    error => {
                        this.setState({
                            checkIn: {
                                address: null,
                                lat: coords.latitude,
                                lng: coords.longitude
                            }
                        })
                    }
                );
            }
        }
    }

    setCheckOut = () => {
        const { isGeolocationAvailable, isGeolocationEnabled, coords } = this.props;

        let message = null
        if (!isGeolocationAvailable) {
            message = i18n.t('visits.form.noGeolocationAvailable')
        }

        if (!isGeolocationEnabled) (
            message = i18n.t('visits.form.noGeolocationEnabled')
        )

        if (message) {
            this.setState({
                checkMessage: message
            })
            return
        }

        if (coords) {
            Geocode.fromLatLng(coords.latitude, coords.longitude).then(
                response => {
                    if (response.status === 'OK') {
                        const address = response.results[0].formatted_address;

                        this.setState({
                            checkingOut: true,
                            checkOut: {
                                address: address,
                                dateTime: moment().format('YYYY-MM-DDTHH:mm:ss') + 'Z',//new Date(),
                                lat: coords.latitude,
                                lng: coords.longitude
                            }
                        }, () => {
                            this.manageEvent(2)
                        })
                    } else {
                        this.setState({
                            checkOut: {
                                address: null,
                                lat: coords.latitude,
                                lng: coords.longitude
                            }
                        })
                    }
                },
                error => {
                    this.setState({
                        checkOut: {
                            address: null,
                            lat: coords.latitude,
                            lng: coords.longitude
                        }
                    })
                }
            );
        }
    }

    deleteEvent = () => {
        const { event } = this.props

        API.Events.deleteEvent({ eventId: event.id }).then(res => {
            if (res.success) {
                this.props.eventsActions.deleteEvent(event)
                this.refs.deleteEventConfirmation.instance.hide();
                this.props.onClose()
            } else {
                this.setState({
                    apiErrorMessage: `${i18n.t('apiErrorMessage')} ${res.message ? res.message : ''}`
                })
            }
        })
    }

    showdeleteConfirmation = () => {
        this.refs.deleteEventConfirmation.instance.show();
    }

    handleOpen = () => {
        this.setState({
            openActions: true
        })
    };

    handleClose = () => {
        this.setState({
            openActions: false
        })
    };

    handleActions = (action) => {
        this.handleClose()

        switch (action) {
            case 'Edit':
                this.setState({
                    openEventForm: true
                })
                break;
            case 'Check In':
                this.calculateCheckIn()
                break;
            case 'Check Out':
                this.setCheckOut()
                break;
            case 'Visit Report':
                this.setState({
                    formType: 'visitReport'
                }, () => {
                    this.setState({
                        openSubListForm: true
                    })
                })
                break;
            case 'Cancel Event':
                this.setState({
                    confirmCancelEvent: true
                })
                break
            case 'Reschedule':
                this.manageEvent(1)
                break
            default:
                this.setState({
                    openEventForm: true
                })
                break;
        }
    }

    getActions = () => {
        const { checkIn, checkOut } = this.state,
            { event } = this.props;

        let newActions = []


        if(event.isVisit && event.visit.status.id === 3){
            newActions = [
                { icon: <Schedule />, name: i18n.t("visits.eventDetails.reSchedule") }
            ];
        } else {
            if (!event.isVisit) {
                return [{ icon: <Edit />, name: i18n.t("visits.eventDetails.editButton") }]
            }


            if (eventIsInValidCheckinTime({ event })) {
                return [
                    { icon: <Edit />, name: i18n.t("visits.eventDetails.editButton") },
                    // { icon: <Assignment />, name: i18n.t("visits.eventDetails.visitReport"), disabled: false },
                    { icon: <Cancel />, name: i18n.t("visits.eventDetails.cancel") }
                ];
            } else {
                if (checkIn.address && checkOut.address) {
                    newActions = [
                        { icon: <Edit />, name: i18n.t("visits.eventDetails.editButton") },
                        { icon: <Assignment />, name: i18n.t("visits.eventDetails.visitReport"), disabled: !checkIn || !checkIn.address ? true : false }
                    ];
                } else {
                    if (!checkIn.address) {
                        newActions = [
                            { icon: <Edit />, name: i18n.t("visits.eventDetails.editButton") },
                            { icon: <Room />, name: i18n.t("visits.eventDetails.checkIn") }, 
                            { icon: <Cancel />, name: i18n.t("visits.eventDetails.cancel") }
                        ];
                    }

                    if (!checkOut.address && checkIn.address) {
                        newActions = [
                            { icon: <Edit />, name: i18n.t("visits.eventDetails.editButton") },
                            { icon: <Check />, name: i18n.t("visits.eventDetails.checkOut"), disabled: !checkIn || !checkIn.address ? true : false },
                            { icon: <Assignment />, name: i18n.t("visits.eventDetails.visitReport"), disabled: !checkIn || !checkIn.address ? true : false }
                        ];
                    }
                }
            }
        }

        return newActions

    }

    render() {
        const { formType,
            openSubListForm,
            checkMessage,
            user,
            openActions,
            openEventForm,
            kmReference,
            checkIn,
            checkOut,
            checkingIn,
            openSnackbar,
            checkingOut,
            snackNotificationMessage,
            confirmCancelEvent
        } = this.state,
            {
                fullScreen,
                classes,
                logedInUser,
                event
            } = this.props;

        if (!event || !event.id) return null

        const actions = this.getActions();
        let isOwn = logedInUser === parseInt(event.user.id)
        return (
            <div style={{
                height: '100%'
            }}>
                <Dialog
                    classes={{
                        paper: classes.dialog__paper
                    }}
                    scroll='paper'
                    fullScreen={fullScreen}
                    open={this.props.open}
                    TransitionComponent={SlideLeft}
                >
                    <div style={{
                        height: '100%'
                    }}>
                        <DialogTitle disableTypography={true} className={classes.events__form__title}>
                            <IconButton onClick={this.props.onClose}>
                                <KeyboardArrowLeft color='inherit' className={classes.events__form__title__back} />
                            </IconButton>
                            <Typography variant='body1' color="inherit">
                                {i18n.t('visits.eventDetails.title')}
                            </Typography>
                            <div style={{ width: '40px' }} />
                            {isOwn ?
                                <SpeedDial
                                    ariaLabel="Event details options"
                                    color='secondary'
                                    classes={{
                                        root: classes.speedDial,
                                        fab: classes.speedDial__fab
                                    }}
                                    icon={<SpeedDialIcon icon={<MoreVert />} />}
                                    onClose={this.handleClose}
                                    onOpen={this.handleOpen}
                                    open={openActions}
                                    direction='down'
                                >
                                    {actions.map(action => (
                                        <SpeedDialAction
                                            key={action.name}
                                            icon={action.icon}
                                            classes={{
                                                staticTooltipLabel: classes.speedDial__tooltip,
                                            }}
                                            tooltipTitle={<div style={{ display: 'flex', flexDirection: 'column', color: action.name === 'Cancel Event' ? '#DB5461' : 'inherit' }}>
                                                {action.name}
                                                {action.disabled &&
                                                    <Typography variant='body2' color='textSecondary' style={{ display: 'flex', fontSize: '12px' }}>
                                                        <Warning className={classes.warning__message__icon} />
                                                        {i18n.t('visits.eventDetails.checkInFirst')}
                                                    </Typography>
                                                }
                                            </div>}
                                            tooltipOpen
                                            onClick={action.disabled ? () => { } : () => this.handleActions(action.name)}
                                        />
                                    ))}
                                </SpeedDial>
                                :
                                <div style={{ width: '23px' }} />
                            }
                        </DialogTitle>

                        <DialogContent style={{
                            padding: '0',
                            position: 'relative',
                            height: '100%'
                        }}>
                            <div className={classes.event__overlay}
                                style={{ display: openActions ? 'block' : 'none' }}
                            />
                            {event && event.isVisit === 1 && event.visit.status.id === 3 ?
                                <div className={classes.event__missing__visit__container} style={{backgroundColor: ''}}>
                                    <Warning className={classes.event__missing__visit__indicator} />
                                    <Typography variant='body1' color='textSecondary'>
                                        {i18n.t('visits.eventDetails.cancelledEvent')}
                                    </Typography>
                                </div>
                            : <>
                                {event.isVisit === 1 && eventIsInValidCheckinTime({ event: event }) && (!event.checkIn.dateTime || !event.checkOut.dateTime) && (
                                    <div className={classes.event__missing__visit__container}>
                                        <Warning className={classes.event__missing__visit__indicator} />
                                        <Typography variant='body1' color='textSecondary'>
                                            {i18n.t('{{username}} did not {{dothis}} ',
                                                { username: event.user.name, dothis: !event.checkIn.dateTime ? 'check in' : 'check out' })
                                            }
                                        </Typography>
                                    </div>
                                )}
                            </>}
                            <mobiscroll.Form className={classes.form}>
                                <mobiscroll.FormGroup style={event ? { marginTop: '8px' } : {}}>
                                    <mobiscroll.FormGroupTitle>
                                        <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '8px' }}>
                                            <div className={classes.userColorElement} style={{ background: event.user.color }} />
                                            {event.user.name}
                                        </div>
                                    </mobiscroll.FormGroupTitle>

                                    <mobiscroll.FormGroupContent className={classes.formGroup__content}>
                                        <div>
                                            <Typography variant='body1' color='textPrimary'>
                                                {event.title}
                                            </Typography>
                                            <Typography variant='body2' color='textSecondary'>
                                                {`${moment.utc(event.from).format('MMM Do, hh:mm a')} - ${moment.utc(event.to).format('hh:mm a')}`}
                                            </Typography>
                                            <Typography variant='body2' color='textSecondary'>
                                                {event.description || ''}
                                            </Typography>
                                        </div>
                                    </mobiscroll.FormGroupContent>
                                </mobiscroll.FormGroup>

                                <mobiscroll.FormGroup>
                                    {event.isVisit === 1 && event.visit.type &&
                                        <mobiscroll.FormGroupContent className={classes.formGroup__content}>
                                            <Typography variant='body1' color='textPrimary'>
                                                {event.visit.type}
                                            </Typography>
                                        </mobiscroll.FormGroupContent>
                                    }

                                    {event.client.id &&
                                        <mobiscroll.FormGroupContent className={classes.formGroup__content} >
                                            <div className={classes.formGroup__content__clickeableSection} onClick={() => this.showSubFormPopUp('client')}>
                                                <div>
                                                    <Typography variant='body1' color='textPrimary'>
                                                        {event.client.name}
                                                    </Typography>
                                                    {event.clientContact && event.clientContact.id &&
                                                        <Typography variant='body2' color='textSecondary'>
                                                            {`${event.clientContact.firstName || ''} ${event.clientContact.lastName || ''}`}
                                                        </Typography>
                                                    }
                                                </div>
                                                <KeyboardArrowRight style={{ color: '#ccc' }} />
                                            </div>
                                        </mobiscroll.FormGroupContent>
                                    }
                                    {event.place && event.place.address && (
                                        <mobiscroll.FormGroupContent className={classes.formGroup__content} >
                                            <div className={classes.formGroup__content__clickeableSection} onClick={() => this.showSubFormPopUp('location')}>
                                                <Typography variant='body1' color='textPrimary'>
                                                    {event.place.address || ''}
                                                </Typography>
                                                <KeyboardArrowRight style={{ color: '#ccc' }} />
                                            </div>
                                        </mobiscroll.FormGroupContent>
                                    )}
                                </mobiscroll.FormGroup>

                                <mobiscroll.FormGroup>
                                    {event.visit.objectives &&
                                        <mobiscroll.FormGroupContent className={classes.formGroup__content}>
                                            <Typography variant='body1' color='textPrimary' style={{ whiteSpace: 'pre-line' }}>
                                                {event.visit.objectives}
                                            </Typography>
                                        </mobiscroll.FormGroupContent>
                                    }

                                    {event.visit.topics &&
                                        <mobiscroll.FormGroupContent className={classes.formGroup__content}>
                                            <Typography variant='body1' color='textPrimary' style={{ whiteSpace: 'pre-line' }}>
                                                {event.visit.topics}
                                            </Typography>
                                        </mobiscroll.FormGroupContent>
                                    }
                                </mobiscroll.FormGroup>

                                <mobiscroll.FormGroup>
                                    {checkMessage &&
                                        <Grid item xs={12}>
                                            <Typography variant='body2' color='textSecondary' style={{ display: 'flex' }}>
                                                <Warning className={classes.warning__message__icon} />
                                                {checkMessage}
                                            </Typography>
                                        </Grid>
                                    }

                                    {checkIn.address && !checkingIn && (<>
                                        <mobiscroll.FormGroupContent className={classes.formGroup__content} >
                                            <div className={classes.formGroup__content__clickeableSection} onClick={() => this.showSubFormPopUp('visitReport')}>
                                                <Typography variant='body1' color='textPrimary'>
                                                    {i18n.t('visits.eventForm.visitReport')}
                                                </Typography>
                                                <KeyboardArrowRight style={{ color: '#ccc' }} />
                                            </div>
                                        </mobiscroll.FormGroupContent>

                                        <mobiscroll.Form.Label>
                                            {i18n.t('visits.eventDetails.checkIn')}
                                            <input disabled value={moment(checkIn.dateTime).utc().format('hh:mm a')} name='title' />
                                        </mobiscroll.Form.Label>
                                    </>)}


                                    {checkOut.address && !checkingOut &&
                                        <mobiscroll.Form.Label>
                                            {i18n.t('visits.eventDetails.checkOut')}
                                            <input disabled value={moment(checkOut.dateTime).utc().format('hh:mm a')} name='title' />
                                        </mobiscroll.Form.Label>
                                    }
                                </mobiscroll.FormGroup>

                                <mobiscroll.FormGroup>
                                    <mobiscroll.FormGroupContent
                                        className={classes.formGroup__content__delete}>
                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                            <Button
                                                size='small'
                                                className={classes.event__deleteButton}
                                                onClick={this.showdeleteConfirmation}
                                            >
                                                {i18n.t("visits.eventDetails.deleteButton")}
                                            </Button>
                                        </div>
                                    </mobiscroll.FormGroupContent>
                                </mobiscroll.FormGroup>
                            </mobiscroll.Form>
                        </DialogContent>
                    </div>
                </Dialog>

                {/* CHECK IN POPUP  NEW */}
                <Dialog
                    open={this.state.showCheckInPopup}
                    onClose={() => {
                        this.setState({
                            checkMessage: null,
                            kmReference: 0,
                            lat: null,
                            lng: null
                        })
                    }}
                    classes={
                        {
                            paper: classes.alert__paper
                        }
                    }
                >

                    <Grid container spacing={1}
                        style={{ padding: '24px 16px' }}
                    >
                        {checkMessage ?
                            <Grid item xs={12}>
                                <Typography variant='body2' color='textSecondary' style={{ display: 'flex' }}>
                                    <Warning className={classes.warning__message__icon} />
                                    {checkMessage}
                                </Typography>
                            </Grid>
                            : <>
                                <Grid item xs={12} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    {!eventCanCheckIn({ event }) ?//moment.utc(event.from).diff(moment(new Date()), 'hours') > 24 ?
                                        <>
                                            <Warning className={classes.warning__message__icon} />
                                            <Typography variant='body2' color='textSecondary'
                                                style={{ marginLeft: '8px' }}>
                                                {`${i18n.t("visits.eventDetails.noCheckIn")}`}
                                                <br />
                                                {`${!moment.utc(event.from).isSame(moment(), 'day') ? moment.utc(event.from).format('MMM Do hh:mm a') : moment.utc(event.from).format('hh:mm a')}`}
                                            </Typography>
                                        </>
                                        : <>
                                            {kmReference > 10 &&
                                                <>
                                                    <Warning className={classes.warning__message__icon} />
                                                    <Typography variant='body2' color='textSecondary'>
                                                        {`${i18n.t("visits.form.mayorDistance")}`}
                                                    </Typography>
                                                </>
                                            }
                                        </>}
                                </Grid>
                            </>}
                    </Grid>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: 15
                    }}>
                        <Button
                            variant='outlined'
                            style={{ width: 80 }}
                            onClick={() => {
                                this.setState({
                                    showCheckInPopup: false,
                                })
                            }}>Ok</Button>
                    </div>
                </Dialog>



                {/* DELETE EVENT CONFIRMATION POPUP */}
                <mobiscroll.Popup
                    ref="deleteEventConfirmation"
                    display="center"
                    buttons={[
                        {
                            text: i18n.t('visits.eventDetails.deleteButton'),
                            icon: 'delete',
                            handler: this.deleteEvent
                        },
                        'cancel'
                    ]}
                >
                    <div className="mbsc-align-center mbsc-padding">
                        <Typography variant='body2' color='textSecondary' style={{ display: 'flex' }}>
                            <Warning className={classes.warning__message__icon} />
                            {i18n.t('visits.confirmation.deleteEventMessage')}
                        </Typography>
                    </div>
                </mobiscroll.Popup>

                <EventForm
                    open={openEventForm}
                    logedInUser={logedInUser}
                    onClose={this.onFormClose}
                />

                <EventFormSub1
                    open={openSubListForm}
                    type={formType}
                    readOnly={true}
                    user={user}
                    isOwn={isOwn}
                    location={event.place || null}
                    onClose={this.onFormClose}
                />


                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                    message={<span >{snackNotificationMessage}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleCloseSnackbar}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />

                <ConfirmationDialog
                    handleClose={()=>{
                        this.setState({
                            confirmCancelEvent: false
                        })
                    }}
                    // loading={saving}
                    onConfirm={()=>this.manageEvent(3)}
                    message={i18n.t('visits.form.confirmCancelEventMessage')}
                    cancelLabel={i18n.t('visits.form.cancelLabel')}
                    confirmLabel={i18n.t('visits.form.confirmCancelLabel')}
                    open={confirmCancelEvent}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        event: state.event
    }
}

function mapDispatchToProps(dispatch) {
    return {
        eventsActions: bindActionCreators(eventsActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(geolocated()(withMobileDialog()(withStyles(styles)(EventDetails))));
