import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import {
    Typography,
    makeStyles
} from '@material-ui/core/';
import { ManageVendor, ManageVendorContactsPopup } from '../components/vendors'

import { connect } from 'react-redux'
import * as vendorsActions from '../actions/vendorsActions'
import { bindActionCreators } from 'redux'

import i18n from 'i18next'


const useStyles = makeStyles(theme => ({
    container: {
        padding: '20px',
        margin: 'auto',
        maxWidth: '700px',
        position: 'relative'
    },
    subTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    centered: {
        textAlign: 'center'
    },
    table: {
        marginTop: '20px',
        textAlign: 'left',
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily
    },
    activeSelect: {
        fontFamily: theme.typography.fontFamily,
        marginTop: '6px'
    },
    circularProgress: {
        marginTop: '20%'
    },
    complete: {
        color: 'green',
        fontSize: '17px',
    },
    incomplete: {
        color: 'red',
        fontSize: '17px',
    },
}));

const defaultVendor = {
    name: '',
    address: {
        address1: '',
        address2: ''
    },
    phone1: '',
    phone2: '',
    website: '',
    remarks: '',
    id: null
}

function CompaniesVendors(props) {

    const classes = useStyles(),
        [selectedVendor, setSelectedVendor] = useState({}),
        [editVendor, setEditVendor] = useState(false);


    useEffect(() => {
        setSelectedVendor(props.vendor || defaultVendor)

    }, [setSelectedVendor, props.vendor])


    const changeVendor =(vendor) => {
        setSelectedVendor(vendor)

        if (vendor) {
            props.vendorsActions.setVendor(vendor)
        } else {
            props.vendorsActions.setVendor({})
        }
    }

    const editOrCreateVendor=(value)=>{
        setEditVendor(value)
    }

    return (
        <div className={classes.container}>
            <Helmet title={`${i18n.t('companyName')} | ${i18n.t('pageSubTitle.companies')}`} />
            <div className={classes.subTitle}>
                <Typography variant="h5">{i18n.t("companies.titleVendors")}</Typography>
            </div>

            <div style={{marginTop: '30px', textAlign: 'left'}}>
                
                <ManageVendor 
                    selectedVendor={selectedVendor}
                    setVendor={changeVendor}
                    editOrCreateVendor={editOrCreateVendor}
                    fromCompanies={true}
                />

                { selectedVendor && selectedVendor.id && !editVendor &&
                    <ManageVendorContactsPopup
                        vendor={selectedVendor}
                        selectedVendorContact={{}}
                        fromCompanies={true}
                    />
                }

            </div>
        
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        vendorsActions: bindActionCreators(vendorsActions, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        vendor: state.vendor,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CompaniesVendors)
