import React from 'react';
import {
    CircularProgress,
    withStyles,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Grid,
    TextField,
    Typography
} from '@material-ui/core/';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { ClientQuoteAcceptedItems } from '.'
import MomentUtils from '@date-io/moment';
import i18n from 'i18next';


const styles = theme => ({
    buttonSpinner: theme.buttonSpinner,
    button__wrapper: {
        margin: theme.spacing(),
        position: 'relative'
    },
})

const customStyles = {
    control: base => ({
        ...base,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        boxShadow: 'none',

        '&:hover': {
            borderColor: '#000'
        }
    })
}

class ConfirmationAcceptQuote extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedDeliveryDate: props.file.clientQuote.length > 0 ? (new Date()).setDate((new Date()).getDate() + props.file.clientQuote[0].deliveryDays) : new Date(),
            selectedPODate: new Date(),
            poClientNumber: '',
            poComments: '',
            validating: false,
            showRequiredFieldsError: false,
            items: props.file.clientQuote.length > 0 ? props.file.clientQuote[0].items.filter(it => !it.deleted).map((item) => {
                return {
                    acceptedVendorQuoteItem: item.acceptedVendorQuoteItem,
                    rfqItem: item.rfqItem,
                    amount: item.unitAmount,
                    quantity: item.rfqItem.quantity,
                    weightedCost: item.weightedCost,
                    markupPercent: item.markupPercent,
                    clientQuoteItemId: item.id,
                    taxPercent: item.taxPercent,
                    include: true
                }
            }) : []
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.file && nextProps.file.clientQuote.length > 0) {
            this.setState({
                items: nextProps.file.clientQuote[0].items.filter(it => !it.deleted).map((item) => {
                    return {
                        acceptedVendorQuoteItem: item.acceptedVendorQuoteItem,
                        rfqItem: item.rfqItem,
                        amount: item.unitAmount,
                        weightedCost: item.weightedCost,
                        quantity: item.rfqItem.quantity,
                        markupPercent: item.markupPercent,
                        clientQuoteItemId: item.id,
                        taxPercent: item.taxPercent,
                        include: true
                    }
                }),
                selectedDeliveryDate: (new Date()).setDate((new Date()).getDate() + nextProps.file.clientQuote[0].deliveryDays),
            })
        }
    }


    handleDeliveryDateChange = date => {
        this.setState({ selectedDeliveryDate: date });
    }

    handlePODateChange = date => {
        this.setState({
            selectedPODate: date,
            selectedDeliveryDate: (new Date(date)).setDate((new Date(date)).getDate() + this.props.file.clientQuote[0].deliveryDays)
        });
    }

    onConfirm = () => {
        const { selectedDeliveryDate, poClientNumber, poComments, selectedPODate, items } = this.state;

        this.setState({
            validating: true
        })
        if (poClientNumber === '') {
            this.setState({
                showRequiredFieldsError: true
            })
            return
        }

        this.setState({
            validating: false,
            showRequiredFieldsError: false
        })

        let body = {
            estimatedDeliveryDate: new Date(selectedDeliveryDate),
            date: selectedPODate,
            poNumber: poClientNumber,
            comments: poComments,
            poItems: items.map(item => {
                return {
                    clientQuoteItemId: item.clientQuoteItemId,
                    amount: item.amount,
                    quantity: item.quantity,
                    markupPercent: item.markupPercent,
                    taxPercent: item.taxPercent,
                    weightedCost: item.weightedCost,
                    rfqItemId: item.rfqItem.id,
                    deleted: item.include ? false : true
                }
            })
        }

        this.props.onConfirm(body)
        this.setState({
            selectedDeliveryDate: new Date(),
            selectedPODate: new Date(),
            poClientNumber: '',
            poComments: '',
            validating: false,
            showRequiredFieldsError: false
        })

    }

    updatePOItems = (items) => {
        this.setState({
            items
        })
    }


    render() {
        const { selectedDeliveryDate, poClientNumber,
            poComments, validating, selectedPODate,
            showRequiredFieldsError, items } = this.state,
            { classes,
                loading,
                open,
                file
            } = this.props;

        return (
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText>
                        {i18n.t('file.confirmation.acceptFileConfirmation')}
                    </DialogContentText>

                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={11}>
                            <TextField required
                                autoComplete='off'
                                variant="outlined"
                                autoFocus
                                margin="dense"
                                id="POClientNumber"
                                error={validating && poClientNumber === ''}
                                label={i18n.t('quotes.pos.poDetails.poClientNumber')}
                                value={poClientNumber}
                                onChange={(e) => {
                                    this.setState({
                                        poClientNumber: e.target.value
                                    })
                                }}
                                type="text"
                                fullWidth
                            />
                        </Grid>
                        <Grid item sm={4} xs={1} />

                        <Grid item sm={3} xs={12}>
                            <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                <InlineDatePicker
                                    variant="outlined"
                                    format="MMM DD YYYY"
                                    error={validating && !selectedPODate}
                                    label={i18n.t('quotes.pos.poDetails.poDate')}
                                    value={selectedPODate}
                                    style={{ width: '100%', marginTop: '5px' }}
                                    styles={customStyles}
                                    onChange={this.handlePODateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <MuiPickersUtilsProvider utils={MomentUtils} className={classes.pickerProvider}>
                                <InlineDatePicker
                                    variant="outlined"
                                    format="MMM DD YYYY"
                                    error={validating && !selectedDeliveryDate}
                                    label={`${i18n.t('quotes.pos.poDetails.eta')} (+ ${file.clientQuote[0] ? file.clientQuote[0].deliveryDays : 0} ${i18n.t('quotes.pos.poDetails.quoteDeliveryDays')})`}
                                    value={selectedDeliveryDate}
                                    minDate={(new Date(selectedPODate)).setDate((new Date(selectedPODate)).getDate() + (file.clientQuote[0] ? file.clientQuote[0].deliveryDays : 0))}
                                    style={{ width: '100%', marginTop: '5px' }}
                                    styles={customStyles}
                                    onChange={this.handleDeliveryDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item sm={9} xs={12}>
                            <TextField
                                autoComplete='off'
                                variant="outlined"
                                margin="dense"
                                id="POcomment"
                                label={i18n.t('quotes.pos.poDetails.poComments')}
                                value={poComments}
                                onChange={(e) => {
                                    this.setState({
                                        poComments: e.target.value
                                    })
                                }}
                                type="text"
                                fullWidth
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <ClientQuoteAcceptedItems
                                file={file}
                                items={items}
                                updatePOItems={this.updatePOItems}
                                clientQuoteTaxPercent={file.clientQuote.length > 0 && file.clientQuote[0].taxPercent ? file.clientQuote[0].taxPercent : null}
                            />
                        </Grid>
                    </Grid>

                    {showRequiredFieldsError && (
                        <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex', marginRight: '8px' }}>
                            <Typography color='error'>{i18n.t("quotes.quoteRequest.requiredFields")}</Typography>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{ width: '110px' }}
                        size='small'
                        variant="outlined"
                        color="secondary"
                        onClick={this.props.handleClose}>
                        {i18n.t('file.confirmation.cancel')}
                    </Button>
                    <div className={classes.button__wrapper}>
                        <Button
                            disabled={loading}
                            color="primary"
                            variant="contained"
                            onClick={this.onConfirm}>
                            {i18n.t('file.confirmation.acceptFile')}
                        </Button>
                        {loading && <CircularProgress className={classes.buttonSpinner} size={24} />}
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ConfirmationAcceptQuote);