import React, { Component } from 'react'
import {
    Grid,
    withStyles,
    Typography,
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
import i18n from 'i18next';
import Permissions from '../../../lib/permissions'


const styles = theme => {
    return (
        {
            quote__totals__container: {
                marginLeft: theme.spacing(),
                marginRight: theme.spacing(),
            },
            totals__container: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end'
            },
            totals__row: {
                display: 'flex',
                justifyContent: 'space-between',
                width: '200px'
            }
        })
}

class QuoteTotals extends Component {

    state = {
        items: this.props.items.filter(it => !it.deleted),
        otherCosts: this.props.otherCosts
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            otherCosts: nextProps.otherCosts,
            items: nextProps.items.filter(it => !it.deleted)
        })
    }


    render() {
        const { currencySymbol, classes, taxPercent } = this.props,
            { items, otherCosts } = this.state;

        let otherCostTotal = otherCosts && otherCosts.length > 0 ? otherCosts.filter(oc => !oc.weighted).reduce(((total, item) => parseFloat(total) + parseFloat(item.amount)), 0) : 0,
            itemsQuotedTotal = (items.length > 0 ?
                items.reduce(((total, item) =>
                    parseFloat(total) + (item.rfqItem.quantity * (item.unitAmount + ((item.unitAmount * item.markupPercent) / 100) + (item.weightedCost ? item.weightedCost / item.rfqItem.quantity : 0)
                    ))), 0)
                : 0),
            itemsProfileTotal = (items.length > 0 ?
                items.reduce(((total, item) => {
                    return (
                        parseFloat(total) + (item.rfqItem.quantity * (
                            ((item.unitAmount * item.markupPercent) / 100) + (item.weightedCost ? item.weightedCost / item.rfqItem.quantity : 0)
                        ))
                    )
                }), 0) : 0),
            itemsQuotedTaxes = (items.length > 0 && taxPercent ?
                items.filter(item => item.taxPercent).reduce(((total, item) =>
                    parseFloat(total) + ((item.rfqItem.quantity * (item.unitAmount +
                        ((item.unitAmount * item.markupPercent) / 100) + (item.weightedCost ? item.weightedCost / item.rfqItem.quantity : 0)
                    ) * taxPercent) / 100)
                ), 0)
                : 0),
            total = otherCostTotal + itemsQuotedTotal + itemsQuotedTaxes,
            totalProfit = itemsProfileTotal;

        return (
            <div>
                {itemsQuotedTotal > 0 && (
                    <Grid container spacing={0}>
                        <Grid item xs={12} className={classes.quote__totals__container}>
                            <div className={classes.totals__container}>
                                <div className={classes.totals__row}>
                                    <Typography variant='body1' color='textSecondary'>{i18n.t('quotes.totals.otherCosts')} </Typography>
                                    <Typography variant='body1' color='textPrimary' style={{ marginLeft: '5px' }}>
                                        <NumberFormat value={otherCostTotal}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={currencySymbol}
                                            decimalScale={2}
                                            fixedDecimalScale={true} />
                                    </Typography>
                                </div>

                                {Permissions.checkSensitiveInformationPermission() ?
                                    <div className={classes.totals__row}>
                                        <Typography variant='body1' color='textSecondary'>{i18n.t('quotes.totals.quoted')} </Typography>
                                        <Typography variant='body1' color='textPrimary' style={{ marginLeft: '5px' }}>
                                            <NumberFormat value={itemsQuotedTotal - totalProfit}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                decimalScale={2}
                                                fixedDecimalScale={true} />
                                        </Typography>
                                    </div>
                                    :
                                    <div className={classes.totals__row}>
                                        <Typography variant='body1' color='textSecondary'>{i18n.t('quotes.totals.quoted')} </Typography>
                                        <Typography variant='body1' color='textPrimary' style={{ marginLeft: '5px' }}>
                                            <NumberFormat value={itemsQuotedTotal}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                decimalScale={2}
                                                fixedDecimalScale={true} />
                                        </Typography>
                                    </div>
                                }

                                {taxPercent &&
                                    <div className={classes.totals__row}>
                                        <Typography variant='body1' color='textSecondary'>{i18n.t('quotes.totals.taxes')} </Typography>
                                        <Typography variant='body1' color='textPrimary' style={{ marginLeft: '5px' }}>
                                            <NumberFormat value={itemsQuotedTaxes}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                decimalScale={2}
                                                fixedDecimalScale={true} />
                                        </Typography>
                                    </div>
                                }

                                {Permissions.checkSensitiveInformationPermission() &&
                                    <div className={classes.totals__row}>
                                        <Typography variant='body1' color='textSecondary'>{i18n.t('quotes.totals.profit')} </Typography>
                                        <Typography variant='body1' color='textPrimary' style={{ marginLeft: '5px' }}>
                                            <NumberFormat value={totalProfit}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                decimalScale={2}
                                                fixedDecimalScale={true} />
                                        </Typography>
                                    </div>
                                }

                                <div className={classes.totals__row}>
                                    <Typography variant='body1' color='textSecondary'><strong>{i18n.t('quotes.totals.total')}</strong></Typography>
                                    <Typography variant='body1' color='textPrimary' style={{ marginLeft: '5px' }}><strong>
                                        <NumberFormat value={total}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={currencySymbol}
                                            decimalScale={2}
                                            fixedDecimalScale={true} />
                                    </strong></Typography>
                                </div>

                            </div>
                        </Grid>
                    </Grid>
                )}
            </div>
        )
    }
}

export default withStyles(styles)(QuoteTotals);